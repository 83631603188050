import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { appConstants } from 'src/app/shared/constants/constants';

import { Document } from '../../../app-sync.service';
import { DocumentsService } from '../documents.service';
import { UsersService } from 'src/app/pages/users/users.service';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { DocumentExtended } from 'src/app/shared/interfaces/document-extended';

@Component({
  selector: 'app-document-item',
  templateUrl: './document-item.component.html',
  styleUrls: ['./document-item.component.css'],
})
export class DocumentItemComponent implements OnInit {
  @ViewChild('documentViewer', { static: false }) documentViewer:
    | TemplateRef<any>
    | undefined;
  @Input() document: DocumentExtended;
  @Input() masterReference: string = '';
  putWarningBadge: boolean = false;
  warningBadgeMessage: string = '';
  modalTitle: string = '';
  pdfSource: string = '';
  isGettingURL: boolean = false;

  constructor(
    private documentsService: DocumentsService,
    private usersService: UsersService,
    private modalsService: ModalsService
  ) {
    this.document = appConstants.document.initialization;
  }

  ngOnInit(): void {
    this.checkWarning(this.document.expirationDate);
  }

  /**
   * Llama al servicio de descarga de documentos.
   * @param {Document} document Documento a descargar.
   * @return {Promise}
   */
  async onDownloadDocument(document: Document): Promise<void> {
    await this.documentsService.downloadDocument(document);
  }

  /**
   * Abre el modal de visualización de un documento.
   * @param {Document} document Documento a visualizar.
   * @return {Promise}
   */
  async onViewDocument(document: Document): Promise<void> {
    this.isGettingURL = true;
    this.modalTitle = document.name;
    await this.documentsService
      .getDocumentPreSignedURL(document)
      .then(async (): Promise<void> => {
        this.pdfSource = this.documentsService.documentPreSignedURL;
        await this.modalsService.showModal(
          <TemplateRef<any>>this.documentViewer,
          true
        );
      });
  }

  /**
   * Acción al tener renderizado un documento a visualizar.
   */
  pageRendered(): void {
    console.log('Documento cargado.');
    this.isGettingURL = false;
  }

  /**
   * Verifica si un documento tiene alguna alerta de vencimiento.
   * @param {string} expirationDateString Fecha de expiración del documento.
   */
  checkWarning(expirationDateString: string): void {
    let expirationDate = new Date(
      expirationDateString + ' 23:59:59 (Chile Standard Time)'
    );
    expirationDate.setHours(0, 0, 0, 0);

    let today = new Date();
    today.setHours(0, 0, 0, 0);

    let fifteenDaysForward = new Date();
    fifteenDaysForward.setHours(0, 0, 0, 0);
    fifteenDaysForward.setDate(fifteenDaysForward.getDate() + 15);

    if (expirationDate < today) {
      // Documento vencido
      this.putWarningBadge = true;
      this.warningBadgeMessage = 'Vencido';
    } else if (expirationDate <= fifteenDaysForward) {
      // Documento por vencer
      this.putWarningBadge = true;
      this.warningBadgeMessage = 'Por vencer';
    }
  }

  /**
   * Retorna un color dependiendo del estado del documento.
   * @return {string}
   */
  getColor(document: Document): string {
    const business: string = this.usersService.business.value.toUpperCase();
    return this.documentsService.getSatusColor(document, business);
  }

  /**
   * Llama al servicio que verifica si el documento no requiere fecha de
   * emisión ni vencimiento.
   * @param {string} masterValueId ID del tipo de documento en el Maestro.
   * @return {Boolean} Responde a la pregunta ¿Requiere fechas de emisión y vencimiento?
   */
  isDatelessDocument(masterValueId: string): boolean {
    return this.documentsService.isDatelessDocument(masterValueId);
  }
}
