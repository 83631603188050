import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ZenObservable } from 'zen-observable-ts';

import { appConstants } from 'src/app/shared/constants/constants';

import { APIService, Driver } from 'src/app/app-sync.service';
import { DriversService } from '../drivers.service';
import { UsersService } from 'src/app/pages/users/users.service';
import { DataTableDirective } from 'angular-datatables';
import { DocumentsService } from '../../documents/documents.service';
import { MasterService } from '../../master/master.service';

@Component({
  selector: 'app-drivers-list',
  templateUrl: './drivers-list.component.html',
  styleUrls: ['./drivers-list.component.css'],
})
export class DriversListComponent implements OnInit, OnDestroy {
  dtOptions: DataTables.Settings = {};
  isGettingDrivers: boolean = false;
  isAdmin: boolean = false;
  isApprover: boolean = false;
  isViewer: boolean = false;
  isCarrier: boolean = false;
  drivers: Driver[];
  driversAttributes: string[] = [
    'SAP ID',
    'Estado',
    'Nombre y Apellido',
    'RUT',
    'Transportista',
    'Alertas',
    'Bloqueos',
  ];

  //Esto permite crear un filtro de búsqueda personalizado en el datatable
  searchText: string = '';
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;

  private driversChangedSubscription: Subscription = new Subscription();
  private onCreateDriverListener: ZenObservable.Subscription =
    new Subscription();
  private onDeleteDriverListener: ZenObservable.Subscription =
    new Subscription();
  private onUpdateDriverListener: ZenObservable.Subscription =
    new Subscription();

  constructor(
    private api: APIService,
    private driversService: DriversService,
    private router: Router,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private documentService: DocumentsService,
    private masterService: MasterService
  ) {
    this.dtOptions = appConstants.datatables.options;
    this.drivers = this.driversService.getDrivers();
  }

  async ngOnInit(): Promise<void> {
    this.driversChangedSubscription =
      this.driversService.driversChanged.subscribe((drivers: Driver[]) => {
        this.drivers = drivers;
      });

    this.isAdmin = this.usersService.isAdmin;
    this.isApprover = this.usersService.isApprover;
    this.isViewer = this.usersService.isViewer;
    this.isCarrier = this.usersService.isCarrier;

    await this.updateDriversList();

    // Una vez cargada la lista de conductores definimos los filtros para subscripciones
    const subscriptionDriversFilter =
      this.driversService.getDriversFilterForSubscriptions();
    // Subscripción a conductores creados
    this.onCreateDriverListener = this.api
      .OnCreateDriverListener(subscriptionDriversFilter)
      .subscribe((response) => {
        if (response) {
          this.driversService.refreshDrivers();
        }
      });
    // Subscripción a conductores eliminados
    this.onDeleteDriverListener = this.api
      .OnDeleteDriverListener(subscriptionDriversFilter)
      .subscribe((response) => {
        if (response) {
          this.driversService.refreshDrivers();
        }
      });
    // Subscripción a conductores actualizados
    this.onUpdateDriverListener = this.api
      .OnUpdateDriverListener(subscriptionDriversFilter)
      .subscribe((response) => {
        if (response) {
          this.driversService.refreshDrivers();
        }
      });
  }

  /**
   * Navega a al formulario de creación de Conductor.
   */
  onNewDriver(): void {
    this.router
      .navigate(['new'], { relativeTo: this.route })
      .then(() => console.log('navigate to new'));
  }

  /**
   * Llama al método que refresca la lista de Conductores.
   * @return {Promise}
   */
  async onRefresh(): Promise<void> {
    this.searchText = '';
    await this.updateDriversList();
  }

  /**
   * Define el conductor que está seleccionado y navega al detalle del mismo.
   * @param {Driver} driver Conductor seleccionado de la lista.
   */
  onSelectedDriver(driver: Driver): void {
    this.documentService.isRefreshFilter.next(true);
    this.driversService.setSelectedDriver(driver);
    this.router
      .navigate([driver.driverId], { relativeTo: this.route })
      .then(() => console.log(`navigate to ${driver.driverId}`));
  }

  /**
   * Indica si un conductor tiene documentos por vencer.
   * @param {Driver} driver Conductor de la lista.
   * @return {boolean} Boolean que indica si el conductor tiene documentos por vencer.
   */
  hasToExpireDocuments(driver: Driver): boolean {
    const docNumber = driver.documentsToExpire || 0;
    return docNumber > 0;
  }

  /**
   * Indica si un conductor tiene documentos vencidos.
   * @param {Driver} driver Conductor de la lista.
   * @return {boolean} Boolean que indica si el conductor tiene documentos vencidos.
   */
  hasExpiredDocuments(driver: Driver): boolean {
    const docNumber = driver.documentsExpired || 0;
    return docNumber > 0;
  }

  /**
   * Retorna la fuente de imagen a mostrar en el Estado del Conductor.
   * @return {string} ruta a imagen.
   */
  getImageSrc(status: string): string {
    return this.masterService.getImageSrc(status);
  }

  /**
   * Refresca la lista de conductores.
   * @private
   * @return {Promise}
   */
  private async updateDriversList(): Promise<void> {
    this.isGettingDrivers = true;
    await this.driversService.refreshDrivers().then(() => {
      this.isGettingDrivers = false;
    });
  }

  /**
   * Determina, con base al rol del usuario, cuáles botones mostrar.
   * @param {string} button Acción del botón.
   * @return {boolean}
   */
  showButton(button: string): boolean {
    let show: boolean = false;

    if (button === 'create') {
      show = this.isAdmin || this.isCarrier;
    }
    return show;
  }

  /**
   * Determina, con base al rol del usuario, si debe ocultarse una columna de la tabla.
   * @param {string} column Nombre de la columna.
   * @return {Boolean}
   */
  hideColumn(column: string): boolean {
    let hide: boolean = false;

    if (column === 'Transportista') {
      hide = !(this.isAdmin || this.isApprover || this.isViewer);
    }
    return hide;
  }

  /**
   * Aplica a la tabla el filtro ingresado por el usuario.
   */
  applyFilter(): void {
    if (this.dtElement) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api): void => {
        dtInstance.search(this.searchText).draw();
      });
    }
  }

  ngOnDestroy(): void {
    this.driversChangedSubscription.unsubscribe();
    this.onCreateDriverListener.unsubscribe();
    this.onDeleteDriverListener.unsubscribe();
    this.onUpdateDriverListener.unsubscribe();
    console.log('drivers-list.component subscriptions removed.');
  }
}
