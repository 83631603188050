import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../../auth/auth.guard';
import { MasterResolver } from '../master/master.resolver';

import { CisternasComponent } from './cisternas.component';
import { CisternaNoSelectedComponent } from './cisterna-no-selected/cisterna-no-selected.component';
import { CisternaEditComponent } from './cisterna-edit/cisterna-edit.component';
import { CisternaDetailsComponent } from './cisterna-details/cisterna-details.component';

const routes: Routes = [
  {
    path: ':business/cisternas',
    component: CisternasComponent,
    canActivate: [AuthGuard],
    resolve: [MasterResolver],
    children: [
      { path: '', component: CisternaNoSelectedComponent },
      { path: 'new', component: CisternaEditComponent },
      { path: ':id', component: CisternaDetailsComponent },
      { path: ':id/edit', component: CisternaEditComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CisternasRoutingModule {}
