<footer class="rounded-top" style="background-color: #ccd7e1">
  <div class="container-fluid p-2 mt-3">
    <div class="row align-items-center" style="color: #336184; font-size: 13px">
      <div class="col-md-6 text-end">
        © Copyright 2023 Compañia de Petróleos de Chile Copec S.A. Todos los
        derechos reservados.
      </div>
      <div class="col-md-2 text-end">Política de privacidad</div>
      <div class="col-md-2 text-end">Terminos y condiciones</div>
      <div class="col-md-2 text-center">
        <img
          ngSrc="assets/images/logo/ROZ-alto-contraste.png"
          height="35"
          width="100"
          alt="logoCopec"
        />
      </div>
    </div>
  </div>
</footer>
