<div class="scroll-to-top" [ngClass]="{ 'show-scrollTop': windowScrolled }">
  <button
    type="button"
    class="btn active"
    data-toggle="button"
    aria-pressed="true"
    (click)="scrollToTop()"
  >
    <span class="material-symbols-outlined"> arrow_upward </span>
  </button>
</div>
