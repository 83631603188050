import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MasterComponent } from './master.component';
import { MasterPipe } from './master.pipe';

@NgModule({
  declarations: [MasterComponent, MasterPipe],
  imports: [CommonModule],
  exports: [MasterComponent, MasterPipe],
})
export class MasterModule {}
