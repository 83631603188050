<div
  class="container-fluid d-flex flex-column justify-content-center min-vh-100"
  *ngIf="isUploadingDocuments"
>
  <div class="text-center">
    <app-spinner
      message="Subiendo documentos a la nube, por favor espere"
    ></app-spinner>
  </div>
</div>
<div
  class="container-fluid d-flex flex-column min-vh-100"
  *ngIf="!isUploadingDocuments"
>
  <app-header></app-header>
  <div class="row flex-grow-1 mb-5">
    <div class="col-md-12">
      <app-feedbacks></app-feedbacks>
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-footer></app-footer>
</div>
