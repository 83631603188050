import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../../auth/auth.guard';
import { MasterResolver } from '../master/master.resolver';
import { HistorialRTCComponent } from './historial-rtc/historial-rtc.component';
const routes: Routes = [
  {
    path: 'rtc/history',
    component: HistorialRTCComponent,
    canActivate: [AuthGuard],
    resolve: [MasterResolver],
    children: [],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HistorialRtcRoutingModule {}
