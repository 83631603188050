import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sapId',
})
export class SapIdPipe implements PipeTransform {
  /**
   * Transforma el ID SAP a formato string si éste no es igual a "Sin Asignar".
   * @param {string} value ID SAP.
   * @return {string} ID SAP en formato string.
   */
  transform(value: string): string {
    if (value === 'Sin Asignar' || value === '') {
      return value;
    } else {
      return parseInt(value).toString();
    }
  }
}
