import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ZenObservable } from 'zen-observable-ts';

import { APIService, Document } from '../../app-sync.service';
import { DocumentsService } from './documents.service';
import { appConstants } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css'],
})
export class DocumentsComponent implements OnInit, OnDestroy {
  documents: Document[] = [];
  model: string = '';
  isGettingDocuments: boolean = false;
  masterReference: string = '';
  // Se crea variable tipo Document para poder realizar búsqueda por filtros
  filteredDocuments: Document[] = []; // Lista de documentos filtrados
  searchText: string = ''; // Texto ingresado en el input de búsqueda
  status: string = '0';
  statusDocuments = [{ status: '', name: '' }];
  private isGettingDocumentsSubscription: Subscription = new Subscription();
  private documentsChangedSubscription: Subscription = new Subscription();
  private onUpdateDocumentListener: ZenObservable.Subscription =
    new Subscription();
  private isRefreshFilterSubscription: Subscription = new Subscription();

  constructor(
    private api: APIService,
    private documentsService: DocumentsService
  ) {
    this.statusDocuments = [
      {
        status: appConstants.document.codes.toBeApproved,
        name: 'Por aprobar',
      },
      {
        status: appConstants.document.codes.valid,
        name: 'Vigente',
      },
      {
        status: appConstants.document.codes.inProcess,
        name: 'Procesando',
      },
      {
        status: appConstants.document.codes.expired,
        name: 'Vencido',
      },
    ];
  }

  async ngOnInit() {
    this.isRefreshFilterSubscription =
      this.documentsService.isRefreshFilter.subscribe(
        (value: boolean): void => {
          if (value) {
            this.resetValues();
          }
        }
      );
    this.model = this.documentsService.getSelectedModel();
    this.masterReference = `${this.model}S#DOCUMENT_TYPES`;
    this.documentsChangedSubscription =
      this.documentsService.documentsChanged.subscribe(
        (documents: Document[]) => {
          documents.sort(function (a, b) {
            let x = a.name.toLowerCase();
            let y = b.name.toLowerCase();
            if (x < y) {
              return -1;
            }
            if (x > y) {
              return 1;
            }
            return 0;
          });
          this.documents = documents;
          this.filteredDocuments = documents;
        }
      );
    this.isGettingDocumentsSubscription =
      this.documentsService.isGettingDocumentsChanged.subscribe(
        (value: boolean) => {
          this.isGettingDocuments = value;
        }
      );

    await this.updateDocumentsList();

    const subscriptionDocumentsFilter =
      this.documentsService.getSubscriptionDocumentsFilter();
    // Subscripción a Documentos actualizados
    this.onUpdateDocumentListener = this.api
      .OnUpdateDocumentListener(subscriptionDocumentsFilter)
      .subscribe((response) => {
        if (response) {
          this.documentsService.refreshDocuments();
        }
      });
  }

  resetValues() {
    this.documentsService.isRefreshFilter.next(false);
    this.searchText = '';
    this.status = '0';
  }

  // Esta función se llama cada vez que se cambia el estado en el select o se ingresa texto en el input de búsqueda
  applyFilter(): void {
    // Convertir el texto de búsqueda a minúsculas
    const searchTextLower = this.searchText.toLowerCase().trim();
    let statusDocument = this.status;

    // Filtrar la lista de documentos según el texto de búsqueda y el estado seleccionado
    if (searchTextLower === '' && statusDocument === '0') {
      // Si el campo de búsqueda está vacío y se selecciona "Todos", mostrar todos los documentos
      this.documents = this.filteredDocuments;
    } else if (searchTextLower !== '' && statusDocument === '0') {
      // Filtrar la lista de documentos solo por texto de búsqueda
      this.documents = this.filteredDocuments.filter((document) => {
        const nombreLowerCase = document.name.toLowerCase();
        return nombreLowerCase.includes(searchTextLower);
      });
    } else if (searchTextLower === '' && statusDocument !== '0') {
      // Filtrar la lista de documentos solo por estado seleccionado
      this.documents = this.filteredDocuments.filter((document) =>
        document.status.endsWith(statusDocument)
      );
    } else {
      // Filtrar la lista de documentos por texto de búsqueda y estado seleccionado
      this.documents = this.filteredDocuments.filter((document) => {
        const nombreLowerCase = document.name.toLowerCase();
        return (
          nombreLowerCase.includes(searchTextLower) &&
          document.status.endsWith(statusDocument)
        );
      });
    }
  }

  /**
   * Actualiza, mediante el servicio de documentos, la lista de documentos.
   * @return {Promise}
   */
  async updateDocumentsList(): Promise<void> {
    this.isGettingDocuments = true;
    await this.documentsService.refreshDocuments().then(() => {
      this.isGettingDocuments = false;
    });

    this.model = this.documentsService.getSelectedModel();
  }

  ngOnDestroy(): void {
    this.isGettingDocumentsSubscription.unsubscribe();
    this.documentsChangedSubscription.unsubscribe();
    this.onUpdateDocumentListener.unsubscribe();
    this.isRefreshFilterSubscription.unsubscribe();
  }
}
