import { Injectable } from '@angular/core';

import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Driver } from '../../app-sync.service';
import { DriversService } from '../../pages/drivers/drivers.service';
import { DocumentsService } from '../../pages/documents/documents.service';
import { FormValidators } from '../interfaces/form-validators';
import { appConstants } from '../constants/constants';

@Injectable({
  providedIn: 'root',
})
export class ValidatorsService {
  constructor(
    private documentsService: DocumentsService,
    private driversService: DriversService
  ) {}
  /**
   * Retorna una función de validación que verifica que la fecha de emisión
   * de un documento sea ≤ que el día actual.
   * @return {ValidatorFn}
   */
  documentIssueDateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const issueDate = control.value;

      if (!issueDate) {
        return null;
      }

      const today: Date = new Date();
      today.setHours(0, 0, 0, 0);
      const todayTimestamp: number = Math.floor(today.getTime() / 1000);

      const timeZoneOffset: string = (
        today.getTimezoneOffset() / 60
      ).toPrecision(1);
      const issueDateHours: string = `T00:00:00.000-0${timeZoneOffset}:00`;
      const issueDateTimestamp: number = Math.floor(
        Date.parse(issueDate + issueDateHours) / 1000
      );

      let issueDateValid: boolean;

      if (
        this.documentsService.selectedModel === 'DRIVER' &&
        (control.parent?.value.documentMasterValueId.endsWith(
          appConstants.document.codes.speeding_retraining
        ) ||
          control.parent?.value.documentMasterValueId.endsWith(
            appConstants.document.codes.commitment_letter_for_speeding
          ))
      ) {
        // Los documentos por exceso de velocidad deben tener fecha de emisión
        // posterior al bloqueo y ≤ que el día en que se carga el documento.
        const driver: Driver = this.driversService.getSelectedDriver();
        const blockDate: string = (
          driver.lastStatusInformedAt || driver.updatedAt
        ).split('T')[0];
        const blockDateTimestamp: number = Math.floor(
          Date.parse(blockDate + issueDateHours) / 1000
        );
        issueDateValid =
          issueDateTimestamp >= blockDateTimestamp &&
          issueDateTimestamp <= todayTimestamp;
      } else {
        // Fecha de emisión debe ser ≤ que el día en que se carga el documento.
        issueDateValid = issueDateTimestamp <= todayTimestamp;
      }

      return issueDateValid ? null : { issueDateInvalid: true };
    };
  }

  /**
   * Retorna una función de validación que verifica que la fecha de vencimiento
   * de un documento sea > que el día actual.
   * @return {ValidatorFn}
   */
  documentExpirationDateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const expirationDate = control.value;

      if (!expirationDate) {
        return null;
      }

      const today: Date = new Date();
      today.setHours(0, 0, 0, 0);
      const todayTimestamp: number = Math.floor(today.getTime() / 1000);

      const timeZoneOffset: string = (
        today.getTimezoneOffset() / 60
      ).toPrecision(1);
      const expirationDateHours: string = `T00:00:00.000-0${timeZoneOffset}:00`;
      const expirationDateTimestamp: number = Math.floor(
        Date.parse(expirationDate + expirationDateHours) / 1000
      );

      // Fecha de vencimiento debe ser > que el día en que se carga el documento.
      const expirationDateValid: boolean =
        expirationDateTimestamp > todayTimestamp;

      return expirationDateValid ? null : { expirationDateInvalid: true };
    };
  }

  /**
   * Retorna una función de validación que verifica que la edad de un conductor
   * sea mayor o igual a 25 años y menor o igual a 70 años.
   * @return {ValidatorFn}
   */
  driverAgeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const birthDate = control.value;

      if (!birthDate) {
        return null;
      }

      const currentYear: number = new Date().getFullYear();
      const currentMonth: number = new Date().getMonth();
      const birthYear: number = new Date(birthDate).getFullYear();
      const birthMonth: number = new Date(birthDate).getMonth();

      let driverAge: number = currentYear - birthYear;
      // Si aún no llegamos al mes de nacimiento, se resta un año
      if (currentMonth < birthMonth) {
        driverAge -= 1;
      }
      // El conductor debe tener entre 25 y 70 años.
      let ageValid: boolean =
        appConstants.driver.age.min <= driverAge &&
        driverAge <= appConstants.driver.age.max;

      return ageValid ? null : { ageInvalid: true };
    };
  }

  /**
   * Retorna una función de validación que verifica que la extensión
   * de un documento sea PDF.
   * @return {ValidatorFn}
   */
  documentFileTypeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const documentFile = control.value;

      if (!documentFile) {
        return null;
      }

      const fileTypeValid: boolean =
        (<string>documentFile.split('.').pop()).toLowerCase() === 'pdf';

      return fileTypeValid ? null : { fileTypeInvalid: true };
    };
  }

  /**
   * Retorna una función de validación que verifica que al menos
   * uno de los checkboxes en verdadero.
   * @return {ValidatorFn}
   */
  atLeastOneTrueValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let hasOneTrue: boolean = false;

      control.value.models.forEach((model: any) => {
        if (model.wanted) {
          hasOneTrue = true;
        }
      });

      return hasOneTrue ? null : { noneIsTrue: true };
    };
  }

  /**
   * Verifica que un control sea válido en cuanto a si ha sido tocado y si es válido.
   * Se usa para mostrar ayudas en el formulario en caso de errores en la entrada.
   * @param {AbstractControl} control Control del formulario a ser evaluado.
   * @return {Boolean | undefined} Responde a la pregunta ¿Es válido?
   */
  showHelper(control: AbstractControl<any, any> | null): boolean | undefined {
    return control?.invalid && (control?.dirty || control?.touched);
  }

  /**
   * Retorna el texto de ayuda que será mostrado al usuario debido a
   * algún error en el formulario.
   * @param {AbstractControl} control Control del formulario.
   * @return {string}
   */
  helperMessages(control: AbstractControl<any, any> | null): string {
    let helperMessage: string = '';
    if (control?.errors?.['required']) {
      helperMessage += 'Campo requerido. ';
    }
    if (control?.errors?.['issueDateInvalid']) {
      helperMessage += 'Fecha de emisión debe ser menor o igual a la de hoy. ';
      if (
        this.documentsService.selectedModel === 'DRIVER' &&
        (control.parent?.value.documentMasterValueId.endsWith(
          appConstants.document.codes.speeding_retraining
        ) ||
          control.parent?.value.documentMasterValueId.endsWith(
            appConstants.document.codes.commitment_letter_for_speeding
          ))
      ) {
        helperMessage +=
          'Fecha de emisión debe ser mayor o igual a la del último bloqueo.';
      }
    } else if (control?.errors?.['expirationDateInvalid']) {
      helperMessage += 'Fecha de vencimiento debe ser mayor a la de hoy. ';
    } else if (control?.errors?.['fileTypeInvalid']) {
      helperMessage += 'El formato debe ser PDF. ';
    } else if (control?.errors?.['noneIsTrue']) {
      helperMessage += 'Debe seleccionar al menos una opción. ';
    } else if (control?.errors?.['ageInvalid']) {
      helperMessage += 'El conductor debe tener entre 25 y 70 años. ';
    } else if (control?.errors?.['minlength']) {
      helperMessage += `El mínimo de caracteres es ${control?.errors?.['minlength']['requiredLength']}, actualmente tiene ${control?.errors?.['minlength']['actualLength']}. `;
    } else if (control?.errors?.['maxlength']) {
      helperMessage += `El máximo de caracteres es ${control?.errors?.['maxlength']['requiredLength']}, actualmente tiene ${control?.errors?.['maxlength']['actualLength']}. `;
    } else if (control?.errors?.['min']) {
      helperMessage += `El valor mínimo es ${control?.errors?.['min']['min']}. `;
    } else if (control?.errors?.['max']) {
      helperMessage += `El valor máximo es ${control?.errors?.['max']['max']}. `;
    }
    return helperMessage;
  }

  /**
   * Retorna validadores de controles de un documento dependiendo del Centro
   * de distribución y de sí el documento es obligatorio.
   * @param {string} center Centro de Distribución.
   * @param {Boolean} isMandatory Responde a la pregunta ¿Es obligatorio el documento?
   * @return {FormValidators}
   */
  getDocumentValidatorForm(
    center: string | undefined,
    isMandatory: boolean
  ): FormValidators {
    let validators: FormValidators;

    // Si el centro está definido, y el documento no es obligatorio el control no es requerido.
    // Si el centro no está definido o el documento es obligatorio, el control es requerido.
    if (center && !isMandatory) {
      validators = {
        documentMasterValueId: [],
        documentName: [],
        documentIssueDate: [this.documentIssueDateValidator()],
        documentExpirationDate: [this.documentExpirationDateValidator()],
        documentFile: [this.documentFileTypeValidator()],
        documentSourceFile: [],
      };
    } else {
      validators = {
        documentMasterValueId: [Validators.required],
        documentName: [Validators.required],
        documentIssueDate: [
          Validators.required,
          this.documentIssueDateValidator(),
        ],
        documentExpirationDate: [
          Validators.required,
          this.documentExpirationDateValidator(),
        ],
        documentFile: [Validators.required, this.documentFileTypeValidator()],
        documentSourceFile: [Validators.required],
      };
    }
    return validators;
  }
}
