import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ZenObservable } from 'zen-observable-ts';

import { appConstants } from '../../../shared/constants/constants';

import {
  APIService,
  Tanque,
  CreateNotificationInput,
  DeleteTanqueInput,
  UpdateTanqueInput,
  UpdateTanqueMutation,
  UpdateDocumentInput,
} from '../../../app-sync.service';
import { FeedbacksService } from '../../../shared/feedbacks/feedbacks.service';
import { TanquesService } from '../tanques.service';
import { ModalsService } from '../../../shared/modals/modals.service';
import { DocumentsService } from '../../documents/documents.service';
import { UsersService } from '../../users/users.service';
import { EditionsService } from '../../../shared/services/editions.service';
import { ApiRequestsService } from '../../../shared/services/api-requests.service';
import { MasterService } from '../../master/master.service';
import { FormsService } from '../../../shared/services/forms.service';

@Component({
  selector: 'app-tanque-details',
  templateUrl: './tanque-details.component.html',
  styleUrls: ['./tanque-details.component.css'],
})
export class TanqueDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('tanqueModal', { static: false }) tanqueModal:
    | TemplateRef<any>
    | undefined;
  @ViewChild('updateTanqueAttributeModal', { static: false })
  updateTanqueAttributeModal: TemplateRef<any> | undefined;
  currentYear: number = new Date().getFullYear();
  isAdmin: boolean = false;
  isApprover: boolean = false;
  isCarrier: boolean = false;
  isBeingRejected: boolean = false;
  isGettingTanque: boolean = false;
  isUploadingDocuments: boolean = false;
  modalMessage: string = '';
  modalQuestion: string = '';
  modalTitle: string = '';
  modalWithComment: boolean = false;
  commentForm: FormGroup;
  updateAttributeForm: FormGroup;
  tanque: Tanque;
  tanqueNeedsApproval: boolean = false;
  tanqueCanBeRejected: boolean = false;
  tanqueIsCoupled: boolean = false;
  showBlockingMotives: boolean = false;
  coupleType: 'Tracto' = 'Tracto';
  coupleIdentification: string = '';
  coupleStatus: string = '';
  hasComment: boolean = false;
  showComment: boolean = false;
  master: any = {};
  masterDisplayMap: any = {};

  private selectedTanqueChangedSubscription: Subscription = new Subscription();
  private onUpdateTanqueListener: ZenObservable.Subscription =
    new Subscription();
  private isUploadingDocumentsSubscription: Subscription = new Subscription();
  private requestSucceededSubscription: Subscription = new Subscription();

  constructor(
    private api: APIService,
    private apiRequestsService: ApiRequestsService,
    private documentsService: DocumentsService,
    private editionsService: EditionsService,
    private feedbacksService: FeedbacksService,
    private masterService: MasterService,
    private modalsService: ModalsService,
    private route: ActivatedRoute,
    private router: Router,
    private tanquesService: TanquesService,
    private usersService: UsersService,
    private formsService: FormsService
  ) {
    this.tanque = this.tanquesService.getSelectedTanque();
    this.isUploadingDocuments =
      this.documentsService.isUploadingDocuments.value;

    this.commentForm = new FormGroup({
      comment: new FormControl(null),
    });
    this.updateAttributeForm = new FormGroup({
      center: new FormControl(null),
    });
  }

  async ngOnInit() {
    this.master = this.masterService.getMaster();
    this.masterDisplayMap = this.masterService.getMasterDisplayMap();

    this.selectedTanqueChangedSubscription =
      this.tanquesService.selectedTanqueChanged.subscribe((tanque: Tanque) => {
        this.tanque = tanque;
        // Chequeamos si tiene acople
        this.tanqueIsCoupled = Boolean(tanque.tanqueCoupleCoupleId);
        if (this.tanqueIsCoupled) {
          this.coupleIdentification = `${tanque.couple!.coupleTractoTractoId}`;
          this.coupleStatus = tanque.couple!.status;
        } else {
          this.coupleIdentification = '';
          this.coupleStatus = '';
        }
        this.setTanqueSituation();
      });

    this.isAdmin = this.usersService.isAdmin;
    this.isApprover = this.usersService.isApprover;
    this.isCarrier = this.usersService.isCarrier;

    await this.updateSelectedTanque();

    // Subscripción a actualizaciones del Tanque
    this.onUpdateTanqueListener = this.api
      .OnUpdateTanqueListener(
        this.tanquesService.getSelectedTanqueFilterForSubscriptions()
      )
      .subscribe((response) => {
        if (response) {
          this.tanquesService.refreshSelectedTanque();
        }
      });

    // Suscripción a la variable que indica si se están cargando documentos.
    this.isUploadingDocumentsSubscription =
      this.documentsService.isUploadingDocuments.subscribe(
        (value: boolean): void => {
          this.isUploadingDocuments = value;
        }
      );

    // Subscripción a consultas de API exitosas
    this.requestSucceededSubscription =
      this.apiRequestsService.requestSucceeded.subscribe(
        (requestSucceeded: boolean): void => {
          if (requestSucceeded) {
            this.onRefreshTanque();
          }
        }
      );
  }

  /**
   * Actualiza el Tanque seleccionado.
   * @return {Promise}
   * @private
   */
  private async updateSelectedTanque(): Promise<void> {
    this.isGettingTanque = true;
    await this.tanquesService.refreshSelectedTanque().then(() => {
      this.setTanqueSituation();
      this.isGettingTanque = false;
    });
  }

  /**
   * Ejecuta el modal de aprobación de un tanque y pide su
   * actualización en caso de ser aprobado.
   * @return {Promise}
   */
  async onApproveTanque(): Promise<void> {
    this.initCommentForm();
    this.modalTitle = appConstants.tanque.modalMessages.approve.title;
    this.modalQuestion =
      appConstants.tanque.modalMessages.approve.question.replace(
        '_',
        this.getTanqueIdentification()
      );
    this.modalMessage = appConstants.tanque.modalMessages.approve.message;
    this.modalWithComment = true;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.tanqueModal
    );

    if (modalResult) {
      this.isGettingTanque = true;
      await this.updateTanque('approve', this.commentForm.value.comment);
    }
  }

  /**
   * Ejecuta el modal de rechazo de un tanque y pide su
   * actualización en caso de ser rechazado.
   * @return {Promise}
   */
  async onRejectTanque(): Promise<void> {
    this.isBeingRejected = true;
    this.initCommentForm();
    this.modalTitle = appConstants.tanque.modalMessages.reject.title;
    this.modalQuestion =
      appConstants.tanque.modalMessages.reject.question.replace(
        '_',
        this.getTanqueIdentification()
      );
    this.modalMessage = appConstants.tanque.modalMessages.reject.message;
    this.modalWithComment = true;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.tanqueModal
    );

    if (modalResult) {
      this.isGettingTanque = true;
      const newNotification = this.commentForm.value;
      const today: Date = new Date();
      const todayTimestamp: string = Math.floor(
        today.getTime() / 1000
      ).toString();
      const creatNotificationInput: CreateNotificationInput = {
        businessId: this.tanque.business,
        notificationId: 'TANQUE#' + this.tanque.tanqueId + '#' + todayTimestamp,
        status: 'TO_BE_SENT',
        model: 'TANQUE',
        motive: 'APPROVE#REJECTED',
        tanqueId: this.tanque.tanqueId,
        comment: newNotification.comment,
      };

      await this.api
        .CreateNotification(creatNotificationInput)
        .then(async (): Promise<void> => {
          await this.updateTanque('reject', newNotification.comment);
        });
    }

    this.isBeingRejected = false;
  }

  /**
   * Actualiza un tanque.
   * @param {string} action Acción a realizar, puede ser 'approve' o 'reject'.
   * @param {string} comment Comentario de aprobación o rechazo.
   * @return {Promise}
   * @private
   */
  private async updateTanque(
    action: 'approve' | 'reject',
    comment: string | null = null
  ): Promise<void> {
    this.isGettingTanque = true;
    let newTanqueStatus: string;
    let feedbackMessage: string = `Tanque ${this.getTanqueIdentification()} `;
    let catchFeedbackMessage: string = `Error al _ tanque ${this.getTanqueIdentification()}`;

    switch (action) {
      case 'approve':
        // Se cambia el estado del Tanque a "Procesando"
        newTanqueStatus = `${this.tanque.business}_${appConstants.entity.codes.inProcess}`;
        feedbackMessage = feedbackMessage + 'aprobado. Actualizando';
        catchFeedbackMessage = catchFeedbackMessage.replace('_', 'aprobar');
        break;
      case 'reject':
        // Se cambia el estado del Tanque a "Rechazado"
        newTanqueStatus = `${this.tanque.business}_${appConstants.entity.codes.rejected}`;
        feedbackMessage = feedbackMessage + 'rechazado. Enviando notificación';
        catchFeedbackMessage = catchFeedbackMessage.replace('_', 'rechazar');
        break;
    }

    const updateInputTanque: UpdateTanqueInput = {
      tanqueId: this.tanque.tanqueId,
      status: newTanqueStatus,
      comment: comment,
    };

    await this.api
      .UpdateTanque(updateInputTanque)
      .then(async (tanque: UpdateTanqueMutation): Promise<void> => {
        this.feedbacksService.showFeedback(feedbackMessage, 'info');

        if (action === 'approve') {
          // Se cambia el estado de los documentos con estado "Por Aprobar"
          // al estado "Procesando"
          for (let document of tanque.documents!.items) {
            if (
              document?.status ===
              `${tanque.business}_${appConstants.document.codes.toBeApproved}`
            ) {
              const updateDocumentInput: UpdateDocumentInput = {
                documentId: document.documentId,
                name: document.name,
                status: `${tanque.business}_${appConstants.document.codes.inProcess}`,
              };
              await this.documentsService.updateDocument(updateDocumentInput);
            }
          }
        } else {
          // Se actualiza el estado del acoplamiento, si aplica.
          if (tanque.tanqueCoupleCoupleId) {
            await this.api.UpdateCouple({
              coupleId: tanque.tanqueCoupleCoupleId,
              status: `${tanque.business}_${appConstants.entity.codes.rejected}`,
            });
          }
        }

        // Actualizamos el Tanque seleccionado.
        await this.updateSelectedTanque();
      })
      .catch((response: any): void => {
        this.feedbacksService.showErrorFeedbacks(
          response,
          catchFeedbackMessage
        );
      });
  }

  /**
   * Ejecuta el modal de edición de atributos del Tanque.
   * @return {Promise}
   */
  async onUpdateTanque(): Promise<void> {
    this.initUpdateAttributeForm();

    this.modalTitle = appConstants.tanque.modalMessages.edit.title;
    this.modalQuestion =
      appConstants.tanque.modalMessages.edit.question.replace(
        '_',
        this.getTanqueIdentification()
      );
    this.modalMessage = appConstants.tanque.modalMessages.edit.message;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.updateTanqueAttributeModal
    );

    if (modalResult) {
      if (this.updateAttributeForm.invalid) {
        this.feedbacksService.showFeedback(
          'Formulario inválido, no será procesada la petición.',
          'danger'
        );
        return;
      }

      const patchedTanque = this.updateAttributeForm.value;
      const payload: { [key: string]: string } = {
        center: patchedTanque.center,
      };

      this.apiRequestsService.updateEntity(
        this.tanque.business,
        this.tanque.__typename,
        this.getTanqueIdentification(),
        payload
      );
    }
  }

  /**
   * Navega al formulario de edición del Tanque.
   * @return {Promise}
   */
  async onEditTanque(): Promise<void> {
    const editionId: string = `${this.usersService.business.value.toUpperCase()}#TANQUE#${
      this.tanque.tanqueId
    }`;

    if (await this.editionsService.editionIsTaken(editionId)) {
      return;
    }

    this.router
      .navigate(['edit'], { relativeTo: this.route })
      .then(() => console.log('navigate to edit'));
  }

  /**
   * Ejecuta el modal de eliminación de un tanque y lo
   * elimina en caso de aceptar.
   * @return {Promise}
   */
  async onDeleteTanque(): Promise<void> {
    if (this.tanqueIsCoupled) {
      this.feedbacksService.showFeedback(
        `Tanque ${this.getTanqueIdentification()} está acoplado. Desacóplelo e intente de nuevo.`,
        'danger'
      );
      return;
    }

    this.modalTitle = appConstants.tanque.modalMessages.delete.title;
    this.modalQuestion =
      appConstants.tanque.modalMessages.delete.question.replace(
        '_',
        this.getTanqueIdentification()
      );
    this.modalMessage = appConstants.tanque.modalMessages.delete.message;
    this.modalWithComment = false;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.tanqueModal
    );

    if (modalResult) {
      const deleteTanqueInput: DeleteTanqueInput = {
        tanqueId: this.tanque.tanqueId,
      };

      await this.api
        .DeleteTanque(deleteTanqueInput)
        .then(async () => {
          this.feedbacksService.showFeedback(
            `Tanque ${this.getTanqueIdentification()} eliminado.`,
            'info'
          );
        })
        .catch((response: any): void => {
          this.feedbacksService.showErrorFeedbacks(
            response,
            `Error al borrar tanque ${this.getTanqueIdentification()}`
          );
        });
      this.router
        .navigate(['/', this.tanque.business.toLowerCase(), 'tanques'])
        .then(() => console.log('navigate to tanques'));
    }
  }

  /**
   * Llama a refrescar el Tanque seleccionado.
   */
  onRefreshTanque(): void {
    this.updateSelectedTanque().then(() =>
      console.log('Tanque seleccionado actualizado.')
    );
  }

  /**
   * Colapsa o muestra el comentario del vehículo.
   */
  onViewComment(): void {
    this.showComment = !this.showComment;
  }

  /**
   * Retorna la clase que colapsa o muestra el comentario del vehículo.
   * @return {string} Clase CSS
   */
  showCommentClass(): string {
    return this.showComment ? 'show' : '';
  }

  /**
   * Inicializa el formulario de rechazo de un tanque.
   * @private
   */
  private initCommentForm(): void {
    // Inicialización del formulario
    this.commentForm = this.formsService.generateCommentForm(
      this.isBeingRejected
    );
  }

  /**
   * Inicializa el formulario de actualización de
   * atributos de un Tanque.
   * @private
   */
  private initUpdateAttributeForm(): void {
    // Inicialización del formulario
    this.updateAttributeForm = new FormGroup({
      center: new FormControl(this.tanque.center, [Validators.required]),
    });
  }

  /**
   * Indica si un tanque tiene documentos por vencer.
   * @return {boolean} Boolean que indica si el Tanque tiene documentos por vencer.
   */
  hasToExpireDocuments(): boolean {
    const docNumber: number = this.tanque.documentsToExpire || 0;
    return docNumber > 0;
  }

  /**
   * Indica si un tanque tiene documentos vencidos.
   * @return {boolean} Boolean que indica si el Tanque tiene documentos vencidos.
   */
  hasExpiredDocuments(): boolean {
    const docNumber: number = this.tanque.documentsExpired || 0;
    return docNumber > 0;
  }

  /**
   * Retorna un color dependiendo del estado del envasado.
   * @return {string}
   */
  getColor(): string {
    return this.masterService.getSatusColor(this.tanque.status);
  }

  /**
   * Retorna la fuente de imagen a mostrar en el Estado del envasado.
   * @return {string} ruta a imagen.
   */
  getImageSrc(status: string): string {
    return this.masterService.getImageSrc(status);
  }

  /**
   * Determina, con base al rol del usuario, cuáles botones mostrar.
   * @param {string} button Acción del botón.
   * @return {boolean}
   */
  showButton(button: string): boolean {
    let show: boolean = false;

    switch (button) {
      case 'delete':
        show = this.isAdmin;
        break;
      case 'edit-center':
        show = this.isAdmin && this.tanque.business === 'LUBRICANTS';
        break;
      case 'approve':
      case 'reject':
        show = this.isAdmin || this.isApprover;
        break;
      case 'edit':
        show = this.isAdmin || this.isCarrier;
        break;
      default:
        break;
    }
    return show;
  }

  /**
   * Define la situación del Tanque: ¿necesita aprobación?, ¿puede ser rechazado?
   * ¿Está bloqueado?
   * @private
   */
  private setTanqueSituation(): void {
    const tanqueToBeApprove: boolean =
      this.tanque.status ===
      `${this.tanque.business}_${appConstants.entity.codes.toBeApproved}`;
    const tanqueRejected: boolean =
      this.tanque.status ===
      `${this.tanque.business}_${appConstants.entity.codes.rejected}`;
    this.tanqueNeedsApproval = tanqueToBeApprove || tanqueRejected;
    this.tanqueCanBeRejected = tanqueToBeApprove;
    this.showBlockingMotives =
      this.tanque.blockingMotives !== null &&
      this.tanque.blockingMotives !== undefined &&
      this.tanque.blockingMotives.length > 0 &&
      this.tanque.status !==
        `${this.tanque.business}_${appConstants.entity.codes.available}`;
    this.hasComment = !!this.tanque.comment;
    this.showComment = false;
  }

  /**
   * Retorna la identificación del Tanque para los modales.
   * @return {string}
   * @private
   */
  private getTanqueIdentification(): string {
    let sapId: string;
    if (this.tanque.sapId! === 'Sin Asignar') {
      sapId = this.tanque.sapId!;
    } else {
      sapId = parseInt(this.tanque.sapId!).toString();
    }
    return sapId + ' - ' + this.tanque.licensePlate;
  }

  /**
   * Retorna la fuente de la imagen según la capacidad del tanque.
   * @return {string} Ruta a la imagen
   */
  capacityImage(): string {
    const business: string = this.usersService.business.value.toUpperCase();
    let srcPath: string = '../../../../assets/images/capacities/capacity-';
    let imageId: string = this.tanque.capacity.replace(business + '_', '');
    return (srcPath + imageId + '.jpg').replaceAll('#', '%23');
  }

  ngOnDestroy(): void {
    this.selectedTanqueChangedSubscription.unsubscribe();
    this.onUpdateTanqueListener.unsubscribe();
    this.isUploadingDocumentsSubscription.unsubscribe();
    this.requestSucceededSubscription.unsubscribe();
    console.log('tanque-details.component subscriptions removed.');
  }
}
