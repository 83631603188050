import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ZenObservable } from 'zen-observable-ts';

import { appConstants } from 'src/app/shared/constants/constants';

import { APIService, User } from 'src/app/app-sync.service';
import { UsersService } from '../users.service';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css'],
})
export class UsersListComponent implements OnInit, OnDestroy {
  dtOptions: DataTables.Settings = {};
  users: User[];
  isGettingUsers: boolean = false;
  usersAttributes = ['E-mail', 'Estatus', 'RUT', 'Nombre', 'Empresa', 'Grupo'];

  //Esto permite crear un filtro de búsqueda personalizado en el datatable
  searchText: string = '';
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;

  private usersChangedSubscription: Subscription = new Subscription();
  private onCreateUserListener: ZenObservable.Subscription = new Subscription();
  private onDeleteUserListener: ZenObservable.Subscription = new Subscription();
  private onUpdateUserListener: ZenObservable.Subscription = new Subscription();

  constructor(
    private api: APIService,
    private route: ActivatedRoute,
    private router: Router,
    private usersService: UsersService
  ) {
    this.dtOptions = appConstants.datatables.options;
    this.users = this.usersService.getUsers();
  }

  async ngOnInit(): Promise<void> {
    this.usersChangedSubscription = this.usersService.usersChanged.subscribe(
      (users: User[]) => {
        this.users = users;
      }
    );

    await this.updateUsersList();

    // Una vez cargada la lista de usuarios definimos los filtros para subscripciones
    const subscriptionUsersFilter =
      this.usersService.getUsersFilterForSubscriptions();
    // Subscripción a Usuarios eliminados
    this.onDeleteUserListener = this.api
      .OnDeleteUserListener(subscriptionUsersFilter)
      .subscribe((response) => {
        if (response) {
          this.usersService.refreshUsers();
        }
      });
    // Subscripción a Usuarios actualizados
    this.onUpdateUserListener = this.api
      .OnUpdateUserListener(subscriptionUsersFilter)
      .subscribe((response) => {
        if (response) {
          this.usersService.refreshUsers();
        }
      });
    // Subscripción a Usuarios creados
    this.onCreateUserListener = this.api
      .OnCreateUserListener(subscriptionUsersFilter)
      .subscribe((response) => {
        if (response) {
          this.usersService.refreshUsers();
        }
      });
  }

  /**
   * Navega a al formulario de creación de Usuario.
   */
  onNewUser(): void {
    this.router
      .navigate(['new'], { relativeTo: this.route })
      .then(() => console.log('navigate to new'));
  }

  /**
   * Llama al método que refresca la lista de Usuarios.
   * @return {Promise}
   */
  async onRefresh(): Promise<void> {
    this.searchText = '';
    await this.updateUsersList();
  }

  /**
   * Define el usuario que está seleccionado y navega al detalle del mismo.
   * @param {User} user Usuario seleccionado de la lista.
   */
  onSelectedUser(user: User) {
    this.usersService.setSelectedUser(user);
    const encodeUserUrl = encodeURIComponent(user.userId);
    this.router
      .navigate([encodeUserUrl], { relativeTo: this.route })
      .then(() => console.log('navigate user details'));
  }

  /**
   * Refresca la lista de usuarios.
   * @private
   * @return {Promise}
   */
  private async updateUsersList(): Promise<void> {
    this.isGettingUsers = true;
    await this.usersService.refreshUsers().then(() => {
      this.isGettingUsers = false;
    });
  }

  /**
   * Retorna la fuente de imagen a mostrar en el Estado del Usuario.
   * @return {string} ruta a imagen.
   */
  getImageSrc(status: string): string {
    const business: string = this.usersService.business.value.toUpperCase();
    return this.usersService.getImageSrc(status, business);
  }

  /**
   * Aplica a la tabla el filtro ingresado por el usuario.
   */
  applyFilter(): void {
    if (this.dtElement) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api): void => {
        dtInstance.search(this.searchText).draw();
      });
    }
  }

  ngOnDestroy(): void {
    this.usersChangedSubscription.unsubscribe();
    this.onCreateUserListener.unsubscribe();
    this.onDeleteUserListener.unsubscribe();
    this.onUpdateUserListener.unsubscribe();
    console.log('users-list.component subscriptions removed.');
  }
}
