import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { DocumentsService } from '../../documents/documents.service';

@Component({
  selector: 'app-tanque-no-selected',
  templateUrl: './tanque-no-selected.component.html',
  styleUrls: ['./tanque-no-selected.component.css'],
})
export class TanqueNoSelectedComponent implements OnInit, OnDestroy {
  isUploadingDocuments: boolean = false;

  private isUploadingDocumentsSubscription: Subscription = new Subscription();

  constructor(private documentsService: DocumentsService) {
    this.isUploadingDocuments =
      this.documentsService.isUploadingDocuments.value;
  }

  ngOnInit() {
    // Suscripción a la variable que indica si se están cargando documentos.
    this.isUploadingDocumentsSubscription =
      this.documentsService.isUploadingDocuments.subscribe(
        (value: boolean): void => {
          this.isUploadingDocuments = value;
        }
      );
  }

  ngOnDestroy(): void {
    this.isUploadingDocumentsSubscription.unsubscribe();
    console.log('tanque-no-selected.component subscriptions removed.');
  }
}
