import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { appConstants } from '../../../shared/constants/constants';
import {
  APIService,
  CreateCoupleInput,
  Semirremolque,
  Tanque,
  Tracto,
  UpdateSemirremolqueInput,
  UpdateTanqueInput,
  UpdateTractoInput,
} from '../../../app-sync.service';
import { TractosService } from '../../tractos/tractos.service';
import { UsersService } from '../../users/users.service';
import { Subscription } from 'rxjs';
import { ZenObservable } from 'zen-observable-ts';
import { TanquesService } from '../../tanques/tanques.service';
import { SemirremolquesService } from '../../semirremolques/semirremolques.service';
import { ModalsService } from '../../../shared/modals/modals.service';
import { FeedbacksService } from '../../../shared/feedbacks/feedbacks.service';
import { MasterService } from '../../master/master.service';

@Component({
  selector: 'app-members-coupling',
  templateUrl: './members-coupling.component.html',
  styleUrls: ['./members-coupling.component.css'],
})
export class MembersCouplingComponent implements OnInit, OnDestroy {
  @ViewChild('couplingModal', { static: false }) couplingModal:
    | TemplateRef<any>
    | undefined;
  dtOptions: DataTables.Settings = {};
  tractoChosen: boolean = false;
  coupleChosen: boolean = false;
  isAdmin: boolean = false;
  isCarrier: boolean = false;
  isGettingVehicles: boolean = true;
  tractosTapSelected: boolean = true;
  tanquesTapSelected: boolean = false;
  semirremolquesTapSelected: boolean = false;
  selectedTracto: Tracto;
  coupleTypeSelected: 'Tanque' | 'Semirremolque' | 'Sin Asignar' =
    'Sin Asignar';
  selectedCouple: Tanque | Semirremolque;
  decoupledTractos: Tracto[];
  decoupledTanques: Tanque[];
  decoupledSemirremolques: Semirremolque[];
  modalMessage: string = '';
  modalQuestion: string = '';
  modalTitle: string = '';
  tractosAttributes: string[] = ['Tracto', 'Estado', 'Centro', 'Bloqueos'];
  coupleAttributes: string[] = [
    `${this.coupleTypeSelected}`,
    'Estado',
    'Centro',
    'Capacidad',
    'Bloqueos',
  ];
  business: string = '';

  private decoupledTractosChangedSubscription: Subscription =
    new Subscription();
  private onDeleteDecoupledTractoListener: ZenObservable.Subscription =
    new Subscription();
  private onCreateDecoupledTractoListener: ZenObservable.Subscription =
    new Subscription();
  private onUpdateDecoupledTractoListener: ZenObservable.Subscription =
    new Subscription();
  private decoupledTanquesChangedSubscription: Subscription =
    new Subscription();
  private onDeleteDecoupledTanqueListener: ZenObservable.Subscription =
    new Subscription();
  private onCreateDecoupledTanqueListener: ZenObservable.Subscription =
    new Subscription();
  private onUpdateDecoupledTanqueListener: ZenObservable.Subscription =
    new Subscription();
  private decoupledSemirremolquesChangedSubscription: Subscription =
    new Subscription();
  private onDeleteDecoupledSemirremolqueListener: ZenObservable.Subscription =
    new Subscription();
  private onCreateDecoupledSemirremolqueListener: ZenObservable.Subscription =
    new Subscription();
  private onUpdateDecoupledSemirremolqueListener: ZenObservable.Subscription =
    new Subscription();

  constructor(
    private api: APIService,
    private tractosService: TractosService,
    private tanquesService: TanquesService,
    private semirremolquesService: SemirremolquesService,
    private usersService: UsersService,
    private modalsService: ModalsService,
    private feedbacksService: FeedbacksService,
    private masterService: MasterService
  ) {
    this.dtOptions = appConstants.datatables.options;
    this.selectedTracto = this.tractosService.getInitialTracto();
    this.selectedCouple = this.tanquesService.getInitialTanque();
    this.decoupledTractos = this.tractosService.getDecoupledTractos();
    this.decoupledTanques = this.tanquesService.getDecoupledTanques();
    this.decoupledSemirremolques =
      this.semirremolquesService.getDecoupledSemirremolques();
  }

  async ngOnInit(): Promise<void> {
    this.isAdmin = this.usersService.isAdmin;
    this.isCarrier = this.usersService.isCarrier;
    this.business = this.usersService.business.value.toLowerCase();
    this.decoupledTractosChangedSubscription =
      this.tractosService.decoupledTractosChanged.subscribe(
        (tractos: Tracto[]) => {
          if (this.tractosTapSelected) {
            this.decoupledTractos = tractos;
          }
        }
      );
    this.decoupledTanquesChangedSubscription =
      this.tanquesService.decoupledTanquesChanged.subscribe(
        (tanques: Tanque[]) => {
          if (this.tanquesTapSelected) {
            this.decoupledTanques = tanques;
          }
        }
      );
    this.decoupledSemirremolquesChangedSubscription =
      this.semirremolquesService.decoupledSemirremolquesChanged.subscribe(
        (semirremolques: Semirremolque[]) => {
          if (this.semirremolquesTapSelected) {
            this.decoupledSemirremolques = semirremolques;
          }
        }
      );

    // Solo se necesita la lista de tractos inicialmente.
    await this.updateDecoupledTractosList();

    this.initTractosSubscriptions();
    this.initTanquesSubscriptions();
    this.initSemirremolquesSubscriptions();
  }

  /**
   * Refresca la lista de tractos desacoplados.
   * @private
   * @return {Promise}
   */
  private async updateDecoupledTractosList(): Promise<void> {
    this.isGettingVehicles = true;
    await this.tractosService.refreshDecoupledTractos().then((): void => {
      this.isGettingVehicles = false;
    });
  }

  /**
   * Refresca la lista de tanques/semirremolques desacoplados.
   * @private
   * @return {Promise}
   */
  private async updateDecoupledMembersList(
    modelType: 'tanque' | 'semirremolque'
  ): Promise<void> {
    this.isGettingVehicles = true;
    if (modelType === 'tanque') {
      await this.tanquesService.refreshDecoupledTanques().then((): void => {
        this.isGettingVehicles = false;
      });
    } else if (modelType === 'semirremolque') {
      await this.semirremolquesService
        .refreshDecoupledSemirremolques()
        .then((): void => {
          this.isGettingVehicles = false;
        });
    }
  }

  /**
   * Llama al método que refresca la lista de Tractos desacoplados.
   * @return {Promise}
   */
  async onRefresh(
    modelType: 'tracto' | 'tanque' | 'semirremolque'
  ): Promise<void> {
    if (modelType === 'tracto') {
      await this.updateDecoupledTractosList();
    } else {
      await this.updateDecoupledMembersList(modelType);
    }
  }

  onTractosSelected() {
    this.tractosTapSelected = true;
    this.tanquesTapSelected = false;
    this.semirremolquesTapSelected = false;
    this.onRefresh('tracto').then();
  }

  onTanquesSelected() {
    this.tractosTapSelected = false;
    this.tanquesTapSelected = true;
    this.semirremolquesTapSelected = false;
    this.coupleTypeSelected = 'Sin Asignar';
    this.selectedCouple = this.tanquesService.getInitialTanque();
    this.coupleChosen = false;
    this.onRefresh('tanque').then();
  }

  onSemirremolquesSelected() {
    this.tractosTapSelected = false;
    this.tanquesTapSelected = false;
    this.semirremolquesTapSelected = true;
    this.coupleTypeSelected = 'Sin Asignar';
    this.selectedCouple = this.semirremolquesService.getInitialSemirremolque();
    this.coupleChosen = false;
    this.onRefresh('semirremolque').then();
  }

  /**
   * Asigna el tanque/semirremolque al tracto
   * y el tracto al tanque/semirremolque.
   * @return {Promise}
   */
  async onCoupling(): Promise<void> {
    if (!this.canBeCoupled()) {
      this.feedbacksService.showFeedback(
        'Debe seleccionar un tracto y un remolque para poder acoplar.',
        'danger'
      );
      return;
    }
    const tractoIdentification: string = `${parseInt(
      this.selectedTracto.sapId!
    ).toString()} - ${this.selectedTracto.licensePlate}`;
    const coupleIdentification: string = `${parseInt(
      this.selectedCouple.sapId!
    ).toString()} - ${this.selectedCouple.licensePlate}`;
    this.modalTitle = appConstants.couple.modalMessages.coupling.title;
    this.modalQuestion = appConstants.couple.modalMessages.coupling.question;
    this.modalMessage = appConstants.couple.modalMessages.coupling.message;
    this.modalQuestion = this.modalQuestion
      .replace('_', tractoIdentification)
      .replace('_', this.coupleTypeSelected.toLowerCase())
      .replace('_', coupleIdentification);
    this.modalMessage = this.modalMessage.replace(
      '_',
      this.coupleTypeSelected.toLowerCase()
    );
    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.couplingModal
    );

    if (modalResult) {
      // 1. Crear el acople en estado "Por Aprobar".
      const coupleId: string = `${this.selectedTracto.tractoId}#${this.selectedCouple.licensePlate}`;
      const createCoupleInput: CreateCoupleInput = {
        coupleId: coupleId,
        business: this.selectedTracto.business,
        company: this.selectedTracto.carrierTractosCarrierId!,
        center: this.selectedTracto.center,
        status: `${this.selectedTracto.business}_${appConstants.entity.codes.toBeApproved}`,
        coupleTractoTractoId: this.selectedTracto.tractoId,
        ...(this.coupleTypeSelected === 'Semirremolque' && {
          coupleSemirremolqueSemirremolqueId: this.selectedCouple.licensePlate,
        }),
        ...(this.coupleTypeSelected === 'Tanque' && {
          coupleTanqueTanqueId: this.selectedCouple.licensePlate,
        }),
      };
      await this.api
        .CreateCouple(createCoupleInput)
        .then((): void => {
          this.feedbacksService.showFeedback(
            `Acople ${coupleId} creado.`,
            'success'
          );
        })
        .catch((response: any): void => {
          this.feedbacksService.showErrorFeedbacks(
            response,
            `Error creando acoplamiento ${coupleId}`
          );
        });

      // 2. Asignar el ID del acople al tracto y cambiar su estado a "Procesando"
      const updateTractoInput: UpdateTractoInput = {
        tractoId: this.selectedTracto.tractoId,
        tractoCoupleCoupleId: coupleId,
        status: `${this.selectedTracto.business}_${appConstants.entity.codes.inProcess}`,
      };

      await this.api
        .UpdateTracto(updateTractoInput)
        .then((): void => {
          this.feedbacksService.showFeedback(
            `Tracto ${tractoIdentification} acoplado. Actualizando`,
            'info'
          );
          // Refrescamos la lista de tractos desacoplados
          this.onTractosSelected();
        })
        .catch((response: any): void => {
          this.feedbacksService.showErrorFeedbacks(
            response,
            `Error al acoplar tracto ${tractoIdentification}`
          );
        });

      // 2. Asignar el ID del acople al acople y cambiar su estado a "Procesando"
      if (this.coupleTypeSelected === 'Tanque') {
        const updateTanqueInput: UpdateTanqueInput = {
          tanqueId: (<Tanque>this.selectedCouple).tanqueId,
          tanqueCoupleCoupleId: coupleId,
          status: `${this.selectedCouple.business}_${appConstants.entity.codes.inProcess}`,
        };

        await this.api
          .UpdateTanque(updateTanqueInput)
          .then((): void => {
            this.feedbacksService.showFeedback(
              `Tanque ${coupleIdentification} acoplado. Actualizando`,
              'info'
            );
          })
          .catch((response: any): void => {
            this.feedbacksService.showErrorFeedbacks(
              response,
              `Error al acoplar tanque ${coupleIdentification}`
            );
          });
      } else {
        const updateSemirremolqueInput: UpdateSemirremolqueInput = {
          semirremolqueId: (<Semirremolque>this.selectedCouple).semirremolqueId,
          semirremolqueCoupleCoupleId: coupleId,
          status: `${this.selectedCouple.business}_${appConstants.entity.codes.inProcess}`,
        };

        await this.api
          .UpdateSemirremolque(updateSemirremolqueInput)
          .then((): void => {
            this.feedbacksService.showFeedback(
              `Semirremolque ${coupleIdentification} acoplado. Actualizando`,
              'info'
            );
          })
          .catch((response: any): void => {
            this.feedbacksService.showErrorFeedbacks(
              response,
              `Error al acoplar semirremolque ${coupleIdentification}`
            );
          });
      }

      this.feedbacksService.showFeedback(
        `Espere un minuto y refresque la lista para actualizar el estado del acoplamiento.`,
        'info'
      );

      // Reseteamos las variables de selección
      this.selectedTracto = this.tractosService.getInitialTracto();
      this.selectedCouple = this.tanquesService.getInitialTanque();
      this.tractoChosen = false;
      this.coupleChosen = false;
    }
  }

  /**
   * Define el tracto seleccionado para ser acoplado.
   * @param {Tracto} tracto
   */
  onSelectedTracto(tracto: Tracto): void {
    this.selectedTracto = tracto;
    this.tractoChosen = true;
  }

  /**
   * Define el tanque/semirremolque seleccionado para ser acoplado.
   * @param {Tanque | Semirremolque} couple
   */
  onSelectedCouple(couple: Tanque | Semirremolque): void {
    this.coupleTypeSelected = couple.__typename;
    this.selectedCouple = couple;
    this.coupleChosen = true;
  }

  /**
   * Retorna la fuente de imagen a mostrar en el Estado del Tracto,
   * Tanque o Semirremolque.
   * @return {string} ruta a imagen.
   */
  getImageSrc(status: string): string {
    return this.masterService.getImageSrc(status);
  }

  /**
   * Retorna la clase de la pestaña.
   * @param {string} linkName Pestaña a considerar.
   * @return {string} Clase CSS.
   */
  getTapClass(linkName: 'tractos' | 'tanques' | 'semirremolques'): string {
    switch (linkName) {
      case 'tractos':
        return this.tractosTapSelected ? 'active' : '';
      case 'tanques':
        return this.tanquesTapSelected ? 'active' : '';
      case 'semirremolques':
        return this.semirremolquesTapSelected ? 'active' : '';
    }
  }

  /**
   * Responde a la pregunta ¿Es posible realizar el acople?.
   * @return {Boolean}
   */
  canBeCoupled(): boolean {
    return this.tractoChosen && this.coupleChosen;
  }

  /**
   * Determina, con base al rol del usuario, cuáles botones mostrar.
   * @param {string} button Acción del botón.
   */
  showButton(button: string): boolean {
    let show: boolean = false;

    if (button === 'couple') {
      show = this.isAdmin || this.isCarrier;
    }

    return show;
  }

  /**
   * Inicializa las subscripciones a cambios en Tractos desacoplados.
   * @private
   */
  private initTractosSubscriptions(): void {
    const decoupledTractosFilterInput =
      this.tractosService.getDecoupledTractosFilterForSubscriptions();
    // Subscripción a Tractos desacoplados eliminados
    this.onDeleteDecoupledTractoListener = this.api
      .OnDeleteTractoListener(decoupledTractosFilterInput)
      .subscribe((response) => {
        if (response && this.tractosTapSelected) {
          this.tractosService
            .refreshDecoupledTractos()
            .then(() => console.log('Tracto eliminado.'));
        }
      });
    // Subscripción a Tractos desacoplados actualizados
    this.onUpdateDecoupledTractoListener = this.api
      .OnUpdateTractoListener(decoupledTractosFilterInput)
      .subscribe((response) => {
        if (response && this.tractosTapSelected) {
          this.tractosService
            .refreshDecoupledTractos()
            .then(() => console.log('Tracto actualizado.'));
        }
      });
    // Subscripción a Tractos desacoplados creados
    this.onCreateDecoupledTractoListener = this.api
      .OnCreateTractoListener(decoupledTractosFilterInput)
      .subscribe((response) => {
        if (response && this.tractosTapSelected) {
          this.tractosService
            .refreshDecoupledTractos()
            .then(() => console.log('Tracto creado.'));
        }
      });
  }

  /**
   * Inicializa las subscripciones a cambios en Tanques desacoplados.
   * @private
   */
  private initTanquesSubscriptions(): void {
    const decoupledTanquesFilterInput =
      this.tanquesService.getDecoupledTanquesFilterForSubscriptions();
    // Subscripción a Tanques desacoplados eliminados
    this.onDeleteDecoupledTanqueListener = this.api
      .OnDeleteTanqueListener(decoupledTanquesFilterInput)
      .subscribe((response) => {
        if (response && this.tanquesTapSelected) {
          this.tanquesService
            .refreshDecoupledTanques()
            .then(() => console.log('Tanque eliminado.'));
        }
      });
    // Subscripción a Tanques desacoplados actualizados
    this.onUpdateDecoupledTanqueListener = this.api
      .OnUpdateTanqueListener(decoupledTanquesFilterInput)
      .subscribe((response) => {
        if (response && this.tanquesTapSelected) {
          this.tanquesService
            .refreshDecoupledTanques()
            .then(() => console.log('Tanque actualizado.'));
        }
      });
    // Subscripción a Tanques desacoplados creados
    this.onCreateDecoupledTanqueListener = this.api
      .OnCreateTanqueListener(decoupledTanquesFilterInput)
      .subscribe((response) => {
        if (response && this.tanquesTapSelected) {
          this.tanquesService
            .refreshDecoupledTanques()
            .then(() => console.log('Tanque creado.'));
        }
      });
  }

  /**
   * Inicializa las subscripciones a cambios en Semirremolques desacoplados.
   * @private
   */
  private initSemirremolquesSubscriptions(): void {
    const decoupledSemirremolquesFilterInput =
      this.semirremolquesService.getDecoupledSemirremolquesFilterForSubscriptions();
    // Subscripción a Semirremolques desacoplados eliminados
    this.onDeleteDecoupledSemirremolqueListener = this.api
      .OnDeleteSemirremolqueListener(decoupledSemirremolquesFilterInput)
      .subscribe((response) => {
        if (response && this.semirremolquesTapSelected) {
          this.semirremolquesService
            .refreshDecoupledSemirremolques()
            .then(() => console.log('Semirremolque eliminado.'));
        }
      });
    // Subscripción a Semirremolques desacoplados actualizados
    this.onUpdateDecoupledSemirremolqueListener = this.api
      .OnUpdateSemirremolqueListener(decoupledSemirremolquesFilterInput)
      .subscribe((response) => {
        if (response && this.semirremolquesTapSelected) {
          this.semirremolquesService
            .refreshDecoupledSemirremolques()
            .then(() => console.log('Semirremolque actualizado.'));
        }
      });
    // Subscripción a Semirremolques desacoplados creados
    this.onCreateDecoupledSemirremolqueListener = this.api
      .OnCreateSemirremolqueListener(decoupledSemirremolquesFilterInput)
      .subscribe((response) => {
        if (response && this.semirremolquesTapSelected) {
          this.semirremolquesService
            .refreshDecoupledSemirremolques()
            .then(() => console.log('Semirremolque creado.'));
        }
      });
  }

  ngOnDestroy(): void {
    this.decoupledTractosChangedSubscription.unsubscribe();
    this.onDeleteDecoupledTractoListener.unsubscribe();
    this.onCreateDecoupledTractoListener.unsubscribe();
    this.onUpdateDecoupledTractoListener.unsubscribe();
    this.decoupledTanquesChangedSubscription.unsubscribe();
    this.onDeleteDecoupledTanqueListener.unsubscribe();
    this.onCreateDecoupledTanqueListener.unsubscribe();
    this.onUpdateDecoupledTanqueListener.unsubscribe();
    this.decoupledSemirremolquesChangedSubscription.unsubscribe();
    this.onDeleteDecoupledSemirremolqueListener.unsubscribe();
    this.onCreateDecoupledSemirremolqueListener.unsubscribe();
    this.onUpdateDecoupledSemirremolqueListener.unsubscribe();
    console.log('members-coupling.component subscriptions removed.');
  }
  showTabs(tab: string): boolean {
    return !(this.business === 'foreign' && tab === 'semirremolques');
  }
}
