import { Component, OnDestroy } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { FeedbackItemComponent } from './feedback-item/feedback-item.component';
import { FeedbacksService } from './feedbacks.service';

@Component({
  selector: 'app-feedbacks',
  standalone: true,
  imports: [NgbTooltipModule, FeedbackItemComponent],
  templateUrl: './feedbacks.component.html',
  styleUrls: ['./feedbacks.component.css'],
})
export class FeedbacksComponent implements OnDestroy {
  constructor(private feedbacksService: FeedbacksService) {}

  ngOnDestroy(): void {
    this.feedbacksService.clearFeedbacks();
  }
}
