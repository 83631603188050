import { Component, OnInit } from '@angular/core';

import { UsersService } from '../users/users.service';

@Component({
  selector: 'app-couplings',
  templateUrl: './couplings.component.html',
  styleUrls: ['./couplings.component.css'],
})
export class CouplingsComponent implements OnInit {
  isViewer: boolean = false;
  isApprover: boolean = false;

  constructor(private usersService: UsersService) {}

  ngOnInit(): void {
    this.isViewer = this.usersService.isViewer;
    this.isApprover = this.usersService.isApprover;
  }

  /**
   * Retorna la clase del componente para listar acoplamientos
   * dependiendo del rol del usuario.
   * @return {string} clase CSS
   */
  getClass(): string {
    if (this.isViewer || this.isApprover) {
      return 'col-md';
    } else {
      return 'col-md-6';
    }
  }
}
