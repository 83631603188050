import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateWithoutSeconds',
})
export class DateWithoutSecondsPipe implements PipeTransform {
  transform(value: string): string {
    // Dividir la fecha y hora
    const [datePart, timePart] = value.split(' ');

    // Eliminar los segundos de la parte de la hora
    const [hour, minute] = timePart.split(':');
    const formattedTime = `${hour}:${minute}`;

    // Unir la fecha y la hora formateada
    return `${datePart} ${formattedTime}`;
  }
}
