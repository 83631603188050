import { Injectable, TemplateRef, ViewChild } from '@angular/core';
import {
  ModalDismissReasons,
  NgbModal,
  NgbModalConfig,
} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class ModalsService {
  @ViewChild('modalTemplate', { static: false }) modalTemplate:
    | TemplateRef<any>
    | undefined;
  dismissedReason: string = '';

  constructor(
    private config: NgbModalConfig,
    private ngModalService: NgbModal
  ) {
    this.config.backdrop = 'static';
    this.config.keyboard = false;
  }

  /**
   * Crea y muestra un modal.
   * @param {Template} modalTemplate Template del modal.
   * @param {Boolean} fullscreen Responde a la pregunta ¿modal en pantalla completa?
   * @return {Promise}
   */
  showModal(
    modalTemplate: TemplateRef<any>,
    fullscreen: boolean = false
  ): Promise<boolean> {
    console.log(modalTemplate);
    this.config.fullscreen = fullscreen;
    return new Promise<boolean>((resolve): void => {
      this.ngModalService.open(modalTemplate, { centered: true }).result.then(
        (result): void => {
          // Se disparará al aceptar
          console.log(`Closed with: ${result}`);
          resolve(true);
        },
        (reason): void => {
          // Se disparará con la X y el Cancelar
          this.setDismissReason(reason);
          console.log(`Dismissed ${this.dismissedReason}`);
          resolve(false);
        }
      );
    });
  }

  /**
   * Configura la respuesta dada en el modal.
   * @param {string} reason Acción que cerró el modal.
   * @private
   */
  private setDismissReason(reason: any): void {
    if (reason === ModalDismissReasons.ESC) {
      this.dismissedReason = 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.dismissedReason = 'by clicking on a backdrop';
    } else {
      this.dismissedReason = `with: ${reason}`;
    }
  }
}
