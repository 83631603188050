import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CognitoService } from '../auth/cognito.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  constructor(
    private router: Router,
    private cognitoService: CognitoService,
    private authenticatorService: AuthenticatorService
  ) {}

  async ngOnInit() {
    this.cognitoService.currentUser();
    await this.cognitoService.getUser();
    if (this.authenticatorService.authStatus === 'authenticated') {
      this.router
        .navigate(['/home'])
        .then(() => console.log('navigate to home'));
    } else {
      this.cognitoService
        .federatedSignIn()
        .then(() => console.log('federate sign in'));
    }
  }
}
