import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { appConstants } from 'src/app/shared/constants/constants';
import { FormValidators } from '../../../shared/interfaces/form-validators';
import { DocumentSpecsObject } from '../../../shared/interfaces/document-specs-object';

import {
  APIService,
  CreateDriverInput,
  CreateDriverMutation,
  CreateNotificationInput,
  CreateUserInput,
  CreateUserMutation,
  Document,
  Driver,
  UpdateDriverInput,
} from 'src/app/app-sync.service';
import { DocumentsService } from 'src/app/pages/documents/documents.service';
import { DriversService } from '../drivers.service';
import { FeedbacksService } from 'src/app/shared/feedbacks/feedbacks.service';
import { MasterService } from 'src/app/pages/master/master.service';
import { ValidatorsService } from 'src/app/shared/services/validators.service';
import { UsersService } from 'src/app/pages/users/users.service';
import { SettingsService } from 'src/app/pages/settings/settings.service';
import { FormsService } from '../../../shared/services/forms.service';
import { DocumentFromForms } from '../../../shared/interfaces/document-from-forms';
import { EditionsService } from '../../../shared/services/editions.service';

@Component({
  selector: 'app-driver-edit',
  templateUrl: './driver-edit.component.html',
  styleUrls: ['./driver-edit.component.css'],
})
export class DriverEditComponent implements OnInit, OnDestroy {
  editMode: boolean = true;
  isAdmin: boolean = false;
  isCarrier: boolean = false;
  isGettingDriver: boolean = false;
  isBlockedForSpeeding: boolean = false;
  currentYear: number = new Date().getFullYear();
  currentMonth: number = new Date().getMonth();
  driverAge: number = 0;
  driverInAgeRange: boolean = false;
  master: any = {};
  masterDisplayMap: any = {};
  documentsSpecs: DocumentSpecsObject = {};
  driverForm: FormGroup;
  driverId: string = '';
  selectedCenter: string = '';
  isDev: boolean = environment.env === 'dev' || environment.env === 'sandbox';
  business: string;
  editionId: string = '';

  get documentsControl() {
    return (<FormArray>this.driverForm.get('documents')).controls;
  }

  get driverControl() {
    return this.driverForm;
  }

  constructor(
    private api: APIService,
    private documentsService: DocumentsService,
    private driversService: DriversService,
    private feedbacksService: FeedbacksService,
    private masterService: MasterService,
    private route: ActivatedRoute,
    private router: Router,
    private settingsService: SettingsService,
    private usersService: UsersService,
    private validatorsService: ValidatorsService,
    private formsService: FormsService,
    private editionsService: EditionsService
  ) {
    this.driverForm = new FormGroup({
      carrier: new FormControl(null),
      firstName: new FormControl(null),
      lastName: new FormControl(null),
      rut: new FormControl(null),
      birthDate: new FormControl(null),
      phone: new FormControl(null),
      email: new FormControl(null),
      center: new FormControl(null),
      documents: new FormArray([
        new FormGroup({
          documentMasterValueId: new FormControl(null),
          documentName: new FormControl(null),
          documentIssueDate: new FormControl(null),
          documentExpirationDate: new FormControl(null),
          documentFile: new FormControl(null),
          documentSourceFile: new FormControl(null),
        }),
      ]),
    });
    this.business = this.usersService.business.value.toUpperCase();
  }

  ngOnInit(): void {
    this.isGettingDriver = true;
    this.isAdmin = this.usersService.isAdmin;
    this.isCarrier = this.usersService.isCarrier;
    this.master = this.masterService.getMaster();
    this.masterDisplayMap = this.masterService.getMasterDisplayMap();
    this.documentsSpecs = this.settingsService.getDocumentsSpecs();

    this.route.params.subscribe(async (params: Params): Promise<void> => {
      // Si la ruta contiene un ID de conductor
      // estamos en modo edición
      this.editMode = params['id'] != null;
      this.driverId = params['id'];

      this.initDriverForm();
      if (!this.editMode) {
        if (this.settingsService.getSelectedModel() !== 'DRIVER') {
          console.log('Loading document specifications');
          this.settingsService.setSelectedModel('DRIVER');
          await this.settingsService.setDocumentsSpecs();
        } else {
          console.log('Reusing document specifications');
        }
        this.documentsSpecs = this.settingsService.getDocumentsSpecs();
      } else {
        this.editionId = `${this.business}#DRIVER#${this.driverId}`;
        await this.editionsService.createOrUpdateEdition(this.editionId);
      }

      // Ordenamos alfabéticamente el formulario de editar las propiedades
      this.documentsControl.sort((a, b) => {
        const documentNameA = a.value.documentName.toLowerCase();
        const documentNameB = b.value.documentName.toLowerCase();
        return documentNameA.localeCompare(documentNameB);
      });

      this.isGettingDriver = false;
    });
  }

  /* ------------------------------------------- */
  /* - Métodos para la creación del Formulario - */
  /* ------------------------------------------- */
  /**
   * Inicializa el formulario para crear o
   * editar un conductor.
   * @private
   */
  private initDriverForm(): void {
    // Inicialización del Formulario
    // Por default, los campos estarán vacíos.
    // Si el usuario es Transportista el campo carrier campo estará seleccionado.
    let initialDriver: Driver = {
      ...appConstants.driver.initialization,
      carrier: appConstants.carrier.initialization,
    };
    let driverDocuments: FormArray =
      this.formsService.documentsFormArrayInitialization();

    // Si es transportista la única opción de carrier es él mismo.
    initialDriver.carrier.carrierId = this.isCarrier
      ? this.usersService.getActiveUser().company
      : '';

    if (this.editMode) {
      // En modo de Edición: cargamos los valores de los campos
      // según el conductor escogido.
      initialDriver = this.driversService.getSelectedDriver();

      const birthYear: number = new Date(
        initialDriver.birthDate || ''
      ).getFullYear();
      const birthMonth: number = new Date(
        initialDriver.birthDate || ''
      ).getMonth();

      this.setDriverAge(birthYear, birthMonth);

      const selectedDriverDocuments: Document[] =
        this.documentsService.getDocuments();
      if (initialDriver.blockingMotives) {
        this.isBlockedForSpeeding = initialDriver.blockingMotives.includes(
          appConstants.driver.blockingMotives.speeding
        );
      }

      let documentMasterValueIdsList: string[] = [];
      driverDocuments.removeAt(0);
      for (let document of selectedDriverDocuments) {
        driverDocuments.push(
          this.formsService.createDocumentControlFromDocument(document)
        );
        documentMasterValueIdsList.push(document.masterValueId);
      }

      // Agregamos al formulario los documentos que existen en el maestro y que no
      // han sido cargados hasta el momento.
      driverDocuments = this.setAdditionalDocumentsFormGroup(
        initialDriver.center,
        driverDocuments,
        documentMasterValueIdsList
      );
    } else {
      // En modo Creación: Creamos un FormControl por cada documento
      // requerido según el Maestro
      driverDocuments = this.setInitialDocumentsFormGroup('');
    }

    // Inicialización del formulario
    this.driverForm = new FormGroup({
      carrier: new FormControl(
        { value: initialDriver.carrier.carrierId, disabled: this.editMode },
        Validators.required
      ),
      firstName: new FormControl(
        { value: initialDriver.firstName, disabled: this.editMode },
        [
          Validators.required,
          Validators.minLength(2),
          Validators.pattern(appConstants.regex.name),
        ]
      ),
      lastName: new FormControl(
        { value: initialDriver.lastName, disabled: this.editMode },
        [
          Validators.required,
          Validators.minLength(2),
          Validators.pattern(appConstants.regex.name),
        ]
      ),
      rut: new FormControl(
        { value: initialDriver.rut, disabled: this.editMode },
        [Validators.required, Validators.pattern(appConstants.regex.rut)]
      ),
      birthDate: new FormControl(
        { value: initialDriver.birthDate, disabled: this.editMode },
        [Validators.required, this.validatorsService.driverAgeValidator()]
      ),
      phone: new FormControl({ value: initialDriver.phone, disabled: false }, [
        Validators.required,
        Validators.pattern(appConstants.regex.phone),
      ]),
      email: new FormControl(
        {
          value: initialDriver.email === '' ? null : initialDriver.email,
          disabled: this.editMode,
        },
        [Validators.required, Validators.email]
      ),
      center: new FormControl(
        { value: initialDriver.center, disabled: this.editMode },
        Validators.required
      ),
      documents: driverDocuments,
    });
  }

  /**
   * Define el arreglo de controles de documentos para el formulario.
   * @param {string} center Centro al que pertenece el conductor.
   * @return {FormArray}
   * @private
   */
  private setInitialDocumentsFormGroup(center: string): FormArray {
    let driverDocuments = this.formsService.documentsFormArrayInitialization();

    let documentTypes = this.master['DRIVERS#DOCUMENT_TYPES'];
    documentTypes.sort(function (a: any, b: any) {
      let x = a.valueToDisplay.toLowerCase();
      let y = b.valueToDisplay.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    driverDocuments.removeAt(0);
    for (let document of documentTypes) {
      const isASpeedingDocument =
        document.valueId.endsWith(
          appConstants.document.codes.speeding_retraining
        ) ||
        document.valueId.endsWith(
          appConstants.document.codes.commitment_letter_for_speeding
        );

      // Los documentos para desbloqueo por exceso de velocidad no se agregan inicialmente.
      if (!isASpeedingDocument) {
        const validators: FormValidators = this.getValidators(
          document.valueId,
          center
        );
        driverDocuments.push(
          this.formsService.createDocumentControlFromMasterWithValidators(
            document,
            validators
          )
        );
      }
    }

    return driverDocuments;
  }

  /**
   * Dado un conjunto de documentos, agrega los controles de documentos
   * faltantes según el maestro, para el formulario.
   * @param {string} center Centro al que pertenece el conductor.
   * @param {FormArray} driverDocuments Arreglo de controles de documentos.
   * @param {string[]} documentMasterValueIdsList Lista de Ids de documentos incluídos en el driverDocuments.
   * @return {FormArray}
   * @private
   */
  private setAdditionalDocumentsFormGroup(
    center: string,
    driverDocuments: FormArray,
    documentMasterValueIdsList: string[]
  ): FormArray {
    let documentTypes = this.master['DRIVERS#DOCUMENT_TYPES'];
    documentTypes.sort(function (a: any, b: any) {
      let x = a.valueToDisplay.toLowerCase();
      let y = b.valueToDisplay.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    for (let document of documentTypes) {
      // Se agregan solo los documentos que no se encuentren ya en el arreglo de controles.
      if (documentMasterValueIdsList.indexOf(document.valueId) === -1) {
        const isASpeedingDocument =
          document.valueId.endsWith(
            appConstants.document.codes.speeding_retraining
          ) ||
          document.valueId.endsWith(
            appConstants.document.codes.commitment_letter_for_speeding
          );

        // Los documentos para desbloqueo por exceso de velocidad, se agregan
        // solamente si el conductor está bloqueado por Exceso de Velocidad.
        if (
          !isASpeedingDocument ||
          (isASpeedingDocument && this.isBlockedForSpeeding)
        ) {
          const validators: FormValidators = this.getValidators(
            document.valueId,
            center
          );
          driverDocuments.push(
            this.formsService.createDocumentControlFromMasterWithValidators(
              document,
              validators
            )
          );
        }
      }
    }

    return driverDocuments;
  }

  /**
   * Crea un objeto con las funciones de validación para cada uno
   * de los controles del formulario.
   * @param {string} center Centro al que pertenece el conductor.
   * @param {string} masterValueId ID del tipo de documento en el maestro.
   * @return {FormValidators}
   * @private
   */
  private getValidators(
    masterValueId: string,
    center: string = ''
  ): FormValidators {
    const isMandatory: boolean =
      this.documentsSpecs[center + '#' + masterValueId];
    return this.validatorsService.getDocumentValidatorForm(center, isMandatory);
  }

  /* ---------------------------------------- */
  /* - Métodos de validación del Formulario - */
  /* ---------------------------------------- */
  /**
   * Determina, a través del servicio de validadores,
   * si debe mostrar la ayuda de un control del formulario.
   * @param {AbstractControl} control Control del formulario.
   * @return {Boolean}
   */
  showHelper(control: AbstractControl<any, any> | null): boolean | undefined {
    return this.validatorsService.showHelper(control);
  }

  /**
   * Determina si un control del formulario es válido.
   * @param {AbstractControl} control Control del formulario.
   * @return {Boolean}
   */
  invalidControl(control: AbstractControl<any, any> | null): boolean {
    return !control?.valid;
  }

  /**
   * Consulta el mensaje de ayuda para un control del formulario.
   * @param {AbstractControl} control Control del formulario.
   * @return {string} Ayuda para el usuario.
   */
  helperMessages(control: AbstractControl<any, any> | null): string {
    return this.validatorsService.helperMessages(control);
  }

  /* --------------------------------------- */
  /* - Métodos que modifican el Formulario - */
  /* --------------------------------------- */
  /**
   * Actualiza la obligatoriedad de los documentos
   * según el centro seleccionado.
   */
  onDriverCenterChange(): void {
    if (this.selectedCenter === this.driverForm.get('center')?.value) {
      // No cambia el formulario
      console.log('Cambio de centro no altera el formulario');
    } else {
      // Actualizamos los documentos requeridos según el centro.
      this.selectedCenter = this.driverForm.get('center')?.value;
      console.log('Actualizamos los documentos requeridos según el centro');
      const driverDocuments = this.setInitialDocumentsFormGroup(
        this.selectedCenter
      );
      this.driverForm.removeControl('documents');
      this.driverForm.setControl('documents', driverDocuments);
    }
  }

  onBirthDateChange(): void {
    const birthYear: number = new Date(
      this.driverForm.get('birthDate')?.value
    ).getFullYear();
    const birthMonth: number = new Date(
      this.driverForm.get('birthDate')?.value
    ).getMonth();

    this.setDriverAge(birthYear, birthMonth);
    // El conductor debe tener entre 25 y 70 años.
    this.driverInAgeRange =
      appConstants.driver.age.min <= this.driverAge &&
      this.driverAge <= appConstants.driver.age.max;
  }

  /**
   * Establece la edad el conductor.
   * @param {number} birthYear Año de nacimiento.
   * @param {number} birthMonth Mes de nacimiento.
   * @private
   */
  private setDriverAge(birthYear: number, birthMonth: number): void {
    this.driverAge = this.currentYear - birthYear;
    // Si aún no llegamos al mes de nacimiento, se resta un año
    if (this.currentMonth < birthMonth) {
      this.driverAge -= 1;
    }
  }

  /**
   * Asigna el archivo al control documentSourceFile
   * del documento.
   * @param {Event} event Evento input.
   * @param {number} index Índice del documento en el arreglo documents.
   */
  onChangeDocumentFile(event: Event, index: number): void {
    // Asignamos el archivo al FormControl 'documentSourceFile'
    const target = event.target as HTMLInputElement;
    if (target.files!.length > 0) {
      const file = target.files![0];
      (<FormArray>this.driverForm.get('documents'))
        .at(index)
        .patchValue({ documentSourceFile: file });
    }
  }

  /**
   * Retorna el ID del Transportista al que pertenece un usuario
   * conductor o transportista.
   * @return {string} ID del transportista.
   */
  carrierValueId(): string {
    return this.usersService.getActiveUser().company;
  }

  /**
   * Retorna el nombre del Transportista al que pertenece un usuario
   * conductor o transportista.
   * @return {string} Nombre del transportista.
   */
  carrierValueToDisplay(): string {
    const company = this.usersService.getActiveUser().company;
    return this.masterDisplayMap['CARRIERS'][company];
  }

  /**
   * Consulta, a través del servicio de documentos, si un tipo de documento
   * requiere fechas de emisión y vencimiento.
   * @param {string} masterValueId ID del tipo de documento en el maestro.
   * @return {boolean}
   */
  isDatelessDocument(masterValueId: string): boolean {
    return this.documentsService.isDatelessDocument(masterValueId);
  }

  /**
   * Consulta, a través del servicio de documentos, si un tipo de documento
   * es obligatorio para un centro.
   * @param {string} masterValueId ID del tipo de documento en el maestro.
   * @return {boolean}
   */
  isMandatoryDocument(masterValueId: string): boolean {
    const center = this.driverForm.get('center')?.value;
    if (this.documentsSpecs[center + '#' + masterValueId]) {
      return this.documentsSpecs[center + '#' + masterValueId];
    } else {
      return false;
    }
  }

  /* ------------------------------- */
  /* - Métodos asociados a botones - */
  /* ------------------------------- */
  /**
   * Crea o edita un conductor.
   */
  async onSubmit(): Promise<void> {
    console.log(' onSubmit @ driver-edit.component ');
    console.log(this.driverForm.value);
    const newDriver = this.driverForm.value;
    const documentList: DocumentFromForms[] =
      this.driverForm.value.documents.slice();
    const business: string = this.usersService.business.value.toUpperCase();

    console.log('newDriver', newDriver);
    console.log('documentList', documentList);

    const uploadDateYYYYMMDD = new Date()
      .toISOString()
      .split('T')[0]
      .replaceAll('-', '');
    const uploadDateTimestamp = Math.floor(Date.now() / 1000).toString();

    if (this.editMode) {
      // Chequeamos si al menos un documento ha sido editado.
      // De lo contrario no se actualiza el conductor.
      let driverEdited: boolean = false;
      documentList.forEach((document: any) => {
        driverEdited = driverEdited || Boolean(document.documentSourceFile);
      });
      if (!driverEdited) {
        this.feedbacksService.showFeedback(
          'Debe adjuntar al menos un documento para editar el conductor',
          'danger'
        );
        return;
      }

      // Nota: En el modo Edición, el ID fue fijado al momento de iniciar el formulario.
      this.feedbacksService.showFeedback(
        'Actualizando conductor: ' + this.driverId,
        'info'
      );

      await this.notifyEdition(uploadDateTimestamp);

      // En modo Edición, solo pueden editarse los documentos y el teléfono
      // Sin embargo, el conductor vuelve a quedar en estado de "Por Aprobar"
      // 1. Se cambia en estatus del conductor
      const updateDriverInput: UpdateDriverInput = {
        driverId: this.driverId,
        status: `${business}_${appConstants.entity.codes.toBeApproved}`,
        phone: newDriver.phone,
      };

      await this.api
        .UpdateDriver(updateDriverInput)
        .then(() => {
          // 2. Se cargan los documentos del conductor, si aplica.
          this.uploadDocuments(
            documentList,
            uploadDateYYYYMMDD,
            uploadDateTimestamp
          );
        })
        .catch((response: any): void => {
          this.feedbacksService.showErrorFeedbacks(
            response,
            `Error al actualizar conductor ${newDriver.firstName} ${newDriver.lastName}`
          );
        });
    } else {
      // En el modo Crear, el ID se toma desde el formulario.
      this.driverId = newDriver.rut.replace(/[^0-9Kk]/g, '').toUpperCase();

      this.feedbacksService.showFeedback(
        `Creando conductor: ${this.driverId}`,
        'info'
      );

      // En modo creación:
      // 1. Se crea un nuevo conductor.
      const createDriverInput: CreateDriverInput = {
        driverId: this.driverId,
        firstName: newDriver.firstName,
        lastName: newDriver.lastName,
        rut: newDriver.rut.toUpperCase(),
        birthDate: newDriver.birthDate,
        phone: newDriver.phone,
        email: newDriver.email.toLowerCase(),
        business: business,
        center: newDriver.center,
        originalCenter: newDriver.center,
        status: `${business}_${appConstants.entity.codes.toBeApproved}`, // Un nuevo conductor debe ser aprobado
        blockingMotives: [],
        company: newDriver.carrier,
        carrierDriversCarrierId: newDriver.carrier,
      };

      this.api
        .CreateDriver(createDriverInput)
        .then((driver: CreateDriverMutation) => {
          this.feedbacksService.showFeedback(
            `Conductor ${driver.firstName} ${driver.lastName} creado correctamente`,
            'success'
          );

          // 2. Se cargan los documentos del conductor.
          this.uploadDocuments(
            documentList,
            uploadDateYYYYMMDD,
            uploadDateTimestamp
          );

          // 3. Se crea un nuevo usuario.
          this.feedbacksService.showFeedback(
            `Creando usuario: ${newDriver.email.toLowerCase()}`,
            'info'
          );

          // Los conductores externos no generan usuarios.
          if (business !== 'FOREIGN') {
            const createUserInput: CreateUserInput = {
              userId: newDriver.email.toLowerCase(),
              firstName: newDriver.firstName,
              lastName: newDriver.lastName,
              rut: newDriver.rut.toUpperCase(),
              phone: newDriver.phone,
              email: newDriver.email.toLowerCase(),
              business: business,
              authGroup: `${business}_DRIVERS`,
              company: newDriver.carrier,
              status: `${business}_${appConstants.user.codes.active}`,
            };
            this.api
              .CreateUser(createUserInput)
              .then((user: CreateUserMutation): void => {
                this.feedbacksService.showFeedback(
                  `Usuario ${user.email} creado correctamente`,
                  'success'
                );
              })
              .catch((response: any): void => {
                this.feedbacksService.showErrorFeedbacks(
                  response,
                  `Error al crear usuario ${newDriver.email.toLowerCase()}`
                );
              });
          }
        })
        .catch((response: any): void => {
          this.feedbacksService.showErrorFeedbacks(
            response,
            `Error al crear conductor ${newDriver.firstName} ${newDriver.lastName}`
          );
        });
    }

    this.router
      .navigate(['../'], { relativeTo: this.route })
      .then(() => console.log('navigate back'));
  }

  /**
   * Notifica la edición de una cisterna, si aplica.
   * @param {string} uploadDateTimestamp Timestamp de edición.
   */
  private async notifyEdition(uploadDateTimestamp: string): Promise<void> {
    // Si el conductor editado viene de un estado "Disponible", "Rechazado" o "Bloqueado"
    // se notifica que fue editado
    const selectedDriver: Driver = this.driversService.getSelectedDriver();
    if (
      selectedDriver.status.endsWith(appConstants.entity.codes.rejected) ||
      selectedDriver.status.endsWith(appConstants.entity.codes.blocked) ||
      selectedDriver.status.endsWith(appConstants.entity.codes.available)
    ) {
      let initialStatus: string;
      let messageInitialStatus: string;
      if (selectedDriver.status.endsWith(appConstants.entity.codes.rejected)) {
        initialStatus = 'FROMREJECTED';
        messageInitialStatus = 'rechazado';
      } else if (
        selectedDriver.status.endsWith(appConstants.entity.codes.available)
      ) {
        initialStatus = 'FROMAVAILABLE';
        messageInitialStatus = 'disponible';
      } else {
        initialStatus = 'FROMBLOCKED';
        messageInitialStatus = 'bloqueado';
      }
      const creatNotificationInput: CreateNotificationInput = {
        businessId: selectedDriver.business,
        notificationId:
          'DRIVER#' + selectedDriver.driverId + '#' + uploadDateTimestamp,
        status: 'TO_BE_SENT',
        model: 'DRIVER',
        motive: `UPDATE#${initialStatus}#TOTOBEAPPROVED`,
        driverId: selectedDriver.driverId,
      };

      await this.api
        .CreateNotification(creatNotificationInput)
        .then(async () => {
          const driverIdentification =
            (selectedDriver.sapId! === 'Sin Asignar'
              ? selectedDriver.sapId
              : parseInt(selectedDriver.sapId!)) +
            ' - ' +
            selectedDriver.firstName +
            ' ' +
            selectedDriver.lastName;
          const message: string = `Se ha enviado una notificación por la actualización del conductor ${driverIdentification} previamente ${messageInitialStatus}.`;
          this.feedbacksService.showFeedback(message, 'info');
        });
    }
  }

  /**
   * Carga una lista de documentos.
   * @param {DocumentFromForms[]} documentList Lista de documentos del formulario.
   * @param {string} uploadDateTimestamp Timestamp de la fecha de edición.
   * @param {string} uploadDateYYYYMMDD Fecha de edición.
   */
  private uploadDocuments(
    documentList: DocumentFromForms[],
    uploadDateYYYYMMDD: string,
    uploadDateTimestamp: string
  ): void {
    for (let document of documentList) {
      // Solo se actualizan los documentos que tengan un archivo asociado.
      if (document.documentSourceFile) {
        const inputDocument = {
          ...document,
          documentId: document.documentMasterValueId + '-' + this.driverId,
        };
        this.documentsService
          .uploadDocument(
            inputDocument,
            uploadDateYYYYMMDD,
            uploadDateTimestamp,
            this.usersService.business.value,
            'DRIVER',
            this.driverId
          )
          .then();
      }
    }
  }

  /**
   * Navega al detalle del conductor.
   */
  onCancel(): void {
    // Regresamos al detalle del conductor.
    this.router
      .navigate(['../'], { relativeTo: this.route })
      .then(() => console.log('navigate to details'));
  }

  /* --------------------------------------- */
  /* - Métodos para ambiente de desarrollo - */
  /* --------------------------------------- */
  /**
   * Muestra el formulario en la consola.
   * Nota: Solo aparece en DEV.
   */
  showForm() {
    console.log('-- Current Form --');
    console.log(this.driverForm);
  }

  async ngOnDestroy(): Promise<void> {
    if (this.editionId !== '') {
      await this.editionsService.releaseEdition(this.editionId);
    }
  }
}
