<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ modalTitle }}</h4>
  <button
    id="details-modal-close"
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="d('Cross')"
  ></button>
</div>
<div class="modal-body">
  <p>
    <strong>{{ modalQuestion }}</strong>
  </p>
  <p></p>
  <form [formGroup]="commentForm">
    <div class="container-fluid">
      <div class="row my-1">
        <div class="col-sm">
          <label class="form-label">{{ modalMessage }}</label>
          <select
            id="exclusionMotive"
            formControlName="exclusionMotive"
            class="form-select"
            (change)="onChangeExclusionMotive()"
          >
            <option value="" selected>Seleccionar...</option>
            <option
              *ngFor="let exclusionMotive of exclusionMotives"
              [ngValue]="exclusionMotive.valueId"
            >
              {{ exclusionMotive.valueToDisplay }}
            </option>
          </select>
        </div>
      </div>
      <div class="row my-1">
        <div class="col-sm">
          <label for="comment" class="form-label"
            >Seleccione la fecha hasta la cual durará el bloqueo</label
          >
          <input
            [min]="tomorrow"
            type="date"
            id="excludeDuration"
            formControlName="excludeDuration"
            class="form-control"
          />
        </div>
      </div>
      <div class="row my-1">
        <div class="col-sm">
          <label class="form-label">
            <input
              type="checkbox"
              id="isPermanent"
              formControlName="isPermanent"
              (change)="onChangePermanent()"
            />
            Bloquear indefinidamente al conductor.
          </label>
        </div>
      </div>
      <div class="row my-1">
        <div class="col-sm">
          <label for="comment" class="form-label"
            >Comentario
            <span *ngIf="false" class="text-danger fs-4">*</span></label
          >
          <div class="form-group">
            <input
              type="text"
              id="comment"
              formControlName="comment"
              class="form-control immutable-field"
              placeholder="máximo 300 caracteres"
              appInputTrim
            />
            <div *ngIf="showHelper(notificationControl.get('comment'))">
              <small class="form-text text-danger text-muted">
                {{ helperMessages(notificationControl.get("comment")) }}
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    id="details-modal-cancel"
    type="button"
    class="btn btn-outline-danger"
    (click)="d('Cancel')"
  >
    Cancelar
  </button>
  <button
    id="details-modal-accept"
    type="button"
    class="btn btn-outline-primary"
    (click)="c('Accept')"
    [disabled]="!commentForm.valid"
  >
    Aceptar
  </button>
</div>
