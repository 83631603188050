import { Pipe, PipeTransform } from '@angular/core';
import { appConstants } from '../constants/constants';

@Pipe({
  name: 'modelName',
})
export class ModelNamePipe implements PipeTransform {
  /**
   * Transforma el nombre de un modelo a valor legible para el front.
   * @param {string} value Nombre del modelo.
   * @return {string} Nombre del modelo legible.
   */
  transform(
    value:
      | 'driver'
      | 'vehicle'
      | 'envasado'
      | 'cisterna'
      | 'tracto'
      | 'tanque'
      | 'semirremolque'
  ): string {
    const displayName = appConstants[value]['displayName'];
    if (displayName) {
      let plural: string = 's';
      if (displayName.charAt(displayName.length - 1) === 'r') {
        plural = 'es';
      }
      return appConstants[value]['displayName'] + plural;
    }
    return 'Sin traducción';
  }
}
