import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  APIService,
  Cisterna,
  CisternasByBusinessAndCisternaIdQuery,
  CisternasByCenterAndCisternaIdQuery,
  CisternasByCompanyAndCisternaIdQuery,
  GetCisternaQuery,
  ModelSubscriptionCisternaFilterInput,
  ModelSubscriptionIDInput,
  User,
} from '../../app-sync.service';
import { DocumentsService } from '../documents/documents.service';
import { UsersService } from '../users/users.service';
import { appConstants } from '../../shared/constants/constants';
import { filterInputs } from '../../shared/types/filter-inputs';
import { FeedbacksService } from '../../shared/feedbacks/feedbacks.service';

@Injectable({
  providedIn: 'root',
})
export class CisternasService {
  selectedCisternaChanged = new Subject<Cisterna>();
  cisternasChanged = new Subject<Cisterna[]>();

  private readonly initialCisterna: Cisterna;
  private selectedCisterna: Cisterna;
  private cisternas: Cisterna[] = [];
  private cisternasFilterForSubscriptions: ModelSubscriptionCisternaFilterInput =
    {};
  private selectedCisternaFilterForSubscriptions: ModelSubscriptionCisternaFilterInput =
    {};

  constructor(
    private api: APIService,
    private documentsService: DocumentsService,
    private usersService: UsersService,
    private feedbacksService: FeedbacksService
  ) {
    this.initialCisterna = {
      ...appConstants.cisterna.initialization,
      carrier: appConstants.carrier.initialization,
    };
    this.selectedCisterna = this.initialCisterna;
    this.setCisternasFilter();
    this.setSelectedCisternaFilterForSubscriptions();
  }

  // -------------------------------------
  // Métodos para la cisterna seleccionada
  // -------------------------------------
  /**
   * Configura la cisterna seleccionada para referencia de
   * todos los componentes.
   * @param {Cisterna} cisterna Cisterna seleccionada.
   */
  setSelectedCisterna(cisterna: Cisterna): void {
    this.selectedCisterna = cisterna;
    this.setSelectedCisternaFilterForSubscriptions();
    this.selectedCisternaChanged.next({ ...this.selectedCisterna });
    console.log('selectedCisterna', this.selectedCisterna);

    // Documentos
    this.documentsService.setId('cisternaId', this.selectedCisterna.cisternaId);
    this.documentsService.setSelectedModel('CISTERNA');
    this.documentsService.setSelectedCenter(cisterna.center);
    this.documentsService.setDocumentsFilter();
    this.documentsService
      .refreshDocuments()
      .then(() => console.log('Documentos de la cisterna actualizados.'));
  }

  /**
   * Actualiza los datos de la cisterna seleccionada.
   * @return {Promise}
   */
  async refreshSelectedCisterna(): Promise<void> {
    const getCisternaResult: GetCisternaQuery = await this.api.GetCisterna(
      this.selectedCisterna.cisternaId
    );
    this.setSelectedCisterna(<Cisterna>getCisternaResult);
  }

  /**
   * Retorna la cisterna seleccionada.
   * @return {Cisterna}
   */
  getSelectedCisterna(): Cisterna {
    return { ...this.selectedCisterna };
  }

  // ----------------------------------
  // Métodos para la lista de Vehículos
  // ----------------------------------
  /**
   * Configura la lista de cisternas para referencia de
   * todos los componentes.
   * @param {Cisterna[]} cisternas Lista de cisternas.
   */
  setCisternas(cisternas: Cisterna[]): void {
    this.cisternas = cisternas;
    this.setCisternasFilter();
    this.cisternasChanged.next(this.cisternas.slice());
  }

  /**
   * Actualiza la lista de cisternas.
   * @return {Promise}
   */
  async refreshCisternas(): Promise<void> {
    const activeUser: User = this.usersService.getActiveUser();
    const business: string = this.usersService.business.value.toUpperCase();
    let tempListCisternas: Cisterna[] = [];

    // Verificamos el grupo del usuario
    if (activeUser.authGroup.endsWith('_CARRIERS')) {
      // Los transportistas podrán acceder a vehículos donde
      // ellos figuren como Carrier
      tempListCisternas = await this.listCisternasByCarrier(activeUser);
    } else if (activeUser.authGroup.endsWith('_ADMINS')) {
      // Los administradores podrán acceder a todos los vehículos/conductores
      // asociados a su negocio
      tempListCisternas = await this.listCisternasByBusiness(business);
    } else if (
      activeUser.authGroup.endsWith('_VIEWERS') ||
      activeUser.authGroup.endsWith('_APPROVERS')
    ) {
      // Los visualizadores y aprobadores podrán acceder a todos los vehículos
      // asociados a los centros a los que pertenece el usuario.
      tempListCisternas = await this.listCisternasByCenter(
        activeUser,
        business
      );
    } else {
      this.feedbacksService.showFeedback(
        `Error obteniendo cisternas. Grupo ${activeUser.authGroup} no tiene regla definida.`,
        'danger'
      );
    }

    this.setCisternas(tempListCisternas.slice());
  }

  /**
   * Retorna la lista de Vehículos usando el GSI de Negocio.
   * @param business {string} Negocio.
   * @private
   */
  private async listCisternasByBusiness(business: string): Promise<Cisterna[]> {
    let listCisternasResult: CisternasByBusinessAndCisternaIdQuery;
    listCisternasResult = await this.api.CisternasByBusinessAndCisternaId(
      business
    );

    let tempListCisternas: Cisterna[] = <Cisterna[]>listCisternasResult.items;
    let nextToken: string | null | undefined = listCisternasResult.nextToken;
    // Es posible que la primera query no retorne todos los vehículos.
    while (nextToken) {
      let loopListCisternasResult: CisternasByBusinessAndCisternaIdQuery =
        await this.api.CisternasByBusinessAndCisternaId(
          business,
          undefined,
          undefined,
          undefined,
          100,
          nextToken
        );
      tempListCisternas.push(...(<Cisterna[]>loopListCisternasResult.items));
      nextToken = loopListCisternasResult.nextToken;
    }
    return tempListCisternas.slice();
  }

  /**
   * Retorna la lista de Vehículos usando el GSI de Centros.
   * @param activeUser {User} Usuario activo.
   * @param business {string} Negocio.
   * @private
   */
  private async listCisternasByCenter(
    activeUser: User,
    business: string
  ): Promise<Cisterna[]> {
    let tempListCisternas: Cisterna[] = [];
    const centers: string[] =
      JSON.parse(activeUser.centers || `{"${business}": []}`)[business] || [];

    // Ejecutar todas las peticiones en paralelo
    const cisternasPromises: Promise<Cisterna[]>[] = centers.map(
      async (center: string): Promise<Cisterna[]> => {
        let listCisternasResult: CisternasByCenterAndCisternaIdQuery;

        listCisternasResult = await this.api.CisternasByCenterAndCisternaId(
          center
        );
        let cisternas: Cisterna[] = <Cisterna[]>listCisternasResult.items;

        let nextToken: string | null | undefined =
          listCisternasResult.nextToken;

        // Si hay más datos, seguir llamando hasta que no haya nextToken
        while (nextToken) {
          let loopListCisternasResult: CisternasByCenterAndCisternaIdQuery =
            await this.api.CisternasByCenterAndCisternaId(
              center,
              undefined,
              undefined,
              undefined,
              100,
              nextToken
            );
          cisternas.push(...(<Cisterna[]>loopListCisternasResult.items));
          nextToken = loopListCisternasResult.nextToken;
        }

        return cisternas;
      }
    );

    // Esperar a que todas las promesas se resuelvan
    const results: Cisterna[][] = await Promise.all(cisternasPromises);

    // Combinar todos los resultados en una sola lista
    results.forEach((cisternas: Cisterna[]): void => {
      tempListCisternas.push(...cisternas);
    });

    return tempListCisternas.slice();
  }

  /**
   * Retorna la lista de Vehículos usando el GSI de Transportista.
   * @param activeUser {User} Usuario activo.
   * @private
   */
  private async listCisternasByCarrier(activeUser: User): Promise<Cisterna[]> {
    let listCisternasResult: CisternasByCompanyAndCisternaIdQuery;
    listCisternasResult = await this.api.CisternasByCompanyAndCisternaId(
      activeUser.company
    );

    let tempListCisternas: Cisterna[] = <Cisterna[]>listCisternasResult.items;
    let nextToken: string | null | undefined = listCisternasResult.nextToken;
    // Es posible que la primera query no retorne todos los vehículos.
    while (nextToken) {
      let loopListCisternasResult: CisternasByCompanyAndCisternaIdQuery =
        await this.api.CisternasByCompanyAndCisternaId(
          activeUser.company,
          undefined,
          undefined,
          undefined,
          100,
          nextToken
        );
      tempListCisternas.push(...(<Cisterna[]>loopListCisternasResult.items));
      nextToken = loopListCisternasResult.nextToken;
    }
    return tempListCisternas.slice();
  }

  /**
   * Retorna la lista de cisternas.
   * @return {Cisterna[]}
   */
  getCisternas(): Cisterna[] {
    return this.cisternas.slice();
  }

  // ------------------------------------------
  // Métodos para filtro de consultas a AppSync
  // ------------------------------------------
  /**
   * Configura el filtro para las consultas
   * de listas de cisternas y subscripciones.
   * @private
   */
  private setCisternasFilter(): void {
    let modelFilterInput: filterInputs = this.usersService.getEntitiesFilter();

    this.cisternasFilterForSubscriptions = <
      ModelSubscriptionCisternaFilterInput
    >modelFilterInput;
  }

  /**
   * Retorna el filtro para las subscripciones
   * de listas de cisternas.
   * @return {ModelSubscriptionCisternaFilterInput}
   */
  getCisternasFilterForSubscriptions(): ModelSubscriptionCisternaFilterInput {
    return { ...this.cisternasFilterForSubscriptions };
  }

  /**
   * Configura el filtro para las subscripciones
   * de la cisterna seleccionada.
   */
  private setSelectedCisternaFilterForSubscriptions(): void {
    const modelSubscriptionIDInput: ModelSubscriptionIDInput = {
      eq: this.selectedCisterna.cisternaId,
    };
    this.selectedCisternaFilterForSubscriptions = {
      cisternaId: modelSubscriptionIDInput,
    };
  }

  /**
   * Retorna el filtro para las subscripciones
   * de la cisterna seleccionada.
   * @return {ModelSubscriptionCisternaFilterInput}
   */
  getSelectedCisternaFilterForSubscriptions(): ModelSubscriptionCisternaFilterInput {
    return { ...this.selectedCisternaFilterForSubscriptions };
  }
}
