import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ZenObservable } from 'zen-observable-ts';

import { appConstants } from '../../../shared/constants/constants';

import {
  APIService,
  CreateNotificationInput,
  DeleteCisternaInput,
  UpdateDocumentInput,
  UpdateCisternaInput,
  UpdateCisternaMutation,
  Cisterna,
} from '../../../app-sync.service';
import { FeedbacksService } from '../../../shared/feedbacks/feedbacks.service';
import { CisternasService } from '../cisternas.service';
import { ModalsService } from '../../../shared/modals/modals.service';
import { DocumentsService } from '../../documents/documents.service';
import { UsersService } from '../../users/users.service';
import { EditionsService } from '../../../shared/services/editions.service';
import { MasterService } from '../../master/master.service';
import { ApiRequestsService } from '../../../shared/services/api-requests.service';
import { FormsService } from '../../../shared/services/forms.service';

@Component({
  selector: 'app-cisterna-details',
  templateUrl: './cisterna-details.component.html',
  styleUrls: ['./cisterna-details.component.css'],
})
export class CisternaDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('cisternaModal', { static: false }) cisternaModal:
    | TemplateRef<any>
    | undefined;
  @ViewChild('updateCisternaAttributeModal', { static: false })
  updateCisternaAttributeModal: TemplateRef<any> | undefined;
  currentYear: number = new Date().getFullYear();
  isAdmin: boolean = false;
  isApprover: boolean = false;
  isCarrier: boolean = false;
  isBeingRejected: boolean = false;
  isGettingCisterna: boolean = false;
  isUploadingDocuments: boolean = false;
  modalMessage: string = '';
  modalQuestion: string = '';
  modalTitle: string = '';
  modalWithComment: boolean = false;
  commentForm: FormGroup;
  updateAttributeForm: FormGroup;
  cisterna: Cisterna;
  cisternaNeedsApproval: boolean = false;
  cisternaCanBeRejected: boolean = false;
  showBlockingMotives: boolean = false;
  hasComment: boolean = false;
  showComment: boolean = false;
  master: any = {};
  masterDisplayMap: any = {};

  private selectedCisternaChangedSubscription: Subscription =
    new Subscription();
  private onUpdateCisternaListener: ZenObservable.Subscription =
    new Subscription();
  private isUploadingDocumentsSubscription: Subscription = new Subscription();
  private requestSucceededSubscription: Subscription = new Subscription();

  constructor(
    private api: APIService,
    private apiRequestsService: ApiRequestsService,
    private cisternasService: CisternasService,
    private documentsService: DocumentsService,
    private editionsService: EditionsService,
    private feedbacksService: FeedbacksService,
    private masterService: MasterService,
    private modalsService: ModalsService,
    private route: ActivatedRoute,
    private router: Router,
    private usersService: UsersService,
    private formsService: FormsService
  ) {
    this.cisterna = this.cisternasService.getSelectedCisterna();
    this.isUploadingDocuments =
      this.documentsService.isUploadingDocuments.value;

    this.commentForm = new FormGroup({
      comment: new FormControl(null),
    });
    this.updateAttributeForm = new FormGroup({
      center: new FormControl(null),
    });
  }

  async ngOnInit() {
    this.master = this.masterService.getMaster();
    this.masterDisplayMap = this.masterService.getMasterDisplayMap();

    this.selectedCisternaChangedSubscription =
      this.cisternasService.selectedCisternaChanged.subscribe(
        (cisterna: Cisterna) => {
          this.cisterna = cisterna;
          this.setCisternaSituation();
        }
      );

    this.isAdmin = this.usersService.isAdmin;
    this.isApprover = this.usersService.isApprover;
    this.isCarrier = this.usersService.isCarrier;

    await this.updateSelectedCisterna();

    // Subscripción a actualizaciones de la Cisterna
    this.onUpdateCisternaListener = this.api
      .OnUpdateCisternaListener(
        this.cisternasService.getSelectedCisternaFilterForSubscriptions()
      )
      .subscribe((response) => {
        if (response) {
          this.cisternasService.refreshSelectedCisterna();
        }
      });

    // Suscripción a la variable que indica si se están cargando documentos.
    this.isUploadingDocumentsSubscription =
      this.documentsService.isUploadingDocuments.subscribe(
        (value: boolean): void => {
          this.isUploadingDocuments = value;
        }
      );

    // Subscripción a consultas de API exitosas
    this.requestSucceededSubscription =
      this.apiRequestsService.requestSucceeded.subscribe(
        (requestSucceeded: boolean): void => {
          if (requestSucceeded) {
            this.onRefreshCisterna();
          }
        }
      );
  }

  /**
   * Actualiza la Cisterna seleccionada.
   * @return {Promise}
   * @private
   */
  private async updateSelectedCisterna(): Promise<void> {
    this.isGettingCisterna = true;
    await this.cisternasService.refreshSelectedCisterna().then(() => {
      this.setCisternaSituation();
      this.isGettingCisterna = false;
    });
  }

  /**
   * Ejecuta el modal de aprobación de una cisterna y pide su
   * actualización en caso de ser aprobado.
   * @return {Promise}
   */
  async onApproveCisterna(): Promise<void> {
    this.initCommentForm();
    this.modalTitle = appConstants.cisterna.modalMessages.approve.title;
    this.modalQuestion =
      appConstants.cisterna.modalMessages.approve.question.replace(
        '_',
        this.getCisternaIdentification()
      );
    this.modalMessage = appConstants.cisterna.modalMessages.approve.message;
    this.modalWithComment = true;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.cisternaModal
    );

    if (modalResult) {
      this.isGettingCisterna = true;
      await this.updateCisterna('approve', this.commentForm.value.comment);
    }
  }

  /**
   * Ejecuta el modal de rechazo de una cisterna y pide su
   * actualización en caso de ser rechazado.
   * @return {Promise}
   */
  async onRejectCisterna(): Promise<void> {
    this.isBeingRejected = true;
    this.initCommentForm();
    this.modalTitle = appConstants.cisterna.modalMessages.reject.title;
    this.modalQuestion =
      appConstants.cisterna.modalMessages.reject.question.replace(
        '_',
        this.getCisternaIdentification()
      );
    this.modalMessage = appConstants.cisterna.modalMessages.reject.message;
    this.modalWithComment = true;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.cisternaModal
    );

    if (modalResult) {
      this.isGettingCisterna = true;
      console.log(' onRejectCisterna @ cisterna-details.component ');
      console.log(this.commentForm.value);
      const newNotification = this.commentForm.value;
      const today: Date = new Date();
      const todayTimestamp: string = Math.floor(
        today.getTime() / 1000
      ).toString();
      const creatNotificationInput: CreateNotificationInput = {
        businessId: this.cisterna.business,
        notificationId:
          'CISTERNA#' + this.cisterna.cisternaId + '#' + todayTimestamp,
        status: 'TO_BE_SENT',
        model: 'CISTERNA',
        motive: 'APPROVE#REJECTED',
        cisternaId: this.cisterna.cisternaId,
        comment: newNotification.comment,
      };

      await this.api
        .CreateNotification(creatNotificationInput)
        .then(async (): Promise<void> => {
          await this.updateCisterna('reject', newNotification.comment);
        });
    }

    this.isBeingRejected = false;
  }

  /**
   * Actualiza una cisterna.
   * @param {string} action Acción a realizar, puede ser 'approve' o 'reject'.
   * @param {string} comment Comentario de aprobación o rechazo.
   * @return {Promise}
   * @private
   */
  private async updateCisterna(
    action: 'approve' | 'reject',
    comment: string | null = null
  ): Promise<void> {
    this.isGettingCisterna = true;
    let newCisternaStatus: string;
    let feedbackMessage: string = `Cisterna ${this.getCisternaIdentification()} `;
    let catchFeedbackMessage: string = `Error al _ cisterna ${this.getCisternaIdentification()}`;

    switch (action) {
      case 'approve':
        // Se cambia el estado de la Cisterna a "Procesando"
        newCisternaStatus = `${this.cisterna.business}_${appConstants.entity.codes.inProcess}`;
        feedbackMessage = feedbackMessage + 'aprobado. Actualizando';
        catchFeedbackMessage = catchFeedbackMessage.replace('_', 'aprobar');
        break;
      case 'reject':
        // Se cambia el estado de la Cisterna a "Rechazado"
        newCisternaStatus = `${this.cisterna.business}_${appConstants.entity.codes.rejected}`;
        feedbackMessage = feedbackMessage + 'rechazado. Enviando notificación';
        catchFeedbackMessage = catchFeedbackMessage.replace('_', 'rechazar');
        break;
    }

    const updateInputCisterna: UpdateCisternaInput = {
      cisternaId: this.cisterna.cisternaId,
      status: newCisternaStatus,
      comment: comment,
    };

    await this.api
      .UpdateCisterna(updateInputCisterna)
      .then(async (cisterna: UpdateCisternaMutation): Promise<void> => {
        this.feedbacksService.showFeedback(feedbackMessage, 'info');

        if (action === 'approve') {
          // Se cambia el estado de los documentos con estado "Por Aprobar"
          // al estado "Procesando"
          for (let document of cisterna.documents!.items) {
            if (
              document?.status ===
              `${cisterna.business}_${appConstants.document.codes.toBeApproved}`
            ) {
              const updateDocumentInput: UpdateDocumentInput = {
                documentId: document.documentId,
                name: document.name,
                status: `${cisterna.business}_${appConstants.document.codes.inProcess}`,
              };
              await this.documentsService.updateDocument(updateDocumentInput);
            }
          }
        }

        // Actualizamos la Cisterna seleccionada.
        await this.updateSelectedCisterna();
      })
      .catch((response: any): void => {
        this.feedbacksService.showErrorFeedbacks(
          response,
          catchFeedbackMessage
        );
      });
  }

  /**
   * Ejecuta el modal de edición de atributos de la Cisterna.
   * @return {Promise}
   */
  async onUpdateCisterna(): Promise<void> {
    this.initUpdateAttributeForm();

    this.modalTitle = appConstants.cisterna.modalMessages.edit.title;
    this.modalQuestion =
      appConstants.cisterna.modalMessages.edit.question.replace(
        '_',
        this.getCisternaIdentification()
      );
    this.modalMessage = appConstants.cisterna.modalMessages.edit.message;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.updateCisternaAttributeModal
    );

    if (modalResult) {
      if (this.updateAttributeForm.invalid) {
        this.feedbacksService.showFeedback(
          'Formulario inválido, no será procesada la petición.',
          'danger'
        );
        return;
      }

      const patchedCisterna = this.updateAttributeForm.value;
      const payload: { [key: string]: string } = {
        center: patchedCisterna.center,
      };

      this.apiRequestsService.updateEntity(
        this.cisterna.business,
        this.cisterna.__typename,
        this.getCisternaIdentification(),
        payload
      );
    }
  }

  /**
   * Navega al formulario de edición de la Cisterna.
   * @return {Promise}
   */
  async onEditCisterna(): Promise<void> {
    const editionId: string = `${this.usersService.business.value.toUpperCase()}#CISTERNA#${
      this.cisterna.cisternaId
    }`;

    if (await this.editionsService.editionIsTaken(editionId)) {
      return;
    }

    this.router
      .navigate(['edit'], { relativeTo: this.route })
      .then(() => console.log('navigate to edit'));
  }

  /**
   * Ejecuta el modal de eliminación de una cisterna y lo
   * elimina en caso de aceptar.
   * @return {Promise}
   */
  async onDeleteCisterna(): Promise<void> {
    this.modalTitle = appConstants.cisterna.modalMessages.delete.title;
    this.modalQuestion =
      appConstants.cisterna.modalMessages.delete.question.replace(
        '_',
        this.getCisternaIdentification()
      );
    this.modalMessage = appConstants.cisterna.modalMessages.delete.message;
    this.modalWithComment = false;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.cisternaModal
    );

    if (modalResult) {
      const deleteCisternaInput: DeleteCisternaInput = {
        cisternaId: this.cisterna.cisternaId,
      };

      await this.api
        .DeleteCisterna(deleteCisternaInput)
        .then(async () => {
          this.feedbacksService.showFeedback(
            `Cisterna ${this.getCisternaIdentification()} eliminado.`,
            'info'
          );
        })
        .catch((response: any): void => {
          this.feedbacksService.showErrorFeedbacks(
            response,
            `Error al borrar cisterna ${this.getCisternaIdentification()}`
          );
        });
      this.router
        .navigate(['/', this.cisterna.business.toLowerCase(), 'cisternas'])
        .then(() => console.log('navigate to cisternas'));
    }
  }

  /**
   * Llama a refrescar la Cisterna seleccionada.
   */
  onRefreshCisterna(): void {
    this.updateSelectedCisterna().then(() =>
      console.log('Cisterna seleccionada actualizado.')
    );
  }

  /**
   * Colapsa o muestra el comentario del vehículo.
   */
  onViewComment(): void {
    this.showComment = !this.showComment;
  }

  /**
   * Retorna la clase que colapsa o muestra el comentario del vehículo.
   * @return {string} Clase CSS
   */
  showCommentClass(): string {
    return this.showComment ? 'show' : '';
  }

  /**
   * Inicializa el formulario de rechazo de una cisterna.
   * @private
   */
  private initCommentForm(): void {
    // Inicialización del formulario
    this.commentForm = this.formsService.generateCommentForm(
      this.isBeingRejected
    );
  }

  /**
   * Inicializa el formulario de actualización de
   * atributos de una Cisterna.
   * @private
   */
  private initUpdateAttributeForm(): void {
    // Inicialización del formulario
    this.updateAttributeForm = new FormGroup({
      center: new FormControl(this.cisterna.center, [Validators.required]),
    });
  }

  /**
   * Indica si una cisterna tiene documentos por vencer.
   * @return {boolean} Boolean que indica si la Cisterna tiene documentos por vencer.
   */
  hasToExpireDocuments(): boolean {
    const docNumber: number = this.cisterna.documentsToExpire || 0;
    return docNumber > 0;
  }

  /**
   * Indica si una cisterna tiene documentos vencidos.
   * @return {boolean} Boolean que indica si la Cisterna tiene documentos vencidos.
   */
  hasExpiredDocuments(): boolean {
    const docNumber: number = this.cisterna.documentsExpired || 0;
    return docNumber > 0;
  }

  /**
   * Retorna un color dependiendo del estado del envasado.
   * @return {string}
   */
  getColor(): string {
    return this.masterService.getSatusColor(this.cisterna.status);
  }

  /**
   * Retorna la fuente de imagen a mostrar en el Estado del envasado.
   * @return {string} ruta a imagen.
   */
  getImageSrc(status: string): string {
    return this.masterService.getImageSrc(status);
  }

  /**
   * Retorna una clase CSS dependiendo de la edad de la Cisterna.
   * @return {string}
   */
  getCisternaAgeClass(age: number): string {
    let cisternaAgeClass: string = '';
    if (age >= appConstants.cisterna.ageToBeConsideredOld) {
      cisternaAgeClass = 'text-danger';
    }
    return cisternaAgeClass;
  }

  /**
   * Determina, con base al rol del usuario, cuáles botones mostrar.
   * @param {string} button Acción del botón.
   */
  showButton(button: string): boolean {
    let show: boolean = false;

    switch (button) {
      case 'delete':
        show = this.isAdmin;
        break;
      case 'edit-center':
        show = this.isAdmin && this.cisterna.business === 'LUBRICANTS';
        break;
      case 'approve':
      case 'reject':
        show = this.isAdmin || this.isApprover;
        break;
      case 'edit':
        show = this.isAdmin || this.isCarrier;
        break;
      default:
        break;
    }
    return show;
  }

  /**
   * Define la situación de la Cisterna: ¿necesita aprobación?, ¿puede ser rechazado?
   * ¿Está bloqueado?
   * @private
   */
  private setCisternaSituation(): void {
    const cisternaToBeApprove: boolean =
      this.cisterna.status ===
      `${this.cisterna.business}_${appConstants.entity.codes.toBeApproved}`;
    const cisternaRejected: boolean =
      this.cisterna.status ===
      `${this.cisterna.business}_${appConstants.entity.codes.rejected}`;
    this.cisternaNeedsApproval = cisternaToBeApprove || cisternaRejected;
    this.cisternaCanBeRejected = cisternaToBeApprove;
    this.showBlockingMotives =
      this.cisterna.blockingMotives !== null &&
      this.cisterna.blockingMotives !== undefined &&
      this.cisterna.blockingMotives.length > 0 &&
      this.cisterna.status !==
        `${this.cisterna.business}_${appConstants.entity.codes.available}`;
    this.hasComment = !!this.cisterna.comment;
    this.showComment = false;
  }

  /**
   * Retorna la identificación de la Cisterna para los modales.
   * @return {string}
   * @private
   */
  private getCisternaIdentification(): string {
    let sapId: string;
    if (this.cisterna.sapId! === 'Sin Asignar') {
      sapId = this.cisterna.sapId!;
    } else {
      sapId = parseInt(this.cisterna.sapId!).toString();
    }
    return sapId + ' - ' + this.cisterna.licensePlate;
  }

  /**
   * Retorna la fuente de la imagen según la capacidad de la cisterna.
   * @return {string} Ruta a la imagen
   */
  capacityImage(): string {
    const business: string = this.usersService.business.value.toUpperCase();
    let srcPath: string = '../../../../assets/images/capacities/capacity-';
    let imageId: string = this.cisterna.capacity.replace(business + '_', '');
    return (srcPath + imageId + '.jpg').replaceAll('#', '%23');
  }

  ngOnDestroy(): void {
    this.selectedCisternaChangedSubscription.unsubscribe();
    this.onUpdateCisternaListener.unsubscribe();
    this.isUploadingDocumentsSubscription.unsubscribe();
    this.requestSucceededSubscription.unsubscribe();
    console.log('cisterna-details.component subscriptions removed.');
  }
}
