import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ZenObservable } from 'zen-observable-ts';

import { appConstants } from '../../../shared/constants/constants';

import {
  APIService,
  CreateNotificationInput,
  DeleteTractoInput,
  Tracto,
  UpdateDocumentInput,
  UpdateTractoInput,
  UpdateTractoMutation,
} from '../../../app-sync.service';
import { FeedbacksService } from '../../../shared/feedbacks/feedbacks.service';
import { TractosService } from '../tractos.service';
import { ModalsService } from '../../../shared/modals/modals.service';
import { DocumentsService } from '../../documents/documents.service';
import { UsersService } from '../../users/users.service';
import { EditionsService } from '../../../shared/services/editions.service';
import { ApiRequestsService } from '../../../shared/services/api-requests.service';
import { MasterService } from '../../master/master.service';
import { FormsService } from '../../../shared/services/forms.service';

@Component({
  selector: 'app-tracto-details',
  templateUrl: './tracto-details.component.html',
  styleUrls: ['./tracto-details.component.css'],
})
export class TractoDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('tractoModal', { static: false }) tractoModal:
    | TemplateRef<any>
    | undefined;
  @ViewChild('updateTractoAttributeModal', { static: false })
  updateTractoAttributeModal: TemplateRef<any> | undefined;
  currentYear: number = new Date().getFullYear();
  isAdmin: boolean = false;
  isApprover: boolean = false;
  isCarrier: boolean = false;
  isBeingRejected: boolean = false;
  isGettingTracto: boolean = false;
  isUploadingDocuments: boolean = false;
  modalMessage: string = '';
  modalQuestion: string = '';
  modalTitle: string = '';
  modalWithComment: boolean = false;
  commentForm: FormGroup;
  updateAttributeForm: FormGroup;
  tracto: Tracto;
  tractoNeedsApproval: boolean = false;
  tractoCanBeRejected: boolean = false;
  tractoIsCoupled: boolean = false;
  showBlockingMotives: boolean = false;
  coupleType: 'Tanque' | 'Semirremolque' | '' = '';
  coupleIdentification: string = '';
  coupleStatus: string = '';
  hasComment: boolean = false;
  showComment: boolean = false;
  master: any = {};
  masterDisplayMap: any = {};

  private selectedTractoChangedSubscription: Subscription = new Subscription();
  private onUpdateTractoListener: ZenObservable.Subscription =
    new Subscription();
  private isUploadingDocumentsSubscription: Subscription = new Subscription();
  private requestSucceededSubscription: Subscription = new Subscription();

  constructor(
    private api: APIService,
    private apiRequestsService: ApiRequestsService,
    private documentsService: DocumentsService,
    private editionsService: EditionsService,
    private feedbacksService: FeedbacksService,
    private masterService: MasterService,
    private modalsService: ModalsService,
    private route: ActivatedRoute,
    private router: Router,
    private tractosService: TractosService,
    private usersService: UsersService,
    private formsService: FormsService
  ) {
    this.tracto = this.tractosService.getSelectedTracto();
    this.isUploadingDocuments =
      this.documentsService.isUploadingDocuments.value;

    this.commentForm = new FormGroup({
      comment: new FormControl(null),
    });
    this.updateAttributeForm = new FormGroup({
      center: new FormControl(null),
    });
  }

  async ngOnInit() {
    this.master = this.masterService.getMaster();
    this.masterDisplayMap = this.masterService.getMasterDisplayMap();

    this.selectedTractoChangedSubscription =
      this.tractosService.selectedTractoChanged.subscribe((tracto: Tracto) => {
        this.tracto = tracto;
        // Chequeamos si tiene acople
        this.tractoIsCoupled = Boolean(tracto.tractoCoupleCoupleId);
        if (this.tractoIsCoupled) {
          this.coupleType = tracto.couple!.coupleTanqueTanqueId
            ? 'Tanque'
            : 'Semirremolque';
          this.coupleStatus = tracto.couple!.status;

          if (this.coupleType === 'Tanque') {
            this.coupleIdentification = `${
              tracto.couple!.coupleTanqueTanqueId
            }`;
          } else {
            this.coupleIdentification = `${
              tracto.couple!.coupleSemirremolqueSemirremolqueId
            }`;
          }
        } else {
          this.coupleType = '';
          this.coupleIdentification = '';
          this.coupleStatus = '';
        }
        this.setTractoSituation();
      });

    this.isAdmin = this.usersService.isAdmin;
    this.isApprover = this.usersService.isApprover;
    this.isCarrier = this.usersService.isCarrier;

    await this.updateSelectedTracto();

    // Subscripción a actualizaciones del Tracto
    this.onUpdateTractoListener = this.api
      .OnUpdateTractoListener(
        this.tractosService.getSelectedTractoFilterForSubscriptions()
      )
      .subscribe((response) => {
        if (response) {
          this.tractosService.refreshSelectedTracto();
        }
      });

    // Suscripción a la variable que indica si se están cargando documentos.
    this.isUploadingDocumentsSubscription =
      this.documentsService.isUploadingDocuments.subscribe(
        (value: boolean): void => {
          this.isUploadingDocuments = value;
        }
      );

    // Subscripción a consultas de API exitosas
    this.requestSucceededSubscription =
      this.apiRequestsService.requestSucceeded.subscribe(
        (requestSucceeded: boolean): void => {
          if (requestSucceeded) {
            this.onRefreshTracto();
          }
        }
      );
  }

  /**
   * Actualiza el tracto seleccionado.
   * @return {Promise}
   * @private
   */
  private async updateSelectedTracto(): Promise<void> {
    this.isGettingTracto = true;
    await this.tractosService.refreshSelectedTracto().then(() => {
      this.setTractoSituation();
      this.isGettingTracto = false;
    });
  }

  /**
   * Ejecuta el modal de aprobación de un tracto y pide su
   * actualización en caso de ser aprobado.
   * @return {Promise}
   */
  async onApproveTracto(): Promise<void> {
    this.initCommentForm();
    this.modalTitle = appConstants.tracto.modalMessages.approve.title;
    this.modalQuestion =
      appConstants.tracto.modalMessages.approve.question.replace(
        '_',
        this.getTractoIdentification()
      );
    this.modalMessage = appConstants.tracto.modalMessages.approve.message;
    this.modalWithComment = true;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.tractoModal
    );

    if (modalResult) {
      this.isGettingTracto = true;
      await this.updateTracto('approve', this.commentForm.value.comment);
    }
  }

  /**
   * Ejecuta el modal de rechazo de un tracto y pide su
   * actualización en caso de ser rechazado.
   * @return {Promise}
   */
  async onRejectTracto(): Promise<void> {
    this.isBeingRejected = true;
    this.initCommentForm();
    this.modalTitle = appConstants.tracto.modalMessages.reject.title;
    this.modalQuestion =
      appConstants.tracto.modalMessages.reject.question.replace(
        '_',
        this.getTractoIdentification()
      );
    this.modalMessage = appConstants.tracto.modalMessages.reject.message;
    this.modalWithComment = true;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.tractoModal
    );

    if (modalResult) {
      this.isGettingTracto = true;
      console.log(' onRejectTracto @ tracto-details.component ');
      console.log(this.commentForm.value);
      const newNotification = this.commentForm.value;
      const today: Date = new Date();
      const todayTimestamp: string = Math.floor(
        today.getTime() / 1000
      ).toString();
      const creatNotificationInput: CreateNotificationInput = {
        businessId: this.tracto.business,
        notificationId: 'TRACTO#' + this.tracto.tractoId + '#' + todayTimestamp,
        status: 'TO_BE_SENT',
        model: 'TRACTO',
        motive: 'APPROVE#REJECTED',
        tractoId: this.tracto.tractoId,
        comment: newNotification.comment,
      };

      await this.api
        .CreateNotification(creatNotificationInput)
        .then(async (): Promise<void> => {
          await this.updateTracto('reject', newNotification.comment);
        });
    }

    this.isBeingRejected = false;
  }

  /**
   * Actualiza un tracto.
   * @param {string} action Acción a realizar, puede ser 'approve' o 'reject'.
   * @param {string} comment Comentario de aprobación o rechazo.
   * @return {Promise}
   * @private
   */
  private async updateTracto(
    action: 'approve' | 'reject',
    comment: string | null = null
  ): Promise<void> {
    this.isGettingTracto = true;
    let newTractoStatus: string;
    let feedbackMessage: string = `Tracto ${this.getTractoIdentification()} `;
    let catchFeedbackMessage: string = `Error al _ tracto ${this.getTractoIdentification()}`;

    switch (action) {
      case 'approve':
        // Se cambia el estado del tracto a "Procesando"
        newTractoStatus = `${this.tracto.business}_${appConstants.entity.codes.inProcess}`;
        feedbackMessage = feedbackMessage + 'aprobado. Actualizando';
        catchFeedbackMessage = catchFeedbackMessage.replace('_', 'aprobar');
        break;
      case 'reject':
        // Se cambia el estado del tracto a "Rechazado"
        newTractoStatus = `${this.tracto.business}_${appConstants.entity.codes.rejected}`;
        feedbackMessage = feedbackMessage + 'rechazado. Enviando notificación';
        catchFeedbackMessage = catchFeedbackMessage.replace('_', 'rechazar');
        break;
    }

    const updateInputTracto: UpdateTractoInput = {
      tractoId: this.tracto.tractoId,
      status: newTractoStatus,
      comment: comment,
    };

    await this.api
      .UpdateTracto(updateInputTracto)
      .then(async (tracto: UpdateTractoMutation): Promise<void> => {
        this.feedbacksService.showFeedback(feedbackMessage, 'info');

        if (action === 'approve') {
          // Se cambia el estado de los documentos con estado "Por Aprobar"
          // al estado "Procesando"
          for (let document of tracto.documents!.items) {
            if (
              document?.status ===
              `${tracto.business}_${appConstants.document.codes.toBeApproved}`
            ) {
              const updateDocumentInput: UpdateDocumentInput = {
                documentId: document.documentId,
                name: document.name,
                status: `${tracto.business}_${appConstants.document.codes.inProcess}`,
              };
              await this.documentsService.updateDocument(updateDocumentInput);
            }
          }
        } else {
          // Se actualiza el estado del acoplamiento, si aplica.
          if (tracto.tractoCoupleCoupleId) {
            await this.api.UpdateCouple({
              coupleId: tracto.tractoCoupleCoupleId,
              status: `${tracto.business}_${appConstants.entity.codes.rejected}`,
            });
          }
        }

        // Actualizamos el tracto seleccionado.
        await this.updateSelectedTracto();
      })
      .catch((response: any): void => {
        this.feedbacksService.showErrorFeedbacks(
          response,
          catchFeedbackMessage
        );
      });
  }

  /**
   * Ejecuta el modal de edición de atributos del Tracto.
   * @return {Promise}
   */
  async onUpdateTracto(): Promise<void> {
    this.initUpdateAttributeForm();

    this.modalTitle = appConstants.tracto.modalMessages.edit.title;
    this.modalQuestion =
      appConstants.tracto.modalMessages.edit.question.replace(
        '_',
        this.getTractoIdentification()
      );
    this.modalMessage = appConstants.tracto.modalMessages.edit.message;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.updateTractoAttributeModal
    );

    if (modalResult) {
      if (this.updateAttributeForm.invalid) {
        this.feedbacksService.showFeedback(
          'Formulario inválido, no será procesada la petición.',
          'danger'
        );
        return;
      }

      const patchedTracto = this.updateAttributeForm.value;
      const payload: { [key: string]: string } = {
        center: patchedTracto.center,
      };

      this.apiRequestsService.updateEntity(
        this.tracto.business,
        this.tracto.__typename,
        this.getTractoIdentification(),
        payload
      );
    }
  }

  /**
   * Navega al formulario de edición del tracto.
   * @return {Promise}
   */
  async onEditTracto(): Promise<void> {
    let editionId: string = `${this.usersService.business.value.toUpperCase()}#TRACTO#${
      this.tracto.tractoId
    }`;

    if (await this.editionsService.editionIsTaken(editionId)) {
      return;
    }

    this.router
      .navigate(['edit'], { relativeTo: this.route })
      .then(() => console.log('navigate to edit'));
  }

  /**
   * Ejecuta el modal de eliminación de un tracto y lo
   * elimina en caso de aceptar.
   * @return {Promise}
   */
  async onDeleteTracto(): Promise<void> {
    if (this.tractoIsCoupled) {
      this.feedbacksService.showFeedback(
        `Tracto ${this.getTractoIdentification()} está acoplado. Desacóplelo e intente de nuevo.`,
        'danger'
      );
      return;
    }

    this.modalTitle = appConstants.tracto.modalMessages.delete.title;
    this.modalQuestion =
      appConstants.tracto.modalMessages.delete.question.replace(
        '_',
        this.getTractoIdentification()
      );
    this.modalMessage = appConstants.tracto.modalMessages.delete.message;
    this.modalWithComment = false;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.tractoModal
    );

    if (modalResult) {
      const deleteTractoInput: DeleteTractoInput = {
        tractoId: this.tracto.tractoId,
      };

      await this.api
        .DeleteTracto(deleteTractoInput)
        .then(async () => {
          this.feedbacksService.showFeedback(
            `Tracto ${this.getTractoIdentification()} eliminado.`,
            'info'
          );
        })
        .catch((response: any): void => {
          this.feedbacksService.showErrorFeedbacks(
            response,
            `Error al borrar tracto ${this.getTractoIdentification()}`
          );
        });
      this.router
        .navigate(['/', this.tracto.business.toLowerCase(), 'tractos'])
        .then(() => console.log('navigate to tractos'));
    }
  }

  /**
   * Llama a refrescar el tracto seleccionado.
   */
  onRefreshTracto(): void {
    this.updateSelectedTracto().then(() =>
      console.log('Tracto seleccionado actualizado.')
    );
  }

  /**
   * Colapsa o muestra el comentario del vehículo.
   */
  onViewComment(): void {
    this.showComment = !this.showComment;
  }

  /**
   * Retorna la clase que colapsa o muestra el comentario del vehículo.
   * @return {string} Clase CSS
   */
  showCommentClass(): string {
    return this.showComment ? 'show' : '';
  }

  /**
   * Inicializa el formulario de rechazo de un tracto.
   * @private
   */
  private initCommentForm(): void {
    // Inicialización del formulario
    this.commentForm = this.formsService.generateCommentForm(
      this.isBeingRejected
    );
  }

  /**
   * Inicializa el formulario de actualización de
   * atributos de un Tracto.
   * @private
   */
  private initUpdateAttributeForm(): void {
    // Inicialización del formulario
    this.updateAttributeForm = new FormGroup({
      center: new FormControl(this.tracto.center, [Validators.required]),
    });
  }

  /**
   * Indica si un tracto tiene documentos por vencer.
   * @return {boolean} Boolean que indica si el tracto tiene documentos por vencer.
   */
  hasToExpireDocuments(): boolean {
    const docNumber: number = this.tracto.documentsToExpire || 0;
    return docNumber > 0;
  }

  /**
   * Indica si un tracto tiene documentos vencidos.
   * @return {boolean} Boolean que indica si el tracto tiene documentos vencidos.
   */
  hasExpiredDocuments(): boolean {
    const docNumber: number = this.tracto.documentsExpired || 0;
    return docNumber > 0;
  }

  /**
   * Retorna un color dependiendo del estado del envasado.
   * @return {string}
   */
  getColor(): string {
    return this.masterService.getSatusColor(this.tracto.status);
  }

  /**
   * Retorna la fuente de imagen a mostrar en el Estado del envasado.
   * @return {string} ruta a imagen.
   */
  getImageSrc(status: string): string {
    return this.masterService.getImageSrc(status);
  }

  /**
   * Retorna una clase CSS dependiendo de la edad del tracto.
   * @return {string}
   */
  getTractoAgeClass(age: number): string {
    let tractoAgeClass: string = '';
    if (age >= appConstants.tracto.ageToBeConsideredOld) {
      tractoAgeClass = 'text-danger';
    }
    return tractoAgeClass;
  }

  /**
   * Determina, con base al rol del usuario, cuáles botones mostrar.
   * @param {string} button Acción del botón.
   */
  showButton(button: string): boolean {
    let show: boolean = false;

    switch (button) {
      case 'delete':
        show = this.isAdmin;
        break;
      case 'edit-center':
        show = this.isAdmin && this.tracto.business === 'LUBRICANTS';
        break;
      case 'approve':
      case 'reject':
        show = this.isAdmin || this.isApprover;
        break;
      case 'edit':
        show = this.isAdmin || this.isCarrier;
        break;
      default:
        break;
    }
    return show;
  }

  /**
   * Define la situación del tracto: ¿necesita aprobación?, ¿puede ser rechazado?
   * ¿Está bloqueado?
   * @private
   */
  private setTractoSituation(): void {
    const tractoToBeApprove: boolean =
      this.tracto.status ===
      `${this.tracto.business}_${appConstants.entity.codes.toBeApproved}`;
    const tractoRejected: boolean =
      this.tracto.status ===
      `${this.tracto.business}_${appConstants.entity.codes.rejected}`;
    this.tractoNeedsApproval = tractoToBeApprove || tractoRejected;
    this.tractoCanBeRejected = tractoToBeApprove;
    this.showBlockingMotives =
      this.tracto.blockingMotives !== null &&
      this.tracto.blockingMotives !== undefined &&
      this.tracto.blockingMotives.length > 0 &&
      this.tracto.status !==
        `${this.tracto.business}_${appConstants.entity.codes.available}`;
    this.hasComment = !!this.tracto.comment;
    this.showComment = false;
  }

  /**
   * Retorna la identificación del tracto para los modales.
   * @return {string}
   * @private
   */
  private getTractoIdentification(): string {
    let sapId: string;
    if (this.tracto.sapId! === 'Sin Asignar') {
      sapId = this.tracto.sapId!;
    } else {
      sapId = parseInt(this.tracto.sapId!).toString();
    }
    return sapId + ' - ' + this.tracto.licensePlate;
  }

  ngOnDestroy(): void {
    this.selectedTractoChangedSubscription.unsubscribe();
    this.onUpdateTractoListener.unsubscribe();
    this.isUploadingDocumentsSubscription.unsubscribe();
    this.requestSucceededSubscription.unsubscribe();
    console.log('tracto-details.component subscriptions removed.');
  }
}
