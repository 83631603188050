import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../../auth/auth.guard';
import { MasterResolver } from '../master/master.resolver';
import { UserDetailsComponent } from './user-details/user-details.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { UserNoSelectedComponent } from './user-no-selected/user-no-selected.component';

import { UsersComponent } from './users.component';

const routes: Routes = [
  {
    path: ':business/users',
    component: UsersComponent,
    canActivate: [AuthGuard],
    resolve: [MasterResolver],
    children: [
      { path: '', component: UserNoSelectedComponent },
      { path: 'new', component: UserEditComponent },
      { path: ':id', component: UserDetailsComponent },
      { path: ':id/edit', component: UserEditComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UsersRoutingModule {}
