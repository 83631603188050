import { Injectable } from '@angular/core';
import {
  APIService,
  CreateEditionInput,
  GetEditionQuery,
  UpdateEditionInput,
  User,
} from '../../app-sync.service';
import { UsersService } from '../../pages/users/users.service';
import { FeedbacksService } from '../feedbacks/feedbacks.service';

@Injectable({
  providedIn: 'root',
})
export class EditionsService {
  activeUser: User;
  activeUserName: string;

  constructor(
    private api: APIService,
    private usersService: UsersService,
    private feedbacksService: FeedbacksService
  ) {
    this.activeUser = this.usersService.getActiveUser();
    this.activeUserName = `${this.activeUser.firstName} ${this.activeUser.lastName}`;
  }

  /**
   * Crear un objeto Edition activo si no existe o lo activa.
   * @param {string} editionId ID de la edición.
   */
  async createOrUpdateEdition(editionId: string): Promise<void> {
    // Nota: si no existe el registro edition solicitado, la respuesta es un null,
    // esto no lo refleja el modelo GetEditionQuery
    const edition: GetEditionQuery = await this.api.GetEdition(editionId);

    if (!edition) {
      // Creamos la edición
      const input: CreateEditionInput = {
        editionId: editionId,
        active: true,
        editor: this.activeUserName,
        editorId: this.activeUser.userId,
      };

      await this.api.CreateEdition(input);
    } else if (!edition.active) {
      // Activamos la edición
      const input: UpdateEditionInput = {
        editionId: editionId,
        active: true,
        editor: this.activeUserName,
        editorId: this.activeUser.userId,
      };

      await this.api.UpdateEdition(input);
    }
  }

  /**
   * Retorna un booleano que responde a la pregunta
   * ¿Está tomada la sesión de edición?
   * @param {string} editionId ID de la edición.
   */
  async editionIsTaken(editionId: string): Promise<boolean> {
    const edition: GetEditionQuery = await this.api.GetEdition(editionId);

    if (
      edition &&
      edition.active &&
      edition.editorId !== this.activeUser.email
    ) {
      this.feedbacksService.showFeedback(
        `No es posible editar ya que ${edition.editor} tiene tomado el objeto`,
        'danger'
      );
      return true;
    } else {
      return false;
    }
  }

  async releaseEdition(editionId: string): Promise<void> {
    const input: UpdateEditionInput = {
      editionId: editionId,
      active: false,
      editor: null,
      editorId: null,
    };

    await this.api.UpdateEdition(input);
  }
}
