import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appInputTrim]',
})
export class InputTrimDirective {
  constructor(private elementRef: ElementRef) {}

  /**
   * Elimina espacios en blanco en los extremos de un input del usuario.
   */
  @HostListener('blur') onBlur(): void {
    const value = this.elementRef.nativeElement.value;
    const trimmedValue = value.trim();
    if (value !== trimmedValue) {
      this.elementRef.nativeElement.value = trimmedValue;
    }
  }
}
