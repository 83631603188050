import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HistorialRTCComponent } from './historial-rtc/historial-rtc.component';
import { HistorialRtcRoutingModule } from './historial-rtc-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { DataTablesModule } from 'angular-datatables';
import { MasterModule } from '../master/master.module';
import { ReactiveFormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  declarations: [HistorialRTCComponent],
  imports: [
    CommonModule,
    HistorialRtcRoutingModule,
    SharedModule,
    DataTablesModule,
    MasterModule,
    ReactiveFormsModule,
    PdfViewerModule,
    NgOptimizedImage,
  ],
})
export class HistorialRtcModule {}
