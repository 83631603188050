import { Component } from '@angular/core';

@Component({
  selector: 'app-envasados',
  templateUrl: './envasados.component.html',
  styleUrls: ['./envasados.component.css'],
})
export class EnvasadosComponent {
  equipName: string = 'Envasados Rígidos';
}
