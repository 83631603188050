<hr />
<div class="row my-2">
  <div class="col-sm-4">
    <h6>
      <img
        class="p-2"
        ngSrc="assets/images/icons/conductores.svg"
        alt="Conductores"
        height="35"
        width="45"
      />Conductores
    </h6>
  </div>
  <div class="col-sm-8 text-end">
    <input
      placeholder="Buscar"
      class="input-search-datatable input-search-datatable-image-drivers"
      id="custom-search-input"
      type="text"
      [(ngModel)]="searchText"
      (input)="applyFilter()"
    />
    <button
      id="drivers-list-new"
      class="btn-btn-create"
      (click)="onNewDriver()"
      *ngIf="showButton('create')"
    >
      Crear Conductor
    </button>
    <button
      id="drivers-list-refresh"
      class="btn btn-light mx-2 btn-refresh btn-refresh-image"
      (click)="onRefresh()"
    ></button>
  </div>
</div>
<div class="row">
  <div class="text-center my-5" *ngIf="isGettingDrivers">
    <app-spinner message="Cargando conductores"></app-spinner>
  </div>
  <div class="col-xs-12">
    <div class="table-responsive-sm" *ngIf="!isGettingDrivers">
      <table
        datatable
        [dtOptions]="dtOptions"
        class="table display nowrap"
        style="width: 100%"
      >
        <caption>
          Lista de Conductores
        </caption>
        <thead>
          <tr class="text-center">
            <th
              scope="col"
              *ngFor="let attribute of driversAttributes"
              [hidden]="hideColumn(attribute)"
            >
              {{ attribute }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let driver of drivers">
            <td>
              <a
                id="drivers-list-{{ driver.rut }}"
                (click)="onSelectedDriver(driver)"
                style="color: #0d6efd"
                >{{ driver.sapId! | sapId }}</a
              >
            </td>
            <td>
              <span>
                <img
                  [ngSrc]="getImageSrc(driver.status)"
                  height="25"
                  width="25"
                  alt="Estado"
                />
              </span>
              {{ driver.status | master : "DRIVERS#STATUSES" }}
            </td>
            <td>{{ driver.firstName + " " + driver.lastName }}</td>
            <td>{{ driver.rut }}</td>
            <td [hidden]="hideColumn('Transportista')">
              {{ driver.carrier!.name }}
            </td>
            <td>
              <span
                class="badge border rounded-4 text-bg-light mx-1"
                *ngIf="hasToExpireDocuments(driver)"
              >
                <span class="badge border rounded-circle text-bg-light">{{
                  driver.documentsToExpire
                }}</span>
                por vencer</span
              >
              <span
                class="badge border rounded-4 text-bg-light mx-1"
                *ngIf="hasExpiredDocuments(driver)"
              >
                <span class="badge border rounded-circle text-bg-light">{{
                  driver.documentsExpired
                }}</span>
                vencido</span
              >
            </td>
            <td>
              <span
                class="badge border rounded-4 text-bg-light mx-1"
                *ngFor="let blockingMotive of driver.blockingMotives"
              >
                {{ blockingMotive }}</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
