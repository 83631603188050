<div class="container-fluid" *ngIf="!isUploadingDocuments">
  <div class="row">
    <div class="col-sm-12 text-center my-5">
      <div class="alert alert-info" role="alert">
        <h4 class="alert-heading">Por favor, seleccione un vehículo</h4>
        <hr />
        <p>
          Para ver detalles o gestionar un vehículo, seleccione su ID SAP en la
          lista de la izquierda.
        </p>
      </div>
    </div>
  </div>
</div>
<div class="text-center my-5" *ngIf="isUploadingDocuments">
  <app-spinner
    message="Subiendo documentos a la nube, por favor espere"
  ></app-spinner>
</div>
