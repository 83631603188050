import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  APIService,
  Semirremolque,
  GetSemirremolqueQuery,
  ModelSemirremolqueFilterInput,
  ModelSubscriptionIDInput,
  ModelSubscriptionSemirremolqueFilterInput,
  User,
  SemirremolquesByBusinessAndSemirremolqueIdQuery,
  SemirremolquesByCenterAndSemirremolqueIdQuery,
  SemirremolquesByCompanyAndSemirremolqueIdQuery,
  ModelTractoFilterInput,
} from '../../app-sync.service';
import { DocumentsService } from '../documents/documents.service';
import { UsersService } from '../users/users.service';
import { appConstants } from '../../shared/constants/constants';
import { filterInputs } from '../../shared/types/filter-inputs';
import { FeedbacksService } from '../../shared/feedbacks/feedbacks.service';

@Injectable({
  providedIn: 'root',
})
export class SemirremolquesService {
  selectedSemirremolqueChanged = new Subject<Semirremolque>();
  semirremolquesChanged = new Subject<Semirremolque[]>();
  decoupledSemirremolquesChanged = new Subject<Semirremolque[]>();

  private readonly initialSemirremolque: Semirremolque;
  private selectedSemirremolque: Semirremolque;
  private semirremolques: Semirremolque[] = [];
  private decoupledSemirremolques: Semirremolque[] = [];
  private semirremolquesFilterForSubscriptions: ModelSubscriptionSemirremolqueFilterInput =
    {};
  private decoupledSemirremolquesFilterForSubscriptions: ModelSubscriptionSemirremolqueFilterInput =
    {};
  private selectedSemirremolqueFilterForSubscriptions: ModelSubscriptionSemirremolqueFilterInput =
    {};

  constructor(
    private api: APIService,
    private documentsService: DocumentsService,
    private usersService: UsersService,
    private feedbacksService: FeedbacksService
  ) {
    this.initialSemirremolque = {
      ...appConstants.semirremolque.initialization,
      carrier: appConstants.carrier.initialization,
    };
    this.selectedSemirremolque = this.initialSemirremolque;
    this.setSemirremolquesFilter();
  }

  // ---------------------------------------
  // Métodos para el semirremolque default
  // ---------------------------------------
  /**
   * Retorna una estructura de semirremolque vacía para iniciar
   * un objeto de tipo Semirremolque.
   * @return {Semirremolque}
   */
  getInitialSemirremolque(): Semirremolque {
    return { ...this.initialSemirremolque };
  }

  // --------------------------------------------
  // Métodos para el semirremolque seleccionado
  // --------------------------------------------
  /**
   * Configura el semirremolque seleccionado para referencia de
   * todos los componentes.
   * @param {Semirremolque} semirremolque Semirremolque seleccionado.
   */
  setSelectedSemirremolque(semirremolque: Semirremolque): void {
    this.selectedSemirremolque = semirremolque;
    this.setSelectedSemirremolqueFilterForSubscriptions();
    this.selectedSemirremolqueChanged.next({ ...this.selectedSemirremolque });
    console.log('selectedSemirremolque', this.selectedSemirremolque);

    // Documentos
    this.documentsService.setId(
      'semirremolqueId',
      this.selectedSemirremolque.semirremolqueId
    );
    this.documentsService.setSelectedModel('SEMIRREMOLQUE');
    this.documentsService.setSelectedCenter(semirremolque.center);
    this.documentsService.setDocumentsFilter();
    this.documentsService
      .refreshDocuments()
      .then(() => console.log('Documentos del semirremolque actualizados.'));
  }

  /**
   * Actualiza los datos del semirremolque seleccionado.
   * @return {Promise}
   */
  async refreshSelectedSemirremolque(): Promise<void> {
    const getSemirremolqueResult: GetSemirremolqueQuery =
      await this.api.GetSemirremolque(
        this.selectedSemirremolque.semirremolqueId
      );
    this.setSelectedSemirremolque(<Semirremolque>getSemirremolqueResult);
  }

  /**
   * Retorna el semirremolque seleccionado.
   * @return {Semirremolque}
   */
  getSelectedSemirremolque(): Semirremolque {
    return { ...this.selectedSemirremolque };
  }

  // ---------------------------------------
  // Métodos para la lista de Semirremolques
  // ---------------------------------------
  /**
   * Configura la lista de semirremolques para referencia de
   * todos los componentes.
   * @param {Semirremolque[]} semirremolques Lista de semirremolques.
   */
  setSemirremolques(semirremolques: Semirremolque[]): void {
    this.semirremolques = semirremolques;
    this.setSemirremolquesFilter();
    this.semirremolquesChanged.next(this.semirremolques.slice());
  }

  /**
   * Actualiza la lista de semirremolques.
   * @return {Promise}
   */
  async refreshSemirremolques(): Promise<void> {
    const activeUser: User = this.usersService.getActiveUser();
    const business: string = this.usersService.business.value.toUpperCase();
    let tempListSemirremolques: Semirremolque[] = [];

    // Verificamos el grupo del usuario
    if (activeUser.authGroup.endsWith('_CARRIERS')) {
      // Los transportistas podrán acceder a vehículos donde
      // ellos figuren como Carrier
      tempListSemirremolques = await this.listSemirremolquesByCarrier(
        activeUser
      );
    } else if (activeUser.authGroup.endsWith('_ADMINS')) {
      // Los administradores podrán acceder a todos los vehículos/conductores
      // asociados a su negocio
      tempListSemirremolques = await this.listSemirremolquesByBusiness(
        business
      );
    } else if (
      activeUser.authGroup.endsWith('_VIEWERS') ||
      activeUser.authGroup.endsWith('_APPROVERS')
    ) {
      // Los visualizadores y aprobadores podrán acceder a todos los vehículos
      // asociados a los centros a los que pertenece el usuario.
      tempListSemirremolques = await this.listSemirremolquesByCenter(
        activeUser,
        business
      );
    } else {
      this.feedbacksService.showFeedback(
        `Error obteniendo semirremolques. Grupo ${activeUser.authGroup} no tiene regla definida.`,
        'danger'
      );
    }

    this.setSemirremolques(tempListSemirremolques.slice());
  }

  /**
   * Retorna la lista de Vehículos usando el GSI de Negocio.
   * @param business {string} Negocio.
   * @param filter {ModelTractoFilterInput | undefined} Filtro de búsqueda.
   * @private
   */
  private async listSemirremolquesByBusiness(
    business: string,
    filter: ModelTractoFilterInput | undefined = undefined
  ): Promise<Semirremolque[]> {
    let listSemirremolquesResult: SemirremolquesByBusinessAndSemirremolqueIdQuery;
    listSemirremolquesResult =
      await this.api.SemirremolquesByBusinessAndSemirremolqueId(
        business,
        undefined,
        undefined,
        filter
      );

    let tempListSemirremolques: Semirremolque[] = <Semirremolque[]>(
      listSemirremolquesResult.items
    );
    let nextToken: string | null | undefined =
      listSemirremolquesResult.nextToken;
    // Es posible que la primera query no retorne todos los vehículos.
    while (nextToken) {
      let loopListSemirremolquesResult: SemirremolquesByBusinessAndSemirremolqueIdQuery =
        await this.api.SemirremolquesByBusinessAndSemirremolqueId(
          business,
          undefined,
          undefined,
          filter,
          100,
          nextToken
        );
      tempListSemirremolques.push(
        ...(<Semirremolque[]>loopListSemirremolquesResult.items)
      );
      nextToken = loopListSemirremolquesResult.nextToken;
    }
    return tempListSemirremolques.slice();
  }

  /**
   * Retorna la lista de Vehículos usando el GSI de Centros.
   * @param activeUser {User} Usuario activo.
   * @param business {string} Negocio.
   * @param filter {ModelTractoFilterInput | undefined} Filtro de búsqueda.
   * @private
   */
  private async listSemirremolquesByCenter(
    activeUser: User,
    business: string,
    filter: ModelTractoFilterInput | undefined = undefined
  ): Promise<Semirremolque[]> {
    let tempListSemirremolques: Semirremolque[] = [];
    const centers: string[] =
      JSON.parse(activeUser.centers || `{"${business}": []}`)[business] || [];

    // Ejecutar todas las peticiones en paralelo
    const semirremolquesPromises: Promise<Semirremolque[]>[] = centers.map(
      async (center: string): Promise<Semirremolque[]> => {
        let listSemirremolquesResult: SemirremolquesByCenterAndSemirremolqueIdQuery;

        listSemirremolquesResult =
          await this.api.SemirremolquesByCenterAndSemirremolqueId(
            center,
            undefined,
            undefined,
            filter
          );
        let semirremolques: Semirremolque[] = <Semirremolque[]>(
          listSemirremolquesResult.items
        );

        let nextToken: string | null | undefined =
          listSemirremolquesResult.nextToken;

        // Si hay más datos, seguir llamando hasta que no haya nextToken
        while (nextToken) {
          let loopListSemirremolquesResult: SemirremolquesByCenterAndSemirremolqueIdQuery =
            await this.api.SemirremolquesByCenterAndSemirremolqueId(
              center,
              undefined,
              undefined,
              filter,
              100,
              nextToken
            );
          semirremolques.push(
            ...(<Semirremolque[]>loopListSemirremolquesResult.items)
          );
          nextToken = loopListSemirremolquesResult.nextToken;
        }

        return semirremolques;
      }
    );

    // Esperar a que todas las promesas se resuelvan
    const results: Semirremolque[][] = await Promise.all(
      semirremolquesPromises
    );

    // Combinar todos los resultados en una sola lista
    results.forEach((semirremolques: Semirremolque[]): void => {
      tempListSemirremolques.push(...semirremolques);
    });

    return tempListSemirremolques.slice();
  }

  /**
   * Retorna la lista de Vehículos usando el GSI de Transportista.
   * @param activeUser {User} Usuario activo.
   * @param filter {ModelTractoFilterInput | undefined} Filtro de búsqueda.
   * @private
   */
  private async listSemirremolquesByCarrier(
    activeUser: User,
    filter: ModelTractoFilterInput | undefined = undefined
  ): Promise<Semirremolque[]> {
    let listSemirremolquesResult: SemirremolquesByCompanyAndSemirremolqueIdQuery;
    listSemirremolquesResult =
      await this.api.SemirremolquesByCompanyAndSemirremolqueId(
        activeUser.company,
        undefined,
        undefined,
        filter
      );

    let tempListSemirremolques: Semirremolque[] = <Semirremolque[]>(
      listSemirremolquesResult.items
    );
    let nextToken: string | null | undefined =
      listSemirremolquesResult.nextToken;
    // Es posible que la primera query no retorne todos los vehículos.
    while (nextToken) {
      let loopListSemirremolquesResult: SemirremolquesByCompanyAndSemirremolqueIdQuery =
        await this.api.SemirremolquesByCompanyAndSemirremolqueId(
          activeUser.company,
          undefined,
          undefined,
          filter,
          100,
          nextToken
        );
      tempListSemirremolques.push(
        ...(<Semirremolque[]>loopListSemirremolquesResult.items)
      );
      nextToken = loopListSemirremolquesResult.nextToken;
    }
    return tempListSemirremolques.slice();
  }

  /**
   * Retorna la lista de semirremolques.
   * @return {Semirremolque[]}
   */
  getSemirremolques(): Semirremolque[] {
    return this.semirremolques.slice();
  }

  /**
   * Configura la lista de semirremolques desacoplados para referencia de
   * todos los componentes.
   * @param {Semirremolque[]} semirremolques Lista de semirremolques acoplados.
   */
  setDecoupledSemirremolques(semirremolques: Semirremolque[]): void {
    this.decoupledSemirremolques = semirremolques;
    this.setSemirremolquesFilter();
    this.decoupledSemirremolquesChanged.next(
      this.decoupledSemirremolques.slice()
    );
  }

  /**
   * Actualiza la lista de semirremolques desacoplados.
   * @return {Promise}
   */
  async refreshDecoupledSemirremolques(): Promise<void> {
    const activeUser: User = this.usersService.getActiveUser();
    const business: string = this.usersService.business.value.toUpperCase();
    let tempListDecoupledSemirremolques: Semirremolque[] = [];
    const decoupledFixedFilters: ModelSemirremolqueFilterInput = {
      semirremolqueCoupleCoupleId: { notContains: '#' },
      status: { eq: `${business}_${appConstants.entity.codes.blocked}` },
      ...(business !== 'FOREIGN' && { sapId: { ne: 'Sin Asignar' } }),
    };

    // Verificamos el grupo del usuario
    if (activeUser.authGroup.endsWith('_CARRIERS')) {
      // Los transportistas podrán acceder a vehículos donde
      // ellos figuren como Carrier
      tempListDecoupledSemirremolques = await this.listSemirremolquesByCarrier(
        activeUser,
        decoupledFixedFilters
      );
    } else if (activeUser.authGroup.endsWith('_ADMINS')) {
      // Los administradores podrán acceder a todos los vehículos/conductores
      // asociados a su negocio
      tempListDecoupledSemirremolques = await this.listSemirremolquesByBusiness(
        business,
        decoupledFixedFilters
      );
    } else if (
      activeUser.authGroup.endsWith('_VIEWERS') ||
      activeUser.authGroup.endsWith('_APPROVERS')
    ) {
      // Los visualizadores y aprobadores podrán acceder a todos los vehículos
      // asociados a los centros a los que pertenece el usuario.
      tempListDecoupledSemirremolques = await this.listSemirremolquesByCenter(
        activeUser,
        business,
        decoupledFixedFilters
      );
    } else {
      this.feedbacksService.showFeedback(
        `Error obteniendo tractos. Grupo ${activeUser.authGroup} no tiene regla definida.`,
        'danger'
      );
    }

    this.setDecoupledSemirremolques(tempListDecoupledSemirremolques.slice());
  }

  /**
   * Retorna la lista de semirremolques desacoplados.
   * @return {Semirremolque[]}
   */
  getDecoupledSemirremolques(): Semirremolque[] {
    return this.decoupledSemirremolques.slice();
  }

  // ------------------------------------------
  // Métodos para filtro de consultas a AppSync
  // ------------------------------------------
  /**
   * Configura los filtros para las consultas
   * de listas de semirremolques y subscripciones.
   * @private
   */
  private setSemirremolquesFilter(): void {
    const business: string = this.usersService.business.value.toUpperCase();
    let modelFilterInput: filterInputs = this.usersService.getEntitiesFilter();
    const decoupledFixedFilters: ModelSubscriptionSemirremolqueFilterInput = {
      sapId: { ne: 'Sin Asignar' },
      status: { eq: `${business}_${appConstants.entity.codes.blocked}` },
      semirremolqueCoupleCoupleId: { notContains: '#' },
    };

    this.semirremolquesFilterForSubscriptions = <
      ModelSubscriptionSemirremolqueFilterInput
    >modelFilterInput;
    this.decoupledSemirremolquesFilterForSubscriptions = {
      ...modelFilterInput,
      ...decoupledFixedFilters,
    };
  }

  /**
   * Retorna el filtro para las subscripciones
   * de listas de semirremolques.
   * @return {ModelSubscriptionSemirremolqueFilterInput}
   */
  getSemirremolquesFilterForSubscriptions(): ModelSubscriptionSemirremolqueFilterInput {
    return { ...this.semirremolquesFilterForSubscriptions };
  }

  /**
   * Configura el filtro para las subscripciones
   * del semirremolque seleccionado.
   */
  private setSelectedSemirremolqueFilterForSubscriptions(): void {
    const modelSubscriptionIDInput: ModelSubscriptionIDInput = {
      eq: this.selectedSemirremolque.semirremolqueId,
    };
    this.selectedSemirremolqueFilterForSubscriptions = {
      semirremolqueId: modelSubscriptionIDInput,
    };
  }

  /**
   * Retorna el filtro para las subscripciones
   * del semirremolque seleccionado.
   * @return {ModelSubscriptionSemirremolqueFilterInput}
   */
  getSelectedSemirremolqueFilterForSubscriptions(): ModelSubscriptionSemirremolqueFilterInput {
    return { ...this.selectedSemirremolqueFilterForSubscriptions };
  }

  /**
   * Retorna el filtro para las consultas
   * de listas de semirremolques desacoplados.
   * @return {ModelSubscriptionSemirremolqueFilterInput}
   */
  getDecoupledSemirremolquesFilterForSubscriptions(): ModelSubscriptionSemirremolqueFilterInput {
    return { ...this.decoupledSemirremolquesFilterForSubscriptions };
  }
}
