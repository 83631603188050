<div
  class="list-group-item list-group-item-action clearfix {{
    document.isMandatory
  }}"
>
  <div class="row align-items-center">
    <div class="col-sm-11">
      <h6 style="margin-left: 29px" class="list-group-item-heading">
        {{
          document.masterValueId
            | master
              : masterReference
              : "[Error]: No existe referencia en Maestro"
        }}
      </h6>
      <div class="row">
        <div class="col-sm-1 p-2">
          <a id="document-item-download" (click)="onDownloadDocument(document)"
            ><span class="material-symbols-outlined">download</span></a
          >
        </div>
        <div class="col-sm-11">
          <a id="document-item-view" (click)="onViewDocument(document)">
            <p class="list-group-item-text">
              Emisión:
              {{
                isDatelessDocument(document.masterValueId)
                  ? "No Aplica"
                  : document.issueDate
              }}
              <br />
              Vencimiento:
              {{
                isDatelessDocument(document.masterValueId)
                  ? "No Aplica"
                  : document.expirationDate
              }}
              <span
                style="width: 80px; height: 19px"
                class="badge border rounded-4 text-bg-light float-end"
                *ngIf="putWarningBadge"
                >{{ warningBadgeMessage }}</span
              >
            </p>
          </a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 align-self-center">
        <div class="col-sm-6">
          <h6>
            <span
              class="badge box-documents"
              [ngStyle]="{ 'background-color': getColor(document) }"
              >{{ document.status | master : "DOCUMENTS#STATUSES" }}</span
            >
          </h6>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modals -->
<ng-template #documentViewer let-c="close" let-d="dismiss">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{ modalTitle }}</h4>
        <button
          id="document-item-modal-cross"
          type="button"
          class="btn-close"
          aria-label="Close"
          (click)="d('Cross')"
        ></button>
      </div>
      <div class="modal-body">
        <div class="text-center my-5" *ngIf="isGettingURL">
          <app-spinner message="Generando Vista Previa"></app-spinner>
        </div>
        <pdf-viewer
          [src]="pdfSource"
          [render-text]="true"
          [original-size]="false"
          [fit-to-page]="true"
          (page-rendered)="pageRendered()"
          style="width: 800px; height: 70vh"
        >
        </pdf-viewer>
      </div>
      <div class="modal-footer" *ngIf="!isGettingURL">
        <button
          id="document-item-modal-accept"
          type="button"
          class="btn btn-outline-primary"
          (click)="c('Accept')"
        >
          Cerrar
        </button>
      </div>
    </div>
  </div>
</ng-template>
