import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appInputUppercase]',
})
export class InputUppercaseDirective {
  constructor(private elementRef: ElementRef) {}

  /**
   * Cambia a mayúsculas el texto de un input del usuario.
   */
  @HostListener('blur') onBlur(): void {
    const value = this.elementRef.nativeElement.value;
    const upperCasedValue = value.toUpperCase();
    if (value !== upperCasedValue) {
      this.elementRef.nativeElement.value = upperCasedValue;
    }
  }
}
