/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://gdwc6x7larf2xdunypaft77bte.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-west-2:cc8cd59b-9630-4192-ab84-2c75d009a927",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_6D8DphZ82",
    "aws_user_pools_web_client_id": "26j5u2rolj6iftvi2j3nccb4c",
    "oauth": {
        "domain": "tms-no-core-auth-stg.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://stg.roz.copec.cl/",
        "redirectSignOut": "https://copec-sa.fusionauth.io/oauth2/logout?client_id=3418aac3-1da7-4c6d-a367-083a7ad17814&nada=nada/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_content_delivery_bucket": "tms-no-core-host-stg",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://d1h4f3gjv0sy2o.cloudfront.net",
    "aws_user_files_s3_bucket": "tms-no-core-documents144839-stg",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
