import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../../auth/auth.guard';
import { MasterResolver } from '../master/master.resolver';

import { CouplingsComponent } from './couplings.component';

const routes: Routes = [
  {
    path: ':business/couplings',
    component: CouplingsComponent,
    canActivate: [AuthGuard],
    resolve: [MasterResolver],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CouplingsRoutingModule {}
