import { Component } from '@angular/core';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { NgFor } from '@angular/common';

import { FeedbacksService } from '../feedbacks.service';

@Component({
  selector: 'app-feedback-item',
  standalone: true,
  imports: [NgFor, NgbToastModule],
  templateUrl: './feedback-item.component.html',
  styleUrls: ['./feedback-item.component.css'],
  host: {
    class: 'toast-container position-fixed bottom-0 end-0 p-3',
    style: 'z-index: 1200',
  },
})
export class FeedbackItemComponent {
  constructor(public feedbacksService: FeedbacksService) {}
}
