import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { APIService, GetUserQuery } from '../app-sync.service';
import { UsersService } from '../pages/users/users.service';
import { FeedbacksService } from '../shared/feedbacks/feedbacks.service';
import { CognitoService } from '../auth/cognito.service';
import { CognitoUser } from '../shared/interfaces/cognito-user';
import { environment } from '../../environments/environment';
import { HeaderButtonsList } from '../shared/interfaces/header-buttons-list';
import { HeaderService } from '../header/header.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, OnDestroy {
  businessDefined: boolean = false;
  userBusiness: string = '';
  isLoading: boolean = true;
  activeUser: CognitoUser | null = null;
  version: string = environment.version;
  userRole: string = '';

  private businessSubscription: Subscription = new Subscription();

  constructor(
    private api: APIService,
    private router: Router,
    private usersService: UsersService,
    private feedbacksService: FeedbacksService,
    private cognitoService: CognitoService,
    private headerService: HeaderService
  ) {}

  async ngOnInit() {
    let data = await this.cognitoService.getUser();
    if (data.attributes['custom:role'] === '[]') {
      // Usuario no tiene rol en Fusion Auth
      this.router
        .navigate(['/blocked'])
        .then(() => console.log('Usuario sin rol en Fusion Auth'));
      this.feedbacksService.showFeedback(
        `Usuario no tiene rol asignado en Fusion Auth.`,
        'danger'
      );
    }
    this.userRole = data.attributes['custom:role'].split('"')[1];
    console.log(this.userRole);

    this.cognitoService.getUser().then((user: any) => {
      this.activeUser = user.attributes;
      this.setUserData().then((): void => {
        this.isLoading = false;
      });
    });
    this.usersService.inHomePage.next(true);

    this.businessSubscription = this.usersService.business.subscribe(
      (business: string): void => {
        this.userBusiness = business;
        this.businessDefined = business !== '';
      }
    );
  }

  /**
   * Maneja el arreglo que contiene información del botón activo
   * y realiza las navegaciones.
   * @param {string} linkName
   * @return {Promise<void>}
   */
  async onSelected(linkName: keyof HeaderButtonsList): Promise<void> {
    this.isLoading = true;
    let path: string[] = this.headerService.setSelectedButton(linkName);

    this.router.navigate(path).then((): void => {
      console.log(`navigate to /${path.slice(1).join('/')}`);
      this.isLoading = false;
    });
  }

  /**
   * Determina, con base al rol y negocio del usuario, cuáles botones mostrar.
   * @param {string} button Nombre del botón.
   * @return {Boolean}
   */
  showButton(button: string): boolean {
    let show: boolean = false;
    // Negocio del usuario
    let isNoCore: boolean = this.userBusiness === 'nocore';
    let isLubricants: boolean = this.userBusiness === 'lubricants';
    let isForeign: boolean = this.userBusiness === 'foreign';
    // Rol del usuario
    let userRole: string = '';
    if (this.activeUser) {
      userRole = this.activeUser['custom:role'].split('"')[1];
    }
    let isAdmin: boolean = userRole === 'ROL_ADMINISTRADOR';
    let isApprover: boolean = userRole === 'ROL_APROBADOR';
    let isCarrier: boolean = userRole === 'ROL_TRANSPORTISTA';
    let isDriver: boolean = userRole === 'ROL_CONDUCTOR';
    let isViewer: boolean = userRole === 'ROL_VISUALIZADOR';
    let isRTC: boolean = userRole === 'ROL_RTC';
    // Lógica para mostrar botones
    if (this.activeUser) {
      switch (button) {
        case 'vehicles':
          show = (isAdmin || isApprover || isCarrier || isViewer) && isNoCore;
          break;
        case 'tanques':
        case 'tractos':
        case 'couplings':
          show =
            (isAdmin || isApprover || isCarrier || isViewer) &&
            (isLubricants || isForeign);
          break;
        case 'cisternas':
        case 'envasados':
        case 'semirremolques':
          show =
            (isAdmin || isApprover || isCarrier || isViewer) && isLubricants;
          break;
        case 'drivers':
          show = isAdmin || isApprover || isCarrier || isDriver || isViewer;
          break;
        case 'rtc-evaluate':
          show = isRTC;
          break;
        case 'rtc-history':
          show = isRTC || (isAdmin && isLubricants);
          break;
        case 'users':
        case 'settings':
        case 'master':
          show = isAdmin;
          break;
        default:
          break;
      }
    }
    return show;
  }

  /**
   * Crea en el localStore información del usuario en la variable userData.
   * @return {Promise}
   */
  private async setUserData(): Promise<void> {
    await this.api
      .GetUser(this.activeUser!.email)
      .then((user: GetUserQuery) => {
        // Acá se inicia el usuario del UsersService
        this.usersService.setActiveUser(user);

        // En caso de usuarios con más de un negocio,
        // primero de carga el componente business-selector
        // para definirlo.
        const businessesList: string[] = user.hasAccessTo
          ? user.hasAccessTo.split(',')
          : [];
        if (businessesList.length > 1) {
          this.usersService.business.next('');
          this.businessDefined = false;
        } else {
          this.userBusiness = user.business.toLowerCase();
          this.businessDefined = true;
        }
      })
      .catch((response: any) => {
        if (Symbol.iterator in Object(response.errors)) {
          this.feedbacksService.showErrorFeedbacks(
            response,
            `Error al cargar datos del usuario ${this.activeUser!.email}`
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.businessSubscription.unsubscribe();
    this.usersService.inHomePage.next(false);
    console.log('home.component subscriptions removed.');
  }
}
