import { Component, OnInit } from '@angular/core';

import { CognitoUser } from '../../shared/interfaces/cognito-user';

import { CognitoService } from '../../auth/cognito.service';
import { UsersService } from '../../pages/users/users.service';
import { User } from '../../app-sync.service';

@Component({
  selector: 'app-business-selector',
  templateUrl: './business-selector.component.html',
  styleUrls: ['./business-selector.component.css'],
})
export class BusinessSelectorComponent implements OnInit {
  activeCognitoUser: CognitoUser | null = null;
  activeUser: User;

  constructor(
    private cognitoService: CognitoService,
    private usersService: UsersService
  ) {
    this.activeUser = this.usersService.getActiveUser();
  }

  ngOnInit() {
    this.cognitoService.getUser().then((user: any) => {
      this.activeCognitoUser = user.attributes;
    });
  }

  showButton(business: string): boolean {
    const businessList: string[] = this.activeUser.hasAccessTo!.split(',');
    return businessList.includes(business.toUpperCase());
  }

  onNoCoreSelected() {
    this.usersService.business.next('nocore');
  }

  onLubricantsSelected() {
    this.usersService.business.next('lubricants');
  }

  onForeignSelected() {
    this.usersService.business.next('foreign');
  }

  onFuelsSelected() {}

  onIndustrialSelected() {}
}
