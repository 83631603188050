import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../../auth/auth.guard';
import { MasterResolver } from '../master/master.resolver';

import { VehicleDetailsComponent } from './vehicle-details/vehicle-details.component';
import { VehicleEditComponent } from './vehicle-edit/vehicle-edit.component';
import { VehicleNoSelectedComponent } from './vehicle-no-selected/vehicle-no-selected.component';
import { VehiclesComponent } from './vehicles.component';

const routes: Routes = [
  {
    path: ':business/vehicles',
    component: VehiclesComponent,
    canActivate: [AuthGuard],
    resolve: [MasterResolver],
    children: [
      { path: '', component: VehicleNoSelectedComponent },
      { path: 'new', component: VehicleEditComponent },
      { path: ':id', component: VehicleDetailsComponent },
      { path: ':id/edit', component: VehicleEditComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class VehiclesRoutingModule {}
