<hr />
<div class="row mb-3">
  <div class="col-sm-7">
    <h6>
      <img
        ngSrc="assets/images/icons/couplings.svg"
        alt="Acoplamientos"
        height="50"
        width="50"
      />Lista de {{ equipName }}
    </h6>
  </div>
  <div class="col-sm-5 text-end">
    <input
      placeholder="Buscar"
      class="input-search-datatable input-search-datatable-image"
      id="custom-search-input"
      type="text"
      [(ngModel)]="searchText"
      (input)="applyFilter()"
    />
    <button
      id="members-coupled-refresh"
      class="btn btn-light mx-2 btn-refresh btn-refresh-image"
      (click)="onRefresh()"
    ></button>
  </div>
</div>
<div class="row">
  <div class="text-center my-5" *ngIf="isGettingCouples">
    <app-spinner message="Cargando {{ equipName }}"></app-spinner>
  </div>
  <div class="col-xs-12">
    <div class="table-responsive-sm" *ngIf="!isGettingCouples">
      <table
        datatable
        [dtOptions]="dtOptions"
        class="table display nowrap"
        style="width: 100%"
      >
        <caption>
          Lista de Vehículos Acoplados
        </caption>
        <thead>
          <tr class="text-center">
            <th
              scope="col"
              *ngFor="let attribute of couplesAttributes"
              [hidden]="hideColumn(attribute)"
            >
              {{ attribute }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let couple of couples">
            <td>
              {{ couple.tracto.sapId! | sapId }} -
              {{ couple.tracto.licensePlate }}
            </td>
            <td>
              {{
                couple.tanque?.sapId! || couple.semirremolque?.sapId! | sapId
              }}
              -
              {{
                couple.tanque?.tanqueId || couple.semirremolque?.semirremolqueId
              }}
            </td>
            <td>
              {{ couple.tanque ? "Tanque" : "Semirremolque" }}
            </td>
            <td>
              <span>
                <img
                  [ngSrc]="getImageSrc(couple.status)"
                  height="25"
                  width="25"
                  alt="Estado"
                />
              </span>
              {{ couple.status | master : "VEHICLES#STATUSES" }}
            </td>
            <td [hidden]="hideColumn('Centro')">
              {{ couple.tracto.center | master : "CENTERS" }}
            </td>
            <td class="text-center" [hidden]="hideColumn('Desacoplar')">
              <button
                id="members-coupled-uncouple-{{ couple.coupleId }}"
                class="btn btn-outline-danger mx-2"
                [disabled]="disableDecouplingButton(couple.status)"
                *ngIf="showButton('decouple')"
                (click)="onDecoupling(couple)"
                (mouseover)="onMouseOverOrOut(couple.coupleId, 'over')"
                (mouseout)="onMouseOverOrOut(couple.coupleId, 'out')"
              >
                <span>
                  <img
                    class="uncouple-icon"
                    alt="Desacoplar"
                    [src]="
                      imgSrc[couple.coupleId] ||
                      'assets/images/icons/uncoupling-danger.svg'
                    "
                  />
                </span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!-- Modals -->
<ng-template #coupledTractoModal let-c="close" let-d="dismiss">
  <app-modal-with-comment
    [modalTitle]="modalTitle"
    [modalQuestion]="modalQuestion"
    [modalMessage]="modalMessage"
    (modalDismissed)="d($event)"
    (modalClosed)="c($event)"
  ></app-modal-with-comment>
</ng-template>
