import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { Storage } from 'aws-amplify';
import { appConstants } from '../../shared/constants/constants';
import {
  APIService,
  Document,
  GetCisternaQuery,
  GetDocumentSpecsQuery,
  GetDriverQuery,
  GetEnvasadoQuery,
  GetSemirremolqueQuery,
  GetTanqueQuery,
  GetTractoQuery,
  GetVehicleQuery,
  ListDocumentsQuery,
  ModelDocumentFilterInput,
  ModelIDInput,
  ModelSubscriptionDocumentFilterInput,
  ModelSubscriptionIDInput,
  UpdateDocumentInput,
  UpdateDocumentMutation,
} from '../../app-sync.service';
import { FeedbacksService } from '../../shared/feedbacks/feedbacks.service';
import { IdsList } from '../../shared/interfaces/ids-list';
import { documentOwner } from '../../shared/types/document-owners';
import { DocumentExtended } from 'src/app/shared/interfaces/document-extended';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  documentsChanged = new Subject<Document[]>();
  isGettingDocumentsChanged = new Subject<boolean>();
  isUploadingDocuments = new BehaviorSubject<boolean>(false);
  uploadingDocumentsList: string[] = [];
  documentPreSignedURL: string = '';
  selectedModel: documentOwner = '';
  selectedCenter: string = '';
  isRefreshFilter = new BehaviorSubject<boolean>(false);
  backgroundColors: { [key: string]: string } = {};

  private documents: DocumentExtended[] = [];
  private documentsFilter: ModelDocumentFilterInput = {};
  private subscriptionDocumentsFilter: ModelSubscriptionDocumentFilterInput =
    {};
  private ids: IdsList = {
    vehicleId: '',
    envasadoId: '',
    cisternaId: '',
    tractoId: '',
    tanqueId: '',
    semirremolqueId: '',
    trailerId: '',
    driverId: '',
  };

  constructor(
    private api: APIService,
    private feedbacksService: FeedbacksService
  ) {}

  /**
   * Configura el ID del vehículo, conductor o semirremolque
   * a quien pertenecen los documentos.
   * @param {keyof IdsList} key Nombre del ID.
   * @param {string} value Valor del ID.
   */
  setId(key: keyof IdsList, value: string): void {
    this.ids[key] = value;
  }

  /**
   * Configura el modelo del ente al que pertenecen los
   * documentos.
   * @param {documentOwner} model Nombre del Modelo.
   */
  setSelectedModel(model: documentOwner): void {
    this.selectedModel = model;
  }

  /**
   * Retorna el modelo seleccionado.
   * @return {string}
   */
  getSelectedModel(): string {
    return this.selectedModel.slice();
  }

  /**
   * Configura el centro del ente al que pertenecen los
   * documentos.
   * @param {string} center Nombre del Centro de distribución.
   */
  setSelectedCenter(center: string): void {
    this.selectedCenter = center;
  }

  // ---------------------------------------------------------------
  // Métodos para los documentos del vehículo/conductor seleccionado
  // ---------------------------------------------------------------
  /**
   * Configura la lista de documentos del vehículo/conductor
   * para referencia de todos los componentes.
   * @param {Document[]} documents
   */
  private setDocuments(documents: Document[]): void {
    this.documents = documents;
    this.setDocumentsFilter();
    this.setSubscriptionDocumentsFilter();
    this.setBackgroundColors().then(() => {
      this.documentsChanged.next(
        this.documents.slice().map((document) => {
          document.isMandatory = this.backgroundColors[document.documentId];
          return document;
        })
      );
      this.isGettingDocumentsChanged.next(false);
      console.log('documents', this.documents);
    });
  }

  /**
   * Configura el color de fondo de los documentos del vehículo/conductor
   * según su estado
   * @private
   */
  private setBackgroundColors(): Promise<void[]> {
    const promises = this.documents.map(async (document) => {
      if (document.status.endsWith(appConstants.document.codes.toBeApproved)) {
        const business: string = document.s3Path.split('/')[0].split('=')[1];
        let documentSpecsId: string;
        documentSpecsId = `${this.selectedModel}S#`;
        documentSpecsId += `${this.selectedCenter}#`;
        documentSpecsId += `${document.masterValueId}`;

        return this.api
          .GetDocumentSpecs(business, documentSpecsId)
          .then((documentSpec: GetDocumentSpecsQuery): void => {
            this.backgroundColors[document.documentId] =
              documentSpec.isMandatory ? 'is-mandatory' : 'is-not-mandatory';
          });
      } else {
        this.backgroundColors[document.documentId] = 'is-not-mandatory';
        return Promise.resolve();
      }
    });

    return Promise.all(promises);
  }

  /**
   * Actualiza la lista de documentos del vehículo/conductor.
   * @return {Promise}
   */
  async refreshDocuments(): Promise<void> {
    console.log('DocumentsFilter: ', this.getDocumentsFilter());
    this.isGettingDocumentsChanged.next(true);
    let tempListDocuments: Document[] = [];
    let getResult:
      | GetVehicleQuery
      | GetDriverQuery
      | GetEnvasadoQuery
      | GetCisternaQuery
      | GetTractoQuery
      | GetTanqueQuery
      | GetSemirremolqueQuery
      | null = null;

    switch (this.selectedModel) {
      case 'VEHICLE':
        getResult = await this.api.GetVehicle(this.ids.vehicleId);
        break;
      case 'DRIVER':
        getResult = await this.api.GetDriver(this.ids.driverId);
        break;
      case 'ENVASADO':
        getResult = await this.api.GetEnvasado(this.ids.envasadoId);
        break;
      case 'CISTERNA':
        getResult = await this.api.GetCisterna(this.ids.cisternaId);
        break;
      case 'TRACTO':
        getResult = await this.api.GetTracto(this.ids.tractoId);
        break;
      case 'TANQUE':
        getResult = await this.api.GetTanque(this.ids.tanqueId);
        break;
      case 'SEMIRREMOLQUE':
        getResult = await this.api.GetSemirremolque(this.ids.semirremolqueId);
        break;
      default:
        console.log('Ojo: Cargando Documentos por Scan!!');

        let listDocumentsResult: ListDocumentsQuery =
          await this.api.ListDocuments('', this.getDocumentsFilter());
        tempListDocuments = <Document[]>listDocumentsResult.items;
        let nextToken = listDocumentsResult.nextToken;
        // Es posible que la primera query no retorne todos los documentos.
        while (nextToken) {
          let loopListDocumentsResult: ListDocumentsQuery =
            await this.api.ListDocuments(
              '',
              this.getDocumentsFilter(),
              100,
              nextToken
            );
          tempListDocuments.push(
            ...(<Document[]>loopListDocumentsResult.items)
          );
          nextToken = loopListDocumentsResult.nextToken;
        }
        break;
    }

    if (getResult) {
      tempListDocuments.push(...(<Document[]>getResult.documents!.items));
    }

    this.setDocuments(tempListDocuments.slice());
  }

  /**
   * Retorna la lista de documentos del vehículo/conductor.
   * @return {Document[]}
   */
  getDocuments(): Document[] {
    return this.documents.slice();
  }

  /**
   * Verifica si un documento no requiere fecha.
   * Nota: Los documentos que no requieren fecha tienen ID
   * que inician con el número "1" después del "_".
   * @param {string} masterValueId ID del tipo de documento.
   * @return {Boolean} Responde a la pregunta ¿documento con fecha?
   */
  isDatelessDocument(masterValueId: string): boolean {
    // Los documentos que no requieren fecha de emisión y vencimiento tienen
    // ID que inician con 1, por ejemplo, NOCORE_1000001
    return masterValueId.split('_')[1].startsWith('1');
  }

  // ---------------------------------
  // Métodos para gestionar documentos
  // ---------------------------------
  /**
   * Actualiza un documento.
   * @param {UpdateDocumentInput} updateDocumentInput Objeto con atributos del documento.
   * @return {Promise}
   */
  async updateDocument(
    updateDocumentInput: UpdateDocumentInput
  ): Promise<void> {
    await this.api
      .UpdateDocument(updateDocumentInput)
      .then((document: UpdateDocumentMutation) => {
        this.feedbacksService.showFeedback(
          `${document.name} actualizado.`,
          'info'
        );
      })
      .catch((response: any): void => {
        this.feedbacksService.showErrorFeedbacks(
          response,
          `Error al actualizar documento ${updateDocumentInput.name}`
        );
      });
  }

  /**
   * Carga un documento a S3.
   * @param {Document} document Objeto con atributos del documento a cargar.
   * @param {string} uploadDateYYYYMMDD Fecha de carga en formato YYYYMMDD.
   * @param {string} uploadDateTimestamp Fecha de carga en formato timestamp.
   * @param {string} business Negocio al que pertenece el ente duelo del documento.
   * @param {string} model Modelo del ente al que pertenece el documento.
   * @param {string} id ID del ente al que pertenece el documento.
   * @return {Promise}
   */
  async uploadDocument(
    document: {
      documentId: string;
      documentMasterValueId: string;
      documentName: string;
      documentIssueDate: string;
      documentExpirationDate: string;
      documentFile: string;
      documentSourceFile: File;
    },
    uploadDateYYYYMMDD: string,
    uploadDateTimestamp: string,
    business: string,
    model: string,
    id: string
  ): Promise<void> {
    // Agregamos el documento a la lista de los documentos que se están cargando.
    this.uploadingDocumentsList.push(document.documentId);
    this.isUploadingDocuments.next(true);

    const file: File = document.documentSourceFile;
    console.log(' --- uploadDocument ---');
    console.log(file);

    const s3Prefix = this.generateS3Prefix(
      business,
      model,
      id,
      uploadDateYYYYMMDD
    );

    // Ejemplo de nombres de documentos en S3:
    // business=NOCORE/model=vehicles/id=AABB01/date=20230324/NOCORE_0000001_1678453020.pdf
    // business=NOCORE/model=trailers/id=BBCC01/vehicle=AABB01/date=20230324/NOCORE_0000001_1678453020.pdf
    const fileName: string =
      s3Prefix +
      document.documentMasterValueId +
      '_' +
      uploadDateTimestamp +
      '.' +
      file.name.split('.').pop();
    console.log('Se cargará el archivo: ', fileName);
    this.feedbacksService.showFeedback(
      'Cargando documento: ' + document.documentName,
      'info'
    );

    // Cargamos el documento a S3
    Storage.put(fileName, file, {
      level: 'public',
      resumable: true,
      completeCallback: async (event) => {
        // Si la carga es exitosa asignamos el documento al vehículo correspondiente
        console.log(`Archivo cargado correctamente ${event.key}`);
        console.log(' --- completeCallback ---');
        console.log(event);

        let documentInput = {
          documentId: document.documentId,
          masterValueId: document.documentMasterValueId,
          name: document.documentName,
          issueDate: document.documentIssueDate,
          expirationDate: document.documentExpirationDate,
          status: `${business.toUpperCase()}_${
            appConstants.document.codes.toBeApproved
          }`, // Estatus "Por Aprobar", un nuevo documento debe ser aprobado.
          s3Path: event.key!,
          // Se agrega el ID que corresponda según sea Vehículo, Semirremolque o Conductor
          ...(model.toUpperCase() === 'VEHICLE' && {
            vehicleDocumentsVehicleId: id,
          }),
          ...(model.toUpperCase() === 'ENVASADO' && {
            envasadoDocumentsEnvasadoId: id,
          }),
          ...(model.toUpperCase() === 'CISTERNA' && {
            cisternaDocumentsCisternaId: id,
          }),
          ...(model.toUpperCase() === 'TRACTO' && {
            tractoDocumentsTractoId: id,
          }),
          ...(model.toUpperCase() === 'TANQUE' && {
            tanqueDocumentsTanqueId: id,
          }),
          ...(model.toUpperCase() === 'SEMIRREMOLQUE' && {
            semirremolqueDocumentsSemirremolqueId: id,
          }),
          ...(model.toUpperCase() === 'DRIVER' && {
            driverDocumentsDriverId: id,
          }),
        };

        console.log('documentInput', documentInput);

        const getDocumentResponse = await this.api.GetDocument(
          document.documentId
        );
        if (getDocumentResponse) {
          console.log('Actualizar documento!');
          await this.api
            .UpdateDocument(documentInput)
            .then((updateDocumentResponse) => {
              this.feedbacksService.showFeedback(
                'Documento ' +
                  document.documentName +
                  ' cargado correctamente.',
                'success'
              );
              console.log('updateDocumentResponse: ', updateDocumentResponse);
            });
        } else {
          console.log('Crear documento!');
          await this.api
            .CreateDocument(documentInput)
            .then((createDocumentResponse) => {
              this.feedbacksService.showFeedback(
                'Documento ' +
                  document.documentName +
                  ' cargado correctamente.',
                'success'
              );
              console.log('createDocumentResponse: ', createDocumentResponse);
            });
        }

        this.uploadingDocumentsList = this.uploadingDocumentsList.filter(
          (documentId: string) => documentId !== document.documentId
        );
        this.isUploadingDocuments.next(this.uploadingDocumentsList.length > 0);
      },
      progressCallback: (progress): void => {
        console.log(`Cargado: ${progress.loaded}/${progress.total}`);
      },
      errorCallback: (err): void => {
        this.feedbacksService.showFeedback(
          'No pudo cargarse el documento ' +
            document.documentName +
            '. ERROR: ' +
            err,
          'danger'
        );
        console.error('Error inesperado al cargar:', err);
      },
    });
  }

  async uploadImage(
    document: {
      documentId: string;
      documentMasterValueId: string;
      documentName: string;
      documentSourceFile: File;
    },
    uploadDateYYYYMMDD: string,
    uploadDateTimestamp: string,
    business: string,
    model: string,
    id: string,
    order: number
  ): Promise<void> {
    // Agregamos la imagen a la lista de los documentos que se están cargando.
    this.uploadingDocumentsList.push(document.documentId);
    this.isUploadingDocuments.next(true);

    const file: File = document.documentSourceFile;
    console.log(file);

    const s3Prefix =
      'images/rtc/' +
      this.generateS3Prefix(business, model, id, uploadDateYYYYMMDD);

    // Ejemplo de nombres de documentos en S3:
    // images/rtc/business=NOCORE/model=vehicles/id=AABB01/date=20230324/0000001_1678453020.png
    const fileName: string =
      s3Prefix +
      document.documentMasterValueId +
      '_' +
      uploadDateTimestamp +
      '.' +
      file.name.split('.').pop();
    console.log('Se cargará el archivo: ', fileName);

    this.feedbacksService.showFeedback(
      `Cargando imagen de la pregunta ${order}, por favor espere.`,
      'info'
    );

    // Cargamos el documento a S3
    Storage.put(fileName, file, {
      level: 'public',
      resumable: true,
      completeCallback: async (event) => {
        // Si la carga es exitosa asignamos el documento al vehículo correspondiente
        console.log(`Archivo cargado correctamente ${event.key}`);
        console.log(' --- completeCallback ---');
        console.log(event);

        await this.api
          .UpdateFormQuestion({
            formQuestionId: document.documentId, //
            imageS3Path: event.key!,
          })
          .then(() => {
            this.feedbacksService.showFeedback(
              `imagen de la pregunta ${order} cargada correctamente.`,
              'success'
            );
          });
        console.log(' --- finishCallback ---');
        this.uploadingDocumentsList = this.uploadingDocumentsList.filter(
          (documentId: string) => documentId !== document.documentId
        );
        this.isUploadingDocuments.next(this.uploadingDocumentsList.length > 0);
      },
      progressCallback: (progress): void => {
        console.log(`Cargado: ${progress.loaded}/${progress.total}`);
      },
      errorCallback: (err): void => {
        this.feedbacksService.showFeedback(
          'No pudo cargarse la imagen de la pregunta ' +
            order +
            '. ERROR: ' +
            err,
          'danger'
        );
        console.error('Error inesperado al cargar:', err);
      },
    });
  }

  /**
   * Obtiene una URL pre-firmada de S3 de un documento y la asigna
   * a la variable documentPreSignedURL.
   * @param {Document} document
   * @return {Promise}
   */
  async getDocumentPreSignedURL(document: Document): Promise<void> {
    await Storage.get(document.s3Path, {
      level: 'public',
      download: false,
      expires: 900,
      progressCallback(progress) {
        console.log(
          `Obteniendo URL Pre-firmada: ${progress.loaded}/${progress.total}`
        );
      },
    })
      .then((preSignedURL: string) => {
        console.log('preSignedURL', preSignedURL);
        this.documentPreSignedURL = preSignedURL;
      })
      .catch((response: any): void => {
        this.feedbacksService.showErrorFeedbacks(
          response,
          `Error al obtener URL pre-firmada de ${document.name}`
        );
      });
  }

  /**
   * Obtiene una URL pre-firmada de S3 de una imagen y la asigna
   * a la variable documentPreSignedURL.
   * @param {string} imageS3Path
   * @return {Promise}
   */
  async getImagePreSignedURL(imageS3Path: string): Promise<void> {
    await Storage.get(imageS3Path, {
      level: 'public',
      download: false,
      expires: 900,
      progressCallback(progress) {
        console.log(
          `Obteniendo URL Pre-firmada: ${progress.loaded}/${progress.total}`
        );
      },
    })
      .then((preSignedURL: string) => {
        console.log('preSignedURL', preSignedURL);
        this.documentPreSignedURL = preSignedURL;
      })
      .catch((response: any): void => {
        this.feedbacksService.showErrorFeedbacks(
          response,
          `Error al obtener URL pre-firmada de ${imageS3Path}`
        );
      });
  }

  /**
   * Obtiene un Blob del documento en S3 y llama a la función
   * de descarga.
   * @param {Document} document
   * @return {Promise}
   */
  async downloadDocument(document: Document): Promise<void> {
    this.feedbacksService.showFeedback(`Descargando ${document.name}`, 'info');
    await Storage.get(document.s3Path, {
      level: 'public',
      download: true,
      expires: 900,
      progressCallback(progress) {
        console.log(`Descargando: ${progress.loaded}/${progress.total}`);
      },
    })
      .then((storageGetResponse) => {
        const documentBlob: Blob = <Blob>storageGetResponse.Body;

        let identity: string;
        switch (this.selectedModel) {
          case 'VEHICLE':
            identity = document.vehicleDocumentsVehicleId!;
            break;
          case 'ENVASADO':
            identity = document.envasadoDocumentsEnvasadoId!;
            break;
          case 'CISTERNA':
            identity = document.cisternaDocumentsCisternaId!;
            break;
          case 'TRACTO':
            identity = document.tractoDocumentsTractoId!;
            break;
          case 'TANQUE':
            identity = document.tanqueDocumentsTanqueId!;
            break;
          case 'SEMIRREMOLQUE':
            identity = document.semirremolqueDocumentsSemirremolqueId!;
            break;
          case 'DRIVER':
            identity = document.driverDocumentsDriverId!;
            break;
          default:
            identity = 'Sin Identidad';
            break;
        }

        this.downloadBlob(documentBlob, `${document.name} - ${identity}.pdf`);

        this.feedbacksService.showFeedback(
          `Documento ${document.name} descargado correctamente`,
          'success'
        );
      })
      .catch((response: any): void => {
        this.feedbacksService.showErrorFeedbacks(
          response,
          `Error al descargar ${document.name}`
        );
      });
  }

  /**
   * Genera un prefijo de S3 para un documento.
   * @param {string} business Negocio al que pertenece el ente duelo del documento.
   * @param {string} model Modelo del ente al que pertenece el documento.
   * @param {string} id ID del ente al que pertenece el documento.
   * @param {string} date Fecha de carga en formato YYYYMMDD.
   * @return {string} Prefijo del documento para S3.
   * @private
   */
  private generateS3Prefix(
    business: string,
    model: string,
    id: string,
    date: string
  ): string {
    // Ejemplo de prefijos de documentos en S3:
    // business=NOCORE/model=VEHICLE/id=AABB01/date=20230324/
    // business=NOCORE/model=DRIVER/id=123456789/date=20230324/
    return (
      'business=' +
      business.toUpperCase() +
      '/' +
      'model=' +
      model.toUpperCase() +
      '/' +
      'id=' +
      id.toUpperCase() +
      '/' +
      'date=' +
      date +
      '/'
    );
  }

  /**
   * Descarga el body del Blob del documento para el usuario.
   * @param {Blob} blob Body del Blob del documento.
   * @param {string} filename Nombre del documento al ser descargado.
   * @return {HTMLAnchorElement}
   * @private
   */
  private downloadBlob(blob: Blob, filename: string): HTMLAnchorElement {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  }

  // ------------------------------------------
  // Métodos para filtro de consultas a AppSync
  // ------------------------------------------
  /**
   * Configura el filtro para las consultas
   * de listas de documentos pertenecientes a vehículos/conductores.
   */
  setDocumentsFilter(): void {
    let modelIDInput: ModelIDInput;
    let modelDocumentFilterInput: ModelDocumentFilterInput;

    // Verificamos el modelo
    if (this.selectedModel === 'VEHICLE') {
      modelIDInput = { eq: this.ids.vehicleId };
      modelDocumentFilterInput = {
        vehicleDocumentsVehicleId: modelIDInput,
      };
    } else if (this.selectedModel === 'ENVASADO') {
      modelIDInput = { eq: this.ids.envasadoId };
      modelDocumentFilterInput = {
        envasadoDocumentsEnvasadoId: modelIDInput,
      };
    } else if (this.selectedModel === 'CISTERNA') {
      modelIDInput = { eq: this.ids.cisternaId };
      modelDocumentFilterInput = {
        cisternaDocumentsCisternaId: modelIDInput,
      };
    } else if (this.selectedModel === 'TRACTO') {
      modelIDInput = { eq: this.ids.tractoId };
      modelDocumentFilterInput = {
        tractoDocumentsTractoId: modelIDInput,
      };
    } else if (this.selectedModel === 'TANQUE') {
      modelIDInput = { eq: this.ids.tanqueId };
      modelDocumentFilterInput = {
        tanqueDocumentsTanqueId: modelIDInput,
      };
    } else if (this.selectedModel === 'SEMIRREMOLQUE') {
      modelIDInput = { eq: this.ids.semirremolqueId };
      modelDocumentFilterInput = {
        semirremolqueDocumentsSemirremolqueId: modelIDInput,
      };
    } else if (this.selectedModel === 'DRIVER') {
      modelIDInput = { eq: this.ids.driverId };
      modelDocumentFilterInput = {
        driverDocumentsDriverId: modelIDInput,
      };
    } else {
      modelDocumentFilterInput = { documentId: { eq: 'NONE' } };
      this.feedbacksService.showFeedback(
        `Error generando filtro de documentos: Modelo ${this.selectedModel} no tiene regla definida.`,
        'danger'
      );
    }

    this.documentsFilter = modelDocumentFilterInput;
  }

  /**
   * Retorna el filtro para las consultas
   * de listas de documentos pertenecientes a vehículos/conductores.
   * @return {ModelDocumentFilterInput}
   */
  getDocumentsFilter(): ModelDocumentFilterInput {
    return { ...this.documentsFilter };
  }

  /**
   * Configura el filtro para las subscripciones
   * de listas de documentos pertenecientes a vehículos/conductores.
   */
  setSubscriptionDocumentsFilter(): void {
    let modelSubscriptionIDInput: ModelSubscriptionIDInput;

    // Verificamos el modelo
    if (this.selectedModel === 'VEHICLE') {
      modelSubscriptionIDInput = { contains: this.ids.vehicleId };
    } else if (this.selectedModel === 'ENVASADO') {
      modelSubscriptionIDInput = { contains: this.ids.envasadoId };
    } else if (this.selectedModel === 'CISTERNA') {
      modelSubscriptionIDInput = { contains: this.ids.cisternaId };
    } else if (this.selectedModel === 'TRACTO') {
      modelSubscriptionIDInput = { contains: this.ids.tractoId };
    } else if (this.selectedModel === 'TANQUE') {
      modelSubscriptionIDInput = { contains: this.ids.tanqueId };
    } else if (this.selectedModel === 'SEMIRREMOLQUE') {
      modelSubscriptionIDInput = { contains: this.ids.semirremolqueId };
    } else if (this.selectedModel === 'DRIVER') {
      modelSubscriptionIDInput = { contains: this.ids.driverId };
    } else {
      modelSubscriptionIDInput = { eq: 'NONE' };
      this.feedbacksService.showFeedback(
        `Error generando filtro para subscripción de documentos: Modelo ${this.selectedModel} no tiene regla definida.`,
        'danger'
      );
    }

    this.subscriptionDocumentsFilter = { documentId: modelSubscriptionIDInput };
  }

  /**
   * Retorna el filtro para las subscripciones
   * de listas de documentos pertenecientes a vehículos/conductores.
   * @return {ModelSubscriptionDocumentFilterInput}
   */
  getSubscriptionDocumentsFilter(): ModelSubscriptionDocumentFilterInput {
    return { ...this.subscriptionDocumentsFilter };
  }

  // --------------------
  // Métodos para estilos
  // --------------------
  /**
   * Retorna un color dependiendo del estado del documento.
   * @param {Document} document Documento a considerar.
   * @param {string} business Negocio del ente al que pertenece el documento.
   * @return {string}
   */
  getSatusColor(document: Document, business: string): string {
    let statusColor: string;
    if (
      document.status ===
      `${business}_${appConstants.document.codes.toBeApproved}`
    ) {
      statusColor = appConstants.colors.statuses.toBeApproved;
    } else if (
      document.status === `${business}_${appConstants.document.codes.valid}`
    ) {
      statusColor = appConstants.colors.statuses.available;
    } else if (
      document.status === `${business}_${appConstants.document.codes.expired}`
    ) {
      statusColor = appConstants.colors.statuses.blocked;
    } else if (
      document.status === `${business}_${appConstants.document.codes.inProcess}`
    ) {
      statusColor = appConstants.colors.statuses.inProcess;
    } else {
      statusColor = appConstants.colors.statuses.rejected;
    }

    return statusColor;
  }
}
