import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule, RouterOutlet } from '@angular/router';

import { MasterModule } from '../master/master.module';
import { SharedModule } from '../../shared/shared.module';

import { CouplingsComponent } from './couplings.component';
import { CouplingsRoutingModule } from './couplings-routing.module';
import { MembersCouplingComponent } from './members-coupling/members-coupling.component';
import { MembersCoupledComponent } from './members-coupled/members-coupled.component';

@NgModule({
  declarations: [
    CouplingsComponent,
    MembersCouplingComponent,
    MembersCoupledComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    DataTablesModule,
    NgOptimizedImage,
    RouterOutlet,
    CouplingsRoutingModule,
    MasterModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    NgOptimizedImage,
  ],
})
export class CouplingsModule {}
