import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ZenObservable } from 'zen-observable-ts';

import { appConstants } from '../../../shared/constants/constants';

import { APIService, Cisterna } from '../../../app-sync.service';
import { CisternasService } from '../cisternas.service';
import { UsersService } from '../../users/users.service';
import { DataTableDirective } from 'angular-datatables';
import { DocumentsService } from '../../documents/documents.service';
import { MasterService } from '../../master/master.service';

@Component({
  selector: 'app-cisternas-list',
  templateUrl: './cisternas-list.component.html',
  styleUrls: ['./cisternas-list.component.css'],
})
export class CisternasListComponent implements OnInit, OnDestroy {
  equipName: string = 'Cisternas';
  dtOptions: DataTables.Settings = {};
  isGettingCisternas: boolean = false;
  isAdmin: boolean = false;
  isApprover: boolean = false;
  isViewer: boolean = false;
  isCarrier: boolean = false;
  cisternas: Cisterna[];
  cisternasAttributes: string[] = [
    'SAP ID',
    'Estado',
    'Patente',
    'Transportista',
    'Centro',
    'Alertas',
    'Bloqueos',
  ];

  //Esto permite crear un filtro de búsqueda personalizado en el datatable
  searchText: string = '';
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;

  private cisternasChangedSubscription: Subscription = new Subscription();
  private onDeleteCisternaListener: ZenObservable.Subscription =
    new Subscription();
  private onCreateCisternaListener: ZenObservable.Subscription =
    new Subscription();
  private onUpdateCisternaListener: ZenObservable.Subscription =
    new Subscription();

  constructor(
    private cisternasService: CisternasService,
    private api: APIService,
    private router: Router,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private documentService: DocumentsService,
    private masterService: MasterService
  ) {
    this.dtOptions = appConstants.datatables.options;
    this.cisternas = this.cisternasService.getCisternas();
  }

  async ngOnInit(): Promise<void> {
    this.cisternasChangedSubscription =
      this.cisternasService.cisternasChanged.subscribe(
        (cisternas: Cisterna[]) => {
          this.cisternas = cisternas;
        }
      );

    this.isAdmin = this.usersService.isAdmin;
    this.isApprover = this.usersService.isApprover;
    this.isViewer = this.usersService.isViewer;
    this.isCarrier = this.usersService.isCarrier;

    await this.updateCisternasList();

    // Una vez cargada la lista de vehículos definimos los filtros para subscripciones
    const subscriptionCisternasFilter =
      this.cisternasService.getCisternasFilterForSubscriptions();
    // Subscripción a Vehículos eliminados
    this.onDeleteCisternaListener = this.api
      .OnDeleteCisternaListener(subscriptionCisternasFilter)
      .subscribe((response) => {
        if (response) {
          this.cisternasService.refreshCisternas();
        }
      });
    // Subscripción a Vehículos actualizados
    this.onUpdateCisternaListener = this.api
      .OnUpdateCisternaListener(subscriptionCisternasFilter)
      .subscribe((response) => {
        if (response) {
          this.cisternasService.refreshCisternas();
        }
      });
    // Subscripción a Vehículos creados
    this.onCreateCisternaListener = this.api
      .OnCreateCisternaListener(subscriptionCisternasFilter)
      .subscribe((response) => {
        if (response) {
          this.cisternasService.refreshCisternas();
        }
      });
  }

  /**
   * Navega a al formulario de creación de Cisterna.
   */
  onNewCisterna(): void {
    this.router
      .navigate(['new'], { relativeTo: this.route })
      .then(() => console.log('navigate to new'));
  }

  /**
   * Llama al método que refresca la lista de Cisternas.
   * @return {Promise}
   */
  async onRefresh(): Promise<void> {
    this.searchText = '';
    await this.updateCisternasList();
  }

  /**
   * Aplica a la tabla el filtro ingresado por el usuario.
   */
  applyFilter(): void {
    if (this.dtElement) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api): void => {
        dtInstance.search(this.searchText).draw();
      });
    }
  }

  /**
   * Define la cisterna que está seleccionada.
   * y navega al detalle del mismo.
   * @param {Cisterna} cisterna Cisterna seleccionada de la lista.
   */
  onSelectedCisterna(cisterna: Cisterna): void {
    this.documentService.isRefreshFilter.next(true);
    this.cisternasService.setSelectedCisterna(cisterna);
    this.router
      .navigate([cisterna.cisternaId], { relativeTo: this.route })
      .then(() => console.log(`navigate to ${cisterna.cisternaId}`));
  }

  /**
   * Indica si una cisterna tiene documentos por vencer.
   * @param {Cisterna} cisterna Cisterna de la lista.
   * @return {boolean} Boolean que indica si la cisterna tiene documentos por vencer.
   */
  hasToExpireDocuments(cisterna: Cisterna): boolean {
    const docNumber = cisterna.documentsToExpire || 0;
    return docNumber > 0;
  }

  /**
   * Indica si una cisterna tiene documentos vencidos.
   * @param {Cisterna} cisterna Cisterna de la lista.
   * @return {boolean} Boolean que indica si la cisterna tiene documentos vencidos.
   */
  hasExpiredDocuments(cisterna: Cisterna): boolean {
    const docNumber = cisterna.documentsExpired || 0;
    return docNumber > 0;
  }

  /**
   * Retorna la fuente de imagen a mostrar en el Estado de la Cisterna.
   * @return {string} ruta a imagen.
   */
  getImageSrc(status: string): string {
    return this.masterService.getImageSrc(status);
  }

  /**
   * Refresca la lista de cisternas.
   * @private
   * @return {Promise}
   */
  private async updateCisternasList(): Promise<void> {
    this.isGettingCisternas = true;
    await this.cisternasService.refreshCisternas().then((): void => {
      this.isGettingCisternas = false;
    });
  }

  /**
   * Determina, con base al rol del usuario, cuáles botones mostrar.
   * @param {string} button Acción del botón.
   * @return {boolean}
   */
  showButton(button: string): boolean {
    let show: boolean = false;

    if (button === 'create') {
      show = this.isAdmin || this.isCarrier;
    }
    return show;
  }

  /**
   * Determina, con base al rol del usuario, si debe ocultarse una columna de la tabla.
   * @param {string} column Nombre de la columna.
   * @return {Boolean}
   */
  hideColumn(column: string): boolean {
    let hide: boolean = false;

    if (column === 'Transportista') {
      hide = !(this.isAdmin || this.isApprover || this.isViewer);
    }
    return hide;
  }

  ngOnDestroy(): void {
    this.cisternasChangedSubscription.unsubscribe();
    this.onDeleteCisternaListener.unsubscribe();
    this.onCreateCisternaListener.unsubscribe();
    this.onUpdateCisternaListener.unsubscribe();
    console.log('cisternas-list.component subscriptions removed.');
  }
}
