import { Component } from '@angular/core';

@Component({
  selector: 'app-cisternas',
  templateUrl: './cisternas.component.html',
  styleUrls: ['./cisternas.component.css'],
})
export class CisternasComponent {
  equipName: string = 'Cisternas';
}
