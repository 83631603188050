<div class="text-center my-5" *ngIf="isLoading">
  <app-spinner message="Cargando"></app-spinner>
</div>
<div class="p-5 mb-4 bg-light rounded-3" *ngIf="!isLoading">
  <div *ngIf="!businessDefined">
    <app-business-selector></app-business-selector>
  </div>
  <div *ngIf="businessDefined">
    <div class="container-fluid py-5">
      <h1 class="display-5 fw-normal">
        <strong>Bienvenido</strong> {{ activeUser?.name }}
      </h1>
      <p class="col-md-12 fs-4">
        <strong>Roz</strong> es una aplicación en la que podrás gestionar la
        documentación de tus vehículos y conductores. Ésta estará en constante
        validación y recibirás notificaciones cuando sus estados cambien.
      </p>
      <p class="col-md-8 fs-4">Por favor, elige qué quieres gestionar.</p>
      <div class="row">
        <div class="row">
          <div
            class="card text-center border-primary m-1"
            *ngIf="showButton('vehicles')"
          >
            <img
              class="card-img-top my-4 p-2"
              ngSrc="../../assets/images/icons/envasado.svg"
              alt="Vehículos"
              height="100"
              width="100"
            />
            <div class="card-body">
              <button
                id="home-button-vehicles"
                class="btn text-primary stretched-link"
                type="button"
                (click)="onSelected('vehicles')"
              >
                Vehículos
              </button>
            </div>
          </div>
          <div
            class="card text-center border-primary m-1"
            *ngIf="showButton('envasados')"
          >
            <img
              class="card-img-top my-4 p-2"
              ngSrc="../../assets/images/icons/envasado.svg"
              alt="Envasados Rígidos"
              height="100"
              width="100"
            />
            <div class="card-body">
              <button
                id="home-button-envasados"
                class="btn text-primary stretched-link"
                type="button"
                (click)="onSelected('envasados')"
              >
                Envasados Rígidos
              </button>
            </div>
          </div>
          <div
            class="card text-center border-primary m-1"
            *ngIf="showButton('cisternas')"
          >
            <img
              class="card-img-top my-4 p-2"
              ngSrc="../../assets/images/icons/cisternas.svg"
              alt="Cisternas"
              height="100"
              width="100"
            />
            <div class="card-body">
              <button
                id="home-button-cisternas"
                class="btn text-primary stretched-link"
                type="button"
                (click)="onSelected('cisternas')"
              >
                Cisternas
              </button>
            </div>
          </div>
          <div
            class="card text-center border-primary m-1"
            *ngIf="showButton('tractos')"
          >
            <img
              class="card-img-top my-4 p-2"
              ngSrc="../../assets/images/icons/tractos.svg"
              alt="Tractos"
              height="100"
              width="100"
            />
            <div class="card-body">
              <button
                id="home-button-tractos"
                class="btn text-primary stretched-link"
                type="button"
                (click)="onSelected('tractos')"
              >
                Tractos
              </button>
            </div>
          </div>
          <div
            class="card text-center border-primary m-1"
            *ngIf="showButton('tanques')"
          >
            <img
              class="card-img-top my-4 p-2"
              ngSrc="../../assets/images/icons/tanques.svg"
              alt="Tanques"
              height="100"
              width="100"
            />
            <div class="card-body">
              <button
                id="home-button-tanques"
                class="btn text-primary stretched-link"
                type="button"
                (click)="onSelected('tanques')"
              >
                Tanques
              </button>
            </div>
          </div>
          <div
            class="card text-center border-primary m-1"
            *ngIf="showButton('semirremolques')"
          >
            <img
              class="card-img-top my-4 p-2"
              ngSrc="../../assets/images/icons/semirremolques.svg"
              alt="Semirremolques"
              height="100"
              width="100"
            />
            <div class="card-body">
              <button
                id="home-button-semirremolques"
                class="btn text-primary stretched-link"
                type="button"
                (click)="onSelected('semirremolques')"
              >
                Semirremolques
              </button>
            </div>
          </div>
          <div
            class="card text-center border-primary m-1"
            *ngIf="showButton('couplings')"
          >
            <img
              id="home-couplings-img"
              class="card-img-top my-4"
              ngSrc="../../assets/images/icons/couplings.svg"
              alt="Acoplamientos"
              height="100"
              width="100"
            />
            <div class="card-body">
              <button
                id="home-button-couplings"
                class="btn text-primary stretched-link"
                type="button"
                (click)="onSelected('couplings')"
              >
                Acoplamientos
              </button>
            </div>
          </div>
          <div
            class="card text-center border-primary m-1"
            *ngIf="showButton('drivers')"
          >
            <img
              id="home-driver-img"
              class="card-img-top my-4 p-2"
              ngSrc="../../assets/images/icons/conductores.svg"
              alt="Conductores"
              height="100"
              width="100"
            />
            <div class="card-body">
              <button
                id="home-button-drivers"
                class="btn text-primary stretched-link"
                type="button"
                (click)="onSelected('drivers')"
              >
                Conductores
              </button>
            </div>
          </div>
          <div
            class="card text-center border-primary m-1"
            *ngIf="showButton('users')"
          >
            <img
              id="home-user-img"
              class="card-img-top p-2"
              ngSrc="../../assets/images/icons/usuarios.svg"
              alt="Usuarios"
              height="150"
              width="100"
            />
            <div class="card-body">
              <button
                id="home-button-users"
                class="btn text-primary stretched-link"
                type="button"
                (click)="onSelected('users')"
              >
                Usuarios
              </button>
            </div>
          </div>
          <div
            class="card text-center border-primary m-1"
            *ngIf="showButton('rtc-evaluate')"
          >
            <img
              class="card-img-top my-4 p-2"
              ngSrc="../../assets/images/icons/evaluar.svg"
              alt="RTCForm"
              height="120"
              width="51"
            />
            <div class="card-body">
              <button
                id="home-button-rtc-evaluate"
                class="btn text-primary stretched-link"
                type="button"
                (click)="onSelected('rtc-evaluate')"
              >
                Evaluar
              </button>
            </div>
          </div>
          <div
            class="card text-center border-primary m-1"
            *ngIf="showButton('rtc-historial')"
          >
            <img
              class="card-img-top my-4 p-2"
              ngSrc="../../assets/images/icons/historial.svg"
              alt="RTCHistorial"
              height="120"
              width="51"
            />
            <div class="card-body">
              <button
                id="home-button-rtc-historial"
                class="btn text-primary stretched-link"
                type="button"
                (click)="onSelected('rtc-historial')"
              >
                Historial RTC
              </button>
            </div>
          </div>
          <div
            class="card text-center border-primary m-1"
            *ngIf="showButton('rtc-history')"
          >
            <img
              class="card-img-top my-4 p-2"
              ngSrc="../../assets/images/icons/historial.svg"
              alt="RTCHistory"
              height="120"
              width="51"
            />
            <div class="card-body">
              <button
                id="home-button-rtc-history"
                class="btn text-primary stretched-link"
                type="button"
                (click)="onSelected('rtc-history')"
              >
                Historial RTC
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <span class="text-muted float-end px-3">
    <small>versión: {{ version }}</small>
  </span>
</div>
