import { Injectable } from '@angular/core';
import {
  APIService,
  Envasado,
  EnvasadosByBusinessAndEnvasadoIdQuery,
  EnvasadosByCenterAndEnvasadoIdQuery,
  EnvasadosByCompanyAndEnvasadoIdQuery,
  GetEnvasadoQuery,
  ModelSubscriptionEnvasadoFilterInput,
  ModelSubscriptionIDInput,
  User,
} from '../../app-sync.service';
import { appConstants } from '../../shared/constants/constants';
import { DocumentsService } from '../documents/documents.service';
import { Subject } from 'rxjs';
import { UsersService } from '../users/users.service';
import { filterInputs } from '../../shared/types/filter-inputs';
import { FeedbacksService } from '../../shared/feedbacks/feedbacks.service';

@Injectable({
  providedIn: 'root',
})
export class EnvasadosService {
  selectedEnvasadoChanged = new Subject<Envasado>();
  envasadosChanged = new Subject<Envasado[]>();

  private readonly initialEnvasado: Envasado;
  private selectedEnvasado: Envasado;
  private envasados: Envasado[] = [];
  private envasadosFilterForSubscriptions: ModelSubscriptionEnvasadoFilterInput =
    {};
  private selectedEnvasadoFilterForSubscriptions: ModelSubscriptionEnvasadoFilterInput =
    {};

  constructor(
    private api: APIService,
    private documentsService: DocumentsService,
    private usersService: UsersService,
    private feedbacksService: FeedbacksService
  ) {
    this.initialEnvasado = {
      ...appConstants.envasado.initialization,
      carrier: appConstants.carrier.initialization,
    };
    this.selectedEnvasado = this.initialEnvasado;
    this.setEnvasadosFilter();
  }

  // --------------------------------------------
  // Métodos para el envasado rígido seleccionado
  // --------------------------------------------
  /**
   * Configura el envasado rígido seleccionado para referencia de
   * todos los componentes.
   * @param {Envasado} envasado Envasado rígido seleccionado.
   */
  setSelectedEnvasado(envasado: Envasado): void {
    this.selectedEnvasado = envasado;
    this.setSelectedEnvasadoFilterForSubscriptions();
    this.selectedEnvasadoChanged.next({ ...this.selectedEnvasado });
    console.log('selectedEnvasado', this.selectedEnvasado);

    // Documentos
    this.documentsService.setId('envasadoId', this.selectedEnvasado.envasadoId);
    this.documentsService.setSelectedModel('ENVASADO');
    this.documentsService.setSelectedCenter(envasado.center);
    this.documentsService.setDocumentsFilter();
    this.documentsService
      .refreshDocuments()
      .then(() => console.log('Documentos del envasado rígido actualizados.'));
  }

  /**
   * Actualiza los datos del envasado rígido seleccionado.
   * @return {Promise}
   */
  async refreshSelectedEnvasado(): Promise<void> {
    const getEnvasadoResult: GetEnvasadoQuery = await this.api.GetEnvasado(
      this.selectedEnvasado.envasadoId
    );
    this.setSelectedEnvasado(<Envasado>getEnvasadoResult);
  }

  /**
   * Retorna el envasado rígido seleccionado.
   * @return {Envasado}
   */
  getSelectedEnvasado(): Envasado {
    return { ...this.selectedEnvasado };
  }

  // ----------------------------------
  // Métodos para la lista de Vehículos
  // ----------------------------------
  /**
   * Configura la lista de envasados rígidos para referencia de
   * todos los componentes.
   * @param {Envasado[]} envasados Lista de envasados rígidos.
   */
  setEnvasados(envasados: Envasado[]): void {
    this.envasados = envasados;
    this.setEnvasadosFilter();
    this.envasadosChanged.next(this.envasados.slice());
  }

  /**
   * Actualiza la lista de envasados rígidos.
   * @return {Promise}
   */
  async refreshEnvasados(): Promise<void> {
    const activeUser: User = this.usersService.getActiveUser();
    const business: string = this.usersService.business.value.toUpperCase();
    let tempListEnvasados: Envasado[] = [];

    // Verificamos el grupo del usuario
    if (activeUser.authGroup.endsWith('_CARRIERS')) {
      // Los transportistas podrán acceder a vehículos donde
      // ellos figuren como Carrier
      tempListEnvasados = await this.listEnvasadosByCarrier(activeUser);
    } else if (activeUser.authGroup.endsWith('_ADMINS')) {
      // Los administradores podrán acceder a todos los vehículos/conductores
      // asociados a su negocio
      tempListEnvasados = await this.listEnvasadosByBusiness(business);
    } else if (
      activeUser.authGroup.endsWith('_VIEWERS') ||
      activeUser.authGroup.endsWith('_APPROVERS')
    ) {
      // Los visualizadores y aprobadores podrán acceder a todos los vehículos
      // asociados a los centros a los que pertenece el usuario.
      tempListEnvasados = await this.listEnvasadosByCenter(
        activeUser,
        business
      );
    } else {
      this.feedbacksService.showFeedback(
        `Error obteniendo envasados rígidos. Grupo ${activeUser.authGroup} no tiene regla definida.`,
        'danger'
      );
    }

    this.setEnvasados(tempListEnvasados.slice());
  }

  /**
   * Retorna la lista de Vehículos usando el GSI de Negocio.
   * @param business {string} Negocio.
   * @private
   */
  private async listEnvasadosByBusiness(business: string): Promise<Envasado[]> {
    let listEnvasadosResult: EnvasadosByBusinessAndEnvasadoIdQuery;
    listEnvasadosResult = await this.api.EnvasadosByBusinessAndEnvasadoId(
      business
    );

    let tempListEnvasados: Envasado[] = <Envasado[]>listEnvasadosResult.items;
    let nextToken: string | null | undefined = listEnvasadosResult.nextToken;
    // Es posible que la primera query no retorne todos los vehículos.
    while (nextToken) {
      let loopListEnvasadosResult: EnvasadosByBusinessAndEnvasadoIdQuery =
        await this.api.EnvasadosByBusinessAndEnvasadoId(
          business,
          undefined,
          undefined,
          undefined,
          100,
          nextToken
        );
      tempListEnvasados.push(...(<Envasado[]>loopListEnvasadosResult.items));
      nextToken = loopListEnvasadosResult.nextToken;
    }
    return tempListEnvasados.slice();
  }

  /**
   * Retorna la lista de Vehículos usando el GSI de Centros.
   * @param activeUser {User} Usuario activo.
   * @param business {string} Negocio.
   * @private
   */
  private async listEnvasadosByCenter(
    activeUser: User,
    business: string
  ): Promise<Envasado[]> {
    let tempListEnvasados: Envasado[] = [];
    const centers: string[] =
      JSON.parse(activeUser.centers || `{"${business}": []}`)[business] || [];

    // Ejecutar todas las peticiones en paralelo
    const envasadosPromises: Promise<Envasado[]>[] = centers.map(
      async (center: string): Promise<Envasado[]> => {
        let listEnvasadosResult: EnvasadosByCenterAndEnvasadoIdQuery;

        listEnvasadosResult = await this.api.EnvasadosByCenterAndEnvasadoId(
          center
        );
        let envasados: Envasado[] = <Envasado[]>listEnvasadosResult.items;

        let nextToken: string | null | undefined =
          listEnvasadosResult.nextToken;

        // Si hay más datos, seguir llamando hasta que no haya nextToken
        while (nextToken) {
          let loopListEnvasadosResult: EnvasadosByCenterAndEnvasadoIdQuery =
            await this.api.EnvasadosByCenterAndEnvasadoId(
              center,
              undefined,
              undefined,
              undefined,
              100,
              nextToken
            );
          envasados.push(...(<Envasado[]>loopListEnvasadosResult.items));
          nextToken = loopListEnvasadosResult.nextToken;
        }

        return envasados;
      }
    );

    // Esperar a que todas las promesas se resuelvan
    const results: Envasado[][] = await Promise.all(envasadosPromises);

    // Combinar todos los resultados en una sola lista
    results.forEach((envasados: Envasado[]): void => {
      tempListEnvasados.push(...envasados);
    });

    return tempListEnvasados.slice();
  }

  /**
   * Retorna la lista de Vehículos usando el GSI de Transportista.
   * @param activeUser {User} Usuario activo.
   * @private
   */
  private async listEnvasadosByCarrier(activeUser: User): Promise<Envasado[]> {
    let listEnvasadosResult: EnvasadosByCompanyAndEnvasadoIdQuery;
    listEnvasadosResult = await this.api.EnvasadosByCompanyAndEnvasadoId(
      activeUser.company
    );

    let tempListEnvasados: Envasado[] = <Envasado[]>listEnvasadosResult.items;
    let nextToken: string | null | undefined = listEnvasadosResult.nextToken;
    // Es posible que la primera query no retorne todos los vehículos.
    while (nextToken) {
      let loopListEnvasadosResult: EnvasadosByCompanyAndEnvasadoIdQuery =
        await this.api.EnvasadosByCompanyAndEnvasadoId(
          activeUser.company,
          undefined,
          undefined,
          undefined,
          100,
          nextToken
        );
      tempListEnvasados.push(...(<Envasado[]>loopListEnvasadosResult.items));
      nextToken = loopListEnvasadosResult.nextToken;
    }
    return tempListEnvasados.slice();
  }

  /**
   * Retorna la lista de envasados rígidos.
   * @return {Envasado[]}
   */
  getEnvasados(): Envasado[] {
    return this.envasados.slice();
  }

  // ------------------------------------------
  // Métodos para filtro de consultas a AppSync
  // ------------------------------------------
  /**
   * Configura el filtro para las consultas
   * de listas de envasados rígidos y subscripciones.
   * @private
   */
  private setEnvasadosFilter(): void {
    let modelFilterInput: filterInputs = this.usersService.getEntitiesFilter();

    this.envasadosFilterForSubscriptions = <
      ModelSubscriptionEnvasadoFilterInput
    >modelFilterInput;
  }

  /**
   * Retorna el filtro para las subscripciones
   * de listas de envasados rígidos.
   * @return {ModelSubscriptionEnvasadoFilterInput}
   */
  getEnvasadosFilterForSubscriptions(): ModelSubscriptionEnvasadoFilterInput {
    return { ...this.envasadosFilterForSubscriptions };
  }

  /**
   * Configura el filtro para las subscripciones
   * del envasado rígido seleccionado.
   */
  private setSelectedEnvasadoFilterForSubscriptions(): void {
    const modelSubscriptionIDInput: ModelSubscriptionIDInput = {
      eq: this.selectedEnvasado.envasadoId,
    };
    this.selectedEnvasadoFilterForSubscriptions = {
      envasadoId: modelSubscriptionIDInput,
    };
  }

  /**
   * Retorna el filtro para las subscripciones
   * del envasado rígido seleccionado.
   * @return {ModelSubscriptionEnvasadoFilterInput}
   */
  getSelectedEnvasadoFilterForSubscriptions(): ModelSubscriptionEnvasadoFilterInput {
    return { ...this.selectedEnvasadoFilterForSubscriptions };
  }
}
