import { Injectable } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { FormValidators } from '../interfaces/form-validators';

import {
  Document,
  FormQuestion,
  FormQuestionTemplate,
  Master,
} from '../../app-sync.service';
import { ValidatorsService } from './validators.service';
import { DocumentsService } from '../../pages/documents/documents.service';
import { MasterService } from '../../pages/master/master.service';
import { UsersService } from '../../pages/users/users.service';

@Injectable({
  providedIn: 'root',
})
export class FormsService {
  private today: Date = this.generateDate();
  private fictitiousIssueDate: string = this.today.toISOString().split('T')[0];
  private fictitiousExpirationDate: string = new Date(
    this.today.setFullYear(this.today.getFullYear() + 100)
  )
    .toISOString()
    .split('T')[0];

  constructor(
    private validatorsService: ValidatorsService,
    private documentsService: DocumentsService,
    private mastersService: MasterService,
    private usersService: UsersService
  ) {}

  /**
   * Retorna un arreglo inicial de grupos de control de preguntas plantilla RTC.
   * @param {FormQuestionTemplate[]} questions Lista de preguntas plantilla RTC.
   * @param {string} licensePlate Patente del vehículo.
   * @param {string} formDate Fecha de evaluación, formato YYYYMM.
   * @return {FormArray<FormGroup>} Arreglo de grupos de control de preguntas RTC.
   */
  formQuestionFormArrayInitialization(
    questions: FormQuestionTemplate[] | FormQuestion[],
    licensePlate: string,
    formDate: string
  ): FormArray<FormGroup> {
    let questionsFormArray: FormArray<FormGroup> = new FormArray([
      new FormGroup({
        formQuestionId: new FormControl<string | null>(null),
        question: new FormControl<string | null>(null),
        criticality: new FormControl<string | null>(null),
        weight: new FormControl<number | null>(null),
        order: new FormControl<number | null>(null),
        answer: new FormControl<string | null>(null),
        comment: new FormControl<string | null>(null),
        imageFile: new FormControl(null),
        imageSourceFile: new FormControl(null),
      }),
    ]);

    // Ordenamos por la propiedad "order".
    questions.sort((a: any, b: any) => a.order - b.order);

    questionsFormArray.removeAt(0);
    for (let question of questions) {
      let formQuestionId: string;
      if ('formQuestionTemplateId' in question) {
        formQuestionId = `RTC#${licensePlate}#${formDate}#${question.formQuestionTemplateId}`;
      } else {
        formQuestionId = question.formQuestionId;
      }

      questionsFormArray.push(
        new FormGroup({
          formQuestionId: new FormControl<string | null>(
            formQuestionId,
            Validators.required
          ),
          question: new FormControl<string | null>(
            question.question,
            Validators.required
          ),
          criticality: new FormControl<string | null>(
            question.criticality!,
            Validators.required
          ),
          weight: new FormControl<number | null>(
            question.weight!,
            Validators.required
          ),
          order: new FormControl<number | null>(
            question.order!,
            Validators.required
          ),
          answer: new FormControl<string | null>(
            'answer' in question ? question.answer! : null,
            Validators.required
          ),
          comment: new FormControl<string | null>(
            'comment' in question ? question.comment! : null,
            [Validators.minLength(1), Validators.maxLength(300)]
          ),
          imageFile: new FormControl(null),
          imageSourceFile: new FormControl(null),
          imageS3Path: new FormControl<string | null>(
            'imageS3Path' in question ? question.imageS3Path! : null
          ),
          imageLocalPath: new FormControl<string | null>(null),
        })
      );
    }

    return questionsFormArray;
  }

  /**
   * Retorna un arreglo inicial de controles de modelos de dato.
   * @param {string[]} modelNames Lista de nombres de modelos.
   * @return {FormArray<FormGroup>} Arreglo de controles de modelos.
   */
  modelsFormArrayInitialization(modelNames: string[]): FormArray<FormGroup> {
    let modelsFormArray: FormArray<FormGroup> = new FormArray([
      new FormGroup({
        modelName: new FormControl<string | null>('', Validators.required),
        wanted: new FormControl<boolean>(false, Validators.required),
      }),
    ]);

    modelsFormArray.removeAt(0);
    for (let modelName of modelNames) {
      modelsFormArray.push(
        new FormGroup({
          modelName: new FormControl<string | null>(
            modelName,
            Validators.required
          ),
          wanted: new FormControl<boolean>(false, Validators.required),
        })
      );
    }

    return modelsFormArray;
  }

  /**
   * Retorna un arreglo inicial de controles de negocios.
   * @param {string[]} businessesWithAccessTo Arreglo con ID de los negocios a los qye se tiene acceso.
   * @return {FormArray<FormGroup>} Arreglo de controles de negocios.
   */
  businessesFormArrayInitialization(
    businessesWithAccessTo: string[]
  ): FormArray<FormGroup> {
    const businesses: Master[] = this.mastersService.getMaster()['BUSINESSES'];
    let businessesFormArray: FormArray<FormGroup> = new FormArray([
      new FormGroup({
        businessName: new FormControl<string | null>('', Validators.required),
        hasAccess: new FormControl<boolean>(false, Validators.required),
      }),
    ]);

    businessesFormArray.removeAt(0);
    for (let business of businesses) {
      const disabled: boolean =
        business.valueId === this.usersService.business.value.toUpperCase();
      const hasAccess: boolean =
        businessesWithAccessTo.includes(business.valueId) || disabled;
      businessesFormArray.push(
        new FormGroup({
          businessName: new FormControl<string | null>(
            business.valueId,
            Validators.required
          ),
          hasAccess: new FormControl<boolean>(
            { value: hasAccess, disabled: disabled },
            Validators.required
          ),
        })
      );
    }

    return businessesFormArray;
  }

  /**
   * Retorna un arreglo inicial de controles de centros de distribución.
   * @param {{[key: string]: string[]}} centersWithAccessTo Objeto cuyos atributos son el nombre de un negocio y su valor es una lista con los ID de los centros a los qye se tiene acceso.
   * @return {Promise<FormArray<FormGroup>>} Arreglo de controles de centros de distribución.
   */
  centersFormArrayInitialization(centersWithAccessTo: {
    [key: string]: string[];
  }): FormArray<FormGroup> {
    const businesses: Master[] = this.mastersService.getMaster()['BUSINESSES'];

    let centersFormArray: FormArray<FormGroup> = new FormArray([
      new FormGroup({
        businessName: new FormControl<string | null>('', Validators.required),
        centerName: new FormControl<string | null>('', Validators.required),
        hasAccess: new FormControl<boolean>(false, Validators.required),
      }),
    ]);
    centersFormArray.removeAt(0);

    if (
      centersWithAccessTo[this.usersService.business.value.toUpperCase()]
        ?.length > 0
    ) {
      // Si el usuario tiene al menos un centro dentro de sus permisos
      // Cargamos los centros tomando en consideración dichos permisos.

      // Por cada negocio válido del maestro, exploramos si tiene permisos.
      for (let masterBusiness of businesses) {
        if (Object.keys(centersWithAccessTo).includes(masterBusiness.valueId)) {
          this.mastersService
            .getUserCentersByBusiness(masterBusiness.valueId)
            .then((centersList: string[]) => {
              centersList.forEach((center: string) => {
                centersFormArray.push(
                  new FormGroup({
                    businessName: new FormControl<string | null>(
                      masterBusiness.valueId,
                      Validators.required
                    ),
                    centerName: new FormControl<string | null>(
                      center,
                      Validators.required
                    ),
                    hasAccess: new FormControl<boolean>(
                      centersWithAccessTo[masterBusiness.valueId].includes(
                        center
                      ),
                      Validators.required
                    ),
                  })
                );
              });
            });
        }
      }
    } else {
      // Si el usuario no tiene centros dentro de sus permisos
      // Generamos el mínimo arreglo de controles para el negocio del usuario.
      const centers: Master[] = this.mastersService.getMaster()['CENTERS'];

      for (let center of centers) {
        centersFormArray.push(
          new FormGroup({
            businessName: new FormControl<string | null>(
              this.usersService.business.value.toUpperCase(),
              Validators.required
            ),
            centerName: new FormControl<string | null>(
              center.valueId,
              Validators.required
            ),
            hasAccess: new FormControl<boolean>(false, Validators.required),
          })
        );
      }
    }

    return centersFormArray;
  }

  /**
   * Retorna un arreglo inicial de grupos de control de documentos.
   * @return {FormArray<FormGroup>} Arreglo de grupos de control de documentos.
   */
  documentsFormArrayInitialization(): FormArray<FormGroup> {
    return new FormArray([
      new FormGroup({
        documentMasterValueId: new FormControl<string | null>(
          '',
          Validators.required
        ),
        documentName: new FormControl<string | null>('', Validators.required),
        documentIssueDate: new FormControl<string | null>(null),
        documentExpirationDate: new FormControl<string | null>(
          null,
          Validators.required
        ),
        documentFile: new FormControl(null, Validators.required),
        documentSourceFile: new FormControl(null, Validators.required),
      }),
    ]);
  }

  /**
   * Retorna un grupo de control de un documento.
   * @param {Master} document Tipo de documento definido en el maestro.
   * @return {FormGroup} Controles del documento.
   */
  createDocumentControlFromMaster(document: Master): FormGroup {
    return new FormGroup({
      documentMasterValueId: new FormControl<string | null>(
        document.valueId,
        Validators.required
      ),
      documentName: new FormControl<string | null>(
        document.valueToDisplay,
        Validators.required
      ),
      documentIssueDate: new FormControl<string | null>(null, [
        Validators.required,
        this.validatorsService.documentIssueDateValidator(),
      ]),
      documentExpirationDate: new FormControl<string | null>(null, [
        Validators.required,
        this.validatorsService.documentExpirationDateValidator(),
      ]),
      documentFile: new FormControl(null, [
        Validators.required,
        this.validatorsService.documentFileTypeValidator(),
      ]),
      documentSourceFile: new FormControl(null, Validators.required),
    });
  }

  /**
   * Retorna un grupo de control de un documento.
   * @param {Document} document Documento.
   * @return {FormGroup} Controles del documento.
   */
  createDocumentControlFromDocument(document: Document): FormGroup {
    return new FormGroup({
      documentMasterValueId: new FormControl(
        document.masterValueId,
        Validators.required
      ),
      documentName: new FormControl(document.name, Validators.required),
      documentIssueDate: new FormControl(document.issueDate, [
        Validators.required,
        this.validatorsService.documentIssueDateValidator(),
      ]),
      documentExpirationDate: new FormControl(document.expirationDate, [
        Validators.required,
        this.validatorsService.documentExpirationDateValidator(),
      ]),
      documentFile: new FormControl(null, [
        this.validatorsService.documentFileTypeValidator(),
      ]),
      documentSourceFile: new FormControl(null),
    });
  }

  /**
   * Retorna un grupo de control con validadores específicos para
   * cada control. Incorpora la creación de fechas ficticias para
   * documentos que no requieren fecha.
   * @param {Master} document Tipo de documento definido en el maestro.
   * @param {FormValidators} validators Conjunto de validadores para cada control.
   */
  createDocumentControlFromMasterWithValidators(
    document: Master,
    validators: FormValidators
  ): FormGroup {
    return new FormGroup({
      documentMasterValueId: new FormControl<string | null>(
        document.valueId,
        validators.documentMasterValueId
      ),
      documentName: new FormControl<string | null>(
        document.valueToDisplay,
        validators.documentName
      ),
      // Los documentos que no requieren fecha se les asigna una ficticia por defecto.
      documentIssueDate: new FormControl<string | null>(
        this.isDatelessDocument(document.valueId)
          ? this.fictitiousIssueDate
          : null,
        validators.documentIssueDate
      ),
      // Los documentos que no requieren fecha se les asigna una ficticia por defecto.
      documentExpirationDate: new FormControl<string | null>(
        this.isDatelessDocument(document.valueId)
          ? this.fictitiousExpirationDate
          : null,
        validators.documentExpirationDate
      ),
      documentFile: new FormControl(null, validators.documentFile),
      documentSourceFile: new FormControl(null, validators.documentSourceFile),
    });
  }

  /**
   * Transforma a string un campo booleano
   * Es usado en los controles del formulario de tipo radio botón.
   * @param {boolean} editMode Indica si se está en modo edición.
   * @param {boolean} value Valor a transformar
   * @private
   */
  radioButtonsTypeParsing(
    editMode: boolean,
    value: boolean | null | undefined
  ): string | null {
    if (editMode) {
      return value ? 'true' : 'false';
    } else {
      return null;
    }
  }

  /**
   * Consulta, a través del servicio de documentos, si un tipo de documento
   * requiere fechas de emisión y vencimiento.
   * @param {string} masterValueId ID del tipo de documento en el maestro.
   * @return {boolean}
   * @private
   */
  private isDatelessDocument(masterValueId: string): boolean {
    return this.documentsService.isDatelessDocument(masterValueId);
  }

  /**
   * Genera la fecha actual con huso horario America/Santiago
   * @return {Date}
   * @private
   */
  private generateDate(): Date {
    let today: Date = new Date();
    today.setHours(0, 0, 0, 0);
    return new Date(
      today.toLocaleString('en-US', { timeZone: 'America/Santiago' })
    );
  }

  /**
   * Retorna un formulario para agregar comentarios.
   * @param {boolean} commentRequired ¿Comentario requerido?
   * @return {FormGroup}
   */
  generateCommentForm(commentRequired: boolean): FormGroup {
    let validators: ValidatorFn[] = [
      Validators.minLength(1),
      Validators.maxLength(300),
    ];
    if (commentRequired) {
      validators.push(Validators.required);
    }

    return new FormGroup({
      comment: new FormControl(null, validators),
    });
  }
}
