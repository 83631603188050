<div class="container-fluid my-2">
  <div class="row">
    <div class="col-md-6" *ngIf="!isViewer && !isApprover">
      <app-members-coupling></app-members-coupling>
    </div>
    <div [ngClass]="getClass()">
      <app-members-coupled></app-members-coupled>
    </div>
  </div>
</div>
