import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

import { DocumentsModule } from '../documents/documents.module';
import { MasterModule } from '../master/master.module';
import { SharedModule } from '../../shared/shared.module';

import { TractoDetailsComponent } from './tracto-details/tracto-details.component';
import { TractoEditComponent } from './tracto-edit/tracto-edit.component';
import { TractoNoSelectedComponent } from './tracto-no-selected/tracto-no-selected.component';
import { TractosComponent } from './tractos.component';
import { TractosListComponent } from './tractos-list/tractos-list.component';
import { TractosRoutingModule } from './tractos-routing.module';

@NgModule({
  declarations: [
    TractosComponent,
    TractosListComponent,
    TractoDetailsComponent,
    TractoNoSelectedComponent,
    TractoEditComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    DataTablesModule,
    DocumentsModule,
    MasterModule,
    NgbAccordionModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    TractosRoutingModule,
    NgOptimizedImage,
  ],
})
export class TractosModule {}
