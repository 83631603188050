import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { ValidatorsService } from '../../services/validators.service';
import { MasterService } from '../../../pages/master/master.service';
import { ExclusionMotives } from '../../interfaces/exclusion-motives';

@Component({
  selector: 'app-modal-with-select',
  templateUrl: './modal-with-select.component.html',
})
export class ModalWithSelectComponent implements OnInit {
  @Input() modalTitle: string = '';
  @Input() modalQuestion: string = '';
  @Input() modalMessage: string = '';
  @Input() showCommentForm: boolean = false;
  @Input() commentForm: FormGroup = new FormGroup({});
  @Output() modalDismissed: EventEmitter<string> = new EventEmitter<string>();
  @Output() modalClosed: EventEmitter<string> = new EventEmitter<string>();
  readonly master: any = {};
  readonly exclusionMotives: Array<ExclusionMotives> = [];
  tomorrow: string;
  get notificationControl() {
    return this.commentForm;
  }

  constructor(
    private validatorsService: ValidatorsService,
    private masterService: MasterService
  ) {
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.tomorrow = tomorrow.toISOString().split('T')[0];
    this.master = this.masterService.getMaster();
    this.exclusionMotives = this.master['DRIVERS#EXCLUSION_MOTIVES'];
  }
  ngOnInit(): void {
    this.commentForm.get('exclusionMotive')?.setValue('');
  }

  /**
   * Determina, a través del servicio de validadores,
   * si debe mostrar la ayuda de un control del formulario.
   * @param {AbstractControl} control Control del formulario.
   * @return {Boolean}
   */
  showHelper(control: AbstractControl<any, any> | null): boolean | undefined {
    return this.validatorsService.showHelper(control);
  }

  /**
   * Consulta el mensaje de ayuda para un control del formulario.
   * @param {AbstractControl} control Control del formulario.
   * @return {string} Ayuda para el usuario.
   */
  helperMessages(control: AbstractControl<any, any> | null): string {
    return this.validatorsService.helperMessages(control);
  }
  onChangePermanent() {
    if (this.commentForm.get('isPermanent')?.value) {
      this.commentForm.get('excludeDuration')?.disable();
    } else {
      this.commentForm.get('excludeDuration')?.enable();
    }
  }
  onChangeExclusionMotive() {
    if (this.commentForm.value.exclusionMotive === 'FOREIGN_1000001') {
      this.commentForm.controls['comment'].addValidators([
        Validators.required,
        Validators.minLength(1),
      ]);
    } else {
      this.commentForm.controls['comment'].removeValidators([
        Validators.required,
        Validators.minLength(1),
      ]);
    }
    this.commentForm.get('comment')?.updateValueAndValidity();
  }

  /**
   * Función de desecho del modal.
   * @param {string} value Tecla con que fue desechado.
   */
  d(value: string): void {
    this.modalDismissed.emit(value);
  }

  /**
   * Función de cierre del modal.
   * @param {string} value Tecla con que fue cerrado.
   */
  c(value: string): void {
    this.modalClosed.emit(value);
  }
}
