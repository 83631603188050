import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../../auth/auth.guard';
import { MasterResolver } from '../master/master.resolver';

import { RtcEvaluationsComponent } from './rtc-evaluations.component';
import { RtcHistorialComponent } from './rtc-historial/rtc-historial.component';
import { RtcEvaluationComponent } from './rtc-evaluation/rtc-evaluation.component';

const routes: Routes = [
  {
    path: 'rtc',
    component: RtcEvaluationsComponent,
    canActivate: [AuthGuard],
    resolve: [MasterResolver],
    children: [
      { path: 'evaluate', component: RtcEvaluationComponent },
      { path: 'historial', component: RtcHistorialComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RtcEvaluationsRoutingModule {}
