import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  APIService,
  Tanque,
  GetTanqueQuery,
  ModelTanqueFilterInput,
  ModelSubscriptionIDInput,
  ModelSubscriptionTanqueFilterInput,
  User,
  TanquesByBusinessAndTanqueIdQuery,
  TanquesByCenterAndTanqueIdQuery,
  TanquesByCompanyAndTanqueIdQuery,
  ModelTractoFilterInput,
} from '../../app-sync.service';
import { DocumentsService } from '../documents/documents.service';
import { UsersService } from '../users/users.service';
import { appConstants } from '../../shared/constants/constants';
import { filterInputs } from '../../shared/types/filter-inputs';
import { FeedbacksService } from '../../shared/feedbacks/feedbacks.service';

@Injectable({
  providedIn: 'root',
})
export class TanquesService {
  selectedTanqueChanged = new Subject<Tanque>();
  tanquesChanged = new Subject<Tanque[]>();
  decoupledTanquesChanged = new Subject<Tanque[]>();

  private readonly initialTanque: Tanque;
  private selectedTanque: Tanque;
  private tanques: Tanque[] = [];
  private decoupledTanques: Tanque[] = [];
  private tanquesFilterForSubscriptions: ModelSubscriptionTanqueFilterInput =
    {};
  private decoupledTanquesFilterForSubscriptions: ModelSubscriptionTanqueFilterInput =
    {};
  private selectedTanqueFilterForSubscriptions: ModelSubscriptionTanqueFilterInput =
    {};

  constructor(
    private api: APIService,
    private documentsService: DocumentsService,
    private usersService: UsersService,
    private feedbacksService: FeedbacksService
  ) {
    this.initialTanque = {
      ...appConstants.tanque.initialization,
      carrier: appConstants.carrier.initialization,
    };
    this.selectedTanque = this.initialTanque;
    this.setTanquesFilter();
  }

  // ---------------------------------------
  // Métodos para el tanque default
  // ---------------------------------------
  /**
   * Retorna una estructura de tanque vacía para iniciar
   * un objeto de tipo Tanque.
   * @return {Tanque}
   */
  getInitialTanque(): Tanque {
    return { ...this.initialTanque };
  }

  // --------------------------------------------
  // Métodos para el tanque seleccionado
  // --------------------------------------------
  /**
   * Configura el tanque seleccionado para referencia de
   * todos los componentes.
   * @param {Tanque} tanque Tanque seleccionado.
   */
  setSelectedTanque(tanque: Tanque): void {
    this.selectedTanque = tanque;
    this.setSelectedTanqueFilterForSubscriptions();
    this.selectedTanqueChanged.next({ ...this.selectedTanque });
    console.log('selectedTanque', this.selectedTanque);

    // Documentos
    this.documentsService.setId('tanqueId', this.selectedTanque.tanqueId);
    this.documentsService.setSelectedModel('TANQUE');
    this.documentsService.setSelectedCenter(tanque.center);
    this.documentsService.setDocumentsFilter();
    this.documentsService
      .refreshDocuments()
      .then(() => console.log('Documentos del tanque actualizados.'));
  }

  /**
   * Actualiza los datos del tanque seleccionado.
   * @return {Promise}
   */
  async refreshSelectedTanque(): Promise<void> {
    const getTanqueResult: GetTanqueQuery = await this.api.GetTanque(
      this.selectedTanque.tanqueId
    );
    this.setSelectedTanque(<Tanque>getTanqueResult);
  }

  /**
   * Retorna el tanque seleccionado.
   * @return {Tanque}
   */
  getSelectedTanque(): Tanque {
    return { ...this.selectedTanque };
  }

  // --------------------------------
  // Métodos para la lista de Tanques
  // --------------------------------
  /**
   * Configura la lista de tanques para referencia de
   * todos los componentes.
   * @param {Tanque[]} tanques Lista de tanques.
   */
  setTanques(tanques: Tanque[]): void {
    this.tanques = tanques;
    this.setTanquesFilter();
    this.tanquesChanged.next(this.tanques.slice());
  }

  /**
   * Actualiza la lista de tanques.
   * @return {Promise}
   */
  async refreshTanques(): Promise<void> {
    const activeUser: User = this.usersService.getActiveUser();
    const business: string = this.usersService.business.value.toUpperCase();
    let tempListTanques: Tanque[] = [];

    // Verificamos el grupo del usuario
    if (activeUser.authGroup.endsWith('_CARRIERS')) {
      // Los transportistas podrán acceder a vehículos donde
      // ellos figuren como Carrier
      tempListTanques = await this.listTanquesByCarrier(activeUser);
    } else if (activeUser.authGroup.endsWith('_ADMINS')) {
      // Los administradores podrán acceder a todos los vehículos/conductores
      // asociados a su negocio
      tempListTanques = await this.listTanquesByBusiness(business);
    } else if (
      activeUser.authGroup.endsWith('_VIEWERS') ||
      activeUser.authGroup.endsWith('_APPROVERS')
    ) {
      // Los visualizadores y aprobadores podrán acceder a todos los vehículos
      // asociados a los centros a los que pertenece el usuario.
      tempListTanques = await this.listTanquesByCenter(activeUser, business);
    } else {
      this.feedbacksService.showFeedback(
        `Error obteniendo tanques. Grupo ${activeUser.authGroup} no tiene regla definida.`,
        'danger'
      );
    }

    this.setTanques(tempListTanques.slice());
  }

  /**
   * Retorna la lista de Vehículos usando el GSI de Negocio.
   * @param business {string} Negocio.
   * @param filter {ModelTractoFilterInput | undefined} Filtro de búsqueda.
   * @private
   */
  private async listTanquesByBusiness(
    business: string,
    filter: ModelTractoFilterInput | undefined = undefined
  ): Promise<Tanque[]> {
    let listTanquesResult: TanquesByBusinessAndTanqueIdQuery;
    listTanquesResult = await this.api.TanquesByBusinessAndTanqueId(
      business,
      undefined,
      undefined,
      filter
    );

    let tempListTanques: Tanque[] = <Tanque[]>listTanquesResult.items;
    let nextToken: string | null | undefined = listTanquesResult.nextToken;
    // Es posible que la primera query no retorne todos los vehículos.
    while (nextToken) {
      let loopListTanquesResult: TanquesByBusinessAndTanqueIdQuery =
        await this.api.TanquesByBusinessAndTanqueId(
          business,
          undefined,
          undefined,
          filter,
          100,
          nextToken
        );
      tempListTanques.push(...(<Tanque[]>loopListTanquesResult.items));
      nextToken = loopListTanquesResult.nextToken;
    }
    return tempListTanques.slice();
  }

  /**
   * Retorna la lista de Vehículos usando el GSI de Centros.
   * @param activeUser {User} Usuario activo.
   * @param business {string} Negocio.
   * @param filter {ModelTractoFilterInput | undefined} Filtro de búsqueda.
   * @private
   */
  private async listTanquesByCenter(
    activeUser: User,
    business: string,
    filter: ModelTractoFilterInput | undefined = undefined
  ): Promise<Tanque[]> {
    let tempListTanques: Tanque[] = [];
    const centers: string[] =
      JSON.parse(activeUser.centers || `{"${business}": []}`)[business] || [];

    // Ejecutar todas las peticiones en paralelo
    const tanquesPromises: Promise<Tanque[]>[] = centers.map(
      async (center: string): Promise<Tanque[]> => {
        let listTanquesResult: TanquesByCenterAndTanqueIdQuery;

        listTanquesResult = await this.api.TanquesByCenterAndTanqueId(
          center,
          undefined,
          undefined,
          filter
        );
        let tanques: Tanque[] = <Tanque[]>listTanquesResult.items;

        let nextToken: string | null | undefined = listTanquesResult.nextToken;

        // Si hay más datos, seguir llamando hasta que no haya nextToken
        while (nextToken) {
          let loopListTanquesResult: TanquesByCenterAndTanqueIdQuery =
            await this.api.TanquesByCenterAndTanqueId(
              center,
              undefined,
              undefined,
              filter,
              100,
              nextToken
            );
          tanques.push(...(<Tanque[]>loopListTanquesResult.items));
          nextToken = loopListTanquesResult.nextToken;
        }

        return tanques;
      }
    );

    // Esperar a que todas las promesas se resuelvan
    const results: Tanque[][] = await Promise.all(tanquesPromises);

    // Combinar todos los resultados en una sola lista
    results.forEach((tanques: Tanque[]): void => {
      tempListTanques.push(...tanques);
    });

    return tempListTanques.slice();
  }

  /**
   * Retorna la lista de Vehículos usando el GSI de Transportista.
   * @param activeUser {User} Usuario activo.
   * @param filter {ModelTractoFilterInput | undefined} Filtro de búsqueda.
   * @private
   */
  private async listTanquesByCarrier(
    activeUser: User,
    filter: ModelTractoFilterInput | undefined = undefined
  ): Promise<Tanque[]> {
    let listTanquesResult: TanquesByCompanyAndTanqueIdQuery;
    listTanquesResult = await this.api.TanquesByCompanyAndTanqueId(
      activeUser.company,
      undefined,
      undefined,
      filter
    );

    let tempListTanques: Tanque[] = <Tanque[]>listTanquesResult.items;
    let nextToken: string | null | undefined = listTanquesResult.nextToken;
    // Es posible que la primera query no retorne todos los vehículos.
    while (nextToken) {
      let loopListTanquesResult: TanquesByCompanyAndTanqueIdQuery =
        await this.api.TanquesByCompanyAndTanqueId(
          activeUser.company,
          undefined,
          undefined,
          filter,
          100,
          nextToken
        );
      tempListTanques.push(...(<Tanque[]>loopListTanquesResult.items));
      nextToken = loopListTanquesResult.nextToken;
    }
    return tempListTanques.slice();
  }

  /**
   * Retorna la lista de tanques.
   * @return {Tanque[]}
   */
  getTanques(): Tanque[] {
    return this.tanques.slice();
  }

  /**
   * Configura la lista de tanques desacoplados para referencia de
   * todos los componentes.
   * @param {Tanque[]} tanques Lista de tanques acoplados.
   */
  setDecoupledTanques(tanques: Tanque[]): void {
    this.decoupledTanques = tanques;
    this.setTanquesFilter();
    this.decoupledTanquesChanged.next(this.decoupledTanques.slice());
  }

  /**
   * Actualiza la lista de tanques desacoplados.
   * @return {Promise}
   */
  async refreshDecoupledTanques(): Promise<void> {
    const activeUser: User = this.usersService.getActiveUser();
    const business: string = this.usersService.business.value.toUpperCase();
    let tempListDecoupledTanques: Tanque[] = [];
    const decoupledFixedFilters: ModelTanqueFilterInput = {
      tanqueCoupleCoupleId: { notContains: '#' },
      status: { eq: `${business}_${appConstants.entity.codes.blocked}` },
      ...(business !== 'FOREIGN' && { sapId: { ne: 'Sin Asignar' } }),
    };

    // Verificamos el grupo del usuario
    if (activeUser.authGroup.endsWith('_CARRIERS')) {
      // Los transportistas podrán acceder a vehículos donde
      // ellos figuren como Carrier
      tempListDecoupledTanques = await this.listTanquesByCarrier(
        activeUser,
        decoupledFixedFilters
      );
    } else if (activeUser.authGroup.endsWith('_ADMINS')) {
      // Los administradores podrán acceder a todos los vehículos/conductores
      // asociados a su negocio
      tempListDecoupledTanques = await this.listTanquesByBusiness(
        business,
        decoupledFixedFilters
      );
    } else if (
      activeUser.authGroup.endsWith('_VIEWERS') ||
      activeUser.authGroup.endsWith('_APPROVERS')
    ) {
      // Los visualizadores y aprobadores podrán acceder a todos los vehículos
      // asociados a los centros a los que pertenece el usuario.
      tempListDecoupledTanques = await this.listTanquesByCenter(
        activeUser,
        business,
        decoupledFixedFilters
      );
    } else {
      this.feedbacksService.showFeedback(
        `Error obteniendo tractos. Grupo ${activeUser.authGroup} no tiene regla definida.`,
        'danger'
      );
    }

    this.setDecoupledTanques(tempListDecoupledTanques.slice());
  }

  /**
   * Retorna la lista de tanques desacoplados.
   * @return {Tanque[]}
   */
  getDecoupledTanques(): Tanque[] {
    return this.decoupledTanques.slice();
  }

  // ------------------------------------------
  // Métodos para filtro de consultas a AppSync
  // ------------------------------------------
  /**
   * Configura el filtro para las consultas
   * de listas de tanques y subscripciones.
   * @private
   */
  private setTanquesFilter(): void {
    const business: string = this.usersService.business.value.toUpperCase();
    let modelFilterInput: filterInputs = this.usersService.getEntitiesFilter();
    const decoupledFixedFilters: ModelSubscriptionTanqueFilterInput = {
      tanqueCoupleCoupleId: { notContains: '#' },
      status: { eq: `${business}_${appConstants.entity.codes.blocked}` },
      ...(business !== 'FOREIGN' && { sapId: { ne: 'Sin Asignar' } }),
    };

    this.tanquesFilterForSubscriptions = <ModelSubscriptionTanqueFilterInput>(
      modelFilterInput
    );
    this.decoupledTanquesFilterForSubscriptions = {
      ...modelFilterInput,
      ...decoupledFixedFilters,
    };
  }

  /**
   * Retorna el filtro para las subscripciones
   * de listas de tanques.
   * @return {ModelSubscriptionTanqueFilterInput}
   */
  getTanquesFilterForSubscriptions(): ModelSubscriptionTanqueFilterInput {
    return { ...this.tanquesFilterForSubscriptions };
  }

  /**
   * Configura el filtro para las subscripciones
   * del tanque seleccionado.
   */
  private setSelectedTanqueFilterForSubscriptions(): void {
    const modelSubscriptionIDInput: ModelSubscriptionIDInput = {
      eq: this.selectedTanque.tanqueId,
    };
    this.selectedTanqueFilterForSubscriptions = {
      tanqueId: modelSubscriptionIDInput,
    };
  }

  /**
   * Retorna el filtro para las subscripciones
   * del tanque seleccionado.
   * @return {ModelSubscriptionTanqueFilterInput}
   */
  getSelectedTanqueFilterForSubscriptions(): ModelSubscriptionTanqueFilterInput {
    return { ...this.selectedTanqueFilterForSubscriptions };
  }

  /**
   * Retorna el filtro para las consultas
   * de listas de tanques desacoplados.
   * @return {ModelSubscriptionTanqueFilterInput}
   */
  getDecoupledTanquesFilterForSubscriptions(): ModelSubscriptionTanqueFilterInput {
    return { ...this.decoupledTanquesFilterForSubscriptions };
  }
}
