import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ValidatorsService } from '../../services/validators.service';

@Component({
  selector: 'app-modal-with-comment',
  templateUrl: './modal-with-comment.component.html',
  styleUrls: ['./modal-with-comment.component.css'],
})
export class ModalWithCommentComponent {
  @Input() modalTitle: string = '';
  @Input() modalQuestion: string = '';
  @Input() modalMessage: string = '';
  @Input() showCommentForm: boolean = false;
  @Input() mandatoryComment: boolean = false;
  @Input() commentForm: FormGroup = new FormGroup({});
  @Output() modalDismissed: EventEmitter<string> = new EventEmitter<string>();
  @Output() modalClosed: EventEmitter<string> = new EventEmitter<string>();

  get notificationControl() {
    return this.commentForm;
  }

  constructor(private validatorsService: ValidatorsService) {}

  /**
   * Determina, a través del servicio de validadores,
   * si debe mostrar la ayuda de un control del formulario.
   * @param {AbstractControl} control Control del formulario.
   * @return {Boolean}
   */
  showHelper(control: AbstractControl<any, any> | null): boolean | undefined {
    return this.validatorsService.showHelper(control);
  }

  /**
   * Consulta el mensaje de ayuda para un control del formulario.
   * @param {AbstractControl} control Control del formulario.
   * @return {string} Ayuda para el usuario.
   */
  helperMessages(control: AbstractControl<any, any> | null): string {
    return this.validatorsService.helperMessages(control);
  }

  /**
   * Función de desecho del modal.
   * @param {string} value Tecla con que fue desechado.
   */
  d(value: string): void {
    this.modalDismissed.emit(value);
  }

  /**
   * Función de cierre del modal.
   * @param {string} value Tecla con que fue cerrado.
   */
  c(value: string): void {
    this.modalClosed.emit(value);
  }
}
