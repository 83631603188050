import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { DocumentsService } from './pages/documents/documents.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  title: string = 'Roz';
  isUploadingDocuments: boolean = false;

  private isUploadingDocumentsSubscription: Subscription = new Subscription();

  constructor(private documentsService: DocumentsService) {
    this.isUploadingDocuments =
      this.documentsService.isUploadingDocuments.value;
  }

  ngOnInit(): void {
    // Suscripción a la variable que indica si se están cargando documentos.
    this.isUploadingDocumentsSubscription =
      this.documentsService.isUploadingDocuments.subscribe(
        (value: boolean): void => {
          this.isUploadingDocuments = value;
        }
      );
  }

  ngOnDestroy(): void {
    this.isUploadingDocumentsSubscription.unsubscribe();
    console.log('app.component subscriptions removed.');
  }
}
