import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { appConstants } from '../shared/constants/constants';

import { UsersService } from '../pages/users/users.service';
import { FeedbacksService } from '../shared/feedbacks/feedbacks.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authenticatorService: AuthenticatorService,
    private usersService: UsersService,
    private router: Router,
    private feedbacksService: FeedbacksService
  ) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const userIsBlocked: boolean = this.usersService
      .getActiveUserStatus()
      .endsWith(appConstants.user.codes.blocked);

    if (this.authenticatorService.authStatus === 'authenticated') {
      if (!userIsBlocked) {
        return true;
      } else {
        this.feedbacksService.showFeedback(
          `Usuario bloqueado en Roz.`,
          'danger'
        );
        return this.router.navigate(['/blocked']);
      }
    }
    return this.router.navigate(['/login']);
  }
}
