import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  /**
   * Transforma el formato de una fecha desde MM/DD/YYYY HH:MM:SS a DD-MM-YYYY HH:MM:SS.
   * @param {string} value Fecha en formato MM/DD/YYYY HH:MM:SS.
   * @return {string} Fecha en formato DD-MM-YYYY HH:MM:SS.
   */
  transform(value: string): string {
    const valueDate: Date = new Date(value);
    const dateArray: string[] = valueDate.toLocaleDateString().split('/');
    let formattedDate: string = dateArray[1].padStart(2, '0');
    formattedDate += '-' + dateArray[0].padStart(2, '0');
    formattedDate += '-' + dateArray[2];
    formattedDate += ' ' + valueDate.toTimeString().split(' ')[0];
    return formattedDate;
  }
}
