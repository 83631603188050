<div class="container-fluid py-5">
  <h1 class="display-5 fw-normal">
    <strong>Bienvenido</strong> {{ activeCognitoUser?.name }}
  </h1>
  <p class="col-md-8 fs-4">Por favor, elige un negocio.</p>
  <div class="row">
    <div class="row justify-content-center">
      <div
        class="card text-center border-primary m-1"
        *ngIf="showButton('nocore')"
      >
        <img
          class="card-img-top my-4 p-2"
          ngSrc="../../../assets/images/business/nocore.png"
          alt="No Core"
          height="200"
          width="200"
        />
        <div class="card-body">
          <button
            id="business-selector-nocore"
            class="btn text-primary stretched-link"
            type="button"
            (click)="onNoCoreSelected()"
          >
            No Core
          </button>
        </div>
      </div>
      <div
        class="card text-center border-primary m-1"
        *ngIf="showButton('lubricants')"
      >
        <img
          class="card-img-top my-4 p-2"
          ngSrc="../../../assets/images/business/lubricants.png"
          alt="Lubricantes"
          height="200"
          width="200"
        />
        <div class="card-body">
          <button
            id="business-selector-lubricants"
            class="btn text-primary stretched-link"
            type="button"
            (click)="onLubricantsSelected()"
          >
            Lubricantes
          </button>
        </div>
      </div>
      <div
        class="card text-center border-primary m-1"
        *ngIf="showButton('fuels')"
      >
        <img
          class="card-img-top my-4 p-2"
          ngSrc="../../../assets/images/business/fuels.png"
          alt="Combustibles"
          height="200"
          width="200"
        />
        <div class="card-body">
          <button
            id="business-selector-fuels"
            class="btn text-primary stretched-link"
            type="button"
            (click)="onFuelsSelected()"
          >
            Combustibles
          </button>
        </div>
      </div>
      <div
        class="card text-center border-primary m-1"
        *ngIf="showButton('FOREIGN')"
      >
        <img
          class="card-img-top my-4 p-2"
          ngSrc="../../../assets/images/business/fuels.png"
          alt="externos"
          height="200"
          width="200"
        />
        <div class="card-body">
          <button
            id="business-selector-foreign"
            class="btn text-primary stretched-link"
            type="button"
            (click)="onForeignSelected()"
          >
            Externos
          </button>
        </div>
      </div>
      <div
        class="card text-center border-primary m-1"
        *ngIf="showButton('industrial')"
      >
        <img
          class="card-img-top my-4 p-2"
          ngSrc="../../../assets/images/business/industrial.png"
          alt="Industrial"
          height="200"
          width="200"
        />
        <div class="card-body">
          <button
            id="business-selector-industrial"
            class="btn text-primary stretched-link"
            type="button"
            (click)="onIndustrialSelected()"
          >
            Industrial
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
