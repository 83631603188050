import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { environment } from '../../../../environments/environment';
import { appConstants } from '../../../shared/constants/constants';
import { FormValidators } from '../../../shared/interfaces/form-validators';
import { DocumentSpecsObject } from '../../../shared/interfaces/document-specs-object';

import {
  APIService,
  CreateTractoInput,
  CreateTractoMutation,
  CreateNotificationInput,
  Document,
  Tracto,
  UpdateTractoInput,
} from '../../../app-sync.service';
import { DocumentsService } from '../../documents/documents.service';
import { FeedbacksService } from '../../../shared/feedbacks/feedbacks.service';
import { MasterService } from '../../master/master.service';
import { SettingsService } from '../../settings/settings.service';
import { UsersService } from '../../users/users.service';
import { ValidatorsService } from '../../../shared/services/validators.service';
import { TractosService } from '../tractos.service';
import { FormsService } from '../../../shared/services/forms.service';
import { DocumentFromForms } from '../../../shared/interfaces/document-from-forms';
import { EditionsService } from '../../../shared/services/editions.service';

@Component({
  selector: 'app-tracto-edit',
  templateUrl: './tracto-edit.component.html',
  styleUrls: ['./tracto-edit.component.css'],
})
export class TractoEditComponent implements OnInit, OnDestroy {
  equipName: string = 'Tracto';
  equipType: string = '';
  equipTypeToDisplay: string = '';
  equipCapacity: string = '';
  equipCapacityToDisplay: string = '';
  currentYear: number = new Date().getFullYear();
  editMode: boolean = true;
  isAdmin: boolean = false;
  isCarrier: boolean = false;
  isGettingTracto: boolean = false;
  master: any = {};
  masterDisplayMap: any = {};
  documentsSpecs: DocumentSpecsObject = {};
  selectedCenter: string = '';
  tractoForm: FormGroup;
  tractoId: string = '';
  tractoIsOld: boolean = false;
  isDev: boolean = environment.env === 'dev' || environment.env === 'sandbox';
  business: string;
  editionId: string = '';

  get documentsControl() {
    return (<FormArray>this.tractoForm.get('documents')).controls;
  }

  constructor(
    private api: APIService,
    private documentsService: DocumentsService,
    private feedbacksService: FeedbacksService,
    private masterService: MasterService,
    private route: ActivatedRoute,
    private router: Router,
    private settingsService: SettingsService,
    private usersService: UsersService,
    private validatorsService: ValidatorsService,
    private tractosService: TractosService,
    private formsService: FormsService,
    private editionsService: EditionsService
  ) {
    this.tractoForm = new FormGroup({
      carrier: new FormControl(null),
      licensePlate: new FormControl(null),
      brand: new FormControl(null),
      model: new FormControl(null),
      year: new FormControl(null),
      color: new FormControl(null),
      type: new FormControl(null),
      capacity: new FormControl(null),
      center: new FormControl(null),
      gpsImei: new FormControl(null),
      forMining: new FormControl(null),
      hasRanchAccreditation: new FormControl(null),
      isSpot: new FormControl(null),
      engineType: new FormControl(null),
      cameraImei: new FormControl(null),
      documents: this.formsService.documentsFormArrayInitialization(),
    });
    this.business = this.usersService.business.value.toUpperCase();
  }

  ngOnInit() {
    this.isGettingTracto = true;
    this.isAdmin = this.usersService.isAdmin;
    this.isCarrier = this.usersService.isCarrier;
    this.master = this.masterService.getMaster();
    this.masterDisplayMap = this.masterService.getMasterDisplayMap();

    this.equipType = this.master['TRACTOS#TYPES'][0].valueId;
    this.equipTypeToDisplay =
      this.masterDisplayMap['TRACTOS#TYPES'][this.equipType];
    this.equipCapacity = this.master['TRACTOS#CAPACITIES'][0].valueId;
    this.equipCapacityToDisplay =
      this.masterDisplayMap['TRACTOS#CAPACITIES'][this.equipCapacity];

    this.route.params.subscribe(async (params: Params): Promise<void> => {
      // Si la ruta contiene un ID de tracto
      // estamos en modo edición
      this.editMode = params['id'] != null;
      this.tractoId = params['id'];

      this.initTractoForm();
      if (!this.editMode) {
        if (this.settingsService.getSelectedModel() !== 'TRACTO') {
          console.log('Loading document specifications');
          this.settingsService.setSelectedModel('TRACTO');
          await this.settingsService.setDocumentsSpecs();
        } else {
          console.log('Reusing document specifications');
        }
        this.documentsSpecs = this.settingsService.getDocumentsSpecs();
      } else {
        this.editionId = `${this.business}#TRACTO#${this.tractoId}`;
        await this.editionsService.createOrUpdateEdition(this.editionId);
      }

      // Ordenamos alfabéticamente el formulario de editar las propiedades
      this.documentsControl.sort((a, b) => {
        const documentNameA = a.value.documentName.toLowerCase();
        const documentNameB = b.value.documentName.toLowerCase();
        return documentNameA.localeCompare(documentNameB);
      });

      this.isGettingTracto = false;
    });
  }

  /* ------------------------------------------- */
  /* - Métodos para la creación del Formulario - */
  /* ------------------------------------------- */
  /**
   * Inicializa el formulario para crear o
   * editar un tracto.
   * @private
   */
  private initTractoForm(): void {
    // Inicialización del Formulario
    // Por default, los campos estarán vacíos.
    // Si el usuario es Transportista el campo carrier campo estará seleccionado.
    let initialTracto: Tracto = {
      ...appConstants.tracto.initialization,
      carrier: appConstants.carrier.initialization,
    };
    let tractoDocuments: FormArray =
      this.formsService.documentsFormArrayInitialization();

    // Si es transportista la única opción de carrier es él mismo.
    initialTracto.carrier.carrierId = this.isCarrier
      ? this.usersService.getActiveUser().company
      : '';
    // El tipo de equipo es un valor fijo.
    initialTracto.type = this.equipType;
    initialTracto.capacity = this.equipCapacity;

    if (this.editMode) {
      // En modo de Edición: cargamos los valores de los campos
      // según el tracto escogido.
      initialTracto = this.tractosService.getSelectedTracto();
      const selectedTractoDocuments: Document[] =
        this.documentsService.getDocuments();

      const tractoAge: number = this.currentYear - initialTracto.year;
      let tractoHasReCertificadoDeFabricacion: boolean = false;

      let documentMasterValueIdsList: string[] = [];
      tractoDocuments.removeAt(0);
      for (let document of selectedTractoDocuments) {
        tractoDocuments.push(
          this.formsService.createDocumentControlFromDocument(document)
        );
        documentMasterValueIdsList.push(document.masterValueId);
        // Verificamos si el Tracto tiene el Re-certificado de Fabricación
        if (document.masterValueId === `${this.business}_0000004`) {
          tractoHasReCertificadoDeFabricacion = true;
        }
      }

      // Si la edad del tracto es >= appConstants.tracto.ageToBeConsideredOld
      // y el tracto NO tiene asignado un documento Re-certificado de Fabricación
      // se agrega al formulario.
      if (
        tractoAge >= appConstants.tracto.ageToBeConsideredOld &&
        !tractoHasReCertificadoDeFabricacion
      ) {
        // Agregamos el Re-certificado de Fabricación
        const index = this.master['TRACTOS#DOCUMENT_TYPES'].findIndex(
          (document: any) => {
            return document.valueId === `${this.business}_0000004`;
          }
        );
        const document = this.master['TRACTOS#DOCUMENT_TYPES'][index];

        tractoDocuments.push(
          this.formsService.createDocumentControlFromMaster(document)
        );
        this.tractoIsOld = true;
      }

      // Agregamos al formulario los documentos que existen en el maestro y que no
      // han sido cargados hasta el momento.
      tractoDocuments = this.setAdditionalDocumentsFormGroup(
        initialTracto.center,
        tractoDocuments,
        documentMasterValueIdsList
      );
    } else {
      // En modo Creación: Creamos un FormControl por cada documento
      // requerido según el Maestro
      tractoDocuments = this.setInitialDocumentsFormGroup('');
    }

    // Inicialización del formulario
    this.tractoForm = new FormGroup({
      carrier: new FormControl(
        { value: initialTracto.carrier.carrierId, disabled: this.editMode },
        Validators.required
      ),
      licensePlate: new FormControl(
        { value: initialTracto.licensePlate, disabled: this.editMode },
        [
          Validators.required,
          Validators.pattern(appConstants.regex.licensePlate),
        ]
      ),
      brand: new FormControl(
        { value: initialTracto.brand, disabled: this.editMode },
        Validators.required
      ),
      model: new FormControl(
        { value: initialTracto.model, disabled: this.editMode },
        Validators.required
      ),
      year: new FormControl(
        {
          value: initialTracto.year === 0 ? null : initialTracto.year,
          disabled: this.editMode,
        },
        [Validators.required, Validators.pattern(appConstants.regex.year)]
      ),
      color: new FormControl(
        { value: initialTracto.color, disabled: this.editMode },
        Validators.required
      ),
      type: new FormControl(
        { value: initialTracto.type, disabled: this.editMode },
        Validators.required
      ),
      capacity: new FormControl(
        { value: initialTracto.capacity, disabled: this.editMode },
        Validators.required
      ),
      center: new FormControl(
        { value: initialTracto.center, disabled: this.editMode },
        Validators.required
      ),
      gpsImei: new FormControl({
        value: initialTracto.gpsImei,
        disabled: this.editMode,
      }),
      cameraImei: new FormControl({
        value: initialTracto.cameraImei,
        disabled: this.editMode,
      }),
      forMining: new FormControl(
        {
          value: this.formsService.radioButtonsTypeParsing(
            this.editMode,
            initialTracto.forMining
          ),
          disabled: this.editMode,
        },
        Validators.required
      ),
      hasRanchAccreditation: new FormControl(
        {
          value: this.formsService.radioButtonsTypeParsing(
            this.editMode,
            initialTracto.hasRanchAccreditation
          ),
          disabled: this.editMode,
        },
        Validators.required
      ),
      isSpot: new FormControl(
        {
          value: this.formsService.radioButtonsTypeParsing(
            this.editMode,
            initialTracto.isSpot
          ),
          disabled: this.editMode,
        },
        Validators.required
      ),
      engineType: new FormControl(
        { value: initialTracto.engineType, disabled: this.editMode },
        Validators.required
      ),
      documents: tractoDocuments,
    });
  }

  /**
   * Define el arreglo de controles de documentos para el formulario.
   * @param {string} center Centro al que pertenece el tracto.
   * @return {FormArray}
   * @private
   */
  private setInitialDocumentsFormGroup(center: string): FormArray {
    let tractoDocuments = this.formsService.documentsFormArrayInitialization();

    let documentTypes = this.master['TRACTOS#DOCUMENT_TYPES'];
    documentTypes.sort(function (a: any, b: any) {
      let x = a.valueToDisplay.toLowerCase();
      let y = b.valueToDisplay.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    tractoDocuments.removeAt(0);
    for (let document of documentTypes) {
      // Se excluye el Re-certificado de Fabricación ya se agrega según el año de fabricación
      if (document.valueId !== `${this.business}_0000004`) {
        const validators: FormValidators = this.getValidators(
          document.valueId,
          center
        );
        tractoDocuments.push(
          this.formsService.createDocumentControlFromMasterWithValidators(
            document,
            validators
          )
        );
      }
    }

    return tractoDocuments;
  }

  /**
   * Dado un conjunto de documentos, agrega los controles de documentos
   * faltantes según el maestro, para el formulario.
   * @param {string} center Centro al que pertenece el tracto.
   * @param {FormArray} tractoDocuments Arreglo de controles de documentos.
   * @param {string[]} documentMasterValueIdsList Lista de Ids de documentos incluídos en el tractoDocuments.
   * @return {FormArray}
   * @private
   */
  private setAdditionalDocumentsFormGroup(
    center: string,
    tractoDocuments: FormArray,
    documentMasterValueIdsList: string[]
  ): FormArray {
    let documentTypes = this.master['TRACTOS#DOCUMENT_TYPES'];
    documentTypes.sort(function (a: any, b: any) {
      let x = a.valueToDisplay.toLowerCase();
      let y = b.valueToDisplay.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    const business: string = this.usersService.business.value.toUpperCase();
    for (let document of documentTypes) {
      // Se excluye el Re-certificado de Fabricación ya se agrega según el año de fabricación
      // Se agregan solo los documentos que no se encuentren ya en el arreglo de controles.
      if (
        document.valueId !== `${business}_0000004` &&
        documentMasterValueIdsList.indexOf(document.valueId) === -1
      ) {
        const validators: FormValidators = this.getValidators(
          document.valueId,
          center
        );
        tractoDocuments.push(
          this.formsService.createDocumentControlFromMasterWithValidators(
            document,
            validators
          )
        );
      }
    }

    return tractoDocuments;
  }

  /**
   * Crea un objeto con las funciones de validación para cada uno
   * de los controles del formulario.
   * @param {string }center Centro al que pertenece el tracto.
   * @param {string} masterValueId ID del tipo de documento en el maestro.
   * @return {FormValidators}
   * @private
   */
  private getValidators(
    masterValueId: string,
    center: string = ''
  ): FormValidators {
    const isMandatory: boolean =
      this.documentsSpecs[center + '#' + masterValueId];
    return this.validatorsService.getDocumentValidatorForm(center, isMandatory);
  }

  /* ---------------------------------------- */
  /* - Métodos de validación del Formulario - */
  /* ---------------------------------------- */
  /**
   * Determina, a través del servicio de validadores,
   * si debe mostrar la ayuda de un control del formulario.
   * @param {AbstractControl} control Control del formulario.
   * @return {Boolean}
   */
  showHelper(control: AbstractControl<any, any> | null): boolean | undefined {
    return this.validatorsService.showHelper(control);
  }

  /**
   * Determina si un control del formulario es válido.
   * @param {AbstractControl} control Control del formulario.
   * @return {Boolean}
   */
  invalidControl(control: AbstractControl<any, any> | null): boolean {
    return !control?.valid;
  }

  /**
   * Consulta el mensaje de ayuda para un control del formulario.
   * @param {AbstractControl} control Control del formulario.
   * @return {string} Ayuda para el usuario.
   */
  helperMessages(control: AbstractControl<any, any> | null): string {
    return this.validatorsService.helperMessages(control);
  }

  /* --------------------------------------- */
  /* - Métodos que modifican el Formulario - */
  /* --------------------------------------- */
  /**
   * Actualiza la obligatoriedad de los documentos
   * según el centro seleccionado.
   */
  onTractoCenterChange(): void {
    if (this.selectedCenter === this.tractoForm.get('center')?.value) {
      // No cambia el formulario
      console.log('Cambio de centro no altera el formulario');
    } else {
      // Actualizamos los documentos requeridos según el centro.
      this.selectedCenter = this.tractoForm.get('center')?.value;
      console.log('Actualizamos los documentos requeridos según el centro');
      const tractoDocuments = this.setInitialDocumentsFormGroup(
        this.selectedCenter
      );
      this.tractoForm.removeControl('documents');
      this.tractoForm.setControl('documents', tractoDocuments);
    }
  }

  /**
   * Ejecuta un flujo para agregar o remover el Re-certificado de fabricación
   * a la lista de documentos dependiendo de la edad del tracto.
   */
  onTractoYearChange(): void {
    // No aplica para externos.
    if (this.business === 'FOREIGN') {
      return;
    }
    // En caso de que el tracto haya sido
    // fabricado hace 7 años o más, el
    // Re-certificado de Fabricación será solicitado.
    if (
      this.currentYear - this.tractoForm.get('year')?.value >=
      appConstants.tracto.ageToBeConsideredOld
    ) {
      this.onTractoIsOld();
    } else {
      if (this.tractoIsOld) {
        // Si antes se había seleccionado un año que hacía
        // que la edad del tracto fuese >= appConstants.tracto.ageToBeConsideredOld
        // la variable tractoIsOld será verdadera
        // Removemos el control de formulario
        // asociado al Re-certificado de Fabricación
        this.onTractoIsYoung();
      }
    }
  }

  /**
   * Agregar el Re-certificado de fabricación a la lista de documentos
   * y define la variable tractoIsOld = True.
   * @private
   */
  private onTractoIsOld(): void {
    if (!this.tractoIsOld) {
      // Agregamos el Re-certificado de Fabricación
      const index = this.master['TRACTOS#DOCUMENT_TYPES'].findIndex(
        (document: any): boolean => {
          return document.valueId === `${this.business}_0000004`;
        }
      );
      const document = this.master['TRACTOS#DOCUMENT_TYPES'][index];

      (<FormArray>this.tractoForm.get('documents')).push(
        this.formsService.createDocumentControlFromMaster(document)
      );
      this.tractoIsOld = true;
    }
  }

  /**
   * Remueve el Re-certificado de fabricación a la lista de documentos
   * y define la variable tractoIsOld = False.
   * @private
   */
  private onTractoIsYoung(): void {
    let documentFormArray = <FormArray>this.tractoForm.get('documents');
    const index: number = (<Array<any>>documentFormArray.value).findIndex(
      (document: any): boolean => {
        return document.documentMasterValueId === `${this.business}_0000004`;
      }
    );
    documentFormArray.removeAt(index);
    this.tractoForm.setControl('documents', documentFormArray);
    this.tractoIsOld = false;
  }

  /**
   * Asigna el archivo al control documentSourceFile
   * del documento.
   * @param {Event} event Evento input.
   * @param {number} index Índice del documento en el arreglo documents.
   */
  onChangeDocumentFile(event: Event, index: number): void {
    // Asignamos el archivo al FormControl 'documentSourceFile'
    const target = event.target as HTMLInputElement;
    console.log(' --- onChangeDocumentFile ---');
    console.log(target);
    console.log(target.files![0]);
    if (target.files!.length > 0) {
      const file: File = target.files![0];
      (<FormArray>this.tractoForm.get('documents'))
        .at(index)
        .patchValue({ documentSourceFile: file });
    }
  }

  /**
   * Retorna el ID del Transportista al que pertenece un usuario
   * conductor o transportista.
   * @return {string} ID del transportista.
   */
  carrierValueId(): string {
    return this.usersService.getActiveUser().company;
  }

  /**
   * Retorna el nombre del Transportista al que pertenece un usuario
   * conductor o transportista.
   * @return {string} Nombre del transportista.
   */
  carrierValueToDisplay(): string {
    const company: string = this.usersService.getActiveUser().company;
    return this.masterDisplayMap['CARRIERS'][company];
  }

  /**
   * Consulta, a través del servicio de documentos, si un tipo de documento
   * requiere fechas de emisión y vencimiento.
   * @param {string} masterValueId ID del tipo de documento en el maestro.
   * @return {boolean}
   */
  isDatelessDocument(masterValueId: string): boolean {
    return this.documentsService.isDatelessDocument(masterValueId);
  }

  /**
   * Consulta, a través del servicio de documentos, si un tipo de documento
   * es obligatorio para un centro.
   * @param {string} masterValueId ID del tipo de documento en el maestro.
   * @return {boolean}
   */
  isMandatoryDocument(masterValueId: string): boolean {
    const center = this.tractoForm.get('center')?.value;
    if (this.documentsSpecs[center + '#' + masterValueId]) {
      return this.documentsSpecs[center + '#' + masterValueId];
    } else {
      return false;
    }
  }

  /* ------------------------------- */
  /* - Métodos asociados a botones - */
  /* ------------------------------- */
  /**
   * Crea o edita un tracto.
   */
  async onSubmit(): Promise<void> {
    console.log(' onSubmit @ tracto-edit.component ');
    console.log(this.tractoForm.value);
    const newTracto = this.tractoForm.value;
    const documentList: DocumentFromForms[] =
      this.tractoForm.value.documents.slice();

    console.log('newTracto', newTracto);
    console.log('documentList', documentList);

    const uploadDateYYYYMMDD = new Date()
      .toISOString()
      .split('T')[0]
      .replaceAll('-', '');
    const uploadDateTimestamp = Math.floor(Date.now() / 1000).toString();

    if (this.editMode) {
      // Chequeamos si al menos un documento ha sido editado.
      // De lo contrario no se actualiza el tracto.
      let tractoEdited: boolean = false;
      documentList.forEach((document: any) => {
        tractoEdited = tractoEdited || Boolean(document.documentSourceFile);
        console.log(tractoEdited);
      });
      if (!tractoEdited) {
        this.feedbacksService.showFeedback(
          'Debe adjuntar al menos un documento para editar el tracto',
          'danger'
        );
        return;
      }

      // Nota: En el modo edición los ID fueron fijados al momento de iniciar el formulario.
      this.feedbacksService.showFeedback(
        'Actualizando tracto: ' + this.tractoId,
        'info'
      );

      await this.notifyEdition(uploadDateTimestamp);

      // En modo edición, solo pueden editarse los documentos
      // Sin embargo, el tracto vuelve a quedar en estado de "Por Aprobar"
      // 1. Se cambia en estatus del tracto
      const updateTractoInput: UpdateTractoInput = {
        tractoId: this.tractoId,
        status: `${this.business}_${appConstants.entity.codes.toBeApproved}`,
      };

      await this.api
        .UpdateTracto(updateTractoInput)
        .then(() => {
          // 2. Se cargan los documentos del tracto, si aplica.
          this.uploadDocuments(
            documentList,
            uploadDateYYYYMMDD,
            uploadDateTimestamp
          );

          // 3. Se actualiza el estado del acoplamiento, si aplica.
          const selectedTracto: Tracto =
            this.tractosService.getSelectedTracto();
          if (selectedTracto.tractoCoupleCoupleId) {
            this.api.UpdateCouple({
              coupleId: selectedTracto.tractoCoupleCoupleId,
              status: `${this.business}_${appConstants.entity.codes.toBeApproved}`,
            });
          }
        })
        .catch((response: any): void => {
          this.feedbacksService.showErrorFeedbacks(
            response,
            `Error al actualizar tracto ${this.tractoId}`
          );
        });
    } else {
      // En el modo crear los ID se toman desde el formulario.
      this.tractoId = newTracto.licensePlate.toUpperCase();

      this.feedbacksService.showFeedback(
        `Creando tracto: ${this.tractoId}`,
        'info'
      );

      // En modo creación:
      // 1. Se crea un nuevo tracto.
      const createTractoInput: CreateTractoInput = {
        tractoId: this.tractoId,
        brand: newTracto.brand,
        licensePlate: this.tractoId, // La licencia corresponde con el ID
        color: newTracto.color,
        model: newTracto.model.toUpperCase(),
        year: newTracto.year,
        type: newTracto.type,
        capacity: newTracto.capacity,
        business: this.business,
        center: newTracto.center,
        originalCenter: newTracto.center,
        blockingMotives: [],
        gpsImei: newTracto.gpsImei,
        cameraImei: newTracto.cameraImei,
        forMining: newTracto.forMining === 'true',
        hasRanchAccreditation: newTracto.hasRanchAccreditation === 'true',
        isSpot: newTracto.isSpot === 'true',
        engineType: newTracto.engineType,
        status: `${this.business}_${appConstants.entity.codes.toBeApproved}`, // Un nuevo tracto debe ser aprobado
        company: newTracto.carrier,
        carrierTractosCarrierId: newTracto.carrier,
      };
      console.log('createTractoInput', createTractoInput);

      this.api
        .CreateTracto(createTractoInput)
        .then((tracto: CreateTractoMutation) => {
          this.feedbacksService.showFeedback(
            `Tracto ${tracto.tractoId} creado correctamente`,
            'success'
          );

          // 2. Se cargan los documentos del tracto.
          this.uploadDocuments(
            documentList,
            uploadDateYYYYMMDD,
            uploadDateTimestamp
          );
        })
        .catch((response: any): void => {
          this.feedbacksService.showErrorFeedbacks(
            response,
            `Error al crear tracto ${this.tractoId}`
          );
        });
    }

    this.router
      .navigate(['../'], { relativeTo: this.route })
      .then(() => console.log('navigate back'));
  }

  /**
   * Notifica la edición de una cisterna, si aplica.
   * @param {string} uploadDateTimestamp Timestamp de edición.
   */
  private async notifyEdition(uploadDateTimestamp: string): Promise<void> {
    // Si el tracto editado viene de un estado "Disponible", "Rechazado" o "Bloqueado"
    // se notifica que fue editado
    const selectedTracto: Tracto = this.tractosService.getSelectedTracto();
    if (
      selectedTracto.status.endsWith(appConstants.entity.codes.rejected) ||
      selectedTracto.status.endsWith(appConstants.entity.codes.blocked) ||
      selectedTracto.status.endsWith(appConstants.entity.codes.available)
    ) {
      let initialStatus: string;
      let messageInitialStatus: string;
      if (selectedTracto.status.endsWith(appConstants.entity.codes.rejected)) {
        initialStatus = 'FROMREJECTED';
        messageInitialStatus = 'rechazado';
      } else if (
        selectedTracto.status.endsWith(appConstants.entity.codes.available)
      ) {
        initialStatus = 'FROMAVAILABLE';
        messageInitialStatus = 'disponible';
      } else {
        initialStatus = 'FROMBLOCKED';
        messageInitialStatus = 'bloqueado';
      }
      const creatNotificationInput: CreateNotificationInput = {
        businessId: selectedTracto.business,
        notificationId:
          'TRACTO#' + selectedTracto.tractoId + '#' + uploadDateTimestamp,
        status: 'TO_BE_SENT',
        model: 'TRACTO',
        motive: `UPDATE#${initialStatus}#TOTOBEAPPROVED`,
        tractoId: selectedTracto.tractoId,
      };

      await this.api
        .CreateNotification(creatNotificationInput)
        .then(async () => {
          const tractoIdentification =
            (selectedTracto.sapId! === 'Sin Asignar'
              ? selectedTracto.sapId
              : parseInt(selectedTracto.sapId!)) +
            ' - ' +
            selectedTracto.licensePlate;
          const message = `Se ha enviado una notificación por la actualización del tracto ${tractoIdentification} previamente ${messageInitialStatus}.`;
          this.feedbacksService.showFeedback(message, 'info');
        });
    }
  }

  /**
   * Carga una lista de documentos.
   * @param {DocumentFromForms[]} documentList Lista de documentos del formulario.
   * @param {string} uploadDateTimestamp Timestamp de la fecha de edición.
   * @param {string} uploadDateYYYYMMDD Fecha de edición.
   */
  private uploadDocuments(
    documentList: DocumentFromForms[],
    uploadDateYYYYMMDD: string,
    uploadDateTimestamp: string
  ): void {
    for (let document of documentList) {
      // Solo se actualizan los documentos que tengan un archivo asociado.
      if (document.documentSourceFile) {
        const inputDocument = {
          ...document,
          documentId: document.documentMasterValueId + '-' + this.tractoId,
        };
        this.documentsService
          .uploadDocument(
            inputDocument,
            uploadDateYYYYMMDD,
            uploadDateTimestamp,
            this.usersService.business.value,
            'TRACTO',
            this.tractoId
          )
          .then();
      }
    }
  }

  /**
   * Navega al detalle del tracto.
   */
  onCancel(): void {
    // Regresamos al detalle del tracto.
    this.router
      .navigate(['../'], { relativeTo: this.route })
      .then(() => console.log('navigate to details'));
  }

  /* --------------------------------------- */
  /* - Métodos para ambiente de desarrollo - */
  /* --------------------------------------- */
  /**
   * Muestra el formulario en la consola.
   * Nota: Solo aparece en DEV.
   */
  showForm(): void {
    console.log('-- Current Form --');
    console.log(this.tractoForm);
  }

  async ngOnDestroy(): Promise<void> {
    if (this.editionId !== '') {
      await this.editionsService.releaseEdition(this.editionId);
    }
  }
}
