<div class="row">
  <div class="text-center my-5" *ngIf="isGettingEnvasado">
    <app-spinner
      [message]="
        editMode ? 'Cargando detalles del equipo' : 'Cargando formulario'
      "
    ></app-spinner>
  </div>
  <div class="col-xs-12" *ngIf="!isGettingEnvasado">
    <h3>{{ editMode ? "Editar" : "Nuevo" }} {{ equipName }}</h3>
    <form [formGroup]="envasadoForm" (ngSubmit)="onSubmit()">
      <div class="container-fluid">
        <h4>
          Transportista
          <span *ngIf="!editMode" class="text-danger fs-4">*</span>
        </h4>
        <div class="row">
          <div class="col-sm">
            <div class="form-group">
              <select
                *ngIf="isCarrier"
                id="envasado-carrier"
                formControlName="carrier"
                class="form-select"
              >
                <option [value]="carrierValueId()" selected>
                  {{ carrierValueToDisplay() }}
                </option>
              </select>
              <select
                *ngIf="isAdmin"
                id="carrier"
                formControlName="carrier"
                class="form-select"
              >
                <option value="" selected>Seleccionar...</option>
                <option
                  *ngFor="let carrier of master.CARRIERS"
                  [ngValue]="carrier.valueId"
                >
                  {{ carrier.valueToDisplay }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="container-fluid">
        <h4>{{ equipName }}</h4>
        <div class="row">
          <div class="col-sm">
            <label for="licensePlate" class="form-label"
              >Patente
              <span *ngIf="!editMode" class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <input
                type="text"
                id="licensePlate"
                formControlName="licensePlate"
                class="form-control immutable-field"
                placeholder="Ejemplo: AABB00"
                appInputUppercase
              />
            </div>
          </div>
          <div class="col-sm">
            <label for="center" class="form-label"
              >Centro
              <span *ngIf="!editMode" class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <select
                id="center"
                formControlName="center"
                class="form-select"
                (change)="onEnvasadoCenterChange()"
              >
                <option value="" selected>Seleccionar...</option>
                <option
                  *ngFor="let center of master.CENTERS"
                  [ngValue]="center.valueId"
                >
                  {{ center.valueToDisplay }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row my-2">
          <div class="col-sm">
            <label for="brand" class="form-label"
              >Marca
              <span *ngIf="!editMode" class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <select id="brand" formControlName="brand" class="form-select">
                <option value="" selected>Seleccionar...</option>
                <option
                  *ngFor="let brand of master['VEHICLES#BRANDS']"
                  [ngValue]="brand.valueId"
                >
                  {{ brand.valueToDisplay }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm">
            <label for="model" class="form-label"
              >Modelo
              <span *ngIf="!editMode" class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <input
                type="text"
                id="model"
                formControlName="model"
                class="form-control immutable-field"
                placeholder="Ejemplo: Volvo FH"
                appInputTrim
              />
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row my-2">
          <div class="col-sm">
            <label for="year" class="form-label"
              >Año de Fabricación
              <span *ngIf="!editMode" class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <input
                type="number"
                id="year"
                formControlName="year"
                class="form-control immutable-field"
                placeholder="Ejemplo: 2020"
                (change)="onEnvasadoYearChange()"
              />
            </div>
          </div>
          <div class="col-sm">
            <label for="color" class="form-label"
              >Color
              <span *ngIf="!editMode" class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <select id="color" formControlName="color" class="form-select">
                <option value="" selected>Seleccionar...</option>
                <option
                  *ngFor="let color of master['VEHICLES#COLORS']"
                  [ngValue]="color.valueId"
                >
                  {{ color.valueToDisplay }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm">
            <label for="capacity" class="form-label"
              >Capacidad
              <span *ngIf="!editMode" class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <select
                id="capacity"
                formControlName="capacity"
                class="form-select"
              >
                <option value="" selected>Seleccionar...</option>
                <option
                  *ngFor="let capacity of master['ENVASADOS#CAPACITIES']"
                  [ngValue]="capacity.valueId"
                >
                  {{ capacity.valueToDisplay }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row my-2">
          <div class="col-sm text-center border rounded mx-1">
            <p class="pt-1">
              Usado en minería
              <span *ngIf="!editMode" class="text-danger fs-4">*</span>
            </p>
            <div class="form-check form-check-inline">
              <input
                type="radio"
                id="forMiningYes"
                formControlName="forMining"
                class="form-check-input"
                value="true"
              />
              <label for="forMiningYes" class="form-check-label">Sí</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                type="radio"
                id="forMiningNo"
                formControlName="forMining"
                class="form-check-input"
                value="false"
              />
              <label for="forMiningNo" class="form-check-label">No</label>
            </div>
          </div>
          <div class="col-sm text-center border rounded mx-1">
            <p class="pt-1">
              Tiene Acreditación Ranchera
              <span *ngIf="!editMode" class="text-danger fs-4">*</span>
            </p>
            <div class="form-check form-check-inline">
              <input
                type="radio"
                id="hasRanchAccreditationYes"
                formControlName="hasRanchAccreditation"
                class="form-check-input"
                value="true"
              />
              <label for="hasRanchAccreditationYes" class="form-check-label"
                >Sí</label
              >
            </div>
            <div class="form-check form-check-inline">
              <input
                type="radio"
                id="hasRanchAccreditationNo"
                formControlName="hasRanchAccreditation"
                class="form-check-input"
                value="false"
              />
              <label for="hasRanchAccreditationNo" class="form-check-label"
                >No</label
              >
            </div>
          </div>
          <div class="col-sm text-center border rounded mx-1">
            <p class="pt-1">
              Es Spot <span *ngIf="!editMode" class="text-danger fs-4">*</span>
            </p>
            <div class="form-check form-check-inline">
              <input
                type="radio"
                id="isSpotYes"
                formControlName="isSpot"
                class="form-check-input"
                value="true"
              />
              <label for="isSpotYes" class="form-check-label">Sí</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                type="radio"
                id="isSpotNo"
                formControlName="isSpot"
                class="form-check-input"
                value="false"
              />
              <label for="isSpotNo" class="form-check-label">No</label>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row my-2">
          <div class="col-sm">
            <label for="engineType" class="form-label"
              >Tipo de Motor
              <span *ngIf="!editMode" class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <select
                id="engineType"
                formControlName="engineType"
                class="form-select"
              >
                <option value="" selected>Seleccionar...</option>
                <option
                  *ngFor="let engine of master['ENVASADOS#ENGINE_TYPES']"
                  [ngValue]="engine.valueId"
                >
                  {{ engine.valueToDisplay }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm">
            <label for="maximumPalletCapacity" class="form-label"
              >Capacidad Máxima de Pallets
              <span *ngIf="!editMode" class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <input
                type="number"
                min="1"
                max="70"
                step="1"
                id="maximumPalletCapacity"
                formControlName="maximumPalletCapacity"
                class="form-control immutable-field"
                placeholder="Ejemplo: 14"
              />
              <div
                *ngIf="showHelper(envasadoControl.get('maximumPalletCapacity'))"
              >
                <small class="form-text text-muted">
                  {{
                    helperMessages(envasadoControl.get("maximumPalletCapacity"))
                  }}
                </small>
              </div>
            </div>
          </div>
          <div class="col-sm">
            <label for="bodyType" class="form-label"
              >Tipo de Carrocería
              <span *ngIf="!editMode" class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <select
                id="bodyType"
                formControlName="bodyType"
                class="form-select"
              >
                <option value="" selected>Seleccionar...</option>
                <option
                  *ngFor="let body of master['ENVASADOS#BODY_TYPES']"
                  [ngValue]="body.valueId"
                >
                  {{ body.valueToDisplay }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row my-2">
          <div class="col-sm">
            <label for="height" class="form-label"
              >Alto (metros)
              <span *ngIf="!editMode" class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <input
                type="number"
                min="1"
                max="50"
                step="0.1"
                id="height"
                formControlName="height"
                class="form-control immutable-field"
                placeholder="Ejemplo: 4.00"
              />
              <div *ngIf="showHelper(envasadoControl.get('height'))">
                <small class="form-text text-muted">
                  {{ helperMessages(envasadoControl.get("height")) }}
                </small>
              </div>
            </div>
          </div>
          <div class="col-sm">
            <label for="width" class="form-label"
              >Ancho (metros)
              <span *ngIf="!editMode" class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <input
                type="number"
                min="1"
                max="50"
                step="0.1"
                id="width"
                formControlName="width"
                class="form-control immutable-field"
                placeholder="Ejemplo: 2.55"
              />
              <div *ngIf="showHelper(envasadoControl.get('width'))">
                <small class="form-text text-muted">
                  {{ helperMessages(envasadoControl.get("width")) }}
                </small>
              </div>
            </div>
          </div>
          <div class="col-sm">
            <label for="length" class="form-label"
              >Largo (metros)
              <span *ngIf="!editMode" class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <input
                type="number"
                min="1"
                max="50"
                step="0.1"
                id="length"
                formControlName="length"
                class="form-control immutable-field"
                placeholder="Ejemplo: 13.95"
              />
              <div *ngIf="showHelper(envasadoControl.get('length'))">
                <small class="form-text text-muted">
                  {{ helperMessages(envasadoControl.get("length")) }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row my-2">
          <div class="col-sm">
            <label for="gpsImei" class="form-label">GPS IMEI</label>
            <div class="form-group">
              <input
                type="text"
                id="gpsImei"
                formControlName="gpsImei"
                class="form-control immutable-field"
                placeholder="Ejemplo: 123456789012345"
                appInputTrim
              />
            </div>
          </div>
          <div class="col-sm">
            <label for="cameraImei" class="form-label">Cámara IMEI</label>
            <div class="form-group">
              <input
                type="text"
                id="cameraImei"
                formControlName="cameraImei"
                class="form-control immutable-field"
                placeholder="Ejemplo: 123456789012345"
                appInputTrim
              />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="container-fluid">
        <h5>
          <i class="material-symbols-outlined align-middle">description</i>
          Documentos del {{ equipName }}
        </h5>
        <div class="alert alert-warning" role="alert">
          <i class="material-symbols-outlined align-middle">warning</i> Solo se
          aceptan documentos en formato PDF.
        </div>
        <div class="col-xs-12" formArrayName="documents">
          <div
            class="row border-top"
            id="envasado-documents"
            *ngFor="let documentControl of documentsControl; let i = index"
            [formGroupName]="i"
          >
            <div class="row my-2">
              <div class="col-sm-11">
                <input
                  type="text"
                  id="documentName"
                  class="form-control-plaintext form-control-lg"
                  formControlName="documentName"
                  readonly
                />
              </div>
              <div
                class="col-sm-1"
                *ngIf="
                  !editMode &&
                  isMandatoryDocument(
                    documentControl.get('documentMasterValueId')?.value
                  )
                "
              >
                <p class="text-danger fs-4">*</p>
              </div>
            </div>
            <div
              *ngIf="
                editMode &&
                invalidControl(documentControl.get('documentExpirationDate'))
              "
            >
              <small class="form-text text-danger">
                Documento vencido, es necesario actualizar.
              </small>
            </div>
            <div
              class="row"
              *ngIf="
                !isDatelessDocument(
                  documentControl.get('documentMasterValueId')?.value
                )
              "
            >
              <label for="documentIssueDate" class="col-sm-2 col-form-label"
                >Emisión</label
              >
              <div class="col-sm-4">
                <input
                  type="date"
                  id="documentIssueDate"
                  formControlName="documentIssueDate"
                  class="form-control"
                />
                <div
                  *ngIf="showHelper(documentControl.get('documentIssueDate'))"
                >
                  <small class="form-text text-muted">
                    {{
                      helperMessages(documentControl.get("documentIssueDate"))
                    }}
                  </small>
                </div>
              </div>
              <label
                for="documentExpirationDate"
                class="col-sm-2 col-form-label"
                >Vencimiento</label
              >
              <div class="col-sm-4">
                <input
                  type="date"
                  id="documentExpirationDate"
                  formControlName="documentExpirationDate"
                  class="form-control"
                />
                <div
                  *ngIf="
                    showHelper(documentControl.get('documentExpirationDate'))
                  "
                >
                  <small class="form-text text-muted">
                    {{
                      helperMessages(
                        documentControl.get("documentExpirationDate")
                      )
                    }}
                  </small>
                </div>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-sm-12">
                <input
                  type="file"
                  accept=".pdf"
                  id="documentFile"
                  formControlName="documentFile"
                  class="form-control"
                  (change)="onChangeDocumentFile($event, i)"
                />
                <div *ngIf="showHelper(documentControl.get('documentFile'))">
                  <small class="form-text text-muted">
                    {{ helperMessages(documentControl.get("documentFile")) }}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-xs-12">
          <button
            id="envasado-edit-cancel"
            type="button"
            class="btn btn-outline-danger"
            (click)="onCancel()"
          >
            Cancelar
          </button>
          <button
            id="envasado-edit-show"
            type="button"
            class="btn btn-outline-secondary mx-2"
            (click)="showForm()"
            *ngIf="isDev"
          >
            Log Form
          </button>
          <button
            id="envasado-edit-submit"
            type="submit"
            class="btn btn-outline-primary mx-2 float-end"
            [disabled]="!envasadoForm.valid"
          >
            {{ editMode ? "Guardar" : "Crear" }} {{ equipName }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
