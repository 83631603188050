import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CognitoService } from '../auth/cognito.service';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  constructor(private cognitoService: CognitoService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Obtener el token de acceso
    const accessToken: string = this.cognitoService.token;

    // Clonar la solicitud y agregar el token de acceso en las cabeceras
    if (accessToken && request.headers.get('without-token') !== 'true') {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }

    // Pasar la solicitud clonada con el token de acceso al siguiente manipulador
    return next.handle(request);
  }
}
