<div class="container-fluid mt-4">
  <div class="row">
    <div class="col-sm-12">
      <h3>Evaluación RTC</h3>
      <form [formGroup]="optionsForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-sm-4">
            <label for="business" class="form-label"
              >Negocio <span class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <select
                id="business"
                formControlName="business"
                class="form-select"
                (change)="onBusinessChanged()"
              >
                <option value="" selected>Seleccionar...</option>
                <option
                  *ngFor="let business of master.BUSINESSES"
                  [ngValue]="business.valueId"
                >
                  {{ business.valueToDisplay }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-4">
            <label for="type" class="form-label"
              >Tipo <span class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <select id="type" formControlName="type" class="form-select">
                <option value="" selected>Seleccionar...</option>
                <option
                  *ngFor="let type of typesForBusinessSelected"
                  [ngValue]="type.valueId"
                >
                  {{ type.valueToDisplay }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-2">
            <label for="licensePlate" class="form-label"
              >Patente <span class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <input
                type="text"
                id="licensePlate"
                formControlName="licensePlate"
                class="form-control immutable-field"
                placeholder="AABB00"
                appInputUppercase
              />
            </div>
          </div>
          <div class="col-sm-2 text-center mt-auto">
            <button
              id="rtc-options-edit-submit"
              type="submit"
              class="btn btn-outline-primary"
              [disabled]="!optionsForm.valid || disableLoadButton"
            >
              Cargar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row my-2" *ngIf="isDev">
    <div class="col-sm-3">
      <button
        id="rtc-options-show"
        type="button"
        class="btn btn-outline-secondary mx-2"
        (click)="showForm()"
      >
        Log Form
      </button>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-sm-12 text-center my-5" *ngIf="isGettingEntity">
      <app-spinner message="Cargando detalles del vehículo..."></app-spinner>
    </div>
    <div class="col-sm-12" *ngIf="!isGettingEntity && selectedEntity">
      <div class="row mb-3">
        <div class="col-sm text-secondary text-center">
          <h4>
            {{ selectedEntity.sapId! | sapId }} -
            {{ selectedEntity.licensePlate }}
          </h4>
        </div>
      </div>
      <div ngbAccordion style="padding: 10px">
        <div ngbAccordionItem [collapsed]="true">
          <h2 ngbAccordionHeader>
            <button id="entity-details-accordion" ngbAccordionButton>
              Ver detalles del vehículo
            </button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody class="table-container">
              <ng-template>
                <table class="table table-hover">
                  <caption>
                    Detalles del Vehículo
                  </caption>
                  <tbody>
                    <tr>
                      <th scope="row">SAP ID</th>
                      <td>{{ selectedEntity.sapId! | sapId }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Patente</th>
                      <td>{{ selectedEntity.licensePlate! }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Transportista</th>
                      <td>{{ selectedEntity.carrier!.name }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Marca</th>
                      <td>
                        {{ selectedEntity.brand! | master : "VEHICLES#BRANDS" }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Modelo</th>
                      <td>{{ selectedEntity.model! }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Año de Fabricación</th>
                      <td>{{ selectedEntity.year! }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Antigüedad</th>
                      <td>
                        {{ currentYear - selectedEntity.year! }} año{{
                          currentYear - selectedEntity.year! === 1 ? "" : "s"
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col-md-12">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
