import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../../auth/auth.guard';
import { MasterResolver } from '../master/master.resolver';

import { DriverDetailsComponent } from './driver-details/driver-details.component';
import { DriverEditComponent } from './driver-edit/driver-edit.component';
import { DriverNoSelectedComponent } from './driver-no-selected/driver-no-selected.component';
import { DriversComponent } from './drivers.component';

const routes: Routes = [
  {
    path: ':business/drivers',
    component: DriversComponent,
    canActivate: [AuthGuard],
    resolve: [MasterResolver],
    children: [
      { path: '', component: DriverNoSelectedComponent },
      { path: 'new', component: DriverEditComponent },
      { path: ':id', component: DriverDetailsComponent },
      { path: ':id/edit', component: DriverEditComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DriversRoutingModule {}
