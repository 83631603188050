import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../../auth/auth.guard';
import { MasterResolver } from '../master/master.resolver';

import { SemirremolquesComponent } from './semirremolques.component';
import { SemirremolqueNoSelectedComponent } from './semirremolque-no-selected/semirremolque-no-selected.component';
import { SemirremolqueEditComponent } from './semirremolque-edit/semirremolque-edit.component';
import { SemirremolqueDetailsComponent } from './semirremolque-details/semirremolque-details.component';

const routes: Routes = [
  {
    path: ':business/semirremolques',
    component: SemirremolquesComponent,
    canActivate: [AuthGuard],
    resolve: [MasterResolver],
    children: [
      { path: '', component: SemirremolqueNoSelectedComponent },
      { path: 'new', component: SemirremolqueEditComponent },
      { path: ':id', component: SemirremolqueDetailsComponent },
      { path: ':id/edit', component: SemirremolqueEditComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SemirremolquesRoutingModule {}
