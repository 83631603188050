<hr />
<div class="row my-2">
  <div class="col-sm-4">
    <h6>
      <img
        class="p-2"
        ngSrc="assets/images/icons/usuarios.svg"
        alt="Usuarios"
        height="40"
        width="50"
      />Usuarios
    </h6>
  </div>
  <div class="col-sm-8 text-end">
    <input
      placeholder="Buscar"
      class="input-search-datatable input-search-datatable-image-users"
      id="custom-search-input"
      type="text"
      [(ngModel)]="searchText"
      (input)="applyFilter()"
    />

    <button id="users-list-new" class="btn-btn-create" (click)="onNewUser()">
      Crear Usuario
    </button>
    <button
      id="users-list-refresh"
      class="btn btn-light mx-2 btn-refresh btn-refresh-image"
      (click)="onRefresh()"
    ></button>
  </div>
</div>
<div class="row">
  <div class="text-center my-5" *ngIf="isGettingUsers">
    <app-spinner message="Cargando usuarios"></app-spinner>
  </div>
  <div class="col-xs-12">
    <div class="table-responsive-sm" *ngIf="!isGettingUsers">
      <table
        datatable
        [dtOptions]="dtOptions"
        class="table display nowrap"
        style="width: 100%"
      >
        <caption>
          Lista de Usuarios
        </caption>
        <thead>
          <tr class="text-center">
            <th scope="col" *ngFor="let attribute of usersAttributes">
              {{ attribute }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of users">
            <td>
              <a
                id="users-list-{{ user.rut }}"
                (click)="onSelectedUser(user)"
                style="color: #0d6efd"
                >{{ user.userId }}</a
              >
            </td>
            <td>
              <span>
                <img
                  [ngSrc]="getImageSrc(user.status)"
                  height="25"
                  width="25"
                  alt="Estado"
                />
              </span>
              {{ user.status | master : "USERS#STATUSES" }}
            </td>
            <td>{{ user.rut }}</td>
            <td>{{ user.firstName + " " + user.lastName }}</td>
            <td>{{ user.company | master : "CARRIERS" : user.company }}</td>
            <td>{{ user.authGroup | master : "USERS#GROUPS" }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
