import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

import { DocumentsModule } from '../documents/documents.module';
import { MasterModule } from '../master/master.module';
import { SharedModule } from '../../shared/shared.module';

import { EnvasadoDetailsComponent } from './envasado-details/envasado-details.component';
import { EnvasadoEditComponent } from './envasado-edit/envasado-edit.component';
import { EnvasadoNoSelectedComponent } from './envasado-no-selected/envasado-no-selected.component';
import { EnvasadosComponent } from './envasados.component';
import { EnvasadosListComponent } from './envasados-list/envasados-list.component';
import { EnvasadosRoutingModule } from './envasados-routing.module';

@NgModule({
  declarations: [
    EnvasadosComponent,
    EnvasadosListComponent,
    EnvasadoDetailsComponent,
    EnvasadoNoSelectedComponent,
    EnvasadoEditComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    DataTablesModule,
    DocumentsModule,
    EnvasadosRoutingModule,
    MasterModule,
    NgbAccordionModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    NgOptimizedImage,
  ],
})
export class EnvasadosModule {}
