import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

import { DocumentsModule } from '../documents/documents.module';
import { MasterModule } from '../master/master.module';
import { SharedModule } from '../../shared/shared.module';

import { CisternasComponent } from './cisternas.component';
import { CisternasListComponent } from './cisternas-list/cisternas-list.component';
import { CisternaNoSelectedComponent } from './cisterna-no-selected/cisterna-no-selected.component';
import { CisternaEditComponent } from './cisterna-edit/cisterna-edit.component';
import { CisternaDetailsComponent } from './cisterna-details/cisterna-details.component';
import { CisternasRoutingModule } from './cisternas-routing.module';

@NgModule({
  declarations: [
    CisternasComponent,
    CisternasListComponent,
    CisternaNoSelectedComponent,
    CisternaEditComponent,
    CisternaDetailsComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    DataTablesModule,
    DocumentsModule,
    CisternasRoutingModule,
    MasterModule,
    NgbAccordionModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    NgOptimizedImage,
  ],
})
export class CisternasModule {}
