import { Component } from '@angular/core';

@Component({
  selector: 'app-semirremolques',
  templateUrl: './semirremolques.component.html',
  styleUrls: ['./semirremolques.component.css'],
})
export class SemirremolquesComponent {
  equipName: string = 'Semirremolques';
}
