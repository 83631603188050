import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MasterService } from '../../../pages/master/master.service';

@Component({
  selector: 'app-modal-change-attributes',
  templateUrl: './modal-change-attributes.component.html',
  styleUrls: ['./modal-change-attributes.component.css'],
})
export class ModalChangeAttributesComponent {
  @Input() modalTitle: string = '';
  @Input() modalQuestion: string = '';
  @Input() modalMessage: string = '';
  @Input() attributeForm: FormGroup = new FormGroup({});
  @Output() modalDismissed: EventEmitter<string> = new EventEmitter<string>();
  @Output() modalClosed: EventEmitter<string> = new EventEmitter<string>();

  readonly master: any = {};

  constructor(private masterService: MasterService) {
    this.master = this.masterService.getMaster();
  }

  /**
   * Función de desecho del modal.
   * @param {string} value Tecla con que fue desechado.
   */
  d(value: string): void {
    this.modalDismissed.emit(value);
  }

  /**
   * Función de cierre del modal.
   * @param {string} value Tecla con que fue cerrado.
   */
  c(value: string): void {
    this.modalClosed.emit(value);
  }
}
