import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

import { DocumentsModule } from '../documents/documents.module';
import { MasterModule } from '../master/master.module';
import { SharedModule } from '../../shared/shared.module';

import { SemirremolqueDetailsComponent } from './semirremolque-details/semirremolque-details.component';
import { SemirremolqueEditComponent } from './semirremolque-edit/semirremolque-edit.component';
import { SemirremolqueNoSelectedComponent } from './semirremolque-no-selected/semirremolque-no-selected.component';
import { SemirremolquesComponent } from './semirremolques.component';
import { SemirremolquesListComponent } from './semirremolques-list/semirremolques-list.component';
import { SemirremolquesRoutingModule } from './semirremolques-routing.module';

@NgModule({
  declarations: [
    SemirremolquesComponent,
    SemirremolquesListComponent,
    SemirremolqueDetailsComponent,
    SemirremolqueNoSelectedComponent,
    SemirremolqueEditComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    DataTablesModule,
    DocumentsModule,
    MasterModule,
    NgbAccordionModule,
    ReactiveFormsModule,
    RouterModule,
    SemirremolquesRoutingModule,
    SharedModule,
    NgOptimizedImage,
  ],
})
export class SemirremolquesModule {}
