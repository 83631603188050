import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../../auth/auth.guard';
import { MasterResolver } from '../master/master.resolver';

import { EnvasadosComponent } from './envasados.component';
import { EnvasadoNoSelectedComponent } from './envasado-no-selected/envasado-no-selected.component';
import { EnvasadoEditComponent } from './envasado-edit/envasado-edit.component';
import { EnvasadoDetailsComponent } from './envasado-details/envasado-details.component';

const routes: Routes = [
  {
    path: ':business/envasados',
    component: EnvasadosComponent,
    canActivate: [AuthGuard],
    resolve: [MasterResolver],
    children: [
      { path: '', component: EnvasadoNoSelectedComponent },
      { path: 'new', component: EnvasadoEditComponent },
      { path: ':id', component: EnvasadoDetailsComponent },
      { path: ':id/edit', component: EnvasadoEditComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EnvasadosRoutingModule {}
