<div class="container">
  <div class="text-center my-5" *ngIf="isGettingEvaluations">
    <app-spinner message="Cargando {{ entityName }}"></app-spinner>
  </div>
  <div class="row my-2" *ngIf="!isGettingEvaluations && showTable">
    <div class="col-sm-5">
      <h4>
        <i class="material-symbols-outlined align-middle text-secondary"
          >description</i
        >
        {{ entityName }}
      </h4>
    </div>
    <div class="col-sm-7 text-end">
      <input
        placeholder="Buscar"
        class="input-search-datatable input-search-datatable-image-users"
        id="custom-search-input"
        type="text"
        [(ngModel)]="searchText"
        (input)="applyFilter()"
      />

      <button
        id="evaluations-list-refresh"
        class="btn btn-light mx-2 btn-refresh btn-refresh-image"
        (click)="onRefresh()"
      ></button>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <div
        class="table-responsive-sm"
        *ngIf="!isGettingEvaluations && showTable"
      >
        <table
          datatable
          [dtOptions]="dtOptions"
          class="table display nowrap"
          style="width: 100%"
        >
          <caption>
            Lista de Evaluaciones
          </caption>
          <thead>
            <tr class="text-center">
              <th scope="col" *ngFor="let attribute of evaluationsAttributes">
                {{ attribute }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let evaluation of evaluations">
              <td>
                {{ evaluation.updatedAt | dateFormat | dateWithoutSeconds }}
              </td>
              <td>{{ Math.round(evaluation.score) }}</td>
              <td>
                <span>
                  <img
                    [ngSrc]="getImageSrc(evaluation.status)"
                    height="25"
                    width="25"
                    alt="Estado"
                  />
                </span>
                {{
                  evaluation.status === "APPROVED" ? "Aprobado" : "Reprobado"
                }}
              </td>
              <td>{{ evaluation.updatedBy }}</td>
              <td>
                <label
                  class="custom-file-upload pt-2 mx-1"
                  style="cursor: pointer"
                >
                  <a
                    id="preview-evaluation"
                    title="Visualizar"
                    (click)="onViewDocument(evaluation)"
                  >
                    <span class="material-symbols-outlined text-secondary"
                      >preview</span
                    >
                  </a>
                </label>
                <label
                  class="custom-file-upload pt-2 mx-1"
                  style="cursor: pointer"
                >
                  <a
                    id="download-evaluation"
                    title="Descargar"
                    (click)="onDownloadDocument(evaluation)"
                  >
                    <span class="material-symbols-outlined text-secondary"
                      >download</span
                    >
                  </a>
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<!-- Modals -->
<ng-template #documentViewer let-c="close" let-d="dismiss">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{ modalTitle }}</h4>
        <button
          id="document-item-modal-cross"
          type="button"
          class="btn-close"
          aria-label="Close"
          (click)="d('Cross')"
        ></button>
      </div>
      <div class="modal-body">
        <div class="text-center my-5" *ngIf="isGettingURL">
          <app-spinner message="Generando Vista Previa"></app-spinner>
        </div>
        <pdf-viewer
          [src]="pdfSource"
          [render-text]="true"
          [original-size]="false"
          [fit-to-page]="true"
          (page-rendered)="pageRendered()"
          style="width: 800px; height: 70vh"
        >
        </pdf-viewer>
      </div>
      <div class="modal-footer" *ngIf="!isGettingURL">
        <button
          id="document-item-modal-accept"
          type="button"
          class="btn btn-outline-primary"
          (click)="c('Accept')"
        >
          Cerrar
        </button>
      </div>
    </div>
  </div>
</ng-template>
