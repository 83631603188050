import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

import { DocumentsModule } from '../documents/documents.module';
import { MasterModule } from '../master/master.module';
import { SharedModule } from '../../shared/shared.module';

import { VehicleDetailsComponent } from './vehicle-details/vehicle-details.component';
import { VehicleEditComponent } from './vehicle-edit/vehicle-edit.component';
import { VehicleNoSelectedComponent } from './vehicle-no-selected/vehicle-no-selected.component';
import { VehiclesComponent } from './vehicles.component';
import { VehiclesListComponent } from './vehicles-list/vehicles-list.component';
import { VehiclesRoutingModule } from './vehicles-routing.module';
import { DataTablesModule } from 'angular-datatables';

@NgModule({
  declarations: [
    VehicleDetailsComponent,
    VehicleEditComponent,
    VehicleNoSelectedComponent,
    VehiclesComponent,
    VehiclesListComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    DataTablesModule,
    DocumentsModule,
    MasterModule,
    NgbAccordionModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    VehiclesRoutingModule,
    NgOptimizedImage,
  ],
})
export class VehiclesModule {}
