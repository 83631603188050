import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ZenObservable } from 'zen-observable-ts';

import { appConstants } from 'src/app/shared/constants/constants';

import {
  APIService,
  CreateNotificationInput,
  DeleteVehicleInput,
  UpdateDocumentInput,
  UpdateVehicleInput,
  UpdateVehicleMutation,
  Vehicle,
} from 'src/app/app-sync.service';
import { DocumentsService } from 'src/app/pages/documents/documents.service';
import { FeedbacksService } from 'src/app/shared/feedbacks/feedbacks.service';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { VehiclesService } from '../vehicles.service';
import { UsersService } from '../../users/users.service';
import { EditionsService } from '../../../shared/services/editions.service';
import { MasterService } from '../../master/master.service';
import { FormsService } from '../../../shared/services/forms.service';

@Component({
  selector: 'app-vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.css'],
})
export class VehicleDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('vehicleModal', { static: false }) vehicleModal:
    | TemplateRef<any>
    | undefined;
  currentYear: number = new Date().getFullYear();
  isAdmin: boolean = false;
  isApprover: boolean = false;
  isCarrier: boolean = false;
  isBeingRejected: boolean = false;
  isGettingVehicle: boolean = false;
  isUploadingDocuments: boolean = false;
  modalMessage: string = '';
  modalQuestion: string = '';
  modalTitle: string = '';
  modalWithComment: boolean = false;
  commentForm: FormGroup;
  vehicle: Vehicle;
  vehicleNeedsApproval: boolean = false;
  vehicleCanBeRejected: boolean = false;
  showBlockingMotives: boolean = false;
  hasTrailer: boolean = false;
  hasComment: boolean = false;
  showComment: boolean = false;

  private selectedVehicleChangedSubscription: Subscription = new Subscription();
  private vehicleHasTrailerChangedSubscription: Subscription =
    new Subscription();
  private onUpdateVehicleListener: ZenObservable.Subscription =
    new Subscription();
  private isUploadingDocumentsSubscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private feedbacksService: FeedbacksService,
    private api: APIService,
    private vehiclesService: VehiclesService,
    private modalsService: ModalsService,
    private documentsService: DocumentsService,
    private usersService: UsersService,
    private editionsService: EditionsService,
    private masterService: MasterService,
    private formsService: FormsService
  ) {
    this.vehicle = this.vehiclesService.getSelectedVehicle();
    this.hasTrailer = !!this.vehicle.trailerLicensePlate;
    this.isUploadingDocuments =
      this.documentsService.isUploadingDocuments.value;

    this.commentForm = new FormGroup({
      comment: new FormControl(null),
    });
    this.vehicleHasTrailerChangedSubscription =
      this.vehiclesService.vehicleHasTrailerChanged.subscribe(
        (value: boolean) => {
          this.hasTrailer = value;
        }
      );
  }

  async ngOnInit() {
    this.selectedVehicleChangedSubscription =
      this.vehiclesService.selectedVehicleChanged.subscribe(
        (vehicle: Vehicle) => {
          this.vehicle = vehicle;
          this.setVehicleSituation();
        }
      );

    this.isAdmin = this.usersService.isAdmin;
    this.isApprover = this.usersService.isApprover;
    this.isCarrier = this.usersService.isCarrier;

    await this.updateSelectedVehicle();

    // Subscripción a actualizaciones del Vehículo
    this.onUpdateVehicleListener = this.api
      .OnUpdateVehicleListener(
        this.vehiclesService.getSelectedVehicleFilterForSubscriptions()
      )
      .subscribe((response) => {
        if (response) {
          this.vehiclesService.refreshSelectedVehicle();
        }
      });

    // Suscripción a la variable que indica si se están cargando documentos.
    this.isUploadingDocumentsSubscription =
      this.documentsService.isUploadingDocuments.subscribe(
        (value: boolean): void => {
          this.isUploadingDocuments = value;
        }
      );
  }

  /**
   * Actualiza el vehículo seleccionado.
   * @return {Promise}
   * @private
   */
  private async updateSelectedVehicle(): Promise<void> {
    this.isGettingVehicle = true;
    await this.vehiclesService.refreshSelectedVehicle().then(() => {
      this.setVehicleSituation();
      this.hasTrailer = !!this.vehicle.trailerLicensePlate;
      this.isGettingVehicle = false;
    });
  }

  /**
   * Ejecuta el modal de aprobación de un vehículo y pide su
   * actualización en caso de ser aprobado.
   * @return {Promise}
   */
  async onApproveVehicle(): Promise<void> {
    this.initCommentForm();
    this.modalTitle = appConstants.vehicle.modalMessages.approve.title;
    this.modalQuestion =
      appConstants.vehicle.modalMessages.approve.question.replace(
        '_',
        this.getVehicleIdentification()
      );
    this.modalMessage = appConstants.vehicle.modalMessages.approve.message;
    this.modalWithComment = true;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.vehicleModal
    );

    if (modalResult) {
      this.isGettingVehicle = true;
      await this.updateVehicle('approve', this.commentForm.value.comment);
    }
  }

  /**
   * Ejecuta el modal de rechazo de un vehículo y pide su
   * actualización en caso de ser rechazado.
   * @return {Promise}
   */
  async onRejectVehicle(): Promise<void> {
    this.isBeingRejected = true;
    this.initCommentForm();
    this.modalTitle = appConstants.vehicle.modalMessages.reject.title;
    this.modalQuestion =
      appConstants.vehicle.modalMessages.reject.question.replace(
        '_',
        this.getVehicleIdentification()
      );
    this.modalMessage = appConstants.vehicle.modalMessages.reject.message;
    this.modalWithComment = true;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.vehicleModal
    );

    if (modalResult) {
      this.isGettingVehicle = true;
      console.log(' onRejectVehicle @ vehicle-details.component ');
      console.log(this.commentForm.value);
      const newNotification = this.commentForm.value;
      const today: Date = new Date();
      const todayTimestamp: string = Math.floor(
        today.getTime() / 1000
      ).toString();
      const creatNotificationInput: CreateNotificationInput = {
        businessId: this.vehicle.business,
        notificationId:
          'VEHICLE#' + this.vehicle.vehicleId + '#' + todayTimestamp,
        status: 'TO_BE_SENT',
        model: 'VEHICLE',
        motive: 'APPROVE#REJECTED',
        vehicleId: this.vehicle.vehicleId,
        comment: newNotification.comment,
      };

      await this.api
        .CreateNotification(creatNotificationInput)
        .then(async (): Promise<void> => {
          await this.updateVehicle('reject', newNotification.comment);
        });
    }

    this.isBeingRejected = false;
  }

  /**
   * Actualiza un vehículo.
   * @param {string} action Acción a realizar, puede ser 'approve' o 'reject'.
   * @param {string} comment Comentario de aprobación o rechazo.
   * @return {Promise}
   * @private
   */
  private async updateVehicle(
    action: 'approve' | 'reject',
    comment: string | null = null
  ): Promise<void> {
    this.isGettingVehicle = true;
    let newVehicleStatus: string;
    let feedbackMessage: string = `Vehículo ${this.getVehicleIdentification()} `;
    let catchFeedbackMessage: string = `Error al _ vehículo ${this.getVehicleIdentification()}`;

    switch (action) {
      case 'approve':
        // Se cambia el estado del vehículo a "Procesando"
        newVehicleStatus = `${this.vehicle.business}_${appConstants.entity.codes.inProcess}`;
        feedbackMessage = feedbackMessage + 'aprobado. Actualizando';
        catchFeedbackMessage = catchFeedbackMessage.replace('_', 'aprobar');
        break;
      case 'reject':
        // Se cambia el estado del vehículo a "Rechazado"
        newVehicleStatus = `${this.vehicle.business}_${appConstants.entity.codes.rejected}`;
        feedbackMessage = feedbackMessage + 'rechazado. Enviando notificación';
        catchFeedbackMessage = catchFeedbackMessage.replace('_', 'rechazar');
        break;
    }

    const updateInputVehicle: UpdateVehicleInput = {
      vehicleId: this.vehicle.vehicleId,
      status: newVehicleStatus,
      comment: comment,
    };

    await this.api
      .UpdateVehicle(updateInputVehicle)
      .then(async (vehicle: UpdateVehicleMutation): Promise<void> => {
        this.feedbacksService.showFeedback(feedbackMessage, 'info');

        if (action === 'approve') {
          await this.documentsFromToBeApprovedToProcessing(vehicle);
        }

        // Actualizamos el vehículo seleccionado.
        await this.updateSelectedVehicle();
      })
      .catch((response: any): void => {
        this.feedbacksService.showErrorFeedbacks(
          response,
          catchFeedbackMessage
        );
      });
  }

  /**
   * Actualiza el estado de una lista de documentos de Por Aprobar a Procesando.
   * @param {UpdateVehicleMutation} vehicle Vehículo al que pertenecen los documentos.
   */
  private async documentsFromToBeApprovedToProcessing(
    vehicle: UpdateVehicleMutation
  ): Promise<void> {
    // Se cambia el estado de los documentos con estado "Por Aprobar"
    // al estado "Procesando"
    for (let document of vehicle.documents!.items) {
      if (
        document?.status ===
        `${vehicle.business}_${appConstants.document.codes.toBeApproved}`
      ) {
        const updateDocumentInput: UpdateDocumentInput = {
          documentId: document.documentId,
          name: document.name,
          status: `${vehicle.business}_${appConstants.document.codes.inProcess}`,
        };
        await this.documentsService.updateDocument(updateDocumentInput);
      }
    }
  }

  /**
   * Navega al formulario de edición del vehículo.
   * @return {Promise}
   */
  async onEditVehicle(): Promise<void> {
    const editionId: string = `${this.usersService.business.value.toUpperCase()}#VEHICLE#${
      this.vehicle.vehicleId
    }`;

    if (await this.editionsService.editionIsTaken(editionId)) {
      return;
    }

    this.router
      .navigate(['edit'], { relativeTo: this.route })
      .then(() => console.log('navigate to edit'));
  }

  /**
   * Ejecuta el modal de eliminación de un vehículo y lo
   * elimina en caso de aceptar.
   * @return {Promise}
   */
  async onDeleteVehicle(): Promise<void> {
    this.modalTitle = appConstants.vehicle.modalMessages.delete.title;
    this.modalQuestion =
      appConstants.vehicle.modalMessages.delete.question.replace(
        '_',
        this.getVehicleIdentification()
      );
    this.modalMessage = appConstants.vehicle.modalMessages.delete.message;
    this.modalWithComment = false;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.vehicleModal
    );

    if (modalResult) {
      const deleteVehicleInput: DeleteVehicleInput = {
        vehicleId: this.vehicle.vehicleId,
      };

      await this.api
        .DeleteVehicle(deleteVehicleInput)
        .then(async () => {
          this.feedbacksService.showFeedback(
            `Vehículo ${this.vehicle.licensePlate} eliminado.`,
            'info'
          );
        })
        .catch((response: any): void => {
          this.feedbacksService.showErrorFeedbacks(
            response,
            `Error al borrar vehículo ${this.getVehicleIdentification()}`
          );
        });
      this.router
        .navigate(['/', this.vehicle.business.toLowerCase(), 'vehicles'])
        .then(() => console.log('navigate to vehicles'));
    }
  }

  /**
   * Llama a refrescar el vehículo seleccionado.
   */
  onRefreshVehicle(): void {
    this.updateSelectedVehicle().then(() =>
      console.log('Vehículo seleccionado actualizado.')
    );
  }

  /**
   * Colapsa o muestra el comentario del vehículo.
   */
  onViewComment(): void {
    this.showComment = !this.showComment;
  }

  /**
   * Retorna la clase que colapsa o muestra el comentario del vehículo.
   * @return {string} Clase CSS
   */
  showCommentClass(): string {
    return this.showComment ? 'show' : '';
  }

  /**
   * Inicializa el formulario de rechazo de un vehículo.
   * @private
   */
  private initCommentForm(): void {
    // Inicialización del formulario
    this.commentForm = this.formsService.generateCommentForm(
      this.isBeingRejected
    );
  }

  /**
   * Indica si un vehículo tiene documentos por vencer.
   * @return {boolean} Boolean que indica si el vehículo tiene documentos por vencer.
   */
  hasToExpireDocuments(): boolean {
    const docNumber: number = this.vehicle.documentsToExpire || 0;
    return docNumber > 0;
  }

  /**
   * Indica si un vehículo tiene documentos vencidos.
   * @return {boolean} Boolean que indica si el vehículo tiene documentos vencidos.
   */
  hasExpiredDocuments(): boolean {
    const docNumber: number = this.vehicle.documentsExpired || 0;
    return docNumber > 0;
  }

  /**
   * Retorna un color dependiendo del estado del envasado.
   * @return {string}
   */
  getColor(): string {
    return this.masterService.getSatusColor(this.vehicle.status);
  }

  /**
   * Retorna la fuente de imagen a mostrar en el Estado del envasado.
   * @return {string} ruta a imagen.
   */
  getImageSrc(status: string): string {
    return this.masterService.getImageSrc(status);
  }

  /**
   * Retorna una clase CSS dependiendo de la edad del vehículo.
   * @return {string}
   */
  getVehicleAgeClass(age: number): string {
    let vehicleAgeClass: string = '';
    if (age >= appConstants.vehicle.ageToBeConsideredOld) {
      vehicleAgeClass = 'text-danger';
    }
    return vehicleAgeClass;
  }

  /**
   * Determina, con base al rol del usuario, cuáles botones mostrar.
   * @param {string} button Acción del botón.
   */
  showButton(button: string): boolean {
    let show: boolean = false;

    switch (button) {
      case 'delete':
        show = this.isAdmin;
        break;
      case 'approve':
      case 'reject':
        show = this.isAdmin || this.isApprover;
        break;
      case 'edit':
        show = this.isAdmin || this.isCarrier;
        break;
      default:
        break;
    }
    return show;
  }

  /**
   * Define la situación del vehículo: ¿necesita aprobación?, ¿puede ser rechazado?
   * ¿Está bloqueado?
   * @private
   */
  private setVehicleSituation(): void {
    const vehicleToBeApprove: boolean =
      this.vehicle.status ===
      `${this.vehicle.business}_${appConstants.entity.codes.toBeApproved}`;
    const vehicleRejected: boolean =
      this.vehicle.status ===
      `${this.vehicle.business}_${appConstants.entity.codes.rejected}`;
    this.vehicleNeedsApproval = vehicleToBeApprove || vehicleRejected;
    this.vehicleCanBeRejected = vehicleToBeApprove;
    this.showBlockingMotives =
      this.vehicle.blockingMotives !== null &&
      this.vehicle.blockingMotives !== undefined &&
      this.vehicle.blockingMotives.length > 0 &&
      this.vehicle.status !==
        `${this.vehicle.business}_${appConstants.entity.codes.available}`;
    this.hasComment = !!this.vehicle.comment;
    this.showComment = false;
  }

  /**
   * Retorna la identificación del vehículo para los modales.
   * @return {string}
   * @private
   */
  private getVehicleIdentification(): string {
    let sapId: string;
    if (this.vehicle.sapId! === 'Sin Asignar') {
      sapId = this.vehicle.sapId!;
    } else {
      sapId = parseInt(this.vehicle.sapId!).toString();
    }
    return sapId + ' - ' + this.vehicle.licensePlate;
  }

  ngOnDestroy(): void {
    this.selectedVehicleChangedSubscription.unsubscribe();
    this.vehicleHasTrailerChangedSubscription.unsubscribe();
    this.onUpdateVehicleListener.unsubscribe();
    this.isUploadingDocumentsSubscription.unsubscribe();
  }
}
