import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';

import { MasterService } from './master.service';

@Injectable({
  providedIn: 'root',
})
export class MasterResolver implements Resolve<{}> {
  constructor(private masterService: MasterService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<{}> | Promise<{}> | {} {
    return this.masterService.setMaster();
  }
}
