import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

import { DataTablesModule } from 'angular-datatables';
import { DocumentsModule } from '../documents/documents.module';
import { MasterModule } from '../master/master.module';
import { SharedModule } from '../../shared/shared.module';

import { DriverNoSelectedComponent } from './driver-no-selected/driver-no-selected.component';
import { DriversComponent } from './drivers.component';
import { DriversListComponent } from './drivers-list/drivers-list.component';
import { DriversRoutingModule } from './drivers-routing.module';
import { DriverEditComponent } from './driver-edit/driver-edit.component';
import { DriverDetailsComponent } from './driver-details/driver-details.component';

@NgModule({
  declarations: [
    DriversComponent,
    DriversListComponent,
    DriverNoSelectedComponent,
    DriverEditComponent,
    DriverDetailsComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    DataTablesModule,
    DocumentsModule,
    DriversRoutingModule,
    MasterModule,
    NgbAccordionModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    NgOptimizedImage,
  ],
})
export class DriversModule {}
