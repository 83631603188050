import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ZenObservable } from 'zen-observable-ts';

import { appConstants } from 'src/app/shared/constants/constants';

import {
  APIService,
  CreateNotificationInput,
  DeleteDriverInput,
  DeleteUserInput,
  Driver,
  UpdateDocumentInput,
  UpdateDriverInput,
  UpdateDriverMutation,
} from 'src/app/app-sync.service';
import { DriversService } from '../drivers.service';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { FeedbacksService } from 'src/app/shared/feedbacks/feedbacks.service';
import { DocumentsService } from 'src/app/pages/documents/documents.service';
import { UsersService } from '../../users/users.service';
import { EditionsService } from '../../../shared/services/editions.service';
import { MasterService } from '../../master/master.service';
import { FormsService } from '../../../shared/services/forms.service';
import { ExclusionMotives } from 'src/app/shared/interfaces/exclusion-motives';

@Component({
  selector: 'app-driver-details',
  templateUrl: './driver-details.component.html',
  styleUrls: ['./driver-details.component.css'],
})
export class DriverDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('driverModal', { static: false }) driverModal:
    | TemplateRef<any>
    | undefined;
  @ViewChild('excludeModal', { static: false }) excludeModal:
    | TemplateRef<any>
    | undefined;
  driver: Driver;
  driverNeedsApproval: boolean = false;
  driverCanBeRejected: boolean = false;
  showBlockingMotives: boolean = false;
  currentYear: number = new Date().getFullYear();
  currentMonth: number = new Date().getMonth();
  driverAge: number = 0;
  driverInAgeRange: boolean = false;
  isAdmin: boolean = false;
  isApprover: boolean = false;
  isCarrier: boolean = false;
  isBeingRejected: boolean = false;
  isGettingDriver: boolean = false;
  isUploadingDocuments: boolean = false;
  modalMessage: string = '';
  modalQuestion: string = '';
  modalTitle: string = '';
  modalExcludeMessage: string = '';
  modalExcludeQuestion: string = '';
  modalExcludeTitle: string = '';
  modalExcludeComment: boolean = false;
  modalWithComment: boolean = false;
  commentForm: FormGroup;
  commentExcludeForm: FormGroup;
  hasComment: boolean = false;
  showComment: boolean = false;
  private selectedDriverChangedSubscription: Subscription = new Subscription();
  private onUpdateDriverListener: ZenObservable.Subscription =
    new Subscription();
  private isUploadingDocumentsSubscription: Subscription = new Subscription();

  constructor(
    private driversService: DriversService,
    private router: Router,
    private route: ActivatedRoute,
    private api: APIService,
    private modalsService: ModalsService,
    private feedbacksService: FeedbacksService,
    private documentsService: DocumentsService,
    private usersService: UsersService,
    private editionsService: EditionsService,
    private masterService: MasterService,
    private formsService: FormsService
  ) {
    this.driver = this.driversService.getSelectedDriver();
    this.isUploadingDocuments =
      this.documentsService.isUploadingDocuments.value;

    this.commentForm = new FormGroup({
      comment: new FormControl(null),
    });
    this.commentExcludeForm = new FormGroup({
      exclusionMotive: new FormControl('', [Validators.required]),
      excludeDuration: new FormControl(null, [Validators.required]),
      isPermanent: new FormControl(false),
      comment: new FormControl(null),
    });
  }

  async ngOnInit() {
    this.selectedDriverChangedSubscription =
      this.driversService.selectedDriverChanged.subscribe((driver: Driver) => {
        this.driver = driver;
        this.setDriverSituation();
      });

    this.isAdmin = this.usersService.isAdmin;
    this.isApprover = this.usersService.isApprover;
    this.isCarrier = this.usersService.isCarrier;
    await this.updateSelectedDriver();

    // Subscripción a actualizaciones del conductor
    this.onUpdateDriverListener = this.api
      .OnUpdateDriverListener(
        this.driversService.getSelectedDriverFilterForSubscriptions()
      )
      .subscribe((response) => {
        if (response) {
          this.driversService.refreshSelectedDriver();
        }
      });

    // Suscripción a la variable que indica si se están cargando documentos.
    this.isUploadingDocumentsSubscription =
      this.documentsService.isUploadingDocuments.subscribe(
        (value: boolean): void => {
          this.isUploadingDocuments = value;
        }
      );
  }

  /**
   * Actualiza el conductor seleccionado.
   * @return {Promise}
   * @private
   */
  private async updateSelectedDriver(): Promise<void> {
    this.isGettingDriver = true;
    await this.driversService.refreshSelectedDriver().then(() => {
      this.setDriverSituation();
      this.isGettingDriver = false;
    });
  }

  /**
   * Ejecuta el modal de aprobación de un conductor y pide su
   * actualización en caso de ser aprobado.
   * @return {Promise}
   */
  async onApproveDriver(): Promise<void> {
    this.initCommentForm();
    const driverIdentification =
      (this.driver.sapId! === 'Sin Asignar'
        ? this.driver.sapId
        : parseInt(this.driver.sapId!)) +
      ' - ' +
      this.driver.firstName +
      ' ' +
      this.driver.lastName;
    this.modalTitle = appConstants.driver.modalMessages.approve.title;
    this.modalQuestion =
      appConstants.driver.modalMessages.approve.question.replace(
        '_',
        driverIdentification
      );
    this.modalMessage = appConstants.driver.modalMessages.approve.message;
    this.modalWithComment = true;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.driverModal
    );

    if (modalResult) {
      this.isGettingDriver = true;
      await this.updateDriver('approve', this.commentForm.value.comment);
    }
  }

  /**
   * Ejecuta el modal de rechazo de un conductor y pide su
   * actualización en caso de ser rechazado.
   * @return {Promise}
   */
  async onRejectDriver(): Promise<void> {
    this.isBeingRejected = true;
    this.initCommentForm();
    const driverIdentification =
      (this.driver.sapId! === 'Sin Asignar'
        ? this.driver.sapId
        : parseInt(this.driver.sapId!)) +
      ' - ' +
      this.driver.firstName +
      ' ' +
      this.driver.lastName;
    this.modalTitle = appConstants.driver.modalMessages.reject.title;
    this.modalQuestion =
      appConstants.driver.modalMessages.reject.question.replace(
        '_',
        driverIdentification
      );
    this.modalMessage = appConstants.driver.modalMessages.reject.message;
    this.modalWithComment = true;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.driverModal
    );

    if (modalResult) {
      this.isGettingDriver = true;
      const newNotification = this.commentForm.value;
      const today = new Date();
      const todayTimestamp = Math.floor(today.getTime() / 1000).toString();
      const creatNotificationInput: CreateNotificationInput = {
        businessId: this.driver.business,
        notificationId: 'DRIVER#' + this.driver.driverId + '#' + todayTimestamp,
        status: 'TO_BE_SENT',
        model: 'DRIVER',
        motive: 'APPROVE#REJECTED',
        driverId: this.driver.driverId,
        comment: newNotification.comment,
      };

      await this.api
        .CreateNotification(creatNotificationInput)
        .then(async () => {
          await this.updateDriver('reject', newNotification.comment);
        });
    }

    this.isBeingRejected = false;
  }

  /**
   * Actualiza un conductor.
   * @param {string} action Acción a realizar, puede ser 'approve' o 'reject'.
   * @param {string} comment Comentario de aprobación o rechazo.
   * @return {Promise}
   * @private
   */
  private async updateDriver(
    action: 'approve' | 'reject',
    comment: string | null = null
  ): Promise<void> {
    this.isGettingDriver = true;
    let newDriverStatus: string;
    let feedbackMessage: string = `Conductor ${this.driver.firstName} ${this.driver.lastName} `;
    let catchFeedbackMessage: string = `Error al _ conductor ${this.driver.firstName} ${this.driver.lastName}`;

    switch (action) {
      case 'approve':
        // Se cambia el estado del conductor a "Procesando"
        newDriverStatus = `${this.driver.business}_${appConstants.entity.codes.inProcess}`;
        feedbackMessage = feedbackMessage + 'aprobado. Actualizando';
        catchFeedbackMessage = catchFeedbackMessage.replace('_', 'aprobar');
        break;
      case 'reject':
        // Se cambia el estado del conductor a "Rechazado"
        newDriverStatus = `${this.driver.business}_${appConstants.entity.codes.rejected}`;
        feedbackMessage = feedbackMessage + 'rechazado. Enviando notificación';
        catchFeedbackMessage = catchFeedbackMessage.replace('_', 'rechazar');
        break;
    }

    const updateDriverInput: UpdateDriverInput = {
      driverId: this.driver.driverId,
      status: newDriverStatus,
      comment: comment,
    };

    await this.api
      .UpdateDriver(updateDriverInput)
      .then(async (driver: UpdateDriverMutation) => {
        this.feedbacksService.showFeedback(feedbackMessage, 'info');

        if (action === 'approve') {
          // Se cambia el estado de los documentos con estado "Por Aprobar"
          // al estado "Procesando"
          for (let document of driver.documents!.items) {
            if (
              document?.status ===
              `${driver.business}_${appConstants.document.codes.toBeApproved}`
            ) {
              const updateDocumentInput: UpdateDocumentInput = {
                documentId: document.documentId,
                name: document.name,
                status: `${driver.business}_${appConstants.document.codes.inProcess}`,
              };
              this.documentsService
                .updateDocument(updateDocumentInput)
                .then(() => console.log('Documento actualizado'));
            }
          }
        }

        // Actualizamos el conductor seleccionado.
        await this.updateSelectedDriver();
      })
      .catch((response: any): void => {
        this.feedbacksService.showErrorFeedbacks(
          response,
          catchFeedbackMessage
        );
      });
  }

  /**
   * Navega al formulario de edición del conductor.
   */
  async onEditDriver(): Promise<void> {
    const editionId: string = `${this.usersService.business.value.toUpperCase()}#DRIVER#${
      this.driver.driverId
    }`;

    if (await this.editionsService.editionIsTaken(editionId)) {
      return;
    }

    this.router
      .navigate(['edit'], { relativeTo: this.route })
      .then(() => console.log('navigate to edit'));
  }

  /**
   * Ejecuta el modal de eliminación de un conductor y lo
   * elimina en caso de aceptar.
   * @return {Promise}
   */
  async onDeleteDriver(): Promise<void> {
    this.modalTitle = appConstants.driver.modalMessages.delete.title;
    this.modalQuestion =
      appConstants.driver.modalMessages.delete.question.replace(
        '_',
        this.driver.sapId!
      );
    this.modalMessage = appConstants.driver.modalMessages.delete.message;
    this.modalWithComment = false;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.driverModal
    );

    if (modalResult) {
      // 1. Se elimina el conductor.
      const deleteDriverInput: DeleteDriverInput = {
        driverId: this.driver.driverId,
      };
      await this.api
        .DeleteDriver(deleteDriverInput)
        .then(async () => {
          this.feedbacksService.showFeedback(
            `Conductor ${this.driver.sapId!} eliminado.`,
            'success'
          );

          // 2. Se elimina el usuario de Cognito.
          this.feedbacksService.showFeedback(
            `Eliminando usuario ${this.driver.email}.`,
            'info'
          );
          const deleteUserInput: DeleteUserInput = {
            userId: this.driver.email,
          };

          await this.api
            .DeleteUser(deleteUserInput)
            .then(() => {
              this.feedbacksService.showFeedback(
                `Usuario ${this.driver.email} eliminado.`,
                'success'
              );
            })
            .catch((response: any): void => {
              this.feedbacksService.showErrorFeedbacks(
                response,
                `Error al eliminar usuario ${this.driver.email}`
              );
            });
        })
        .catch((response: any): void => {
          this.feedbacksService.showErrorFeedbacks(
            response,
            `Error al borrar conductor ${this.driver.sapId}`
          );
        });
      this.router
        .navigate(['/', this.driver.business.toLowerCase(), 'drivers'])
        .then(() => console.log('navigate to drivers'));
    }
  }

  /**
   * Llama a refrescar el conductor seleccionado.
   */
  onRefreshDriver(): void {
    this.updateSelectedDriver().then(() =>
      console.log('Conductor seleccionado actualizado.')
    );
  }

  /**
   * Colapsa o muestra el comentario del vehículo.
   */
  onViewComment(): void {
    this.showComment = !this.showComment;
  }

  /**
   * Retorna la clase que colapsa o muestra el comentario del vehículo.
   * @return {string} Clase CSS
   */
  showCommentClass(): string {
    return this.showComment ? 'show' : '';
  }

  /**
   * Inicializa el formulario de rechazo de un conductor.
   * @private
   */
  private initCommentForm(): void {
    // Inicialización del formulario
    this.commentForm = this.formsService.generateCommentForm(
      this.isBeingRejected
    );
  }

  /**
   * Indica si un conductor tiene documentos por vencer.
   * @return {boolean} Boolean que indica si el conductor tiene documentos por vencer.
   */
  hasToExpireDocuments(): boolean {
    const docNumber = this.driver.documentsToExpire || 0;
    return docNumber > 0;
  }

  /**
   * Indica si un conductor tiene documentos vencidos.
   * @return {boolean} Boolean que indica si el conductor tiene documentos vencidos.
   */
  hasExpiredDocuments(): boolean {
    const docNumber = this.driver.documentsExpired || 0;
    return docNumber > 0;
  }

  /**
   * Retorna una clase CSS dependiendo de la edad del Conductor.
   * @return {string}
   */
  driverAgeClass(): string {
    return this.driverInAgeRange ? '' : 'text-danger';
  }

  /**
   * Retorna un color dependiendo del estado del envasado.
   * @return {string}
   */
  getColor(): string {
    return this.masterService.getSatusColor(this.driver.status);
  }

  /**
   * Retorna la fuente de imagen a mostrar en el Estado del envasado.
   * @return {string} ruta a imagen.
   */
  getImageSrc(status: string): string {
    return this.masterService.getImageSrc(status);
  }

  /**
   * Determina, con base al rol del usuario, cuáles botones mostrar.
   * @param {string} button Acción del botón.
   */
  showButton(button: string): boolean {
    let show: boolean = false;

    switch (button) {
      case 'delete':
        show = this.isAdmin;
        break;
      case 'approve':
      case 'reject':
        show = this.isAdmin || this.isApprover;
        break;
      case 'edit':
        show = this.isAdmin || this.isCarrier;
        break;
      case 'block':
        show = (this.isAdmin || this.isApprover) && !this.checkBlockMotives();
        break;
      case 'unlock':
        show = (this.isAdmin || this.isApprover) && this.checkBlockMotives();
        break;
      default:
        break;
    }
    return show;
  }

  /**
   * Define la situación del conductor: ¿necesita aprobación?, ¿puede ser rechazado?,
   * ¿Está bloqueado?
   */
  private setDriverSituation(): void {
    const driverToBeApprove: boolean =
      this.driver.status ===
      `${this.driver.business}_${appConstants.entity.codes.toBeApproved}`;
    const driverRejected: boolean =
      this.driver.status ===
      `${this.driver.business}_${appConstants.entity.codes.rejected}`;
    const birthYear: number = new Date(
      this.driver.birthDate || ''
    ).getFullYear();
    const birthMonth: number = new Date(this.driver.birthDate || '').getMonth();

    this.driverNeedsApproval = driverToBeApprove || driverRejected;
    this.driverCanBeRejected = driverToBeApprove;
    this.showBlockingMotives =
      this.driver.blockingMotives !== null &&
      this.driver.blockingMotives !== undefined &&
      this.driver.blockingMotives.length > 0 &&
      this.driver.status !==
        `${this.driver.business}_${appConstants.entity.codes.available}`;
    this.hasComment = !!this.driver.comment;
    this.showComment = false;
    this.driverAge = this.currentYear - birthYear;
    // Si aún no llegamos al mes de nacimiento, se resta un año
    if (this.currentMonth < birthMonth) {
      this.driverAge -= 1;
    }

    // El conductor debe tener entre 25 y 70 años.
    this.driverInAgeRange =
      appConstants.driver.age.min <= this.driverAge &&
      this.driverAge <= appConstants.driver.age.max;
  }
  /**
   *
   */
  async onExcludeDriver(): Promise<void> {
    this.commentExcludeForm.reset();
    const driverIdentification =
      (this.driver.sapId! === 'Sin Asignar'
        ? this.driver.sapId
        : parseInt(this.driver.sapId!)) +
      ' - ' +
      this.driver.firstName +
      ' ' +
      this.driver.lastName;
    this.modalExcludeMessage =
      appConstants.driver.modalMessages.exclude.message;
    this.modalExcludeQuestion =
      appConstants.driver.modalMessages.exclude.question.replace(
        '_',
        driverIdentification
      );
    this.modalExcludeTitle = appConstants.driver.modalMessages.exclude.title;
    let feedbackMessage: string = `Conductor ${this.driver.firstName} ${this.driver.lastName} bloqueado con éxito.`;
    let errorfeedbackMessage: string = `Conductor ${this.driver.firstName} ${this.driver.lastName} no pudo ser bloqueado. `;
    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.excludeModal
    );
    // respuesta afirmativa y valida del formulario
    if (modalResult) {
      let newDriverStatus = `${this.driver.business}_${appConstants.entity.codes.inProcess}`;
      const updateDriverInput: UpdateDriverInput = {
        driverId: this.driver.driverId,
        status: newDriverStatus,
      };
      let today = new Date().toISOString().split('T')[0];
      this.api
        .CreateDriverExclusion({
          driverId: this.driver.driverId,
          startDate: today,
          endDate: this.commentExcludeForm.value.isPermanent
            ? '2100-12-21'
            : this.commentExcludeForm.value.excludeDuration,
          exclusionMotives: this.commentExcludeForm.value.exclusionMotive,
          comment: this.commentExcludeForm.value.comment,
        })
        .then(async (response) => {
          await this.api.UpdateDriver(updateDriverInput).then(async () => {
            this.feedbacksService.showFeedback(feedbackMessage, 'info');
            await this.updateSelectedDriver();
          });
        })
        .catch((error) => {
          this.feedbacksService.showErrorFeedbacks(error, errorfeedbackMessage);
          console.error(error);
        });
    }
  }
  /**
   *
   */
  async onUnlockDriver(): Promise<void> {
    //config modal
    this.modalTitle = appConstants.driver.modalMessages.unlock.title;
    this.modalQuestion =
      appConstants.driver.modalMessages.unlock.question.replace(
        '_',
        `${this.driver.sapId!} - ${this.driver.firstName} ${
          this.driver.lastName
        }`
      );
    this.modalMessage = appConstants.driver.modalMessages.unlock.message;
    this.modalWithComment = false;
    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.driverModal
    );
    //configuracion para el servicio.
    let newDriverStatus = `${this.driver.business}_${appConstants.entity.codes.inProcess}`;
    const updateDriverInput: UpdateDriverInput = {
      driverId: this.driver.driverId,
      status: newDriverStatus,
      comment: '',
    };
    let feedbackMessage: string = `Conductor ${this.driver.firstName} ${this.driver.lastName} desbloqueado con éxito.`;
    let errorfeedbackMessage: string = `Conductor ${this.driver.firstName} ${this.driver.lastName} no pudo ser desbloqueado. `;
    // se acepta el modal y se llama  a los servicios
    if (modalResult) {
      this.api
        .DeleteDriverExclusion({
          driverId: this.driver.driverId,
        })
        .then(async (response) => {
          await this.api.UpdateDriver(updateDriverInput).then(async () => {
            this.feedbacksService.showFeedback(feedbackMessage, 'info');
            await this.updateSelectedDriver();
          });
        })
        .catch((error) => {
          this.feedbacksService.showErrorFeedbacks(error, errorfeedbackMessage);
          console.error(error);
        });
    }
  }

  checkBlockMotives(): boolean {
    return (
      this.driver.blockingMotives?.some(
        (blockMotiveDriver) => blockMotiveDriver === 'Lista de Exclusión'
      ) || false
    );
  }

  ngOnDestroy(): void {
    this.selectedDriverChangedSubscription.unsubscribe();
    this.onUpdateDriverListener.unsubscribe();
    this.isUploadingDocumentsSubscription.unsubscribe();
    console.log('driver-details.component subscriptions removed.');
  }
}
