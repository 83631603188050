import { Injectable } from '@angular/core';
import { HeaderButtonsList } from '../shared/interfaces/header-buttons-list';
import { BehaviorSubject } from 'rxjs';
import { UsersService } from '../pages/users/users.service';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private buttonsState: HeaderButtonsList = {
    users: false,
    drivers: false,
    vehicles: false,
    envasados: false,
    cisternas: false,
    tractos: false,
    tanques: false,
    semirremolques: false,
    couplings: false,
    'rtc-evaluate': false,
    'rtc-historial': false,
  };

  selectedButtonChanged = new BehaviorSubject<HeaderButtonsList>(
    this.buttonsState
  );

  constructor(private usersService: UsersService) {}

  /**
   * Configura el botón activo del header y retorna el path de navegación.
   * @param {keyof HeaderButtonsList} linkName
   * @return {string[]} path de navegación
   */
  setSelectedButton(linkName: keyof HeaderButtonsList): string[] {
    // Reseteamos todos los botones
    Object.keys(this.buttonsState).forEach((key: string): void => {
      this.buttonsState[<keyof HeaderButtonsList>key] = false;
    });
    // Fijamos el botón seleccionado como activo
    this.buttonsState[linkName] = true;
    // Emitimos el evento de cambio
    this.selectedButtonChanged.next(this.buttonsState);

    let path: string[] = ['/'];
    if (!linkName.includes('rtc')) {
      path = path.concat([this.usersService.business.value, linkName]);
    } else {
      path = path.concat(linkName.split('-'));
    }
    return path;
  }

  /**
   * Retorna el estado de los botones del header.
   * @return {HeaderButtonsList}
   */
  getButtonsState(): HeaderButtonsList {
    return { ...this.buttonsState };
  }
}
