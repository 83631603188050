import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { UserBlockedComponent } from './user-blocked/user-blocked.component';

@NgModule({
  declarations: [PageNotFoundComponent, UserBlockedComponent],
  imports: [CommonModule],
})
export class ErrorPagesModule {}
