import { RtcEvaluationsRoutingModule } from './rtc-evaluations-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { DocumentsModule } from '../documents/documents.module';
import { MasterModule } from '../master/master.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { RtcEvaluationsComponent } from './rtc-evaluations.component';
import { RtcHistorialComponent } from './rtc-historial/rtc-historial.component';
import { RtcEvaluationComponent } from './rtc-evaluation/rtc-evaluation.component';
import { DataTablesModule } from 'angular-datatables';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  declarations: [
    RtcEvaluationsComponent,
    RtcHistorialComponent,
    RtcEvaluationComponent,
  ],
  imports: [
    CommonModule,
    RtcEvaluationsRoutingModule,
    DocumentsModule,
    MasterModule,
    NgbAccordionModule,
    RouterModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    NgOptimizedImage,
    DataTablesModule,
    PdfViewerModule,
  ],
})
export class RtcEvaluationsModule {}
