import { Component } from '@angular/core';

@Component({
  selector: 'app-tanques',
  templateUrl: './tanques.component.html',
  styleUrls: ['./tanques.component.css'],
})
export class TanquesComponent {
  equipName: string = 'Tanques';
}
