<div class="modal-header">
  <h4 class="modal-title" id="update-attr-modal-basic-title">
    {{ modalTitle }}
  </h4>
  <button
    id="cisterna-details-update-attr-modal-close"
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="d('Cross')"
  ></button>
</div>
<div class="modal-body">
  <p>
    <strong>{{ modalQuestion }}</strong>
  </p>
  <p>{{ modalMessage }}</p>
  <form [formGroup]="attributeForm">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm">
          <label for="center" class="form-label"
            >Centro de Apoyo <span class="text-danger fs-4">*</span></label
          >
          <div class="form-group">
            <select id="center" formControlName="center" class="form-select">
              <option value="" selected>Seleccionar...</option>
              <option
                *ngFor="let center of master.CENTERS"
                [ngValue]="center.valueId"
              >
                {{ center.valueToDisplay }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    id="cisterna-details-update-attr-modal-cancel"
    type="button"
    class="btn btn-outline-danger"
    (click)="d('Cancel')"
  >
    Cancelar
  </button>
  <button
    id="cisterna-details-update-attr-modal-accept"
    type="button"
    class="btn btn-outline-primary"
    (click)="c('Accept')"
    [disabled]="!attributeForm.valid"
  >
    Aceptar
  </button>
</div>
