<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ modalTitle }}</h4>
  <button
    id="details-modal-close"
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="d('Cross')"
  ></button>
</div>
<div class="modal-body">
  <p>
    <strong>{{ modalQuestion }}</strong>
  </p>
  <p>{{ modalMessage }}</p>
  <form [formGroup]="commentForm" *ngIf="showCommentForm">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm">
          <label for="comment" class="form-label"
            >Comentario
            <span class="text-danger fs-4" *ngIf="mandatoryComment"
              >*</span
            ></label
          >
          <div class="form-group">
            <input
              type="text"
              id="comment"
              formControlName="comment"
              class="form-control immutable-field"
              placeholder="máximo 300 caracteres"
              appInputTrim
            />
            <div
              *ngIf="
                showHelper(notificationControl.get('comment')) &&
                mandatoryComment
              "
            >
              <small class="form-text text-muted">
                {{ helperMessages(notificationControl.get("comment")) }}
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    id="details-modal-cancel"
    type="button"
    class="btn btn-outline-danger"
    (click)="d('Cancel')"
  >
    Cancelar
  </button>
  <button
    id="details-modal-accept"
    type="button"
    class="btn btn-outline-primary"
    (click)="c('Accept')"
    [disabled]="!commentForm.valid"
  >
    Aceptar
  </button>
</div>
