import { Component } from '@angular/core';

import { User } from 'src/app/app-sync.service';
import { UsersService } from 'src/app/pages/users/users.service';

@Component({
  selector: 'app-user-blocked',
  templateUrl: './user-blocked.component.html',
  styleUrls: ['./user-blocked.component.css'],
})
export class UserBlockedComponent {
  activeUser: User;
  userEmail: string;

  constructor(private usersService: UsersService) {
    this.activeUser = this.usersService.getActiveUser();
    this.userEmail = this.activeUser.email;
  }
}
