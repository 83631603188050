import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  /**
   * Transforma el formato de un número telefónico desde +56987654321 a +56 9 8765 4321.
   * @param {string} value Número telefónico en formato +56987654321.
   * @return {string} Número telefónico en formato +56 9 8765 4321.
   */
  transform(value: string): string {
    return [
      value.slice(0, 3),
      value.slice(3, 4),
      value.slice(4, 8),
      value.slice(8),
    ].join(' ');
  }
}
