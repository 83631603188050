import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ZenObservable } from 'zen-observable-ts';

import { appConstants } from '../../../shared/constants/constants';

import { APIService, Tracto } from '../../../app-sync.service';
import { TractosService } from '../tractos.service';
import { UsersService } from '../../users/users.service';
import { DataTableDirective } from 'angular-datatables';
import { DocumentsService } from '../../documents/documents.service';
import { MasterService } from '../../master/master.service';

@Component({
  selector: 'app-tractos-list',
  templateUrl: './tractos-list.component.html',
  styleUrls: ['./tractos-list.component.css'],
})
export class TractosListComponent implements OnInit, OnDestroy {
  equipName: string = 'Tractos';
  dtOptions: DataTables.Settings = {};
  isGettingTractos: boolean = false;
  isAdmin: boolean = false;
  isApprover: boolean = false;
  isViewer: boolean = false;
  isCarrier: boolean = false;
  tractos: Tracto[];
  tractosAttributes: string[] = [
    'SAP ID',
    'Estado',
    'Patente',
    'Transportista',
    'Centro',
    'Alertas',
    'Bloqueos',
  ];

  //Esto permite crear un filtro de búsqueda personalizado en el datatable
  searchText: string = '';
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;

  private tractosChangedSubscription: Subscription = new Subscription();
  private onDeleteTractoListener: ZenObservable.Subscription =
    new Subscription();
  private onCreateTractoListener: ZenObservable.Subscription =
    new Subscription();
  private onUpdateTractoListener: ZenObservable.Subscription =
    new Subscription();

  constructor(
    private tractosService: TractosService,
    private api: APIService,
    private router: Router,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private documentService: DocumentsService,
    private masterService: MasterService
  ) {
    this.dtOptions = appConstants.datatables.options;
    this.tractos = this.tractosService.getTractos();
  }

  async ngOnInit(): Promise<void> {
    this.tractosChangedSubscription =
      this.tractosService.tractosChanged.subscribe((tractos: Tracto[]) => {
        this.tractos = tractos;
      });

    this.isAdmin = this.usersService.isAdmin;
    this.isApprover = this.usersService.isApprover;
    this.isViewer = this.usersService.isViewer;
    this.isCarrier = this.usersService.isCarrier;

    await this.updateTractosList();

    // Una vez cargada la lista de vehículos definimos los filtros para subscripciones
    const subscriptionTractosFilter =
      this.tractosService.getTractosFilterForSubscriptions();
    // Subscripción a Tractos eliminados
    this.onDeleteTractoListener = this.api
      .OnDeleteTractoListener(subscriptionTractosFilter)
      .subscribe((response) => {
        if (response) {
          this.tractosService.refreshTractos();
        }
      });
    // Subscripción a Tractos actualizados
    this.onUpdateTractoListener = this.api
      .OnUpdateTractoListener(subscriptionTractosFilter)
      .subscribe((response) => {
        if (response) {
          this.tractosService.refreshTractos();
        }
      });
    // Subscripción a Tractos creados
    this.onCreateTractoListener = this.api
      .OnCreateTractoListener(subscriptionTractosFilter)
      .subscribe((response) => {
        if (response) {
          this.tractosService.refreshTractos();
        }
      });
  }

  /**
   * Navega a al formulario de creación de Tracto.
   */
  onNewTracto(): void {
    this.router
      .navigate(['new'], { relativeTo: this.route })
      .then(() => console.log('navigate to new'));
  }

  /**
   * Llama al método que refresca la lista de Tractos.
   * @return {Promise}
   */
  async onRefresh(): Promise<void> {
    this.searchText = '';
    await this.updateTractosList();
  }

  /**
   * Define el tracto que está seleccionado.
   * y navega al detalle del mismo.
   * @param {Tracto} tracto Tracto seleccionado de la lista.
   */
  onSelectedTracto(tracto: Tracto): void {
    this.documentService.isRefreshFilter.next(true);
    this.tractosService.setSelectedTracto(tracto);
    this.router
      .navigate([tracto.tractoId], { relativeTo: this.route })
      .then(() => console.log(`navigate to ${tracto.tractoId}`));
  }

  /**
   * Indica si un tracto tiene documentos por vencer.
   * @param {Tracto} tracto Tracto de la lista.
   * @return {boolean} Boolean que indica si el Tracto tiene documentos por vencer.
   */
  hasToExpireDocuments(tracto: Tracto): boolean {
    const docNumber = tracto.documentsToExpire || 0;
    return docNumber > 0;
  }

  /**
   * Indica si un tracto tiene documentos vencidos.
   * @param {Tracto} tracto Tracto de la lista.
   * @return {boolean} Boolean que indica si el Tracto tiene documentos vencidos.
   */
  hasExpiredDocuments(tracto: Tracto): boolean {
    const docNumber = tracto.documentsExpired || 0;
    return docNumber > 0;
  }

  /**
   * Retorna la fuente de imagen a mostrar en el Estado del Tracto.
   * @return {string} ruta a imagen.
   */
  getImageSrc(status: string): string {
    return this.masterService.getImageSrc(status);
  }

  /**
   * Refresca la lista de tractos.
   * @private
   * @return {Promise}
   */
  private async updateTractosList(): Promise<void> {
    this.isGettingTractos = true;
    await this.tractosService.refreshTractos().then((): void => {
      this.isGettingTractos = false;
    });
  }

  /**
   * Determina, con base al rol del usuario, cuáles botones mostrar.
   * @param {string} button Acción del botón.
   * @return {boolean}
   */
  showButton(button: string): boolean {
    let show: boolean = false;

    if (button === 'create') {
      show = this.isAdmin || this.isCarrier;
    }
    return show;
  }

  /**
   * Determina, con base al rol del usuario, si debe ocultarse una columna de la tabla.
   * @param {string} column Nombre de la columna.
   * @return {Boolean}
   */
  hideColumn(column: string): boolean {
    let hide: boolean = false;

    if (column === 'Transportista') {
      hide = !(this.isAdmin || this.isApprover || this.isViewer);
    }
    return hide;
  }

  /**
   * Aplica a la tabla el filtro ingresado por el usuario.
   */
  applyFilter(): void {
    if (this.dtElement) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api): void => {
        dtInstance.search(this.searchText).draw();
      });
    }
  }

  ngOnDestroy(): void {
    this.tractosChangedSubscription.unsubscribe();
    this.onDeleteTractoListener.unsubscribe();
    this.onCreateTractoListener.unsubscribe();
    this.onUpdateTractoListener.unsubscribe();
    console.log('tractos-list.component subscriptions removed.');
  }
}
