import { Injectable } from '@angular/core';
import { RozApiResponse } from '../interfaces/roz-api-response';
import { FeedbackClasses } from '../interfaces/feedback-classes';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { FeedbacksService } from '../feedbacks/feedbacks.service';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { RTCHistoryFileParams } from 'src/app/shared/interfaces/rtc-parameters';

@Injectable({
  providedIn: 'root',
})
export class ApiRequestsService {
  requestSucceeded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private endpoint: string = environment.api;

  constructor(
    private feedbacksService: FeedbacksService,
    private httpClient: HttpClient
  ) {}

  /**
   * Actualiza atributos de una entidad en SAP y Roz.
   * @param {string} entityBusiness Negocio de la entidad.
   * @param {string} entityType Tipo de entidad.
   * @param {string} entityIdentification Identificación para los feedbacks "<sapId> - <entityId>".
   * @param {{ [key: string]: string }} payload Payload con los atributos a modificar.
   */
  updateEntity(
    entityBusiness: string,
    entityType: string,
    entityIdentification: string,
    payload: { [key: string]: string }
  ): void {
    this.feedbacksService.showFeedback(
      `Actualizando, por favor espere.`,
      'info'
    );

    const pathParams: string = `${entityBusiness}/${entityType}/${
      entityIdentification.split(' - ')[1]
    }`;

    this.httpClient
      .patch<RozApiResponse>(`${this.endpoint}/entity/${pathParams}`, payload)
      .subscribe({
        next: (response: RozApiResponse): void => {
          let feedbackMessage: string = response.message;
          let feedbackType: keyof FeedbackClasses = 'info';

          if (response.success && response.message.includes('exitosa')) {
            feedbackMessage = `Éxito actualizando ${entityType} ${entityIdentification}.`;
            feedbackType = 'success';
            this.requestSucceeded.next(true);
          }
          this.feedbacksService.showFeedback(feedbackMessage, feedbackType);
        },
        error: (error): void => {
          const errorMessage: string = (<HttpErrorResponse>error).error.message;
          let feedbackMessage: string = ` ${entityType} ${entityIdentification}: ${errorMessage}`;
          let feedbackMessageType: 'info' | 'danger' = 'danger';

          if ((<HttpErrorResponse>error).status === 400) {
            feedbackMessage = 'No se actualizó' + feedbackMessage;
            feedbackMessageType = 'info';
          } else {
            feedbackMessage = 'Error al actualizar' + feedbackMessage;
          }
          this.feedbacksService.showFeedback(
            feedbackMessage,
            feedbackMessageType
          );
        },
      });
  }

  /**
   * Realiza la petición de una URL pre-firmada para la descarga de un archivo
   * Excel con los datos de Roz.
   * @param {string} business Negocio
   * @param {string} models Modelos de datos a descargar
   */
  downloadExcelFile(business: string, models: string): void {
    this.feedbacksService.showFeedback(
      `Descargado datos, por favor espere.`,
      'info'
    );

    const options: { params: HttpParams } = {
      params: new HttpParams().set('business', business).set('models', models),
    };

    this.httpClient
      .get<RozApiResponse>(`${this.endpoint}/file`, options)
      .subscribe({
        next: (response: RozApiResponse): void => {
          if (response.success) {
            // Verificamos si no hay datos
            if (response.data && Object.keys(response.data).length === 0) {
              this.feedbacksService.showFeedback(response.message, 'danger');
              return;
            }

            this.downloadFromPreSignedURL(response.data!.url);
          }
        },
        error: (error): void => {
          const errorMessage: string = (<HttpErrorResponse>error).error.message;
          this.feedbacksService.showFeedback(
            `Error al descargar archivo: ${errorMessage}`,
            'danger'
          );
        },
      });
  }

  /**
   * Recibe una URL pre-firmada de descarga y descargar un documento.
   * @param {string} url URL pre-firmada
   */
  private downloadFromPreSignedURL(url: string): void {
    this.httpClient
      .get(url, { responseType: 'blob', headers: { 'without-token': 'true' } })
      .subscribe((blob: Blob) => {
        const urlArchivo: string = window.URL.createObjectURL(blob);
        // Agregamos manualmente la extensión del archivo al nombre del archivo
        const nombreArchivo: string =
          url.split('?', 1)[0].split('/').pop() || 'datos_roz.xlsx';
        const a: HTMLAnchorElement = document.createElement('a');
        a.href = urlArchivo;
        a.download = nombreArchivo;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        this.feedbacksService.showFeedback(
          `Datos descargados correctamente.`,
          'success'
        );
      });
  }

  /**
   * Realiza la petición de una URL pre-firmada para la descarga de un archivo
   * Excel con los datos de Roz Historial RTC.
   * @param {RTCHistoryFileParams}parameters  interfaz creada por error limite de parametros sonar
   * /rtc-historial/{business}/{year_month}
   */
  downloadExcelRTCHistoryFile(parameters: RTCHistoryFileParams): void {
    this.feedbacksService.showFeedback(
      `Descargado datos historial RTC, por favor espere.`,
      'info'
    );
    //Destructuracion de parametros por sonar Error
    const {
      license_plate,
      carrier,
      center,
      sap_id,
      status,
      business,
      startDate,
      endDate,
    } = parameters;
    let httpParams = new HttpParams();
    const params = { carrier, center, sap_id, status, license_plate };
    for (const [key, value] of Object.entries(params)) {
      if (value) {
        httpParams = httpParams.set(key, value);
      }
    }

    this.httpClient
      .get<RozApiResponse>(
        `${
          this.endpoint
        }/rtc-historial/${business.toUpperCase()}/${startDate}/${endDate}`,
        { params: httpParams }
      )
      .subscribe({
        next: (response: RozApiResponse): void => {
          if (response.success) {
            // Verificamos si no hay datos
            if (!response.data) {
              this.feedbacksService.showFeedback(response.message, 'danger');
            }
            this.downloadFromPreSignedURL(response.data!.url);
          }
        },
        error: (error): void => {
          const errorMessage: string = (<HttpErrorResponse>error).error.message;
          this.feedbacksService.showFeedback(
            `Error al descargar archivo: ${errorMessage}`,
            'danger'
          );
        },
      });
  }
}
