import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../../auth/auth.guard';
import { MasterResolver } from '../master/master.resolver';

import { TanquesComponent } from './tanques.component';
import { TanqueNoSelectedComponent } from './tanque-no-selected/tanque-no-selected.component';
import { TanqueEditComponent } from './tanque-edit/tanque-edit.component';
import { TanqueDetailsComponent } from './tanque-details/tanque-details.component';

const routes: Routes = [
  {
    path: ':business/tanques',
    component: TanquesComponent,
    canActivate: [AuthGuard],
    resolve: [MasterResolver],
    children: [
      { path: '', component: TanqueNoSelectedComponent },
      { path: 'new', component: TanqueEditComponent },
      { path: ':id', component: TanqueDetailsComponent },
      { path: ':id/edit', component: TanqueEditComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TanquesRoutingModule {}
