import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../../auth/auth.guard';
import { MasterResolver } from '../master/master.resolver';

import { TractosComponent } from './tractos.component';
import { TractoNoSelectedComponent } from './tracto-no-selected/tracto-no-selected.component';
import { TractoEditComponent } from './tracto-edit/tracto-edit.component';
import { TractoDetailsComponent } from './tracto-details/tracto-details.component';

const routes: Routes = [
  {
    path: ':business/tractos',
    component: TractosComponent,
    canActivate: [AuthGuard],
    resolve: [MasterResolver],
    children: [
      { path: '', component: TractoNoSelectedComponent },
      { path: 'new', component: TractoEditComponent },
      { path: ':id', component: TractoDetailsComponent },
      { path: ':id/edit', component: TractoEditComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TractosRoutingModule {}
