<div class="row">
  <div class="text-center my-5" *ngIf="isGettingDriver">
    <app-spinner
      [message]="
        editMode ? 'Cargando detalles del conductor' : 'Cargando formulario'
      "
    ></app-spinner>
  </div>
  <div class="col-xs-12" *ngIf="!isGettingDriver">
    <h3>{{ editMode ? "Editar" : "Nuevo" }} Conductor</h3>
    <form [formGroup]="driverForm" (ngSubmit)="onSubmit()">
      <div class="container-fluid">
        <h4>
          Transportista
          <span *ngIf="!editMode" class="text-danger fs-4">*</span>
        </h4>
        <div class="row">
          <div class="col-sm">
            <div class="form-group">
              <select
                *ngIf="isCarrier"
                id="driver-carrier"
                formControlName="carrier"
                class="form-select"
              >
                <option [value]="carrierValueId()" selected>
                  {{ carrierValueToDisplay() }}
                </option>
              </select>
              <select
                *ngIf="isAdmin"
                id="carrier"
                formControlName="carrier"
                class="form-select"
              >
                <option value="" selected>Seleccionar...</option>
                <option
                  *ngFor="let carrier of master.CARRIERS"
                  [ngValue]="carrier.valueId"
                >
                  {{ carrier.valueToDisplay }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="container-fluid">
        <h4>Conductor</h4>
        <div class="row">
          <div class="col-sm-6">
            <label for="email" class="form-label"
              >E-mail
              <span *ngIf="!editMode" class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <input
                type="email"
                id="email"
                formControlName="email"
                class="form-control immutable-field"
                placeholder="Ejemplo: micorreo@empresa.com"
              />
            </div>
          </div>
          <div class="col-sm-6">
            <label for="driver-rut" class="form-label"
              >RUT
              <span *ngIf="!editMode" class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <input
                type="text"
                id="driver-rut"
                formControlName="rut"
                class="form-control immutable-field"
                placeholder="Ejemplo: 12.345.678-9"
                appInputUppercase
              />
            </div>
            <small
              id="driver-rut-helper"
              class="form-text text-muted"
              *ngIf="!editMode"
              >Con puntos y guión</small
            >
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <label for="firstName" class="form-label"
              >Nombre
              <span *ngIf="!editMode" class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <input
                type="text"
                id="firstName"
                formControlName="firstName"
                class="form-control immutable-field"
                placeholder="Ejemplo: Pedro"
                appInputTrim
              />
            </div>
          </div>
          <div class="col-sm-6">
            <label for="lastName" class="form-label"
              >Apellido
              <span *ngIf="!editMode" class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <input
                type="text"
                id="lastName"
                formControlName="lastName"
                class="form-control immutable-field"
                placeholder="Ejemplo: Pérez"
                appInputTrim
              />
            </div>
          </div>
        </div>
        <div class="row my-2">
          <div class="col-sm-4">
            <label for="birthDate" class="form-label"
              >Fecha de Nacimiento
              <span *ngIf="!editMode" class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <input
                type="date"
                id="birthDate"
                formControlName="birthDate"
                class="form-control"
                (change)="onBirthDateChange()"
              />
              <div *ngIf="showHelper(driverControl.get('birthDate'))">
                <small class="form-text text-muted">
                  {{ helperMessages(driverControl.get("birthDate")) }}
                </small>
              </div>
            </div>
          </div>
          <div class="col-sm-2">
            <label for="noAnInput" class="form-label">Edad</label>
            <div class="form-group">
              <input
                type="text"
                id="noAnInput"
                class="form-control immutable-field"
                placeholder="{{ driverAge }} años"
                disabled
              />
            </div>
          </div>
          <div class="col-sm-6">
            <label for="phone" class="form-label"
              >Teléfono
              <span *ngIf="!editMode" class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <input
                type="text"
                id="phone"
                formControlName="phone"
                class="form-control immutable-field"
                placeholder="Ejemplo: +56987654321"
              />
            </div>
            <small id="rut" class="form-text text-muted" *ngIf="!editMode"
              >Con código de país</small
            >
          </div>
        </div>
        <div class="row">
          <div class="col-sm">
            <label for="center" class="form-label"
              >Centro
              <span *ngIf="!editMode" class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <select
                id="center"
                formControlName="center"
                class="form-select"
                (change)="onDriverCenterChange()"
              >
                <option value="" selected>Seleccionar...</option>
                <option
                  *ngFor="let center of master.CENTERS"
                  [ngValue]="center.valueId"
                >
                  {{ center.valueToDisplay }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="container-fluid">
        <h5>
          <i class="material-symbols-outlined align-middle">description</i>
          Documentos del Conductor
        </h5>
        <div class="alert alert-warning" role="alert">
          <i class="material-symbols-outlined align-middle">warning</i> Solo se
          aceptan documentos en formato PDF.
        </div>
        <div class="col-xs-12" formArrayName="documents">
          <div
            class="row border-top"
            id="vehicle-documents"
            *ngFor="let documentControl of documentsControl; let i = index"
            [formGroupName]="i"
          >
            <div class="row my-2">
              <div class="col-sm-11">
                <input
                  type="text"
                  id="documentName"
                  class="form-control-plaintext form-control-lg"
                  formControlName="documentName"
                  readonly
                />
              </div>
              <div
                class="col-sm-1"
                *ngIf="
                  !editMode &&
                  isMandatoryDocument(
                    documentControl.get('documentMasterValueId')?.value
                  )
                "
              >
                <p class="text-danger fs-4">*</p>
              </div>
            </div>
            <div
              *ngIf="
                editMode &&
                invalidControl(documentControl.get('documentExpirationDate'))
              "
            >
              <small class="form-text text-danger">
                Documento vencido, es necesario actualizar.
              </small>
            </div>
            <div
              class="row"
              *ngIf="
                !isDatelessDocument(
                  documentControl.get('documentMasterValueId')?.value
                )
              "
            >
              <label for="documentIssueDate" class="col-sm-2 col-form-label"
                >Emisión</label
              >
              <div class="col-sm-4">
                <input
                  type="date"
                  id="documentIssueDate"
                  formControlName="documentIssueDate"
                  class="form-control"
                />
                <div
                  *ngIf="showHelper(documentControl.get('documentIssueDate'))"
                >
                  <small class="form-text text-muted">
                    {{
                      helperMessages(documentControl.get("documentIssueDate"))
                    }}
                  </small>
                </div>
              </div>
              <label
                for="documentExpirationDate"
                class="col-sm-2 col-form-label"
                >Vencimiento</label
              >
              <div class="col-sm-4">
                <input
                  type="date"
                  id="documentExpirationDate"
                  formControlName="documentExpirationDate"
                  class="form-control"
                />
                <div
                  *ngIf="
                    showHelper(documentControl.get('documentExpirationDate'))
                  "
                >
                  <small class="form-text text-muted">
                    {{
                      helperMessages(
                        documentControl.get("documentExpirationDate")
                      )
                    }}
                  </small>
                </div>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-sm-12">
                <input
                  type="file"
                  accept=".pdf"
                  id="documentFile"
                  formControlName="documentFile"
                  class="form-control"
                  (change)="onChangeDocumentFile($event, i)"
                />
                <div *ngIf="showHelper(documentControl.get('documentFile'))">
                  <small class="form-text text-muted">
                    {{ helperMessages(documentControl.get("documentFile")) }}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-xs-12">
          <button
            id="driver-edit-cancel"
            type="button"
            class="btn btn-outline-danger"
            (click)="onCancel()"
          >
            Cancelar
          </button>
          <button
            id="driver-edit-show"
            type="button"
            class="btn btn-outline-secondary mx-2"
            (click)="showForm()"
            *ngIf="isDev"
          >
            Log Form
          </button>
          <button
            id="driver-edit-submit"
            type="submit"
            class="btn btn-outline-primary mx-2 float-end"
            [disabled]="!driverForm.valid"
          >
            {{ editMode ? "Guardar" : "Crear Conductor" }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
