<div class="row" *ngIf="isGettingDocuments">
  <div class="text-center my-5">
    <app-spinner message="Cargando documentos"></app-spinner>
  </div>
</div>
<div
  class="container-fluid"
  *ngIf="documents.length === 0 && !isGettingDocuments"
>
  <div class="row">
    <div class="col-sm-12 text-center my-1">
      <div class="alert alert-info" role="alert">
        <h4 class="alert-heading">Entidad sin documentos</h4>
        <hr />
        <p>
          No se encontraron documentos asociados a esta entidad en la base de
          datos.
        </p>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="documents.length > 0 && !isGettingDocuments">
  <div class="" style="padding-top: 10px; padding-bottom: 10px; display: flex">
    <input
      placeholder="Buscar"
      class="input-search-datatable"
      id="custom-search-input"
      type="text"
      (input)="applyFilter()"
      [(ngModel)]="searchText"
      autocomplete="off"
    />
    <select
      style="
        width: 100%;
        height: 35px;
        border-radius: 35px;
        border: 2px solid #b3c6ea;
        outline: none;
        text-align: center;
        margin-right: 10px;
      "
      id="statusDocument"
      required
      (change)="applyFilter()"
      [(ngModel)]="status"
    >
      <option value="0">Todos</option>
      <option *ngFor="let v of statusDocuments" [value]="v.status">
        {{ v.name }}
      </option>
    </select>
  </div>

  <h5>
    <i class="material-symbols-outlined align-middle">description</i>
    Documentos
  </h5>
  <div
    id="documents-list-group"
    class="col-xs-12 overflow-auto"
    *ngIf="!isGettingDocuments"
  >
    <ul class="list-group">
      <app-document-item
        *ngFor="let document of documents"
        [document]="document"
        [masterReference]="masterReference"
      >
      </app-document-item>
    </ul>
  </div>
</div>
