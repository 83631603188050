import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FormsModule } from '@angular/forms';

import { MasterModule } from '../master/master.module';
import { SharedModule } from '../../shared/shared.module';

import { DocumentItemComponent } from './document-item/document-item.component';
import { DocumentsComponent } from './documents.component';
import { DocumentsRoutingModule } from './documents-routing.module';

@NgModule({
  declarations: [DocumentsComponent, DocumentItemComponent],
  imports: [
    CommonModule,
    DocumentsRoutingModule,
    MasterModule,
    SharedModule,
    PdfViewerModule,
    FormsModule,
  ],
  exports: [DocumentsComponent, DocumentItemComponent],
})
export class DocumentsModule {}
