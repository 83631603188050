<div class="container-fluid my-2">
  <div class="row">
    <div class="col-md-6">
      <app-users-list></app-users-list>
    </div>
    <div class="col-md-6">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
