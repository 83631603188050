<hr />
<h3 class="mb-4">Acoplar Vehículos</h3>
<div class="row text-center">
  <div class="col-sm-5">
    <div class="card text-center">
      <div class="card-header">Tracto</div>
      <div class="card-body">
        <h5 class="card-title">
          {{ selectedTracto.sapId! || "" | sapId }} -
          {{ selectedTracto.licensePlate }}
        </h5>
      </div>
    </div>
  </div>
  <div class="col-sm-2 justify-content-center my-4">
    <button
      id="members-coupling-acoplar"
      type="button"
      class="btn btn-primary"
      [disabled]="!canBeCoupled()"
      *ngIf="showButton('couple')"
      (click)="onCoupling()"
    >
      Acoplar
    </button>
  </div>
  <div class="col-sm-5">
    <div class="card text-center">
      <div class="card-header">
        {{ coupleTypeSelected }}
      </div>
      <div class="card-body">
        <h5 class="card-title">
          {{ selectedCouple.sapId! || "" | sapId }} -
          {{ selectedCouple.licensePlate }}
        </h5>
      </div>
    </div>
  </div>
</div>
<div class="alert alert-info mt-3 p-2" role="alert">
  <i class="material-symbols-outlined align-middle">info</i> Use las listas de
  abajo para seleccionar vehículos a acoplar.
</div>
<hr />
<ul class="nav nav-tabs">
  <li class="nav-item">
    <a
      id="members-coupling-tractos"
      class="nav-link"
      [ngClass]="getTapClass('tractos')"
      (click)="onTractosSelected()"
    >
      Tractos
      <span
        *ngIf="tractosTapSelected"
        class="material-symbols-outlined fs-6 align-middle"
      >
        refresh
      </span>
    </a>
  </li>
  <li class="nav-item">
    <a
      id="members-coupling-tanques"
      class="nav-link"
      [ngClass]="getTapClass('tanques')"
      (click)="onTanquesSelected()"
    >
      Tanques
      <span
        *ngIf="tanquesTapSelected"
        class="material-symbols-outlined fs-6 align-middle"
      >
        refresh
      </span>
    </a>
  </li>
  <li *ngIf="showTabs('semirremolques')" class="nav-item">
    <a
      id="members-coupling-semirremolques"
      class="nav-link"
      [ngClass]="getTapClass('semirremolques')"
      (click)="onSemirremolquesSelected()"
    >
      Semirremolques
      <span
        *ngIf="semirremolquesTapSelected"
        class="material-symbols-outlined fs-6 align-middle"
      >
        refresh
      </span>
    </a>
  </li>
</ul>
<div class="row py-2">
  <div class="text-center my-5" *ngIf="isGettingVehicles">
    <app-spinner message="Cargando Vehículos..."></app-spinner>
  </div>
  <div class="col-sm-12 py-2">
    <div
      class="table-responsive-sm"
      *ngIf="!isGettingVehicles && tractosTapSelected"
    >
      <table
        datatable
        [dtOptions]="dtOptions"
        class="table display nowrap"
        style="width: 100%"
      >
        <caption>
          Lista de Tractos Desacoplados
        </caption>
        <thead>
          <tr class="text-center">
            <th scope="col" *ngFor="let attribute of tractosAttributes">
              {{ attribute }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let tracto of decoupledTractos">
            <td>
              <a
                id="tractos-list-{{ tracto.licensePlate }}"
                (click)="onSelectedTracto(tracto)"
                style="color: #0d6efd"
              >
                {{ tracto.sapId! | sapId }} - {{ tracto.licensePlate }}
              </a>
            </td>
            <td>
              <span>
                <img
                  [ngSrc]="getImageSrc(tracto.status)"
                  height="25"
                  width="25"
                  alt="Estado"
                />
              </span>
              {{ tracto.status | master : "VEHICLES#STATUSES" }}
            </td>
            <td>{{ tracto.center | master : "CENTERS" }}</td>
            <td>
              <span
                class="badge border rounded-4 text-bg-light mx-1"
                *ngFor="let blockingMotive of tracto.blockingMotives"
              >
                {{ blockingMotive }}</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="col-sm-12 py-2">
    <div
      class="table-responsive-sm"
      *ngIf="!isGettingVehicles && tanquesTapSelected"
    >
      <table
        datatable
        [dtOptions]="dtOptions"
        class="table display nowrap"
        style="width: 100%"
      >
        <caption>
          Lista de Tanques Desacoplados
        </caption>
        <thead>
          <tr class="text-center">
            <th scope="col" *ngFor="let attribute of coupleAttributes">
              {{ attribute }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let tanque of decoupledTanques">
            <td>
              <a
                id="tractos-list-{{ tanque.licensePlate }}"
                (click)="onSelectedCouple(tanque)"
                style="color: #0d6efd"
              >
                {{ tanque.sapId! | sapId }} - {{ tanque.licensePlate }}
              </a>
            </td>
            <td>
              <span>
                <img
                  [ngSrc]="getImageSrc(tanque.status)"
                  height="25"
                  width="25"
                  alt="Estado"
                />
              </span>
              {{ tanque.status | master : "VEHICLES#STATUSES" }}
            </td>
            <td>{{ tanque.center | master : "CENTERS" }}</td>
            <td>{{ tanque.capacity | master : "TANQUES#CAPACITIES" }}</td>
            <td>
              <span
                class="badge border rounded-4 text-bg-light mx-1"
                *ngFor="let blockingMotive of tanque.blockingMotives"
              >
                {{ blockingMotive }}</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="col-sm-12 py-2">
    <div
      class="table-responsive-sm"
      *ngIf="!isGettingVehicles && semirremolquesTapSelected"
    >
      <table
        datatable
        [dtOptions]="dtOptions"
        class="table display nowrap"
        style="width: 100%"
      >
        <caption>
          Lista de Semirremolques Desacoplados
        </caption>
        <thead>
          <tr class="text-center">
            <th scope="col" *ngFor="let attribute of coupleAttributes">
              {{ attribute }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let semirremolque of decoupledSemirremolques">
            <td>
              <a
                id="tractos-list-{{ semirremolque.licensePlate }}"
                (click)="onSelectedCouple(semirremolque)"
                style="color: #0d6efd"
              >
                {{ semirremolque.sapId! | sapId }} -
                {{ semirremolque.licensePlate }}
              </a>
            </td>
            <td>
              <span>
                <img
                  [ngSrc]="getImageSrc(semirremolque.status)"
                  height="25"
                  width="25"
                  alt="Estado"
                />
              </span>
              {{ semirremolque.status | master : "VEHICLES#STATUSES" }}
            </td>
            <td>{{ semirremolque.center | master : "CENTERS" }}</td>
            <td>
              {{
                semirremolque.capacity | master : "SEMIRREMOLQUES#CAPACITIES"
              }}
            </td>
            <td>
              <span
                class="badge border rounded-4 text-bg-light mx-1"
                *ngFor="let blockingMotive of semirremolque.blockingMotives"
              >
                {{ blockingMotive }}</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!-- Modals -->
<ng-template #couplingModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ modalTitle }}</h4>
    <button
      id="members-coupling-modal-close"
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="d('Cross')"
    ></button>
  </div>
  <div class="modal-body">
    <p>
      <strong>{{ modalQuestion }}</strong>
    </p>
    <p>{{ modalMessage }}</p>
  </div>
  <div class="modal-footer">
    <button
      id="members-coupling-modal-cancel"
      type="button"
      class="btn btn-outline-danger"
      (click)="d('Cancel')"
    >
      Cancelar
    </button>
    <button
      id="members-coupling-modal-accept"
      type="button"
      class="btn btn-outline-primary"
      (click)="c('Accept')"
    >
      Aceptar
    </button>
  </div>
</ng-template>
